import { ReactElement } from 'react';
import LogoutPrompt from '@Components/Authentication/LogoutPrompt';
import VerifyUser from '@Components/Authentication/VerifyUser';
import UserForm from '@Components/Users/ModalContent/UserForm';
import DeleteLayer from '@Components/DataPage/OverlayLayers/ModalContent/DelateLayer';
import ChangePassword from '@Components/Authentication/ChangePassword';
import SendNotification from '@Components/TriggerMessage/SendNotification';
import ViewPhotos from '@Views/Notification/NotificationPlayground/ViewPhotos';

export type ModalContentsType =
  | 'sign-up-success'
  | 'add-user'
  | 'edit-user'
  | 'verify-user'
  | 'change-password'
  | 'view-inncident-photos'
  | null;
export type PromptDialogContentsType =
  | 'delete-layer'
  | 'confirm-logout'
  | 'send-notification'
  | null;

type ModalReturnType = {
  title: string;
  subtitle?: string;
  iconName?: string;
  content: ReactElement;
  className?: string;
  hideCloseButton?: boolean;
};

export function getModalContent(content: ModalContentsType): ModalReturnType {
  switch (content) {
    case 'add-user':
      return {
        title: 'Add User',
        subtitle: 'Please fill up the details to add user',
        content: <UserForm />,
      };
    case 'edit-user':
      return {
        title: 'Edit User',
        subtitle: 'Please fill up the details to edit user',
        content: <UserForm />,
      };
    case 'verify-user':
      return {
        title: 'Verify Your Account',
        content: <VerifyUser />,
      };
    case 'change-password':
      return {
        title: 'Change Password',
        content: <ChangePassword />,
      };
    case 'view-inncident-photos':
      return {
        title: 'Incident Photos',
        className: '!w-fit !h-fit',
        content: <ViewPhotos />,
      };
    default:
      return {
        title: '',
        content: <></>,
      };
  }
}

export function getPromptDialogContent(
  content: PromptDialogContentsType,
): ModalReturnType {
  switch (content) {
    case 'delete-layer':
      return { title: 'Delete', content: <DeleteLayer /> };
    case 'confirm-logout':
      return {
        iconName: 'error_outline',
        title: 'Confirm Logout',
        content: <LogoutPrompt />,
      };
    case 'send-notification':
      return {
        iconName: 'check_circle',
        title: 'Alert Sent',
        content: <SendNotification />,
      };
    default:
      return {
        title: '',
        content: <></>,
      };
  }
}
