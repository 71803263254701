import DastaaLogo from '@Assets/images/dastaa-full-logo.svg';
import Image from '@Components/RadixComponents/Image';
import { Flex } from '../Layouts';

export default function Fallback() {
  return (
    <div className="absolute top-0 h-[100vh] w-[100vw] overflow-hidden bg-grey-200">
      <Flex className="h-full items-center justify-center">
        <Image src={DastaaLogo} width={200} className="animate-pulse" />
      </Flex>
    </div>
  );
}
