import A4Paper from '@Components/common/A4Paper';
import { FlexColumn } from '@Components/common/Layouts';
import InfoSection from '../InfoSection';
import RiskAndMap from '../RiskAndMap';
import EmergencySteps from '../EmergencySteps';
import ParticipationDecision from '../ParticipationDecision';
import CheckList from '../CheckList';
import CheckListDisabled from '../CheckListDisabled';
import CheckListPregnantWoman from '../CheckListPregnantWoman';
import CheckListOldPeople from '../CheckListOldPeople';
import CheckListDisease from '../CheckListDisease';
import TollFreeContact from '../TollFreeContact';
// import DescriptionSection from '../DescriptionSection';
import Footer from '../Footer';
import VulnerablePeople from '../VulnerablePeople';
import ContactSection from '../ContactSection';
import ImportanLinks from '../ImportantLinks';
import {
  AfterFloodPreparationSection,
  BeforeFloodPreparationSection,
  DuringFloodPreparationSection,
  FloodHeaderSection,
} from '../FloodPreparation';
import TrainingSteps from '../TrainingSteps';
import ActionsToAvoidFloodRisk from '../ActionsToAvoidFloodRisk';
import PalikaCapacity from '../PalikaCapacity';
// import { useTypedSelector } from '@Store/hooks';
// import RiskSteps from '../RiskSteps';
// import RiskCommunicationMedium from '../RiskCommunicationMedium';

interface IExportHDRMPProps {
  exportMode?: boolean;
}

export default function ExportHDPRP({ exportMode = false }: IExportHDRMPProps) {
  const numberOfPapers = 7;

  return (
    <div
      id="hdrmp-report"
      style={
        exportMode
          ? {
              clipPath: 'inset(0 100% 0 0)',
              transform: 'translateX(-100%)',
              position: 'absolute',
            }
          : {}
      }
    >
      <A4Paper pageNumber={`1/${numberOfPapers}`}>
        <FlexColumn gap={5}>
          <InfoSection />
          <RiskAndMap />
          <ActionsToAvoidFloodRisk />
          {/* <RiskCommunicationMedium /> */}
          <ImportanLinks />
        </FlexColumn>
      </A4Paper>

      <A4Paper pageNumber={`2/${numberOfPapers}`}>
        <FlexColumn gap={6} className="mt-5">
          <VulnerablePeople />
          <ParticipationDecision />
        </FlexColumn>
      </A4Paper>

      <A4Paper pageNumber={`3/${numberOfPapers}`} className="px-0">
        <div className="h-full w-full bg-[#3397F3]">
          <FloodHeaderSection />
          <BeforeFloodPreparationSection />
          <DuringFloodPreparationSection />
        </div>
      </A4Paper>

      <A4Paper
        className="relative flex flex-col gap-4 bg-white"
        pageNumber={`4/${numberOfPapers}`}
      >
        <div className="flex flex-col gap-4 pt-4 ">
          <AfterFloodPreparationSection />
          <TrainingSteps />
          <EmergencySteps />
        </div>
      </A4Paper>

      <A4Paper
        className="relative flex flex-col gap-4 bg-white"
        pageNumber={`5/${numberOfPapers}`}
      >
        <div className="mt-4 flex flex-col gap-4">
          {/* <ParticipationDecision /> */}
          <CheckList />
          <hr />
          <CheckListDisabled />
          <hr />
          <CheckListPregnantWoman />
          <hr />
          <CheckListOldPeople />
          <hr />
          <CheckListDisease />
        </div>
      </A4Paper>

      <A4Paper
        className="relative mt-5 flex flex-col gap-4 bg-white"
        pageNumber={`6/${numberOfPapers}`}
      >
        <div className="mt-4 flex flex-col gap-4">
          <TollFreeContact />
          <ContactSection />
          <PalikaCapacity />
        </div>
      </A4Paper>
      <A4Paper pageNumber={`7/${numberOfPapers}`}>
        <Footer />
      </A4Paper>

      {/* <A4Paper
        className="relative flex flex-col justify-center gap-4 bg-white"
        pageNumber={`11/${numberOfPapers}`}
      >
        <div className="mt-16">
          <ContactSection />
        </div>
      </A4Paper>
      <A4Paper
        className="relative flex flex-col justify-center gap-4 bg-white"
        pageNumber={`12/${numberOfPapers}`}
      >
        <div className="mt-14 flex flex-col gap-14">
          <TollFreeContact />
          <div className="px-5">
            <DescriptionSection />
            <Footer />
          </div>
        </div>
      </A4Paper>
      <A4Paper
        className="relative flex flex-col justify-center gap-4 bg-white"
        pageNumber={`13/${numberOfPapers}`}
      >
        <div className="mt-16">
          <CheckList />
        </div>
      </A4Paper> */}
      {/* <A4Paper
        className="relative flex flex-col justify-center gap-4 bg-white"
        pageNumber={`14/${numberOfPapers}`}
      >
        <div className="mt-10 flex flex-col gap-5">
          <CheckListDisabled />
          <CheckListPregnantWoman />
          <CheckListOldPeople />
          <CheckListDisease />
        </div>
      </A4Paper> */}
      {/* <A4Paper
        className="relative flex flex-col justify-center gap-4 bg-white"
        pageNumber={`2/${numberOfPapers}`}
      >
        <FlexColumn gap={4}>
          {vulnerableStatus?.high_risk ||
            vulnerableStatus?.medium_risk ||
            (vulnerableStatus?.low_risk && <RiskSteps />)}

          {(vulnerableStatus?.disability ||
            vulnerableStatus?.pregnent_women ||
            vulnerableStatus?.old_person ||
            vulnerableStatus?.ill_ness) && <VulnerablePeople />}
        </FlexColumn>
      </A4Paper> */}
    </div>
  );
}
