import { useEffect } from 'react';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useGetTriggerMessageTemplateQuery } from '@Api/triggerMessage';
import { SidebarSection, TemplateSection } from '@Components/TriggerMessage';
import AddAlertTemplate from '@Components/TriggerMessage/AddAlertTemplate';
import { setTriggerMessageState } from '@Store/actions/triggerMessage';

export default function TriggerMessage() {
  const dispatch = useTypedDispatch();

  const templateType = useTypedSelector(
    state => state.triggerMessage.templateType,
  );

  const activeAlertTab = useTypedSelector(
    state => state.triggerMessage.activeAlertTab,
  );

  const { isLoading } = useGetTriggerMessageTemplateQuery({
    onSuccess: (res: any) => {
      dispatch(
        setTriggerMessageState({
          triggerMessageTemplateData: res,
        }),
      );
      const activeTabId = activeAlertTab ? res[res.length - 1]?.id : res[0]?.id;
      dispatch(setTriggerMessageState({ activeAlertTab: activeTabId }));
    },
  });

  useEffect(() => {
    dispatch(
      setTriggerMessageState({
        triggerMessageTemplateDataLoading: isLoading,
      }),
    );
  }, [dispatch, isLoading]);

  const getTemplateContent = () => {
    switch (templateType) {
      case 'send-notifcation':
        return <TemplateSection />;
      case 'add-alert-template':
        return <AddAlertTemplate />;
      default:
        return <TemplateSection />;
    }
  };

  return (
    <FlexColumn className="h-screen w-full px-5 ">
      <h3 className="py-5">Trigger Message</h3>
      <FlexRow gap={10} className="h-full">
        <SidebarSection />
        {getTemplateContent()}
      </FlexRow>
    </FlexColumn>
  );
}
