import Image from '@Components/RadixComponents/Image';
import LoginBanner from '@Assets/images/login-banner.svg';

const SignInBanner = () => {
  return (
    <Image
      src={LoginBanner}
      alt="banner"
      className="h-full w-full object-cover"
      styleClass="h-full w-full"
    />
  );
};

export default SignInBanner;
