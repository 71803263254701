import { useGetTriggerMessageTemplatePaginatedQuery } from '@Api/triggerMessage';
import useIntersectionObserver from '@Hooks/useIntersectionObserver';
import NotificationCard, {
  NotificationCardSkeleton,
} from '@Views/Notification/NotificationPlayground/NotificationCard';
import { useEffect } from 'react';

export default function NotificationList() {
  const {
    data: notifications,
    isLoading: isNotificationLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetTriggerMessageTemplatePaginatedQuery({});

  const [isIntersecting, parentRef, viewRef] = useIntersectionObserver();

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, hasNextPage, fetchNextPage, notifications]);

  return (
    <div ref={parentRef} className="flex flex-col gap-3">
      <h4>Notifications</h4>
      <div className="flex  max-h-[80svh]  flex-col gap-1 ">
        {isNotificationLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <NotificationCardSkeleton key={index} />
            ))
          : notifications?.pages?.map((page: any) => {
              return page?.data?.results?.map(
                (notification: Record<string, any>) => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                  />
                ),
              );
            })}

        {isFetchingNextPage &&
          Array.from({ length: 2 }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <NotificationCardSkeleton key={index} />
          ))}
      </div>

      {hasNextPage && !isNotificationLoading && !isFetchingNextPage && (
        <div className="w-full bg-red-600 text-center" ref={viewRef}>
          {!hasNextPage && 'No more notifications.'}
        </div>
      )}
    </div>
  );
}
