import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '../Layouts';

type InfoDialogProps = {
  status?: string;
  title?: string;
  className?: string;
  description?: string;
  onClose?: () => void;
};

const getStatus = (status: string | undefined) => {
  switch (status) {
    case 'info':
      return { icon: 'info', bgColor: 'bg-primary-600' };
    case 'success':
      return {
        icon: 'check_circle',
        bgColor: 'bg-primary-50',
        iconColor: 'text-[#40B449] !text-4xl',
      };
    case 'error':
      return {
        icon: 'cancel',
        bgColor: 'bg-red-400 opacity-90',
        iconColor: 'text-white',
        textColor: 'text-white',
      };
    default:
      return { icon: 'info', bgColor: 'bg-primary-100' };
  }
};

export default function InfoDialog({
  status,
  title,
  description,
  onClose,
  className,
}: InfoDialogProps) {
  const infoStatus = getStatus(status);

  return (
    <FlexRow
      className={`${infoStatus.bgColor} w-full
      items-center justify-between rounded-md px-3 py-1 ${className}`}
    >
      <FlexRow gap={3} className="items-center ">
        <Icon name={infoStatus.icon} className={`${infoStatus.iconColor}`} />
        <FlexColumn className={infoStatus.textColor}>
          <h5 className="text-sm">{title}</h5>
          <div className="text-sm">{description}</div>
        </FlexColumn>
      </FlexRow>
      <Icon
        name="close"
        className={`text-grey-600 hover:text-primary-600 ${infoStatus.textColor}`}
        onClick={onClose}
      />
    </FlexRow>
  );
}
