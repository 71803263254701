import { ChartTypes } from '@Components/common/Charts/types';
import {
  getAvailableMobileNetwork,
  getAvailableMobileSet,
  getBankAccount,
  getBuildingTopology,
  getCheckToilet,
  getCookingSources,
  getDisabilityData,
  getEarlyWarningInformation,
  getElectricity,
  getEthnicityGroup,
  getFoodSufficiency,
  getHouseOfLeadership,
  getLifeInsurance,
  getNearbyMarket,
  getNearbySchool,
  getNearbyealthServices,
  getOpenSpaceDistance,
  getSexGender,
  getToiletType,
  getNearbyRoadType,
  getWaterResources,
  getWeatherForecastInformation,
  getNumberOfStoreys,
  getIncomeSource,
  getHHlevelVulnerability,
  getHHLevelRisk,
} from '@Services/dashboard';

export const wardOptions = [{ label: 'ward 1', value: 'ward_1' }];

export const featureCountData = [
  {
    id: 'total_no_of_house',
    title: 'Total No. of Households',
    count: 0,
    iconName: 'home',
  },
  {
    id: 'total_no_of_region',
    title: 'Surveyed wards',
    count: 0,
    iconName: 'fact_check',
  },
  {
    id: 'resources',
    title: 'Capacity & Resources',
    count: 0,
    iconName: 'settings_suggest',
  },
  {
    id: 'total_population',
    title: 'Total Population',
    count: 0,
    iconName: 'groups',
  },
];
const { SITE_NAME } = process.env;

export const getMunicipalCapacityCount = () => {
  switch (SITE_NAME) {
    case 'Dastaa':
      return 913;
    case 'Dastaa Bheemdatta':
      return 913;
    case 'Dastaa Parshuram':
      return 898;
    case 'Dastaa Dodahra':
      return 282;
    default:
      return 913;
  }
};

export const getName = () => {
  switch (SITE_NAME) {
    case 'Dastaa':
      return '';
    case 'Dastaa Bheemdatta':
      return 'Bhimdatta Municipality';
    case 'Dastaa Parshuram':
      return 'Parshuram Municipality';
    case 'Dastaa Dodahra':
      return 'Dodahra Municipality';
    case 'Dastaa Bangladesh':
      return 'Bangladesh';
    default:
      return '';
  }
};

export const dastaaUsersData = [
  { id: 'total_male', name: 'Total Male', color: '#01A0A2', value: 0 },
  { id: 'total_female', name: 'Total Female', color: '#FF9525', value: 0 },
  { id: 'other', name: 'Others', color: '#BDBDBD', value: 0 },
];

interface IContentType {
  id: string;
  title: string;
  chartType: ChartTypes;
  queryFn: any;
  params?: any;
  labelY?: string;
  wrapperClassName?: string;
  enable?: () => boolean;
}

interface IDashboarChartProps {
  title: string;
  id: string;
  contents: IContentType[];
  layout?: 'flex';
}

export const dashboardSummaryCharts: IContentType[] = [
  {
    id: 'hh-level-vulnerability',
    title: 'HH Level Vulnerability',
    chartType: 'donut',
    queryFn: getHHlevelVulnerability,
    params: { vulnerability_name: 'HVI' },
    wrapperClassName: '!h-[248px]',
  },

  {
    id: 'hh-level-risk',
    title: 'HH Level Risk',
    chartType: 'donut',
    queryFn: getHHLevelRisk,
    params: {
      risk_name:
        process.env.SITE_NAME === 'Dastaa Bangladesh' ? 'Risk INFORM1' : 'Risk',
    },
    wrapperClassName: '!h-[248px]',
  },
];

export const dashboardChart: Record<string, IDashboarChartProps> = {
  'general-information': {
    title: `General Information of ${getName()}`,
    id: 'general-information',
    contents: [
      // {
      //   id: 'age-of-res',
      //   title: 'Age of Respondents',
      //   chartType: 'bar',
      //   queryFn: getAgeOfRes,
      //   labelY: "Number of People's Age",
      //   wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      // },
      {
        id: 'available-mobile-set',
        title: 'Available mobile set',
        chartType: 'horizontalBar',
        queryFn: getAvailableMobileSet,
        labelY: 'Number of People',
        wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      },
      {
        id: 'available-mobile-network',
        title: 'Available mobile network',
        chartType: 'horizontalBar',
        queryFn: getAvailableMobileNetwork,
        wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      },
      {
        id: 'sex',
        title: 'Sex',
        chartType: 'horizontalBar',
        queryFn: getSexGender,
        wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      },
      {
        id: 'house-leadership',
        title: 'House Leadership',
        chartType: 'bar',
        queryFn: getHouseOfLeadership,
        labelY: 'Number of people',
        wrapperClassName: 'xl:col-span-6 xl:h-[290px]',
        enable: () => {
          return process.env.SITE_NAME !== 'Dastaa Bangladesh';
        },
      },
      {
        id: 'ethnicity',
        title: 'Ethnicity',
        chartType: 'bar',
        queryFn: getEthnicityGroup,
        labelY: 'Number of People',
        wrapperClassName: 'xl:col-span-6 xl:h-[290px]',
        enable: () => {
          return process.env.SITE_NAME !== 'Dastaa Bangladesh';
        },
      },
      {
        id: 'disability',
        title: 'Disability',
        chartType: 'bar',
        queryFn: getDisabilityData,
        labelY: 'Number of People',
        wrapperClassName: 'xl:col-span-6 xl:h-[290px]',
      },
    ],
  },
  accessibility: {
    title: `Accessibility of ${getName()}`,
    id: 'general-information',
    contents: [
      {
        id: 'early-warning-information',
        title: 'Early warning information',
        chartType: 'donut',
        queryFn: getEarlyWarningInformation,
        wrapperClassName: 'xl:col-span-2 gap-1 !h-[290px]',
      },
      {
        id: 'life-insurance',
        title: 'Life insurance',
        chartType: 'donut',
        queryFn: getLifeInsurance,
        wrapperClassName: 'xl:col-span-2 gap-1 !h-[290px]',
      },
      {
        id: 'bank-account',
        title: 'Bank account',
        chartType: 'donut',
        queryFn: getBankAccount,
        wrapperClassName: 'xl:col-span-2 gap-1 !h-[290px]',
      },
      {
        id: 'electricity',
        title: 'Electricity',
        chartType: 'donut',
        queryFn: getElectricity,
        wrapperClassName: 'xl:col-span-2 gap-1 !h-[290px]',
      },
      {
        id: 'weather-forecast-information',
        title: 'Weather forecast information',
        chartType: 'donut',
        queryFn: getWeatherForecastInformation,
        wrapperClassName: 'xl:col-span-2 gap-5 !h-[290px]',
      },
    ],
  },
  livelihood: {
    title: `Livelihood Information of ${getName()}`,
    id: 'livelihood-information',
    contents: [
      {
        id: 'food-sufficiency',
        title: 'Food sufficiency',
        chartType: 'horizontalBar',
        queryFn: getFoodSufficiency,
        wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      },
      {
        id: 'type-of-road-nearby',
        title: 'Type of road nearby',
        chartType: 'bar',
        queryFn: getNearbyRoadType,
        labelY: 'Number of Different Road types',
        wrapperClassName: 'xl:col-span-4 xl:h-[290px]',
        enable: () => {
          return process.env.SITE_NAME !== 'Dastaa Bangladesh';
        },
      },
      {
        id: 'cooking-sources',
        title: 'Distribution of Cooking Fuel Types',
        chartType: 'bar',
        queryFn: getCookingSources,
        labelY: 'Number of Cooking Sources',
        wrapperClassName: 'xl:col-span-3 xl:h-[290px]',
      },
      {
        id: 'types-of-toilet',
        title: 'Distribution of Sanitation Facilities by Type',
        chartType: 'bar',
        queryFn: getToiletType,
        labelY: 'Number of Toilet',
        wrapperClassName: 'xl:col-span-3 xl:h-[290px]',
      },
      {
        id: 'water-resources',
        title: 'Distribution of Water Sources by Type',
        chartType: 'bar',
        queryFn: getWaterResources,
        labelY: 'Number of Water Resources',
        wrapperClassName: 'xl:col-span-4 xl:h-[290px]',
      },
      {
        id: 'toilet',
        title: 'Toilet Usage Distribution',
        chartType: 'donut',
        queryFn: getCheckToilet,
        wrapperClassName: 'xl:col-span-2  xl:h-[290px]',
      },
    ],
  },
  building: {
    title: `Building information of ${getName()}`,
    id: 'building-information',
    contents: [
      {
        id: 'building-topography',
        title: 'Building topography',
        chartType: 'bar',
        queryFn: getBuildingTopology,
        labelY: 'Number of Building Topography',
        wrapperClassName: ' xl:col-span-6 xl:h-[290px]',
      },
      {
        id: 'number-of-storeys',
        title: 'Number of storeys',
        chartType: 'scatterChart',
        queryFn: getNumberOfStoreys,
        labelY: 'Number of storeys',
        wrapperClassName: 'xl:col-span-6 xl:h-[290px]',
      },
    ],
  },
  income: {
    title: `Income Source of ${getName()}`,
    id: 'income source',
    contents: [
      {
        id: 'source of income',
        title: 'Source of Income',
        chartType: 'bar',
        queryFn: getIncomeSource,
        labelY: 'Number of People',
        wrapperClassName: 'xl:col-span-6 xl:h-[290px]',
      },
    ],
  },
  // flood: {
  //   title: `Flood occurance of ${getName()}`,
  //   id: 'flood-occurance',
  //   contents: [
  //     {
  //       id: 'frequency-of-flood',
  //       title: 'Frequency of flood occurrence',
  //       chartType: 'scatterChart',
  //       queryFn: getFrequencyOfFloodOccurrence,
  //       labelY: 'Frequency of Flood Occurrence',
  //       wrapperClassName: 'xl:col-span-6 xl:h-[290px]',
  //     },
  //   ],
  // },
  proximity: {
    title: `Proximity information of ${getName()}`,
    id: 'proximity-information',
    contents: [
      {
        id: 'house-school',
        title: 'House & school',
        chartType: 'YLabelHorizontalBar',
        queryFn: getNearbySchool,
        wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      },
      {
        id: 'house-market',
        title: 'House & market',
        chartType: 'YLabelHorizontalBar',
        queryFn: getNearbyMarket,
        wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      },
      {
        id: 'house-health',
        title: 'House & health services',
        chartType: 'YLabelHorizontalBar',
        queryFn: getNearbyealthServices,
        wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      },
      {
        id: 'house-open',
        title: 'House & open space',
        chartType: 'YLabelHorizontalBar',
        queryFn: getOpenSpaceDistance,
        wrapperClassName: 'xl:col-span-2 xl:h-[290px]',
      },
    ],
  },
};

export default wardOptions;
