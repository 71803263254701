import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTypedDispatch } from '@Store/hooks';
import { logoutUser } from '@Services/authentication';
import { togglePromptDialog } from '@Store/actions/common';
import { Button } from '@Components/RadixComponents/Button';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { removeCookie } from '@Utils/cookieUtils';

export default function LogoutPrompt() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      localStorage.clear();
      removeCookie('csrftoken');

      dispatch(togglePromptDialog());
      navigate('/login');
      window.location.reload();
    },
  });

  return (
    <FlexColumn className="text-sm">
      <p className=" text-grey-600">Are you sure you want to logout ?</p>
      <FlexRow gap={3} className="w-full justify-end pt-5">
        <Button
          variant="outline"
          onClick={() => dispatch(togglePromptDialog())}
        >
          Cancel
        </Button>
        <Button
          rightIcon="logout"
          withLoader
          isLoading={isLoading}
          onClick={() => mutate()}
          variant="destructive"
        >
          Logout
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
