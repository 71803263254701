/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { LngLatBoundsLike } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { baseUrl, geoserverUrlDastaa } from '@Services/index';
import {
  riskStyles,
  sitesStyles,
  userInputColor,
  userLocationColor,
  vulnerabilityStyles,
} from '@Constants/map';
import { useGetOverallSitesBboxQuery } from '@Api/common';
import useDebouncedLoadingState from '@Hooks/useDebouncedLoadingState';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  setVisualizationState,
  getOverlayLayersRequest,
} from '@Store/actions/visualization';
import '@Components/common/MapLibreComponents/map.css';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import MapFooter from '@Components/common/MapLibreComponents/MapFooter';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import MapLoader from '@Components/common/MapLibreComponents/MapLoader';
import VectorLayer from '@Components/common/MapLibreComponents/Layers/VectorLayer';
import CircleMarker from '@Components/common/MapLibreComponents/Layers/CircleMarker';
import MeasureTool from '@Components/common/MapLibreComponents/MeasureTool';
import WMSLayer from '@Components/common/MapLibreComponents/Layers/WMSLayer';
import OLVectorTileLayer from '@Components/common/MapLibreComponents/Layers/OLVectorTileLayer';
import MapPopup from '@Components/common/MapLibreComponents/Popup';
import MapTools from '../MapTools';
// import SearchFilter from './SearchFilter';
// import FilterSection from '../FilterSection';
import MapExportSection from './ExportSection';

export default function MapSection() {
  const dispatch = useTypedDispatch();

  const [isSitesLayerLoaded, setIsSitesLayerLoaded] = useState(false);
  useState(false);
  const [isSitesRegionBoundaryLoaded, setIsSitesRegionBoundaryLoaded] =
    useState(false);

  const activeBaseLayer = useTypedSelector(
    state => state.visualization.activeBaseLayer,
  );
  const is3DToggled = useTypedSelector(
    state => state.visualization.is3DToggled,
  );
  // const showFilterSection = useTypedSelector(
  //   state => state.visualization.showFilterSection,
  // );
  const isExportOn = useTypedSelector(state => state.mapExport.isExportOn);
  const layers = useTypedSelector(state => state.visualization.layers);
  const layerData = layers.map(layer => layer.layers).flat();
  const geolocation = useTypedSelector(
    state => state.visualization.geolocation,
  );
  const zoomToLocationType = useTypedSelector(
    state => state.visualization.zoomToLocationType,
  );
  const inputCoordinates = useTypedSelector(
    state => state.visualization.inputCoordinates,
  );
  const measureType = useTypedSelector(
    state => state.visualization.measureType,
  );
  const measureFrom = useTypedSelector(
    state => state.visualization.measureFrom,
  );
  const selectedOption = useTypedSelector(
    state => state.visualization.visualizationOptions.selectedOption,
  );
  const activeTab = useTypedSelector(state => state.visualization.activeTab);
  const changeStyle = useTypedSelector(
    state => state.visualization.changeStyle,
  );
  const isLoginChecked = useTypedSelector(
    state => state.authentication.isLoginChecked,
  );

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 2,
      center: [80.0711, 28.994],
      pitch: 60,
      maxZoom: 19,
    },
    enable3D: is3DToggled,
  });

  // fetch overall sites bounding box
  const { data: overallSitesBbox } = useGetOverallSitesBboxQuery();

  // set setIsSitesLayerLoaded state
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'sites' || ev?.sourceId === 'overall-sites') {
        // setIsSitesLayerLoaded to true if ev has tile property
        setIsSitesLayerLoaded(!!ev?.tile);
      }
    });
  }, [map]);

  // set isSitesRegionBoundaryLoaded state
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'region-boundary') {
        // setIsSitesRegionBoundaryLoaded to true if ev has tile property
        setIsSitesRegionBoundaryLoaded(!!ev?.tile);
      }
    });
  }, [map]);

  // handle 3D toggle & pitch change
  useEffect(() => {
    if (!map || !isSitesLayerLoaded) return;
    map.setPaintProperty(
      'sites',
      'fill-extrusion-height',
      is3DToggled ? sitesStyles.paint['fill-extrusion-height'] : 0,
    );
    // set sites opacity to 1 on 3D toggle
    // map.setPaintProperty(
    //   'sites',
    //   'fill-extrusion-opacity',
    //   is3DToggled ? 1 : 0.9,
    // );
    setTimeout(() => {
      map.easeTo({
        duration: 1000,
        pitch: is3DToggled ? 60 : 0,
      });
    }, 10);
  }, [map, is3DToggled, isSitesLayerLoaded]);

  // zoom to sites extent on initial map load
  useEffect(() => {
    if (
      !map ||
      !isMapLoaded ||
      !overallSitesBbox ||
      Array.isArray(overallSitesBbox?.[0])
      // process.env.SITE_NAME === 'Dastaa Bangladesh'
    )
      return;
    map.fitBounds(overallSitesBbox as LngLatBoundsLike, { duration: 0 });
  }, [map, isMapLoaded, overallSitesBbox]);

  // change layer styles
  useEffect(() => {
    if (!map || !changeStyle.layerId) return;
    Object.entries(changeStyle.styles).forEach(([key, val]) => {
      map.setPaintProperty(changeStyle.layerId, key, val);
    });
  }, [map, changeStyle]);

  // cache geolocation coordinates
  const geolocationCoords: [number, number] | null = useMemo(
    () => (geolocation ? [geolocation.longitude, geolocation.latitude] : null),
    [geolocation],
  );

  // fetch overlay layer list
  useEffect(() => {
    if (!isLoginChecked) return;
    dispatch(getOverlayLayersRequest());
  }, [dispatch, isLoginChecked]);

  const isLoading = useDebouncedLoadingState(
    !isSitesLayerLoaded || !isMapLoaded || !isSitesRegionBoundaryLoaded,
  );

  return (
    <div className="relative w-full">
      {/* map loadder */}
      {isLoading && <MapLoader />}

      {/* <SearchFilter />
      {showFilterSection && (
        <div className="animate-fade-right">
          <FilterSection />
        </div>
      )} */}

      <MapContainer
        map={map}
        isMapLoaded={isMapLoaded}
        style={{ width: '100%', height: '100vh' }}
      >
        <BaseLayerSwitcher activeLayer={activeBaseLayer} />

        {/* reverse layers order for z-index */}
        {[...layerData]
          .reverse()
          .map(
            ({
              id,
              endPoint,
              styles,
              checked,
              type,
              geojson,
              showOutline,
              dynamicStyle,
              icon,
            }) =>
              type === 'geojson' ? (
                <VectorLayer
                  key={id}
                  id={id}
                  geojson={geojson}
                  layerOptions={styles}
                  visibleOnMap={checked}
                  showOutline={showOutline}
                />
              ) : type === 'overlay-layer' ? (
                <OLVectorTileLayer
                  key={id}
                  id={`overlay-layer-${id}`}
                  visibleOnMap={checked}
                  layers={dynamicStyle as Record<string, any>[]}
                  url={`${baseUrl}dastaa/layers/vector-layer-tile/{z}/{x}/{y}/?vector_layer_id=${id}`}
                  icon={icon}
                />
              ) : type === 'raster' ? (
                <WMSLayer
                  key={id}
                  map={map}
                  ismapLoaded={isMapLoaded}
                  id={id}
                  visibleOnMap={
                    id === 'flood_hazard_100_years' &&
                    activeTab === 'exposure_assessment'
                      ? true
                      : checked
                  }
                  wmsUrl={`${geoserverUrlDastaa}?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&${endPoint}`}
                />
              ) : // render vector tile for sites and overall-sites only
              id === 'sites' || id === 'overall-sites' ? (
                <VectorTileLayer
                  key={id}
                  id={id}
                  // send 'site=all' for overall sites only
                  url={`${baseUrl}${endPoint}/{z}/{x}/{y}.pbf?${
                    id === 'overall-sites' ? 'site=all' : ''
                  }`}
                  layerOptions={
                    selectedOption === 'risk' ? riskStyles : vulnerabilityStyles
                  }
                  visibleOnMap={checked}
                  showOutline={!is3DToggled}
                  interactions={['select']}
                />
              ) : (
                <VectorTileLayer
                  key={id}
                  id={id}
                  url={`${baseUrl}${endPoint}/{z}/{x}/{y}.pbf`}
                  layerOptions={styles}
                  visibleOnMap={checked}
                  showOutline={!is3DToggled}
                />
              ),
          )}

        <MapTools />

        <MapPopup enable={!measureType} />

        {/* map export section */}
        {isExportOn && <MapExportSection map={map} />}

        {/* plot circle marker on user location enable */}
        {geolocationCoords && (
          <CircleMarker
            id="geolocation"
            coordinates={geolocationCoords}
            zoomToLayer={zoomToLocationType === 'geolocation'}
            layerOptions={{
              type: 'circle',
              paint: {
                'circle-radius': 8,
                'circle-color': userLocationColor,
              },
            }}
          />
        )}

        {/* plot circle marker on user location enable */}
        {inputCoordinates.length && (
          <CircleMarker
            id="inputCoordinates"
            coordinates={[inputCoordinates[1], inputCoordinates[0]]}
            zoomToLayer={zoomToLocationType === 'user-input'}
            layerOptions={{
              type: 'circle',
              paint: {
                'circle-radius': 8,
                'circle-color': userInputColor,
              },
            }}
          />
        )}

        {/* measure tool */}
        <MeasureTool
          enable={!!measureType}
          measureType={measureType}
          onDrawChange={result => {
            dispatch(setVisualizationState({ measureResult: result }));
          }}
          onDrawComplete={data => {
            if (measureFrom !== 'exposure_assessment') return;
            dispatch(setVisualizationState({ drawnArea: data }));
          }}
        />

        <MapFooter />
      </MapContainer>
    </div>
  );
}
