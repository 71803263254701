import { formSchemaType } from '@Components/common/FormHelpers/types';

export const MAX_ZOOM: number = 22;
export const ZOOM_INTERVAL_ON_NEW_ADD: number = 4;

export const defaultLayerStyleValues: Record<string, any> = {
  'fill-color': '#0077E4',
  'fill-opacity': 0.8,
  'line-color': '#ff0000',
  'line-opacity': 0.5,
  'line-width': 1,
  'line-gap-width': 0,
  'circle-radius': 5,
  'circle-color': '#0077E4',
  'circle-stroke-color': '#ff0000',
  'circle-stroke-width': 1,
  'circle-stroke-opacity': 0.8,
  'circle-opacity': 0.8,
};

export const defaultLabelValues: Record<string, any> = {
  'text-opacity': 0.8,
  'text-halo-width': 0,
  'text-halo-color': '#ffffff',
  'text-size': 16,
  'text-color': '#000000',
  'text-offset': ['literal', [0, 0]],
};

export const layoutProperties = ['text-size', 'text-font', 'text-offset'];

export const overlayFormModal = {
  file_upload: null,
  display_name_en: '',
  category: '',
  description: '',
  is_public: false,
};
export const polygonGeomTypeResponses = ['Polygon', 'MultiPolygon'];
export const lineGeomTypeResponses = ['LineString', 'MultiLineString'];
export type OverlayLayersFormKeys = keyof typeof overlayFormModal;
export const overlayDataFormIndex: Record<number, string> = {
  0: 'upload data',
  // 1: 'attributes alias',
  // 2: 'public fields',
  1: 'layer styles',
  2: 'label styles',
};

export const overlayFormSchema: Record<string, formSchemaType> = {
  'upload data': [
    {
      id: 'file_upload',
      label: 'Upload Data',
      type: 'file-upload',
      required: true,
      placeholder: 'Please Upload your data (shape file)',
      fileAccept: '*',
    },
    {
      id: 'category',
      label: 'Category',
      type: 'select',
      placeholder: 'Choose Category',
      required: true,
    },
    {
      id: 'display_name_en',
      label: 'Layer Name',
      type: 'input',
      placeholder: 'Input Layer Name',
      required: true,
    },
    {
      id: 'description',
      label: 'Description',
      type: 'input',
      placeholder: 'Input Layer Description',
    },
    {
      id: 'is_public',
      label: 'Do you want to make this data public',
      type: 'switch',
    },
  ],
  // 'attributes alias': 'table',
  // 'public fields': 'table',
  'layer styles': 'map',
  'label styles': 'map',
};
