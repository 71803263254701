interface IUserAvatarProps {
  name: string;
  className?: string;
}

export default function UserAvatar({ name, className }: IUserAvatarProps) {
  const nameParts = name?.split(' ');
  const firstNameInitial = nameParts?.[0] ? nameParts?.[0][0] : '';

  return (
    <div
      className={`flex h-8 w-8 items-center justify-center rounded-full bg-primary-700 text-base font-semibold capitalize text-white ${className}`}
    >
      {firstNameInitial || '-'}
    </div>
  );
}
