/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback } from 'react';

type IDataProps = {
  id: number | string;
  title: string;
  disabled?: boolean;
  content?: JSX.Element;
};

interface ITabProps {
  data: IDataProps[];
  activeId?: string;
  contentClassName?: string;
  onChange: (value: any) => void;
  className?: string;
  activeTabClassName?: string;
}

export default function Tab({
  data,
  activeId,
  contentClassName,
  onChange,
  className,
  activeTabClassName,
}: ITabProps) {
  const [activeTabId, setActiveTabId] = useState(data[0]?.id || null);

  useEffect(() => {
    if (!activeId) return;
    setActiveTabId(activeId);
  }, [activeId]);

  const getTabContent = useCallback(
    (selected: any) => data.find(item => item.id === selected)?.content || '',
    [data],
  );

  return (
    <div className={`${className} h-full`}>
      <ul className="flex flex-wrap p-1.5">
        {data.map(({ id, title, disabled }) => (
          <li
            role="tab"
            key={id}
            onClick={() => {
              if (disabled) return;
              setActiveTabId(id);
              onChange(id);
            }}
          >
            <a
              className={`inline-block border-transparent
                px-3 py-1.5 text-body-btn ${
                  activeTabId === id
                    ? `border-b-2 border-b-primary-600 text-primary-600
                     ${activeTabClassName}`
                    : ' hover:text-primary-600 '
                } ${disabled ? 'cursor-not-allowed' : ''}`}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
      <div className={`${contentClassName}`}>{getTabContent(activeTabId)}</div>
    </div>
  );
}
