/* eslint-disable import/prefer-default-export */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getFloodRiskData } from '@Services/visualization';

export const useGetFloodRiskDataQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['flood-risk-data'],
    queryFn: getFloodRiskData,
    ...queryOptions,
    select: (res: any) => res.data,
  });
};
