import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  getVectorLayerRequest,
  getVectorLayerSuccess,
  getVectorLayerFailure,
  postVectorLayerFailure,
  postVectorLayerRequest,
  postVectorLayerSuccess,
  getLayerAttributeSuccess,
  getLayerAttributeFailure,
  getLayerAttributeRequest,
} from '@Store/actions/overlayLayer';
import {
  getLayerAttributesList,
  getTaskResponse,
  getVectorLayer,
  postVectorLayer,
  uploadBuildingData,
  uploadBuildingImages,
  uploadRoadData,
} from '@Services/overlayLayers';
import { withLoader } from '@Utils/sagaUtils';
import { setActiveIndex } from '@Store/actions/dataManagementForm';
import { toggleInfoDialog } from '@Store/actions/common';

function* getVectorLayerRequestSaga({ payload }: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(getVectorLayer, payload);
    yield put(getVectorLayerSuccess(response.data));
    // @ts-ignore
    yield put(getLayerAttributeRequest({ id: response.data.id }));
  } catch (error) {
    yield put(getVectorLayerFailure());
  }
}

function* getLayerAttributesListRequestSaga({
  payload,
}: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(getLayerAttributesList, payload);
    yield put(getLayerAttributeSuccess(response.data));
  } catch (error) {
    yield put(getLayerAttributeFailure());
  }
}

function* postVectorLayerRequestSaga({
  payload: { formData, activeIndex, navigate },
}: PayloadAction<Record<string, any>>) {
  try {
    let taskStatus = 'processing';
    let response: AxiosResponse;
    // call different api as per category
    if (formData.category === 'building_data') {
      response = yield call(uploadBuildingData, {
        file_upload: formData.file_upload,
      });
    } else if (formData.category === 'building_images') {
      response = yield call(uploadBuildingImages, {
        file_upload: formData.file_upload,
      });
    } else if (formData.category === 'road_data') {
      response = yield call(uploadRoadData, {
        file_upload: formData.file_upload,
      });
    } else {
      response = yield call(postVectorLayer, formData);
    }

    // check task response until it response is processing
    while (taskStatus === 'processing') {
      yield delay(5000);
      const taskResponse: AxiosResponse = yield call(getTaskResponse, {
        task_id: response.data.task_id,
      });
      taskStatus = taskResponse.data.stat;
    }

    // show error and terminate the process if task response is not success
    if (taskStatus !== 'success') {
      yield put(toggleInfoDialog('overlay-layer-file-upload-error'));
      return;
    }

    // navigate to the route as per category
    if (formData.category === 'building_data') {
      yield navigate(`/data/building-data`);
    } else if (formData.category === 'building_images') {
      yield navigate(`/data/building-data`);
    } else if (formData.category === 'road_data') {
      yield navigate(`/data/road-data`);
    } else {
      yield put(postVectorLayerSuccess());
      yield put(setActiveIndex(activeIndex + 1));
      yield put(getLayerAttributeRequest(response.data.vector_layer_id));
      yield put(getVectorLayerRequest(response.data.vector_layer_id));
      yield navigate(`/data/overlay-layer/${response.data.vector_layer_id}/`);
    }
  } catch (error) {
    yield put(toggleInfoDialog('overlay-layer-file-upload-error'));
    yield put(postVectorLayerFailure());
  }
}

function* overlayLayersWatcher() {
  yield takeLatest(
    getVectorLayerRequest.type,
    withLoader(getVectorLayerRequestSaga),
  );
  yield takeLatest(
    postVectorLayerRequest.type,
    withLoader(postVectorLayerRequestSaga),
  );
  yield takeLatest(
    getLayerAttributeRequest.type,
    withLoader(getLayerAttributesListRequestSaga),
  );
}

export default overlayLayersWatcher;
