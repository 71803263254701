import React, { Suspense, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  useGetCurrentPlaceWeatherQuery,
  useGetCurrentPlaceWeatherQueryWindy,
  useGetWeatherStationsWaterLevelQuery,
} from '@Api/publicPage';
import NaxaLogo from '@Assets/images/public-page/HDPRP/naxa.svg';
import { setPublicPageState } from '@Store/actions/publicPage';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { Flex, FlexColumn, FlexRow } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';

// Lazy load components
const WeatherBar = React.lazy(
  () => import('@Components/PublicPage/WeatherBar'),
);
const MapSection = React.lazy(
  () => import('@Components/PublicPage/PublicMapSection'),
);
const AlertBox = React.lazy(() => import('@Components/PublicPage/AlertBox'));
const DataCardSection = React.lazy(
  () => import('@Components/PublicPage/DataCardSection'),
);
const RainAndTemperatureSection = React.lazy(
  () => import('@Components/PublicPage/RainAndTemperatureSection'),
);
const SummaryChart = React.lazy(
  () => import('@Components/PublicPage/SummaryChart'),
);
const ParameterVisualization = React.lazy(
  () => import('@Components/PublicPage/ParameterVisualization'),
);
const HazardSection = React.lazy(
  () => import('@Components/PublicPage/HazardSection'),
);

const inViewOptions = { triggerOnce: true };

export default function Dashboard() {
  const dispatch = useTypedDispatch();

  // State to track loading of all sections
  const [allLoaded, setAllLoaded] = useState(false);
  const [isMapSectionLoaded, setIsMapSectionLoaded] = useState(false);

  const currentLocation = useTypedSelector(
    state => state.publicPage.currentLocation,
  );
  const selectedSource = useTypedSelector(
    state => state.publicPage.selectedSource,
  );
  const regionCentroid = useTypedSelector(
    state => state.publicPage.regionCentroid,
  );

  // Intersection observers for various sections
  const { ref: weatherBarRef, inView: isWeatherBarInView } =
    useInView(inViewOptions);
  const { ref: mapSectionRef, inView: isMapSectionInView } =
    useInView(inViewOptions);
  const {
    ref: rainAndTemperatureSectionRef,
    inView: isRainAndTemperatureSectionInView,
  } = useInView(inViewOptions);
  const { ref: summaryChartRef, inView: isSummaryChartInView } =
    useInView(inViewOptions);
  const {
    ref: parameterVisualizationRef,
    inView: isParameterVisualizationInView,
  } = useInView(inViewOptions);
  const { ref: hazardSectionRef, inView: isHazardSectionInView } =
    useInView(inViewOptions);

  useGetCurrentPlaceWeatherQuery(
    { lat: currentLocation.latitude, lon: currentLocation.longitude },
    {
      onSuccess: (res: any) => {
        dispatch(setPublicPageState({ currentPlaceWeatherData: res }));
      },
      enabled:
        !!currentLocation.latitude &&
        !!currentLocation.longitude &&
        selectedSource === 'weatherapi',
    },
  );

  useGetWeatherStationsWaterLevelQuery(regionCentroid, {
    onSuccess: (res: any) => {
      dispatch(setPublicPageState({ weatherStationsData: res }));
    },
    enabled: !!regionCentroid.lat,
  });

  useGetCurrentPlaceWeatherQueryWindy(
    { lat: currentLocation.latitude, lon: currentLocation.longitude },
    {
      onSuccess: (res: any) => {
        dispatch(setPublicPageState({ currentPlaceWeatherDataWindy: res }));
      },
      enabled:
        !!currentLocation.latitude &&
        !!currentLocation.longitude &&
        selectedSource === 'windy',
    },
  );

  useEffect(() => {
    if (
      isWeatherBarInView &&
      isSummaryChartInView &&
      isParameterVisualizationInView &&
      isHazardSectionInView &&
      isMapSectionInView &&
      isRainAndTemperatureSectionInView
    ) {
      setAllLoaded(true);
    }
  }, [
    isWeatherBarInView,
    isSummaryChartInView,
    isParameterVisualizationInView,
    isHazardSectionInView,
    isMapSectionInView,
    isRainAndTemperatureSectionInView,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMapSectionLoaded(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Flex gap={5} className="pb-10">
      <Suspense fallback={<></>}>
        <div ref={weatherBarRef}>{isWeatherBarInView && <WeatherBar />}</div>
        <Flex gap={5} className="flex-col-reverse xl:grid xl:grid-cols-3">
          <div ref={mapSectionRef} className="col-span-2">
            {isMapSectionInView && <MapSection />}
          </div>
          <FlexColumn className="grid grid-cols-1 gap-5 gap-y-5 md:grid-cols-2 xl:gap-x-0">
            <AlertBox />
            <DataCardSection />
          </FlexColumn>
        </Flex>
        {/* for stopping api call */}
        <div style={{ height: isMapSectionLoaded ? '0' : '100vh' }} />
        <div
          ref={rainAndTemperatureSectionRef}
          style={{ minHeight: !isMapSectionLoaded ? '25rem' : '0' }}
        >
          {isRainAndTemperatureSectionInView && <RainAndTemperatureSection />}
        </div>
        <div
          ref={summaryChartRef}
          style={{ minHeight: !isMapSectionLoaded ? '25rem' : '0' }}
        >
          {isSummaryChartInView && <SummaryChart />}
        </div>
        <div
          ref={parameterVisualizationRef}
          style={{ minHeight: !isMapSectionLoaded ? '25rem' : '0' }}
        >
          {isParameterVisualizationInView && <ParameterVisualization />}
        </div>
        <div
          ref={hazardSectionRef}
          style={{ minHeight: !isMapSectionLoaded ? '25rem' : '0' }}
        >
          {isHazardSectionInView && <HazardSection />}
        </div>
      </Suspense>

      {/* techinical partner logo */}
      {allLoaded && (
        <FlexRow gap={3} className="mt-10 items-center justify-center">
          <h5 className="text-base">Technical Partner</h5>
          <Image src={NaxaLogo} width={80} alt="technical-partner-naxa" />
        </FlexRow>
      )}
    </Flex>
  );
}
