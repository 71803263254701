import { useEffect, useMemo } from 'react';
import { IVectorTileLayer } from '@Components/common/MapLibreComponents/types';
import usePrevious from '@Hooks/usePrevious';
import { detailedDiff } from 'deep-object-diff';

interface IOLVectorTileLayer extends IVectorTileLayer {
  layers: Record<string, any>[];
  hasInteraction?: boolean;
  icon?: string | null;
}

export default function OLVectorTileLayer({
  map,
  id,
  url,
  isMapLoaded,
  visibleOnMap,
  layers,
  hasInteraction = false,
  icon,
}: IOLVectorTileLayer) {
  const sourceId = useMemo(() => id.toString(), [id]);
  const prevLayers = usePrevious(layers, []);

  useEffect(() => {
    if (!map || !isMapLoaded || !visibleOnMap) return;
    if (map.getSource(sourceId)) {
      if (map.getLayer(sourceId)) map?.removeLayer(sourceId);
      map?.removeSource(sourceId);
    }
    map.addSource(sourceId, {
      type: 'vector',
      tiles: [url],
    });
  }, [sourceId, isMapLoaded, map, url, visibleOnMap]);

  // Load all layer with style when style change need to refactor
  useEffect(() => {
    if (
      !map ||
      !isMapLoaded ||
      !layers ||
      !visibleOnMap ||
      hasInteraction ||
      icon
    )
      return;

    layers.forEach(layer => {
      if (layer?.type === 'symbol') {
        map.setGlyphs(
          'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
        );
      }
      map.addLayer({
        id: `${sourceId}-${layer.type}`,
        source: sourceId,
        'source-layer': 'default',
        type: layer.type,
        ...layer,
      });
    });

    // eslint-disable-next-line consistent-return
    return () => {
      if (hasInteraction) return;
      layers.forEach(layer => {
        if (map.getLayer(`${sourceId}-${layer.type}`)) {
          map.removeLayer(`${sourceId}-${layer.type}`);
        }
      });
    };
  }, [
    map,
    isMapLoaded,
    visibleOnMap,
    layers,
    sourceId,
    url,
    hasInteraction,
    icon,
  ]);

  useEffect(() => {
    if (
      !map ||
      !isMapLoaded ||
      !visibleOnMap ||
      !layers ||
      !sourceId ||
      !url ||
      !hasInteraction ||
      icon
    )
      return;
    const { updated, added }: Record<string, any> = detailedDiff(
      prevLayers,
      layers,
    );
    if (Object.keys(added).length > 0) {
      layers.forEach(layer => {
        if (layer?.type === 'symbol') {
          map.setGlyphs(
            'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
          );
        }
        if (map.getLayer(`${sourceId}-${layer.type}`)) {
          map.removeLayer(`${sourceId}-${layer.type}`);
        }
        map.addLayer({
          id: `${sourceId}-${layer.type}`,
          source: sourceId,
          'source-layer': 'default',
          type: layer.type,
          ...layer,
        });
      });
    }

    if (Object.keys(updated).length > 0) {
      map.setStyle(map.getStyle(), {
        transformStyle: (_, newStyle) => {
          const newSource = Object.keys(newStyle.sources).reduce(
            (prev, current) => {
              const currentSource = newStyle.sources[current];
              // @ts-ignore
              if (current === 'terrainSource' && !currentSource?.url)
                return prev;
              return { ...prev, [current]: currentSource };
            },
            {},
          );
          return {
            ...newStyle,
            sources: { ...newSource },
            layers: newStyle.layers.map(layerStyle => {
              const found = layers.find(lyr => lyr.type === layerStyle.type);
              if (found) {
                return {
                  ...layerStyle,
                  ...found,
                };
              }
              return {
                ...layerStyle,
              };
            }),
          };
        },
      });
    }
  }, [map, isMapLoaded, visibleOnMap, layers, sourceId, url, icon]); // eslint-disable-line

  useEffect(() => {
    if (!map || !isMapLoaded || !visibleOnMap || !sourceId || !url || !icon)
      return () => {};

    const iconId = `${sourceId}-icon`;

    map.loadImage(icon, (err, image) => {
      if (err) return;
      if (map.getLayer(iconId)) return;
      // @ts-ignore
      map.addImage(iconId, image);
      map.addLayer({
        id: iconId,
        type: 'symbol',
        source: sourceId,
        'source-layer': 'default',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': iconId,
          'icon-size': 0.9,
        },
      });
    });
    return () => {
      if (map.getLayer(iconId)) {
        map.removeImage(iconId);
        map.removeLayer(iconId);
      }
    };
  }, [map, icon, isMapLoaded, sourceId, url, visibleOnMap]);

  return null;
}
