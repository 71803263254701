/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { logoutUser } from '@Services/authentication';
import UserAvatar from '@Components/common/UserAvatar';
import { useTypedSelector } from '@Store/hooks';

export default function UserProfile() {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const user = useTypedSelector(state => state.authentication.user);

  const { mutate } = useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      localStorage.clear();
      setToggle(false);
      navigate('/');
      window.location.reload();
    },
    onError: (err: Record<string, any>) => toast.error(err.message),
  });

  const settingOptions = [
    // {
    //   id: 1,
    //   name: 'Account Settings',
    //   icon: 'settings',
    //   onClick: () => {
    //     setToggle(false);
    //   },
    // },
    {
      id: 2,
      name: 'Sign Out',
      icon: 'logout',
      onClick: () => mutate(),
    },
  ];

  return (
    <div className="relative" tabIndex={0} onBlur={() => setToggle(false)}>
      <div onClick={() => setToggle(!toggle)}>
        <UserAvatar className="cursor-pointer" name={user?.username} />
      </div>
      {toggle && (
        <div className="slide-in-top absolute right-0 top-[3rem] z-20">
          <ul className="w-[8rem] divide-y-2 rounded-md border bg-white p-1 shadow-lg">
            {settingOptions.map(item => (
              <li
                key={item.id}
                className="flex cursor-pointer items-center gap-x-2 p-2 text-body-md hover:bg-[#F5F5F5]"
                onClick={item.onClick}
              >
                <span className="material-icons">{item.icon}</span> {item.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
