import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@Components/RadixComponents/Table';
import { Input } from '@Components/common/FormUI';

interface ITableFormSectionProps {
  inputType: 'checkbox' | 'input';
  label: string;
}

export default function TableFormSection({
  inputType,
  label,
}: ITableFormSectionProps) {
  return (
    <div className="flex w-full justify-center ">
      <div className="w-full  md:w-1/3 xl:px-12">
        <p className="py-5 text-[13.4px]">{label}</p>
        <div style={{ height: 'calc(100vh - 22rem)' }}>
          <Table>
            <TableHeader className="">
              <TableHead className="text-left">sn</TableHead>
              {inputType === 'input' ? (
                <>
                  <TableHead className="text-left">Name</TableHead>
                  <TableHead className="text-left">Alias</TableHead>
                </>
              ) : (
                <>
                  <TableHead className="flex justify-center">
                    <Input type="checkbox" id="check" className="h-6 w-6" />
                  </TableHead>
                  <TableHead className="text-left">Title</TableHead>
                </>
              )}
            </TableHeader>
            <TableBody>
              {Array.from({ length: 20 })?.map((_, ind) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={`${ind}-'ind'`} className="border-b-0">
                  <TableCell className="w-1/5">{ind + 1}</TableCell>

                  {inputType === 'input' ? (
                    <>
                      <TableCell className="w-2/5">Text</TableCell>
                      <TableCell className="w-3/5">
                        <Input />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell className="flex w-full justify-center">
                        <Input type="checkbox" id="check" className="h-5 w-5" />
                      </TableCell>
                      <TableCell className="w-3/6">Text</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
