import { visualizationSlice } from '@Store/slices/visualization';

export const {
  handle3DToggle,
  setVisualizationState,
  setShowFilterSection,
  setExpandFilter,
  setSitesRegionBoundary,
  handleLabelToggle,
  handleLayerSort,
  handleLayerStyleChange,
  handleLayerToggle,
  setVisualizationOptions,
  getOverlayLayersRequest,
  getOverlayLayersSuccess,
  getOverlayLayersFailure,
} = visualizationSlice.actions;
