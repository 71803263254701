import { createSelector } from 'reselect';
import { RootState } from '..';

const layersSelector = (state: RootState) => state.visualization.layers;

export const allLayersSelector = createSelector(layersSelector, layers =>
  layers
    .map(layer =>
      layer.layers.map(item => ({ category: layer.category, ...item })),
    )
    .flat(),
);

export const checkedLayersSelector = createSelector(
  allLayersSelector,
  allLayers => allLayers.filter(layer => layer.checked),
);
