import { useEffect, useMemo } from 'react';
import removeObjectKeys from '@Utils/removeObjectKeys';
import { heatmapToPointTransitionZoomLevel } from '@Constants/map';
import { IVectorLayer } from '../types';

export default function HeatMapLayer({
  map,
  id,
  geojson,
  isMapLoaded,
  layerOptions,
  circleLayerOptions,
  visibleOnMap = true,
}: IVectorLayer) {
  const sourceId = useMemo(() => id.toString(), [id]);

  useEffect(() => {
    if (!map || !isMapLoaded || !geojson) return () => {};
    map.addSource(sourceId, {
      type: 'geojson',
      data: geojson,
    });
    return () => {
      if (map.getSource(sourceId)) {
        map?.removeLayer(`${sourceId}-point`);
        map?.removeLayer(sourceId);
        map?.removeSource(sourceId);
      }
    };
  }, [sourceId, isMapLoaded, map, geojson]);

  useEffect(() => {
    if (!map || !isMapLoaded || !geojson) return () => {};
    const pointLayerId = `${sourceId}-point`;
    if (visibleOnMap) {
      map.addLayer({
        id: sourceId,
        type: 'heatmap',
        source: sourceId,
        maxzoom: heatmapToPointTransitionZoomLevel,
        ...removeObjectKeys(layerOptions || {}, ['layout']),
      });
      map.addLayer({
        id: pointLayerId,
        type: 'circle',
        source: sourceId,
        minzoom: heatmapToPointTransitionZoomLevel - 3,
        ...removeObjectKeys(circleLayerOptions || {}, ['layout']),
      });
    } else if (map.getLayer(sourceId)) {
      map.removeLayer(sourceId);
    }
    return () => {
      if (map.getLayer(sourceId)) {
        map.removeLayer(sourceId);
        map.removeLayer(pointLayerId);
      }
    };
  }, [
    map,
    isMapLoaded,
    visibleOnMap,
    sourceId,
    geojson,
    layerOptions,
    circleLayerOptions,
  ]);

  return null;
}
