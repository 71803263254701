import Incident from '@Assets/images/incident.png';
import { FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { setNotificationState } from '@Store/actions/notification';
import { useTypedSelector } from '@Store/hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface INotificationProps {
  notification: Record<string, any>;
}

export default function NotificationCard({ notification }: INotificationProps) {
  const [isOpen, setisOpen] = useState(false);
  const dispatch = useDispatch();
  const activeNotificationId = useTypedSelector(
    state => state.notification.activeNotificationId,
  );

  useEffect(() => {
    if (activeNotificationId && activeNotificationId === notification.id) {
      setisOpen(true);
    } else {
      setisOpen(false);
    }
  }, [activeNotificationId]);

  return (
    <div
      className=" rounded-lg bg-white"
      style={{ border: '1px solid var(--Grey-Grey-300, #D7D7D7)' }}
    >
      <button
        type="button"
        onClick={() => {
          if (activeNotificationId === notification.id) {
            dispatch(setNotificationState({ activeNotificationId: null }));
            return;
          }
          dispatch(
            setNotificationState({ activeNotificationId: notification.id }),
          );
        }}
        className="top flex w-full justify-between overflow-hidden rounded-lg border-none p-3  outline-none hover:bg-gray-50"
      >
        <div className="left flex gap-5">
          <div className="icon">
            <img src={Incident} alt="incident icon" />
          </div>
          <div className="top-content flex flex-col items-start gap-2">
            <div className="flex gap-2">
              <p className="text-sm font-semibold">
                {notification.alert_name || '--'}
              </p>
              <p className="text-sm font-normal text-[#484848]">
                {notification.description || '--'}
              </p>
            </div>
            {/* <p className="date text-xs font-semibold text-[#757575]">
              Sep 2, 2023
            </p> */}
          </div>
        </div>
        {/* <div className="material-icons">
          {isOpen ? 'expand_less' : 'expand_more'}
        </div> */}
      </button>
      {isOpen && (
        <div className="content p-3">
          <FlexRow className="justify-between">
            <div className="info">
              <FlexRow className="items-center">
                <p className="text-xs text-[#757575]">Hazard Type</p>
                <p>: Flood</p>
              </FlexRow>
            </div>
            {/* <FlexRow className="images items-center gap-3">
              <img
                src={Incident}
                alt="incident"
                className="h-[6.375rem] w-[8.5rem]"
              />
              <img
                src={Incident}
                className="h-[6.375rem] w-[8.5rem]"
                alt="incident"
              />
            </FlexRow> */}
          </FlexRow>
          <div />
        </div>
      )}
    </div>
  );
}
export function NotificationCardSkeleton() {
  return (
    <div
      className=" rounded-lg bg-white"
      style={{ border: '1px solid var(--Grey-Grey-300, #D7D7D7)' }}
    >
      <button
        type="button"
        className="top flex w-full justify-between gap-4 overflow-hidden rounded-lg border-none p-3  outline-none "
      >
        <div className="left flex w-full gap-5">
          <div className="icon">
            <Skeleton className="h-10 w-10" />
          </div>
          <div className="top-content flex w-full flex-col items-start gap-2">
            <div className="flex w-full gap-2">
              <Skeleton className="h-5 flex-1" />
              <Skeleton className="h-5 flex-1" />
            </div>
            <Skeleton className="h-5 w-full flex-1" />
          </div>
        </div>
        <Skeleton className="h-10 w-10" />
      </button>
    </div>
  );
}
