/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export type FormStateType = Record<string, any>;

export type ChangeStyleType = {
  layerId: string | number;
  styles: Record<string, any>;
};

export type DataManagementFormState = {
  stepIndex: number;
  activeIndex: number;
  formState: FormStateType;
};

const initialState: DataManagementFormState = {
  stepIndex: 0,
  activeIndex: 0,
  formState: {},
};

const setDataManagementFormState: CaseReducer<
  DataManagementFormState,
  PayloadAction<Partial<DataManagementFormState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setActiveIndex: CaseReducer<
  DataManagementFormState,
  PayloadAction<number>
> = (state, { payload }) => ({
  ...state,
  activeIndex: payload,
  stepIndex: state.stepIndex > payload ? state.stepIndex : payload,
});

const setFormState: CaseReducer<
  DataManagementFormState,
  PayloadAction<Partial<FormStateType>>
> = (state, action) => ({
  ...state,
  formState: {
    ...state.formState,
    ...action.payload,
  },
});

const dataManagementFormSlice = createSlice({
  name: 'dataManagementForm',
  initialState,
  reducers: {
    setDataManagementFormState,
    setActiveIndex,
    setFormState,
  },
});

export { dataManagementFormSlice };

export default dataManagementFormSlice.reducer;
