import { PAPERSIZE } from '@Constants/publicPage';
import { cn, getDeviceDPI } from '@Utils/index';
import { HtmlHTMLAttributes } from 'react';

interface IA4PaperProps extends HtmlHTMLAttributes<HTMLDivElement> {
  pageNumber?: string;
}
export default function A4Paper({
  children,
  pageNumber,
  className,
}: IA4PaperProps) {
  const DPI = getDeviceDPI();

  return (
    <div
      className={cn(
        'relative overflow-hidden border border-gray-200 bg-white px-5',
        className,
      )}
      style={
        DPI
          ? {
              width: `${(PAPERSIZE.a4[0] * DPI) / 25.4}px`,
              height: `${(PAPERSIZE.a4[1] * DPI) / 25.4}px`,
            }
          : {}
      }
    >
      {children}
      {pageNumber && (
        <div className="page_number absolute bottom-1 right-2 text-[0.6rem] text-gray-600">
          {pageNumber}
        </div>
      )}
    </div>
  );
}
