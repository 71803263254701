import { Button } from '@Components/RadixComponents/Button';
import { useTypedSelector } from '@Store/hooks';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Flex from '@Components/common/Layouts/Flex';
import OverlayLayerCategory from './OverlayLayerCategory';

export default function OverlayLayers() {
  const layers = useTypedSelector(state => state.visualization.layers);

  return (
    <FlexColumn className="h-full justify-between px-3">
      <FlexColumn>
        <FlexRow className="h-11 items-center">
          <h5>Overlay Layers</h5>
        </FlexRow>
        <FlexColumn className="scrollbar h-[26rem] overflow-y-auto overflow-x-hidden">
          {layers.map(layer => (
            <OverlayLayerCategory
              key={layer.id}
              // icon="cloudy_snowing"
              category={layer.category}
              layerList={layer.layers}
            />
          ))}
        </FlexColumn>
      </FlexColumn>
      <Flex className="my-3">
        <Button variant="outline" leftIcon="upload">
          Upload Temporary Layer
        </Button>
      </Flex>
    </FlexColumn>
  );
}
