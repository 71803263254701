/* eslint-disable camelcase */
import { Input, Select } from '@Components/common/FormUI';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { getHazardSelectChoices } from '@Services/notification';
import { setNotificationState } from '@Store/actions/notification';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { downloadFile, objectToQueryParams } from '@Utils/index';

export default function FilterSection() {
  const dispatch = useTypedDispatch();
  const searchInput = useTypedSelector(state => state.notification.search);
  const hazard_types = useTypedSelector(
    state => state.notification.hazard_types,
  );
  const start_time = useTypedSelector(state => state.notification.start_time);
  const end_time = useTypedSelector(state => state.notification.end_time);

  const { data: hazardChoices } = useQuery({
    queryFn: getHazardSelectChoices,
  });

  const { BASE_URL } = process.env;

  return (
    <FlexColumn>
      <FlexRow className="mb-3 flex-col justify-between md:flex-row">
        <FlexRow className="relative w-full flex-wrap items-center gap-6">
          <FlexRow className="group relative w-full items-center border-b-2 px-3 hover:border-b-primary-600 md:w-1/4">
            <Icon
              name="search"
              className="text-grey-500 group-hover:text-primary-600"
            />
            <Input
              type="text"
              className="w-full border-none"
              value={searchInput || ''}
              onChange={e =>
                dispatch(setNotificationState({ search: e.target.value }))
              }
            />
          </FlexRow>

          <div className="w-[8rem]">
            <Select
              placeholder="Filter by status"
              labelKey="hazard_type"
              valueKey="hazard_type"
              options={hazardChoices?.data || []}
              selectedOption={hazard_types}
              onChange={changedStatus => {
                dispatch(setNotificationState({ hazard_types: changedStatus }));
              }}
            />
          </div>
          <div className="flex items-center gap-3">
            <p className="text-xs">Occurance date</p>
            <div className="flex items-center gap-3">
              <Input
                type="date"
                className="!block"
                placeholder="Start date"
                value={start_time}
                onChange={e => {
                  dispatch(
                    setNotificationState({ start_time: e.target.value }),
                  );
                }}
              />
              <p>--</p>
              <Input
                type="date"
                className="!block"
                placeholder="Start date"
                value={end_time}
                onChange={e => {
                  dispatch(setNotificationState({ end_time: e.target.value }));
                }}
              />
            </div>
          </div>
          <Button
            onClick={() => {
              dispatch(
                setNotificationState({
                  search: '',
                  hazard_types: '',
                  start_time: '',
                  end_time: '',
                }),
              );
            }}
          >
            <i className="material-symbols-outlined">restart_alt</i>
            <p>Clear filter</p>
          </Button>
        </FlexRow>
        <Button
          onClick={() => {
            downloadFile(
              `${BASE_URL}dastaa/incident/export-incident-reports/?${objectToQueryParams(
                { hazard_types, start_time, end_time },
              )}`,
            );
          }}
        >
          <i className="material-symbols-outlined">download</i>
          <p>Download</p>
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
