/* eslint-disable no-empty-pattern */
import { publicPageSlice } from '@Store/slices/publicPage';

export const {
  setPublicPageState,
  getSummaryChartsTabRequest,
  getSummaryChartsTabSuccess,
  getSummaryChartsTabFailure,
  setActiveTab,
  setSitesRegionBoundary,
  handleLayerToggle,
  getOverlayLayersRequest,
  getOverlayLayersSuccess,
  getOverlayLayersFailure,
  setWeatherDataLayers,
} = publicPageSlice.actions;
