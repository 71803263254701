import { useState } from 'react';
import { handleLayerToggle } from '@Store/actions/visualization';
import { useTypedDispatch } from '@Store/hooks';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import { FlexRow } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';
import Input from '@Components/common/FormUI/Input';

interface IOverlayLayerCateogryProps {
  category: string;
  // icon: string;
  layerList: Record<string, any>[];
}

export default function OverlayLayerCategory({
  category,
  // icon,
  layerList,
}: IOverlayLayerCateogryProps) {
  const dispatch = useTypedDispatch();
  const [showLayer, setShowLayer] = useState(true);
  return (
    <>
      <FlexRow
        className="cursor-pointer items-center justify-between py-[1.125rem]
      text-primary-600 hover:bg-primary-50"
        gap={1}
        onClick={() => setShowLayer(!showLayer)}
      >
        <FlexRow className="items-center" gap={2}>
          {/* <Icon
            className="icon-md material-icons text-primary-600"
            name={icon}
          /> */}
          <p className="text-center text-sm font-bold text-primary-600">
            {capitalizeFirstLetter(category)}
          </p>
        </FlexRow>
        <Icon
          name={showLayer ? 'expand_less' : 'expand_more'}
          onClick={() => setShowLayer(!showLayer)}
        />
      </FlexRow>
      <div className={`${showLayer ? 'inherit' : 'hidden'}`}>
        {layerList?.map(layer => {
          return (
            <FlexRow className="items-center" gap={2} key={layer.id}>
              <FlexRow className="flex-1 items-center" gap={2}>
                <Input
                  type="checkbox"
                  className="cursor-pointer accent-grey-800"
                  checked={layer.checked}
                  onChange={() => {
                    dispatch(
                      handleLayerToggle({
                        layerId: layer.id,
                        category,
                      }),
                    );
                  }}
                />
                {/* <Icon
                  name="school"
                  className="flex h-7 w-7 items-center justify-center rounded-[2.5rem] border border-solid border-grey-300 bg-primary-600 p-2 text-white lg:!text-xl"
                /> */}
                <p className="truncate text-body-lg text-grey-800">
                  {capitalizeFirstLetter(layer.name)}
                </p>
              </FlexRow>
              <Icon name="more_vert" />
            </FlexRow>
          );
        })}
      </div>
    </>
  );
}
