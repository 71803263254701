import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface RTMState {
  activeTab: string;
  search: string;
}

const initialState: RTMState = {
  activeTab: 'river-watch',
  search: '',
};

const setRTMState: CaseReducer<RTMState, PayloadAction<Partial<RTMState>>> = (
  state,
  action,
) => ({
  ...state,
  ...action.payload,
});

const rtmSlice = createSlice({
  name: 'rtm',
  initialState,
  reducers: {
    setRTMState,
  },
});

export { rtmSlice };

export default rtmSlice.reducer;
