import React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

interface IHorizontalBarChartProps {
  data: any;
}

export default function YLabelHorizontalBarChart({
  data,
}: IHorizontalBarChartProps) {
  return (
    <div className="h-[200px]">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          barCategoryGap={1}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 0,
          }}
        >
          <XAxis type="number" hide />
          <YAxis
            style={{
              fontSize: '12px',
              color: '#212121',
            }}
            width={82}
            dataKey="name"
            type="category"
            tickLine={false}
          />
          <Bar
            dataKey="value"
            barSize={38}
            fill="#98DFE0"
            radius={[0, 10, 10, 0]}
            label={{
              position: 'insideLeft',
              fontSize: 12,
              fontWeight: 700,
              fill: '#484848',
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
