import { api, authenticated } from '.';

export const getUserList = (params: any) =>
  authenticated(api).get(`/user/admin_dashboard/`, { params });

export const getUserRoleUniqueFields = () =>
  authenticated(api).get('/user/user-role-unique-fields');

export const postUser = (data: any) =>
  authenticated(api).post('/user/admin_dashboard/', data);

export const updateUser = (id: any, data: any) =>
  authenticated(api).patch(`/user/admin_dashboard/${id}/`, data);
