export const sidebarWidth = '80px';

export const infoDialogTimeoutValue = 5000;

export const routesWithoutSidebar = [
  '/login',
  '/sign-up',
  '/forgot-password',
  '/privacy-policy',
];

export const publicRoutes = ['/sign-up', '/forgot-password', '/privacy-policy'];

interface IChildrenProps {
  id: string;
  label: string;
}

interface IPublicNavLinksProps {
  link: string;
  linkName: string;
  authenticated?: boolean;
  children?: IChildrenProps[];
}

export const publicNavLinks: IPublicNavLinksProps[] = [
  { link: '', linkName: 'Home' },
  { link: 'about', linkName: 'About' },
  { link: 'hdrmp', linkName: 'HDRMP', authenticated: true },
  {
    link: 'resources',
    linkName: 'Resources',
    authenticated: true,
    children: [
      {
        id: 'information',
        label: 'Know More information',
      },
      {
        id: 'emergency-detail',
        label: 'Emergency Detail',
      },
      {
        id: 'user-form',
        label: 'View User Form',
      },
    ],
  },
];
