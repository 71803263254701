import { useState } from 'react';
import { MapInstanceType } from '@Components/common/MapLibreComponents/types';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { setVisualizationState } from '@Store/actions/visualization';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useGetOverallSitesBboxQuery } from '@Api/common';
import BaseLayers from './BaseLayers';
import Toolbox from './Toolbox';
import Measure from './Measure';
import Location from './Location';
import VisualizationOptions from './VisualizationOptions';
import Legend from './Legend';
import OverlayLayers from './OverlayLayers';
import ExposureAssessment from './ExposureAssessment';

type IconId =
  | 'toggle'
  | 'visualization'
  | 'overlay-layers'
  | 'base_layers'
  | 'legend'
  | 'exposure_assessment'
  | 'measure'
  | 'my_location';

export interface IconDataProp {
  id: IconId;
  icon: string;
  message?: string;
}

const iconData: IconDataProp[] = [
  { id: 'toggle', icon: 'chevron_right' },
  { id: 'visualization', icon: 'poll', message: 'Visualizations' },
  { id: 'overlay-layers', icon: 'layers', message: 'View Overlay' },
  { id: 'base_layers', icon: 'public', message: 'View Layer' },
  { id: 'legend', icon: 'legend_toggle', message: 'Legend' },
  { id: 'exposure_assessment', icon: 'exposure', message: 'View Assessment' },
  // { id: 'toolbox', icon: 'build_circle', message: 'View Toolbox' },
  { id: 'measure', icon: 'straighten', message: 'Measure' },
  { id: 'my_location', icon: 'my_location', message: 'GPS Location' },
];

interface IMapToolsProps {
  map?: MapInstanceType | null;
}

export default function MapTools({ map }: IMapToolsProps) {
  const [showMapTool, setShowMapTool] = useState(true);
  const dispatch = useTypedDispatch();

  const activeTab = useTypedSelector(state => state.visualization.activeTab);
  const activeBaseLayer = useTypedSelector(
    state => state.visualization.activeBaseLayer,
  );

  const { data: overallSitesBbox } = useGetOverallSitesBboxQuery();

  function getTabContent(name: string) {
    switch (name) {
      case 'base_layers':
        return (
          <BaseLayers
            activeBaseLayer={activeBaseLayer}
            onChange={id =>
              dispatch(setVisualizationState({ activeBaseLayer: id }))
            }
          />
        );
      case 'visualization':
        return <VisualizationOptions />;
      case 'overlay-layers':
        return <OverlayLayers />;
      case 'legend':
        return <Legend />;
      case 'toolbox':
        return <Toolbox />;
      case 'exposure_assessment':
        return <ExposureAssessment />;
      case 'measure':
        return <Measure />;
      case 'my_location':
        return <Location />;
      default:
        return '';
    }
  }

  return (
    <div
      className={`${
        showMapTool ? 'w-[296px] translate-x-0' : 'w-10 -translate-x-0'
      } absolute bottom-[2.625rem] right-0 z-20 flex 
      h-[32.63rem]  duration-200 ease-in-out`}
    >
      <div className="flex w-10 flex-col gap-3 bg-transparent">
        <div className="overflow-hidden rounded-tl-md">
          {iconData.map(({ id, icon, message }) => {
            const isActive = showMapTool && activeTab === id && id !== 'toggle';
            return (
              <ToolTip
                key={id}
                className={`flex h-10 w-10 cursor-pointer items-center
                 justify-center bg-primary-600 pt-2
                 text-white hover:bg-primary-900
                 ${isActive ? 'bg-primary-900 text-white' : ''} ${
                   id === 'toggle' && !showMapTool ? 'rotate-180' : ''
                 }`}
                name={icon}
                message={message}
                iconClassName="icon-md text-white hover:text-white hover:animate-none "
                onClick={() => {
                  if (id === 'toggle') {
                    setShowMapTool(!showMapTool);
                  } else {
                    dispatch(setVisualizationState({ activeTab: id }));
                    setShowMapTool(true);
                  }
                }}
              />
            );
          })}
        </div>
        <div className="divide-y-[1px] divide-grey-500 ">
          <ToolTip
            name="center_focus_weak"
            message="Zoom to Extent"
            className="400 bg-white pt-3"
            iconClassName="icon-md text-primary-600"
            onClick={() => {
              if (!map || !overallSitesBbox) return;
              map.fitBounds(overallSitesBbox);
            }}
          />
          <ToolTip
            name="add"
            message="Zoom In"
            className="400 bg-white pt-3"
            iconClassName="icon-md text-primary-600"
            onClick={() => {
              map?.zoomIn();
            }}
          />
          <ToolTip
            name="remove"
            message="Zoom Out"
            className="400 bg-white pt-3"
            iconClassName="icon-md text-primary-600"
            onClick={() => {
              map?.zoomOut();
            }}
          />
        </div>
      </div>

      <div
        className={`translate-x-0 overflow-clip rounded-lg rounded-tl-none
           bg-white ${showMapTool ? 'w-[256px]' : 'w-0'}`}
      >
        {getTabContent(activeTab)}
      </div>
    </div>
  );
}
