import * as React from 'react';
import { cn } from '@Utils/index';

export interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ className, ...rest }, ref) => {
    return (
      <textarea
        className={cn(
          `scrollbar flex rounded border-b border-grey-800 bg-transparent p-1 text-body-md file:font-medium hover:border-primary-600 focus:border-primary-600
            focus:bg-transparent focus:outline-none disabled:cursor-not-allowed `,
          className,
        )}
        ref={ref}
        {...rest}
      />
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;
