import { useRef } from 'react';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import { FlexColumn } from '@Components/common/Layouts';
import { dashboardChart } from '@Constants/dashboard';

export default function ChartSection() {
  // for all children chart count from json
  const chartIndexCount = useRef(0);

  // reset chartIndexCount on every rerender
  // count value exceeds actual count otherwise
  chartIndexCount.current = 0;

  return (
    <FlexColumn gap={12} className="mt-[3.75rem] w-full animate-fade">
      {Object.entries(dashboardChart).map(
        ([id, { title, contents, layout }]) => (
          <div key={id}>
            <FlexColumn gap={5} className="w-full">
              <h5 className="text-[20px] text-primary-700">{title}</h5>
              <div
                className={`gap-5 ${
                  layout === 'flex'
                    ? 'flex flex-col xl:flex-row'
                    : 'grid h-full xl:grid-cols-6'
                }`}
              >
                {contents.map(data => {
                  chartIndexCount.current += 1;
                  if (data.enable && data.enable()) {
                    return (
                      <ChartRenderer
                        chartIndex={chartIndexCount.current}
                        key={data.id}
                        data={data}
                        dynamicRender
                      />
                    );
                  }

                  // We return the chart if there is no enable
                  return (
                    <ChartRenderer
                      chartIndex={chartIndexCount.current}
                      key={data.id}
                      data={data}
                      dynamicRender
                    />
                  );
                })}
              </div>
            </FlexColumn>
          </div>
        ),
      )}
    </FlexColumn>
  );
}
