import { useGetSitesRegionBoundaryQuery } from '@Api/common';
import { setSitesRegionBoundary } from '@Store/actions/visualization';
import { useTypedDispatch } from '@Store/hooks';
import { MapSection } from '@Components/Visualization';

export default function Visualization() {
  const dispatch = useTypedDispatch();

  // fetch sites region boundary
  useGetSitesRegionBoundaryQuery({
    onSuccess: (res: any) => {
      dispatch(setSitesRegionBoundary(res.data.regions));
    },
  });

  return <MapSection />;
}
