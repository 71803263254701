/* eslint-disable import/prefer-default-export */
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handlePDFExport = async ({
  exportableComponentID,
  siteId,
}: {
  exportableComponentID: string;
  siteId: string;
}) => {
  const style = document.createElement('style');
  document.head.appendChild(style);
  style.sheet?.insertRule(
    'body > div:last-child img { display: inline-block; }',
  );
  const element = document.getElementById(exportableComponentID);

  if (!element) return;
  const canvas = await html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    scale: 2,
  });

  const data = canvas.toDataURL('image/png');

  // eslint-disable-next-line new-cap
  const pdf = new jsPDF();
  const imgWidth = 210;
  const pageHeight = 297;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;

  pdf.addImage(data, 'JPEG', 0, 0, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    pdf.addPage();
    pdf.addImage(data, 'JPEG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  pdf.save(`hdrmp-report-${siteId}`);
};

export function getDeviceDPI(): number | null {
  // Check if the devicePixelRatio property is supported
  if (window.devicePixelRatio !== undefined) {
    // Calculate DPI using the devicePixelRatio
    // 100 -120
    // 120 -120
    const dpiList = { 1: 120, 1.25: 120 };
    // const dpi = (window.devicePixelRatio * 3) / 2; // Assuming 96 DPI is the default for most screens

    // @ts-expect-error -this is
    const dpi = dpiList[window.devicePixelRatio] || 120; // Assuming 96 DPI is the default for most screens
    return dpi;
  }
  // Fallback for older browsers that do not support devicePixelRatio
  // console.warn('Device DPI information not available.');
  return null;

  // const dpi = getDeviceDPI();
  // const zoomLevel = window.devicePixelRatio;
  // return dpi ? dpi / zoomLevel : null;
}

export function downloadFile(url: string): void {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank'; // Opens in a new tab
  a.download = ''; // The browser will automatically infer the filename from the URL
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function objectToQueryParams(params: Record<string, any>): string {
  const searchParams = new URLSearchParams();

  // eslint-disable-next-line no-restricted-syntax
  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      searchParams.append(key, params[key]);
    }
  }

  return searchParams.toString();
}
