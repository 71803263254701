/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */

import { QueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

export async function handleLayerTableDelete(
  queryClient: QueryClient,
  payloadData: Record<string, any>,
) {
  const queryResults = queryClient.getQueriesData(['overlay-layer-list']);
  const [keys, previousData] = queryResults[0];
  if (!previousData) return;

  await queryClient.cancelQueries({ queryKey: keys });
  const updatedData = produce(previousData, (draft: Record<string, any>) => {
    draft.data = draft.data.filter(
      (result: any) => result.id !== payloadData.id,
    );
  });
  queryClient.setQueryData(keys, updatedData);
}
