import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Accessibility } from 'accessibility';
import { useQuery } from '@tanstack/react-query';
import { initDomToCode } from 'dom-to-code';
import { useSetCSRFQuery } from '@Api/authentication';
import { checkLogin } from '@Services/authentication';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import generateRoutes from '@Routes/generateRoutes';
import appRoutes from '@Routes/appRoutes';
import testRoutes from '@Routes/testRoutes';
import {
  setModalContent,
  setPromptDialogContent,
  toggleModal,
  togglePromptDialog,
} from '@Store/actions/common';
import sidebarData from '@Constants/sidebarData';
import Modal from '@Components/common/Modal';
import DevTool from '@Components/common/DevTool';
import PromptDialog from '@Components/common/PromptDialog';
import {
  getModalContent,
  getPromptDialogContent,
} from '@Constants/modalContents';
import { setAuthenticationState } from '@Store/actions/authentication';
import Sidebar from './components/common/Sidebar/index';
import { publicRoutes } from './constants';

export default function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const showModal = useTypedSelector(state => state.common.showModal);
  const modalContent = useTypedSelector(state => state.common.modalContent);
  const isLoginChecked = useTypedSelector(
    state => state.authentication.isLoginChecked,
  );
  const showPromptDialog = useTypedSelector(
    state => state.common.showPromptDialog,
  );
  const promptDialogContent = useTypedSelector(
    state => state.common.promptDialogContent,
  );

  // set csrf token - need this to avoid csrftoken missing error
  useSetCSRFQuery();

  const isPublicRoute = publicRoutes.some(url => pathname.includes(url));

  const isDashboardRoute = sidebarData
    .flatMap(item =>
      item.children
        ? [item.path, ...item.children.map(child => child.path)]
        : item.path,
    )
    .some(url => pathname.includes(url));

  useEffect(() => {
    const options = {
      icon: {
        circular: [true],
        img: ['accessibility'],
      },
      language: {
        textToSpeechLang: 'en-US',
      },
    };
    // @ts-ignore
    new Accessibility(options);
  }, []);

  const { refetch } = useQuery({
    queryKey: ['check-login'],
    queryFn: checkLogin,
    onSuccess: res => {
      const { data } = res;
      const isAdmin = data.is_admin;
      dispatch(
        setAuthenticationState({
          user: data,
          isLoginChecked: true,
        }),
      );
      if (isPublicRoute) return;
      if (!isAdmin && isDashboardRoute) {
        navigate('/');
      }
      if (isAdmin && !isDashboardRoute) {
        navigate('/dashboard');
      }
      if (!isAdmin && pathname.includes('/login')) {
        navigate('/');
      }
    },
    onError: () => {
      localStorage.removeItem('token');
      dispatch(
        setAuthenticationState({
          isLoginChecked: true,
        }),
      );

      // eslint-disable-next-line no-useless-return
      if (isPublicRoute) return;
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, pathname]);

  const handleModalClose = () => {
    dispatch(toggleModal());
    setTimeout(() => {
      dispatch(setModalContent(null));
    }, 150);
  };

  const handlePromptDialogClose = () => {
    dispatch(togglePromptDialog());
    setTimeout(() => {
      dispatch(setPromptDialogContent(null));
    }, 150);
  };

  return (
    <>
      {process.env.NODE_ENV !== 'production' &&
        !process.env.DISABLE_DOM_TO_CODE &&
        initDomToCode()}

      {process.env.NODE_ENV !== 'production' && <DevTool />}

      <div
        className={`${
          isDashboardRoute && isLoginChecked ? 'ml-0 flex md:ml-[90px]' : `ml-0`
        }`}
      >
        <ToastContainer />

        <Modal
          show={showModal}
          className={getModalContent(modalContent)?.className || ''}
          title={getModalContent(modalContent)?.title}
          subtitle={getModalContent(modalContent)?.subtitle}
          onClose={handleModalClose}
          hideCloseButton={!!getModalContent(modalContent)?.hideCloseButton}
        >
          {getModalContent(modalContent)?.content}
        </Modal>

        <PromptDialog
          show={showPromptDialog}
          title={getPromptDialogContent(promptDialogContent)?.title}
          iconName={getPromptDialogContent(promptDialogContent)?.iconName}
          onClose={handlePromptDialogClose}
        >
          {getPromptDialogContent(promptDialogContent)?.content}
        </PromptDialog>

        {isDashboardRoute && isLoginChecked && <Sidebar />}

        {generateRoutes({
          routes:
            process.env.NODE_ENV !== 'production'
              ? [...testRoutes, ...appRoutes]
              : appRoutes,
        })}
      </div>
    </>
  );
}
