import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { withLoader } from '@Utils/sagaUtils';
import {
  getOverlayLayersRequest,
  getOverlayLayersSuccess,
  getOverlayLayersFailure,
} from '@Store/actions/visualization';
import { getOverlayLayers } from '@Services/common';
import { getOverlayLayersIcon } from '@Services/visualization';

function* getOverlayLayersSaga() {
  try {
    const layersResponse: AxiosResponse = yield call(getOverlayLayers);
    const layersIconResponse: AxiosResponse = yield call(getOverlayLayersIcon);
    yield put(
      getOverlayLayersSuccess({
        layerData: layersResponse.data,
        layerIconData: layersIconResponse.data,
      }),
    );
  } catch (error) {
    yield put(getOverlayLayersFailure());
  }
}

function* visualizationWatcher() {
  yield takeLatest(
    getOverlayLayersRequest.type,
    withLoader(getOverlayLayersSaga),
  );
}

export default visualizationWatcher;
