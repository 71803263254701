import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { chnageNewPassword } from '@Services/authentication';
import { toggleModal } from '@Store/actions/common';
import { useTypedDispatch } from '@Store/hooks';
import validatePassword from '@Utils/validatePassword';
import { Button } from '@Components/RadixComponents/Button';
import { FormControl, Input, Label } from '@Components/common/FormUI';
import { FlexColumn } from '@Components/common/Layouts';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import Icon from '@Components/common/Icon';

export default function ChangePassword() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm({
    defaultValues: {
      token: null,
      newPassword: '',
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      chnageNewPassword(payloadData),
    onSuccess: res => {
      toast.success(res?.data?.message);
      dispatch(toggleModal());
      navigate('/login');
    },
    onError: (err: Record<string, any>) => {
      toast.error(err?.response?.data?.error);
    },
  });

  const onSubmit = (data: Record<string, any>) => {
    mutate(data);
  };

  return (
    <FlexColumn gap={5}>
      <h6>
        Please check you email and enter verification token to change your
        newPassword
      </h6>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <FormControl>
          <Label htmlFor="token" required>
            Enter Verification Code
          </Label>
          <Input
            id="token"
            type="number"
            placeholder="Verification Code"
            {...register('token', {
              required: 'Required',
              maxLength: {
                value: 6,
                message: 'Code should be of 6 digits',
              },
            })}
            maxLength={6}
            pattern="[0-9]*"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              const inputValue = e.target.value;
              if (inputValue.length > 6) {
                e.target.value = inputValue.slice(0, 6);
              }
            }}
          />

          <ErrorMessage message={errors?.token?.message || ''} />
        </FormControl>
        <FormControl className="relative">
          <Label htmlFor="newPassword" required>
            Enter New Password
          </Label>
          <Input
            id="newPassword"
            type={showPassword ? 'text' : 'password'}
            placeholder="New Password"
            {...register('newPassword', {
              required: 'Required',
              validate: {
                matchPattern: value => {
                  return validatePassword(value);
                },
              },
              onChange: () => {
                trigger('newPassword');
              },
            })}
          />
          <Icon
            title={`${showPassword ? 'Hide' : 'Show'} Password`}
            name={showPassword ? 'visibility' : 'visibility_off'}
            className="absolute right-2 top-9 cursor-pointer !text-xl text-grey-800"
            onClick={() => setShowPassword(!showPassword)}
          />
          <ErrorMessage message={errors?.newPassword?.message || ''} />
        </FormControl>
        <FlexColumn className="items-center justify-center">
          <Button type="submit" isLoading={isLoading} withLoader>
            Change Password
          </Button>
        </FlexColumn>
      </form>
    </FlexColumn>
  );
}
