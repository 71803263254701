import { withLoader } from '@Utils/sagaUtils';
import {
  getOverlayLayersFailure,
  getOverlayLayersRequest,
  getOverlayLayersSuccess,
  getSummaryChartsTabFailure,
  getSummaryChartsTabRequest,
  getSummaryChartsTabSuccess,
} from '@Store/actions/publicPage';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { FormOptionsRequestDataType } from '@Constants/publicPage';
import { getOverlayLayers } from '@Services/common';
import { getOverlayLayersIcon } from '@Services/publicPage';

function* getSummaryChartsTabListSaga({
  payload,
}: PayloadAction<FormOptionsRequestDataType>) {
  try {
    const responses: AxiosResponse[] = yield all(
      payload.map(({ queryFn }) => call(queryFn)),
    );
    yield put(getSummaryChartsTabSuccess(responses));
  } catch (error) {
    yield put(getSummaryChartsTabFailure());
  }
}

function* getOverlayLayersSaga() {
  try {
    const layersResponse: AxiosResponse = yield call(getOverlayLayers);
    const layersIconResponse: AxiosResponse = yield call(getOverlayLayersIcon);
    yield put(
      getOverlayLayersSuccess({
        layerData: layersResponse.data,
        layerIconData: layersIconResponse.data,
      }),
    );
  } catch (error) {
    yield put(getOverlayLayersFailure());
  }
}

function* publicPageWatcher() {
  yield takeLatest(
    getSummaryChartsTabRequest.type,
    withLoader(getSummaryChartsTabListSaga),
  );
  yield takeLatest(
    getOverlayLayersRequest.type,
    withLoader(getOverlayLayersSaga),
  );
}

export default publicPageWatcher;
