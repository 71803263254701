import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  searchInput: string;
  selectedUser: Record<string, any> | null;
}

const initialState: UserState = {
  searchInput: '',
  selectedUser: null,
};

const setUserState: CaseReducer<
  UserState,
  PayloadAction<Partial<UserState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserState,
  },
});

export { userSlice };

export default userSlice.reducer;
