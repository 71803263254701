import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import { ChartFills } from '../constants';
import { IChartProps } from '../types';
import { CustomizedLabel } from '../BarChart';

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip relative z-20 rounded-xl border-2 bg-white px-3 py-2 text-sm text-primary-600">
        {payload?.map((item: any) => {
          if (item.dataKey !== 'id')
            return (
              <div
                key={item.dataKey}
                className="flex w-fit items-center justify-between gap-5"
              >
                <div className="flex items-center justify-center gap-1">
                  <div
                    className="h-3 w-3 rounded-sm"
                    style={{ backgroundColor: `${item?.fill}` }}
                  />
                  <span>{capitalizeFirstLetter(item.dataKey)}</span>
                </div>
                <p className="font-semibold">
                  {typeof item.value === 'number'
                    ? item.value
                    : capitalizeFirstLetter(item.value)}
                </p>
              </div>
            );
          return <></>;
        })}
      </div>
    );
  }

  return null;
};

export default function ScatterChartComponent({
  data,
  fills = ChartFills,
  scrollable = false,
  width = '150%',
}: IChartProps) {
  const { width: deviceWidth } = useWindowDimensions();

  return (
    <ResponsiveContainer
      minHeight={150}
      maxHeight={230}
      width={scrollable && width ? width : '100%'}
    >
      <ScatterChart
        margin={{
          top: 20,
          right: 0,
          bottom: 20,
          left: 5,
        }}
      >
        <CartesianGrid vertical={false} stroke="#DDD" />

        <XAxis
          dataKey="name"
          style={{
            fontSize: '12px',
            color: '#484848',
          }}
          tickLine={false}
          tick={<CustomizedLabel deviceWidth={deviceWidth} />}
        />
        <YAxis
          dataKey="value"
          type="number"
          style={{
            fontSize: '12px',
            color: '#484848',
          }}
          tickLine={false}
        />

        <Tooltip content={<CustomTooltip />} />
        <Scatter
          animationDuration={1000}
          data={data}
          name="House Floors"
          fill={fills[5]}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
}
