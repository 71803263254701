import { useGetOverallSitesBboxQuery } from '@Api/common';
import { useGetSiteRiskPlotQuery } from '@Api/dashboard';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import HeatMapLayer from '@Components/common/MapLibreComponents/Layers/HeatMapLayer';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import MapLoader from '@Components/common/MapLibreComponents/MapLoader';
import { GeojsonType } from '@Components/common/MapLibreComponents/types';
import {
  heatMapVulnerabilityCircleStyle,
  heatMapVulnerabilityStyle,
} from '@Constants/map';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { LngLatBoundsLike } from 'maplibre-gl';
import { useEffect, useState } from 'react';

export default function MapSection() {
  const { width } = useWindowDimensions();
  // const [selectedOption, setSelectedOption] = useState('risk');
  const [isHeatMapLoaded, setIsHeatMapLoaded] = useState(false);

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 2,
      center: [80.122, 28.946],
      maxZoom: 19,
    },
  });

  const { data: siteRiskPlotData } = useGetSiteRiskPlotQuery();
  const { data: overallSitesBbox, isLoading: isBboxLoading } =
    useGetOverallSitesBboxQuery();

  // zoom to sites extent on initial map load
  useEffect(() => {
    if (
      !map ||
      !isMapLoaded ||
      !overallSitesBbox ||
      Array.isArray(overallSitesBbox?.[0])
      // process.env.SITE_NAME === 'Dastaa Bangladesh'
    )
      return;
    map.fitBounds(overallSitesBbox as LngLatBoundsLike, {
      duration: 0,
      padding: 80,
    });
  }, [map, isMapLoaded, overallSitesBbox]);

  // set isHeatMapLoaded state
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'sites-heatmap') {
        // setIsHeatMapLoaded to true if ev has tile property
        setIsHeatMapLoaded(!!ev?.tile);
      }
    });
  }, [map]);

  // const handleSelect = (value: string) => {
  //   setSelectedOption(value);
  // };

  const isLoading = !isMapLoaded || isBboxLoading || !isHeatMapLoaded;

  return (
    <div className="relative h-[514px] overflow-hidden rounded-xl border-[0.5px] bg-grey-50">
      {isLoading && <MapLoader />}

      <MapContainer
        map={map}
        isMapLoaded={isMapLoaded}
        style={{ width: '100%', height: width <= 390 ? '390px' : '514px' }}
      >
        <BaseLayerSwitcher activeLayer="osm" />

        {siteRiskPlotData && (
          <HeatMapLayer
            id="sites-heatmap"
            geojson={siteRiskPlotData as GeojsonType}
            layerOptions={
              // selectedOption === 'risk'
              //   ? heatMapRiskStyle
              // :
              heatMapVulnerabilityStyle
            }
            circleLayerOptions={
              // selectedOption === 'risk'
              //   ? heatMapRiskCircleStyle
              // :
              heatMapVulnerabilityCircleStyle
            }
          />
        )}
        {/* 
        <HazardFilterSection
          selectedOption={selectedOption}
          onChange={handleSelect}
        /> */}
      </MapContainer>
    </div>
  );
}
