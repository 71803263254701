import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@Components/RadixComponents/Carousel';
import Image from '@Components/RadixComponents/Image';
import { useTypedSelector } from '@Store/hooks';

export default function ViewPhotos() {
  const incidentPhotos = useTypedSelector(
    state => state.notification.incidentPhotos,
  );

  return (
    <div className="viewphotos !w-[60vw]">
      <Carousel opts={{ loop: true }} className="relative h-full w-full">
        <CarouselContent>
          {incidentPhotos?.map(incidentImage => (
            <CarouselItem key={incidentImage} className="h-full w-full ">
              <Image
                className="h-full w-full"
                src={incidentImage}
                alt="incident"
              />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="absolute left-0 top-1/2 translate-y-1/2" />
        <CarouselNext className="absolute right-0 top-1/2 translate-y-1/2" />
      </Carousel>
    </div>
  );
}
