/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import Image from '@Components/RadixComponents/Image';
import DastaaFullLogo from '@Assets/images/dastaa-full-logo.svg';
import { Button } from '@Components/RadixComponents/Button';
import { FlexRow } from '@Components/common/Layouts';
import { publicNavLinks } from '@Constants/index';
import { Select } from '@Components/common/FormUI';
import LanguageToggle from '@Components/common/LanguageToggle';
import { setCommonState } from '@Store/actions/common';
import UserProfile from '../UserProfile';

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const [selectedLink, setSelectedLink] = useState<string | null>(null);

  const user = useTypedSelector(state => state.authentication.user);
  const language = useTypedSelector(state => state.common.language);

  const isLoggedIn = !!user;

  const filteredNavLinks = isLoggedIn
    ? publicNavLinks
    : publicNavLinks.filter(navLink => !navLink.authenticated);

  useEffect(() => {
    if (!selectedLink) return;
    navigate(selectedLink);
    setSelectedLink(null);
  }, [navigate, selectedLink]);

  return (
    <header className="flex h-20 items-center justify-between">
      <div
        onClick={() => navigate('/')}
        className="cursor-pointer hover:animate-pulse"
      >
        <Image className="h-8 w-full" src={DastaaFullLogo} width={200} />
      </div>

      <FlexRow className="items-center justify-center" gap={5}>
        <div className="menu-items flex items-center gap-5">
          {filteredNavLinks.map(({ link, linkName, children }) => {
            return (
              <NavLink
                key={linkName}
                end
                to={
                  // eslint-disable-next-line no-nested-ternary
                  children ? '' : !link ? '/' : `/${link}`
                }
                className={navData =>
                  navData.isActive && !children
                    ? 'border-b-2 border-b-primary-600 text-primary-600'
                    : 'text-grey-600 hover:text-primary-600'
                }
              >
                <div className="body-btn px-3 py-2 font-semibold ">
                  {children ? (
                    <Select
                      options={children}
                      selectedOption={pathname?.split('/')?.[2]}
                      placeholder={linkName}
                      className={`${
                        pathname?.split('/')?.length > 2
                          ? `!h-10 !w-44 border-spacing-2 !border-b-2
                           border-primary-600 [&>i]:!text-primary-500 [&>p]:!text-primary-500`
                          : '!h-5 !w-[110px] border-none'
                      }  [&>p]:text-grey-600 [&>p]:hover:!text-primary-500 [&>ul]:!w-44 
                      
                       `}
                      direction="right"
                      onChange={selectedItem => {
                        setSelectedLink(`/${link}/${selectedItem}`);
                      }}
                      labelKey="label"
                      valueKey="id"
                    />
                  ) : (
                    linkName
                  )}
                </div>
              </NavLink>
            );
          })}
        </div>
        <LanguageToggle
          selectedLanguage={language}
          languages={['English', 'Nepali']}
          className="mt-1"
          onLanguageChange={changedLanguage => {
            dispatch(setCommonState({ language: changedLanguage }));
          }}
        />
        <FlexRow className="ml-4">
          {isLoggedIn ? (
            <UserProfile />
          ) : (
            <div className="login-btn">
              <Button
                className="!rounded-lg !px-5 !py-3 font-semibold"
                onClick={() =>
                  navigate('/login', { state: { isPublicPage: pathname } })
                }
              >
                Sign In
              </Button>
            </div>
          )}
        </FlexRow>
      </FlexRow>
    </header>
  );
};

export default Navbar;
