function createRiskLegendArray() {
  const colorMap = {
    '#dc1010': 'High',
    '#e8b620': 'Medium',
    '#13de13': 'Low',
    '#BDBDBD': 'No Risk',
  };
  return Object.entries(colorMap).map(([color, label]) => ({
    id: color,
    color,
    label,
  }));
}

export default function getLayerStyleColors(styles: Record<string, any>) {
  if (!('paint' in styles)) return null;
  const keys = Object.keys(styles.paint);
  const colorKey = keys.find(key => key.includes('color'));
  if (!colorKey) return null;
  const colorArr = styles.paint[colorKey];
  if (typeof colorArr === 'string')
    return [{ id: 1, color: colorArr, label: 'All' }];
  const legendArray = createRiskLegendArray();
  return legendArray;
}
