import Medium from '@Assets/images/public-page/HDPRP/medium.svg';
import Image from '@Components/RadixComponents/Image';
import { FlexColumn } from '@Components/common/Layouts';

export default function RiskCommunicationMedium() {
  return (
    <section className="grid grid-cols-5 items-center gap-20 rounded-md bg-[#037342] px-4 text-white md:px-16">
      <FlexColumn className="col-span-2 items-center gap-4">
        <Image src={Medium} width={80} />
        <div className="flex-1 !text-[1.5rem] font-semibold !leading-10">
          जोखिम संचारका माध्यम:
        </div>
      </FlexColumn>
      <FlexColumn
        gap={1}
        className="col-span-3 h-full w-full rounded-lg bg-white p-4 text-[#037342]"
      >
        <div className="flex gap-3">
          <div>
            <p className="flex h-[1.5rem] w-[1.5rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1rem] font-bold text-white">
              १
            </p>
          </div>
          <p className="text-left text-[1rem] leading-[1.688rem] text-[#037342]">
            घरधुरीस्तरीय विपद जोखिम व्यवस्थापन योजनाबाट प्राप्त गर्न सक्नुहुनेछ
            ।
          </p>
        </div>
        <div className="flex gap-3">
          <div>
            <p className="flex h-[1.5rem] w-[1.5rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1rem] font-bold text-white">
              २
            </p>
          </div>
          <p className="text-left text-[1rem] leading-[1.688rem] text-[#037342]">
            DASTAA मोबाइल एप
          </p>
        </div>
        <div className="flex gap-3">
          <div>
            <p className="flex h-[1.5rem] w-[1.5rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1rem] font-bold text-white">
              ३
            </p>
          </div>
          <p className="text-left text-[1rem] leading-[1.688rem] text-[#037342]">
            DASTAA सार्वजनिक पृष्ठ
          </p>
        </div>
        <div className="flex gap-3">
          <div>
            <p className="flex h-[1.5rem] w-[1.5rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1rem] font-bold text-white">
              ४
            </p>
          </div>
          <p className="text-left text-[1rem] leading-[1.688rem] text-[#037342]">
            Incoming IVR सेवाको लागि ३२१०० मा डायल गर्नुहोस्
          </p>
        </div>
        <div className="flex gap-3">
          <div>
            <p className="flex h-[1.5rem] w-[1.5rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1rem] font-bold text-white">
              ५
            </p>
          </div>
          <p className="text-left text-[1rem] leading-[1.688rem] text-[#037342]">
            Outgoing IVR सेवाको लागि ०१५९७०८५३ मा डायल गर्नुहोस्
          </p>
        </div>
        {/* <FlexRow className="items-start">
          <Icon name="arrow_top_left" className="rotate-180 !text-3xl" />
          <h4>
            घरधुरीस्तरीय विपद जोखिम व्यवस्थापन योजनाबाट प्राप्त गर्न सक्नुहुनेछ
            ।
          </h4>
        </FlexRow>
        <ul className="list-outside list-disc pl-4">
          <li>DASTAA मोबाइल एपबाट जानकारी लिन सक्नुहुनेछ ।</li>
          <li>DASTAA सार्वजनिक पृष्ठबाट प्राप्त गर्न सक्नुहुनेछ ।</li>
        </ul> */}
      </FlexColumn>
    </section>
  );
}
