import { Button } from '@Components/RadixComponents/Button';
import { Input } from '@Components/common/FormUI';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { setLayerState } from '@Store/actions/overlayLayer';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';

export default function FilterSection() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const searchInput = useTypedSelector(state => state.overlayLayer.searchInput);

  return (
    <FlexColumn>
      <FlexRow className="mb-3 flex-col justify-between md:flex-row">
        <FlexRow className="group relative w-full items-center border-b-2 px-3 hover:border-b-primary-600 md:w-1/4">
          <Icon
            name="search"
            className="text-grey-500 group-hover:text-primary-600"
          />
          <Input
            type="text"
            className="w-full border-none"
            value={searchInput}
            onChange={e =>
              dispatch(setLayerState({ searchInput: e.target.value }))
            }
          />
        </FlexRow>
        <FlexRow className="flex items-center justify-end gap-2 pt-3 md:py-0">
          <Button leftIcon="add" onClick={() => navigate('form')}>
            Add Layers
          </Button>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
