import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setPublicPageState } from '@Store/actions/publicPage';
import Tab from '@Components/common/Tab';

const tabData = [
  { id: 'pdf', title: 'PDFs' },
  { id: 'video', title: 'Videos' },
  { id: 'image', title: 'Images' },
];

export default function TabSection() {
  const dispatch = useTypedDispatch();

  const knowMoreInfoActiveTab = useTypedSelector(
    state => state.publicPage.knowMoreInfoActiveTab,
  );

  return (
    <div>
      <Tab
        data={tabData}
        activeId={knowMoreInfoActiveTab}
        onChange={tabId => {
          dispatch(
            setPublicPageState({
              knowMoreInfoActiveTab: tabId,
            }),
          );
        }}
      />
    </div>
  );
}
