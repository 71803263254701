/* eslint-disable no-nested-ternary */
import { useGetTotalUserQuery } from '@Api/common';
import { useGetHouseHoldRegionQuery } from '@Api/dashboard';
import Skeleton from '@Components/RadixComponents/Skeleton';
import DataCard from '@Components/common/DataCard';
import Flex from '@Components/common/Layouts/Flex';
import {
  featureCountData,
  getMunicipalCapacityCount,
} from '@Constants/dashboard';
import useTranslation from '@Hooks/useTranslation';

export default function DataCardSection() {
  const { _t } = useTranslation();

  const { data: householdRegionData, isLoading: isHouseholdRegionDataLoading } =
    useGetHouseHoldRegionQuery();

  const { data: totalUserData, isLoading: isTotalUserDataLoading } =
    useGetTotalUserQuery();

  // const { data: capacitanceCount, isLoading: isCapacitanceLoading } =
  //   useGetVectorLayerCountQuery();

  const updatedDataCount = featureCountData.map(item => ({
    ...item,
    count:
      item.id === 'resources'
        ? getMunicipalCapacityCount()
        : item.id === 'total_population'
        ? totalUserData?.total_pop !== undefined
          ? totalUserData.total_pop
          : item.count
        : householdRegionData?.[item.id] !== undefined
        ? householdRegionData[item.id]
        : item.count,
  }));

  if (
    isHouseholdRegionDataLoading ||
    // isCapacitanceLoading ||
    isTotalUserDataLoading
  ) {
    return (
      <Flex gap={5} className="grid sm:grid-cols-2 xl:grid-cols-4">
        {Array.from({ length: 4 }, (_, index) => (
          <Skeleton
            key={index}
            className="h-32 rounded-lg
               bg-primary-50 shadow-sm md:h-28"
          />
        ))}
      </Flex>
    );
  }

  return (
    <Flex gap={5} className="grid sm:grid-cols-2 xl:grid-cols-4">
      {updatedDataCount?.map(({ title, count, iconName }) => (
        <DataCard
          key={title}
          title={_t(title)}
          count={count}
          iconName={iconName}
        />
      ))}
    </Flex>
  );
}
