import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface TriggerMessageState {
  templateType: 'add-alert-template' | 'send-notifcation';
  activeAlertTab: number | null;
  triggerMessageTemplateData: Record<string, any>[];
  triggerMessageTemplateDataLoading: boolean;
}

const initialState: TriggerMessageState = {
  templateType: 'send-notifcation',
  activeAlertTab: null,
  triggerMessageTemplateData: [],
  triggerMessageTemplateDataLoading: false,
};

const setTriggerMessageState: CaseReducer<
  TriggerMessageState,
  PayloadAction<Partial<TriggerMessageState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const triggerMessageSlice = createSlice({
  name: 'triggerMessage',
  initialState,
  reducers: {
    setTriggerMessageState,
  },
});

export { triggerMessageSlice };

export default triggerMessageSlice.reducer;
