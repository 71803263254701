import Image from '@Components/RadixComponents/Image';
import Call from '@Assets/images/public-page/HDPRP/Tele.png';
import { tollFreeContacts } from '@Constants/hdprp';

export default function TollFreeContact() {
  return (
    <section className="rounded-lg  px-6 pt-8">
      <div className="header flex flex-col gap-5 md:flex md:flex-row md:items-center">
        <div className="flex gap-5">
          <Image src={Call} alt="call" width={48} />
          <p className="text-[1.6rem] font-bold leading-[48px] text-grey-950">
            टोल फ्री{' '}
          </p>
        </div>
        {/* <p className="text-2xl">
          (टोल फ्री नम्बरमा सम्पर्क गर्दा कुनै शुल्क लाग्दैन)
        </p> */}
      </div>
      <div className="mt-6 flex border-b border-[#dfe5fc] pb-4">
        <p className="w-1/2 text-[20px] font-bold  leading-[30px] text-grey-950">
          नाम
        </p>
        <p className="w-1/2 text-[20px] font-bold  leading-[30px] text-grey-950">
          सम्पर्क नम्बर
        </p>
      </div>
      {tollFreeContacts.map(contact => (
        <div
          key={contact.name}
          className="flex border-b-2 border-[#DFE5FC] px-2 py-2 even:bg-[#F8F8F8]"
        >
          <p className="w-1/2 text-[16px] font-medium leading-[24px] text-[#4C4C4C]">
            {contact.name}
          </p>
          <p className="w-1/2 text-[16px] font-medium leading-[24px] text-[#4C4C4C]">
            {contact.contact}
          </p>
        </div>
      ))}
    </section>
  );
}
