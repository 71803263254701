import { infoDialogTimeoutValue } from '@Constants/index';
import { toggleInfoDialog } from '@Store/actions/common';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useEffect } from 'react';
import RtmHeader from './RtmHeader';
import RtmTable from './RtmTable';

export default function Rtm() {
  const dispatch = useTypedDispatch();

  const infoDialogContent = useTypedSelector(
    state => state.common.infoDialogContent,
  );

  // reset info dialog content on unmount
  useEffect(() => {
    if (!infoDialogContent) return () => {};
    const timer = setTimeout(
      () => dispatch(toggleInfoDialog()),
      infoDialogTimeoutValue,
    );
    return () => {
      clearTimeout(timer);
    };
  }, [infoDialogContent, dispatch]);

  return (
    <div className="relative flex w-screen animate-fade flex-col gap-5 px-5 pt-5 md:h-screen">
      <h3 className=" text-grey-800">Real Time Monitering</h3>
      <div className="flex flex-col gap-3">
        {/* RTM header */}
        <RtmHeader />
        <RtmTable />
      </div>
      {/* <Tab key="THis ia" /> */}
    </div>
  );
}
