/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILayerState, defaultLayersData } from '@Constants/map';
import { FeatureCollection } from 'geojson';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { IconDataProp } from '@Components/Visualization/MapTools';
import { v4 as uuidv4 } from 'uuid';

export type ChangeStyleType = {
  layerId: string | number;
  category: string;
  styles: Record<string, any>;
};

type VisualizationOptionsType = {
  selectedOption: 'risk' | 'vulnerability';
};

export interface VisualizationState {
  activeBaseLayer: string;
  layers: {
    id: string;
    category: string;
    layers: ILayerState[];
  }[];
  is3DToggled: boolean;
  isFullScreenToggled: boolean;
  showFilterSection: boolean;
  expandFilter: boolean;
  changeStyle: ChangeStyleType | Record<string, any>;
  geolocation: Record<string, any> | null;
  zoomToLocationType: 'geolocation' | 'user-input' | null;
  inputCoordinates: number[];
  measureType: 'length' | 'area' | null;
  measureResult: Record<string, any> | null;
  measureFrom: 'exposure_assessment' | null;
  drawnArea: Record<string, any> | null;
  visualizationOptions: VisualizationOptionsType;
  activeTab: IconDataProp['id'];
}

const initialState: VisualizationState = {
  activeBaseLayer: 'satellite',
  layers: defaultLayersData,
  is3DToggled: true,
  isFullScreenToggled: false,
  showFilterSection: false,
  expandFilter: false,
  changeStyle: {},
  geolocation: null, // [lat, lng]
  zoomToLocationType: null,
  inputCoordinates: [],
  measureType: null,
  measureResult: null,
  measureFrom: null,
  drawnArea: null,
  visualizationOptions: {
    selectedOption: 'risk',
  },
  activeTab: 'visualization',
};

const setVisualizationState: CaseReducer<
  VisualizationState,
  PayloadAction<Partial<VisualizationState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const handle3DToggle: CaseReducer<VisualizationState> = state => {
  // eslint-disable-next-line no-param-reassign
  state.is3DToggled = !state.is3DToggled;
};

const setShowFilterSection: CaseReducer<VisualizationState> = state => {
  // eslint-disable-next-line no-param-reassign
  state.showFilterSection = !state.showFilterSection;
  // eslint-disable-next-line no-param-reassign
  state.expandFilter = false;
};

const setExpandFilter: CaseReducer<VisualizationState> = state => {
  // eslint-disable-next-line no-param-reassign
  state.expandFilter = !state.expandFilter;
};

const setSitesRegionBoundary: CaseReducer<VisualizationState> = (
  state,
  action,
) => {
  const areaGeojson = action.payload
    .map((item: any) => item.area_json)
    .filter((item: any) => item !== null);
  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: areaGeojson,
  };
  return {
    ...state,
    layers: state.layers.map(layer =>
      layer.category === 'baseline_data'
        ? {
            ...layer,
            layers: layer.layers.map(item =>
              item.id === 'region-boundary'
                ? { ...item, geojson }
                : { ...item },
            ),
          }
        : layer,
    ),
  };
};

const getOverlayLayersSuccess: CaseReducer<
  VisualizationState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { layerData, layerIconData } = action.payload;
  const uniqueCategories = [
    ...new Set(layerData.map((item: any) => item.category)),
  ];
  const modifiedData = uniqueCategories.map(category => ({
    id: uuidv4(),
    category,
    layers: layerData
      .filter((item: any) => item.category === category)
      .map((item: any) => ({
        id: item.id,
        name: item.display_name_en,
        endPoint: 'vector-layer',
        checked: false,
        styles: {
          type:
            // eslint-disable-next-line no-nested-ternary
            item.geometry_type === 'Polygon'
              ? 'fill'
              : item.geometry_type === 'LineString'
                ? 'line'
                : 'circle',
          layout: {},
          paint: item.style || {},
        },
        showLabel: false,
        type: 'overlay-layer',
        dynamicStyle: item.style,
        isSubLayerActive: item.is_sublayer_active,
        dynamicSubLayerStyle: item.sublayer_style,
        icon:
          layerIconData.find((layerIcon: any) => layerIcon.name_en === category)
            ?.image || null,
      })),
  }));
  // @ts-ignore
  state.layers = [...state.layers, ...modifiedData];
};

const handleLabelToggle: CaseReducer<
  VisualizationState,
  PayloadAction<string | number>
  // eslint-disable-next-line no-unused-vars
> = (state, action) => {
  // const layer = state.layers.find(item => item.id === action.payload);
  // if (!layer) return;
  // layer.showLabel = !layer.showLabel;
};

const handleLayerSort: CaseReducer<
  VisualizationState,
  PayloadAction<DragEndEvent>
> = (state, action) => {
  const { active, over } = action.payload;
  // if (!over?.id) return;
  // if (active.id === over.id) return;
  // const oldIndex = state.layers.findIndex(item => item.id === active.id);
  // const newIndex = state.layers.findIndex(item => item.id === over.id);
  // const sortedLayers = arrayMove(state.layers, oldIndex, newIndex);
  // state.layers = sortedLayers;
  // // @ts-ignore
  // state.layerOrderChanged =
  //   newIndex > oldIndex ? [active.id, over.id] : [over.id, active.id];
};

const handleLayerStyleChange: CaseReducer<
  VisualizationState,
  PayloadAction<ChangeStyleType>
  // eslint-disable-next-line no-unused-vars
> = (state, action) => {
  const { layerId, category, styles } = action.payload;
  const selectedLayerCategory = state.layers.find(
    layer => layer.category === category,
  );
  const selectedLayer = selectedLayerCategory?.layers?.find(
    item => item.id === layerId,
  );
  if (!selectedLayer?.styles) return;
  const keys = Object.keys(styles);
  if (!(keys[0] in selectedLayer.styles.paint)) return;
  selectedLayer.styles.paint = {
    ...selectedLayer.styles.paint,
    ...styles,
  };
  state.changeStyle = action.payload;
};

const handleLayerToggle: CaseReducer<
  VisualizationState,
  PayloadAction<{ layerId: string | number; category: string }>
> = (state, action) => {
  const { layerId, category } = action.payload;
  const toggledLayerCategory = state.layers.find(
    layer => layer.category === category,
  );
  const toggledLayer = toggledLayerCategory?.layers?.find(
    item => item.id === layerId,
  );
  if (!toggledLayer) return;
  toggledLayer.checked = !toggledLayer.checked;
};

const setVisualizationOptions: CaseReducer<
  VisualizationState,
  PayloadAction<Partial<VisualizationOptionsType>>
> = (state, action) => ({
  ...state,
  visualizationOptions: {
    ...state.visualizationOptions,
    ...action.payload,
  },
});

const visualizationSlice = createSlice({
  name: 'visualization',
  initialState,
  reducers: {
    handle3DToggle,
    setVisualizationState,
    setShowFilterSection,
    setExpandFilter,
    setSitesRegionBoundary,
    handleLabelToggle,
    handleLayerSort,
    handleLayerStyleChange,
    handleLayerToggle,
    setVisualizationOptions,
    getOverlayLayersRequest() {},
    getOverlayLayersSuccess,
    getOverlayLayersFailure() {},
  },
});

export { visualizationSlice };

export default visualizationSlice.reducer;
