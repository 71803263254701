import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import OldMan1 from '@Assets/images/public-page/HDPRP/OldMan1.svg';
import DisabledMan from '@Assets/images/public-page/HDPRP/DisabledMan1.svg';
import PregnantWoman from '@Assets/images/public-page/HDPRP/PregnantWoman.svg';
import SickMan from '@Assets/images/public-page/HDPRP/permanent-sickness.svg';
// import { useTypedSelector } from '@Store/hooks';

const sectionsData = {
  disabled: {
    title: 'अपाङ्गता भएका व्यक्ति बस्ने घरले के के कार्य अपनाउने ?',
    imgSrc: DisabledMan,
    dataList: [
      'अपांगता कार्ड नभिज्ने गरि पहुंचमा राख्ने ।',
      'अपांगता भएका व्यक्तिहरूको विशेष ख्याल राख्ने र विपदको बेला बिशेष प्राथमिकता दिने ।',
      'अत्यावश्यक सामग्रीहरु (जस्तै मोबाइल, टर्च, औषधि, अपांगता परिचय पत्र) अपांगता भएको व्यक्तिको पहुंचमा राख्ने ।',
      'अपांगता भएका व्यक्तिहरूको सहज हिसाबले सुरक्षित स्थानसम्म स्थानान्तरण गर्नका लागि ।',
      'आवश्यकता अनुरूप आपतकालीन योजना विकास गर्ने । धैर्य रहने र सहयोगको लागि अरुलाई बोलाउने (सिट्ठी मार्फत, या शंख, घण्ट, या थाल उपकरण प्रयोग गर्ने ) ।',
    ],
  },
  pregnant: {
    title: 'गर्भवती/ सुत्केरी महिला बस्ने घरले के के कार्य अपनाउने?',
    imgSrc: PregnantWoman,
    dataList: [
      'अत्यावश्यक सामग्रीहरु (जस्तै मोबाइल, टर्च, सफा टालो, आइरन चक्की, औषधि, आदि) पहुंचमा राख्ने ।',
      'गर्भवती / सुत्केरी महिलाहरुलाई सहज हिसाबले सुरक्षित स्थानसम्म स्थानान्तरण गर्नका लागि आवश्यकता अनुरूप आपतकालीन योजना विकास गर्ने ।',
      'विपद्को प्रतिकार्यको दौरानमा गर्भवती / सुत्केरी महिलाहरुलाई विशेष प्राथमिकता दिने ।',
    ],
  },
  old: {
    title: 'बालबालिका या बृद्ध /बृद्धा बस्ने घरले के के कार्य अपनाउन ?',
    imgSrc: OldMan1,
    dataList: [
      'सामाजिक सुरक्षा भत्ता कार्ड नभिज्ने गरि पहुंचमा राख्ने ।',
      'विपद्को प्रतिकार्यको दौरानमा बालबालिका तथा बृद्ध/बृद्धा हरुलाई विशेष प्राथमिकता दिने ।',
      'बालबालिका तथा बृद्ध/बृद्धा हरुको पहुँचमा हतियार वा अन्य हानिकारक सामग्री नराख्ने ।',
      'बालबालिका तथा बृद्ध/बृद्धा हरुलाई सहज हिसाबले सुरक्षित स्थानसम्म स्थानान्तरण गर्नका लागि आवश्यकता अनुरूप आपतकालीन योजना विकास गर्ने ।',
      'बालबालिकाको किताब कापी र अन्य महत्वपूर्ण सामानहरु नाभिज्ने ठाउमा राख्ने ।',
      'शिशु वा बालबालिकाको विशेष खाना जगेडा र पहुंचमा राख्ने ।',
    ],
  },
  illness: {
    title: 'दीर्घरोग भएको व्यक्ति बस्ने घरले के के कार्य अपनाउने ?',
    imgSrc: SickMan,
    dataList: [
      'दिर्घरोघीलाइ आवश्यक पर्ने अत्यावश्यक औषधिहरुलाई पहुंचमा राख्ने ।',
      'आवश्यक परेमा निकासी सहयोगको लागि स्थानीय अधिकारीहरूसँग समन्वय गर्ने ।',
      'स्वास्थ्य संस्था वा आवश्यक सेवाको फोन नम्बर पहुंचमा राख्ने ।',
    ],
  },
};

interface ISectionProps {
  title: string;
  imgSrc: string;
  dataList: string[];
  // pdfEnabled?: boolean;
}

const Section = ({ title, imgSrc, dataList }: ISectionProps) => (
  <section className="grid overflow-hidden rounded-lg md:grid-cols-3">
    <FlexRow className="items-center justify-center bg-[#3397f3]  p-5 md:col-span-1">
      <FlexRow gap={5} className="items-center">
        <img src={imgSrc} alt="icon" width={60} className="max-h-24" />
        <p className="text-base font-bold  text-white">{title}</p>
      </FlexRow>
    </FlexRow>
    <div className="bg-[#E2ECF7] px-5 py-3 md:col-span-2 ">
      <ul className="ml-4 flex list-disc flex-col gap-2">
        {dataList.map((item: string) => (
          <li key={item} className="text-xs text-grey-950">
            {item}
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default function VulnerablePeople() {
  // const vulnerableStatus = useTypedSelector(
  //   state => state.publicPage.vulnerableStatus,
  // );

  // const isPDFExportEnabled = useTypedSelector(
  //   state => state.publicPage.isPDFExportEnabled,
  // );

  return (
    <FlexColumn className="gap-4">
      {/* {vulnerableStatus?.disability && ( */}
      <Section
        {...sectionsData.disabled}
        // pdfEnabled={isPDFExportEnabled}
      />
      {/* )} */}
      {/* {vulnerableStatus?.pregnent_women && ( */}
      <Section
        {...sectionsData.pregnant}
        // pdfEnabled={isPDFExportEnabled}
      />
      {/* )} */}
      {/* {vulnerableStatus?.old_person && ( */}
      <Section
        {...sectionsData.old}
        //  pdfEnabled={isPDFExportEnabled}
      />
      {/* )} */}
      {/* {vulnerableStatus?.ill_ness && ( */}
      <Section
        {...sectionsData.illness}
        // pdfEnabled={isPDFExportEnabled}
      />
      {/* )} */}
    </FlexColumn>
  );
}
