import { useGetVectorLayerCountQuery } from '@Api/dashboard';
import RiskCommunicationMedium from '../RiskCommunicationMedium';

export default function PalikaCapacity() {
  const { data: capacityAndResourcesCount } = useGetVectorLayerCountQuery();

  return (
    <div className="two-cards grid grid-cols-5 gap-5">
      <div className="col-span-1 flex flex-col rounded-lg bg-[#08688B] px-3 py-11 text-center">
        <p className="text-[1.25rem] font-bold leading-8 text-white">
          पालिकामा क्षमता र स्रोत संख्या
        </p>
        <p className="text-[3.75em] font-bold leading-[80px] text-white">
          <>{capacityAndResourcesCount}</>
        </p>
      </div>
      <div className="col-span-4 flex flex-col rounded-lg bg-[#037342] px-3 py-11 text-center">
        <RiskCommunicationMedium />
        {/* <p className="text-[1.25rem] font-bold leading-8 text-white">
          वडामा क्षमता र स्रोत संख्या
        </p>
        <p className="text-[3.75em] font-bold leading-[80px] text-white">
          <>{capacityAndResourcesCount}</>
        </p> */}
      </div>
    </div>
  );
}
