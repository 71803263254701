/* eslint-disable no-unused-vars */
import { AxiosResponse } from 'axios';
import { getUserRoleUniqueFields } from '@Services/users';
import validatePassword from '@Utils/validatePassword';
import { FormFieldType } from '@Components/common/FormHelpers/types';

export const usersFormSchema: FormFieldType[] = [
  {
    id: 'first_name',
    label: 'First Name',
    type: 'input',
    placeholder: 'First Name',
    required: true,
  },
  {
    id: 'last_name',
    label: 'Last Name',
    placeholder: 'Last Name',
    type: 'input',
    required: true,
  },
  {
    id: 'username',
    label: 'Username',
    placeholder: 'Username',
    type: 'input',
    required: true,
  },
  {
    id: 'gender',
    label: 'Gender',
    placeholder: 'Gender',
    type: 'select',
    required: true,
  },
  {
    id: 'password',
    label: 'Password',
    placeholder: 'Password',
    type: 'input',
    inputType: 'password',
    required: true,
    validationSchema: {
      matchPattern: (value: any) => validatePassword(value),
    },
  },

  {
    id: 'confirm_password',
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
    type: 'input',
    inputType: 'password',
    required: true,
    comparingKey: 'password',
  },
  {
    id: 'email',
    label: 'Email',
    placeholder: 'Email',
    type: 'input',
    required: false,
    validationSchema: {
      matchPattern: (value: any) =>
        !value ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
        'Email address must be a valid address',
    },
  },
  {
    id: 'address',
    label: 'Address',
    placeholder: 'Address',
    type: 'input',
    required: false,
  },
  {
    id: 'role',
    label: 'Role',
    placeholder: 'Role',
    type: 'select',
    required: true,
  },
  {
    id: 'image',
    label: 'Photo',
    type: 'file-upload',
    required: false,
    layout: 'grid-cols-2',
  },
];

export const genderOptions = [
  { name: 'male', alias: 'Male' },
  { name: 'female', alias: 'Female' },
  { name: 'others', alias: 'Others' },
];

export type FormOptionsRequestDataType = {
  queryKey: string[];
  queryFn: (params: any) => Promise<AxiosResponse<any, any>>;
  params: { field_name: string };
}[];

export const usersFormOptionsRequestData: FormOptionsRequestDataType = [
  {
    queryKey: ['user-role-unique-fields'],
    queryFn: getUserRoleUniqueFields,
    params: {
      field_name: 'role',
    },
  },
];

export default usersFormSchema;
