import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface MapExportState {
  isExportOn: boolean;
  mapTitle: string;
  pageSize: 'a4' | 'a3' | 'a2' | 'a1' | 'a0';
  currentPaperSize: number[];
  orientation: 'landscape' | 'potrait';
  outputFormat: 'pdf' | 'png' | 'jpeg' | 'print';
  mapRotation: string;
  baseLayerOpacity: number;
  margin: string;
  marginValue: number;
  marginColor: string;
  isLegendOn: boolean;
  isNorthArrowOn: boolean;
  isScaleBarOn: boolean;
  isGridOn: boolean;
  isOverlayOn: boolean;
  marginTop: number;
  marginRight: number;
  marginBottom: number;
  marginLeft: number;
}

const initialState: MapExportState = {
  isExportOn: false,
  mapTitle: '',
  pageSize: 'a4',
  currentPaperSize: [210, 287],
  orientation: 'landscape',
  outputFormat: 'png',
  mapRotation: '0',
  baseLayerOpacity: 100,
  margin: '0',
  marginValue: 0,
  marginColor: '#FFFFFF',
  isLegendOn: true,
  isNorthArrowOn: true,
  isScaleBarOn: false,
  isGridOn: false,
  isOverlayOn: false,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
};

const setMapExportState: CaseReducer<
  MapExportState,
  PayloadAction<Partial<MapExportState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const mapExportSlice = createSlice({
  name: 'mapExport',
  initialState,
  reducers: {
    setMapExportState,
  },
});

export { mapExportSlice };

export default mapExportSlice.reducer;
