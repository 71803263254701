/* eslint-disable import/prefer-default-export */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getSignUpFormCountryList,
  getSignUpFormSecurityQuestion,
  setCsrf,
} from '@Services/authentication';

export const useSetCSRFQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['set-csrf'],
    queryFn: setCsrf,
    ...queryOptions,
  });
};

export const useSignUpFormCountryListQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['sign-up-country-list'],
    queryFn: getSignUpFormCountryList,
    ...queryOptions,
  });
};

export const useSignUpFormSecurityQuestionQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['sign-up-security-question'],
    queryFn: getSignUpFormSecurityQuestion,
    ...queryOptions,
    select: res => res,
  });
};
