import { cn } from '@Utils/index';
import { HTMLAttributes } from 'react';

interface ITabBtnProps extends HTMLAttributes<HTMLButtonElement> {
  activeTab: string;
  name: string;
}

export default function TabBtn({ activeTab, onClick, name, id }: ITabBtnProps) {
  return (
    <button
      type="button"
      className={cn(
        ' px-3 py-2 text-left text-[0.875rem] font-semibold capitalize text-[#757575]',
        activeTab === id
          ? 'border-b-2 border-b-[#01A0A2] bg-transparent text-[#01A0A2]'
          : 'border-b-2 border-b-transparent',
      )}
      onClick={(e: any) => {
        if (onClick) onClick(e);
      }}
    >
      {name}
    </button>
  );
}
