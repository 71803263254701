import formatNumberWithCommas from '@Utils/formatNumberWithCommas';
import { cn } from '@Utils/index';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Icon from '../Icon';
import RoundedContainer from '../RoundedContainer';

interface DataCardProps {
  title: string;
  count: number;
  iconName: string;
  className?: string;
}

export default function DataCard({
  title,
  count,
  iconName,
  className,
}: DataCardProps) {
  return (
    <RoundedContainer
      className={cn(`${className} h-full w-full min-w-[180px] bg-primary-50 px-5 py-4
      shadow-md`)}
    >
      <FlexColumn>
        <h5>{title}</h5>
        <FlexRow className="items-center justify-between text-[36px] font-bold text-primary-600">
          <FlexRow>{formatNumberWithCommas(count)}</FlexRow>
          <Icon
            name={iconName}
            className="!cursor-default !text-[38px] text-primary-600"
          />
        </FlexRow>
      </FlexColumn>
    </RoundedContainer>
  );
}
