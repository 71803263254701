import { Input } from '@Components/common/FormUI';
import { FlexColumn, Grid } from '@Components/common/Layouts';
import { MAX_ZOOM } from '@Constants/overlayLayers';
import { Label } from '@radix-ui/react-label';

interface IZoomLevelCustomization {
  minZoom: number;
  maxZoom: number;
  // eslint-disable-next-line no-unused-vars
  handleChange: (zoomValue: number) => void;
}

function ZoomLevelCustomization({
  minZoom,
  maxZoom,
  handleChange,
}: IZoomLevelCustomization) {
  return (
    <Grid gap={3} className="py-4">
      <FlexColumn>
        <Label className="text-sm">Zoom level from</Label>
        <Input
          type="number"
          value={minZoom}
          readOnly={minZoom === 0}
          onChange={e => {
            handleChange(Number(e.target.value));
          }}
        />
      </FlexColumn>
      <FlexColumn>
        <Label className="text-sm">Zoom level to</Label>
        <Input type="number" value={maxZoom} readOnly={maxZoom === MAX_ZOOM} />
      </FlexColumn>
    </Grid>
  );
}

export default ZoomLevelCustomization;
