/* eslint-disable import/prefer-default-export */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  getHouseHoldRegion,
  getSiteRiskPlot,
  getVectorLayerCount,
} from '@Services/dashboard';

export const useGetHouseHoldRegionQuery = () => {
  return useQuery({
    queryKey: ['household-region'],
    queryFn: () => getHouseHoldRegion({}),
    select: res => res.data,
  });
};

export const useGetVectorLayerCountQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['vector-layer-count'],
    queryFn: getVectorLayerCount,
    select: (res: any) => res?.data?.count,
    ...queryOptions,
  });
};

export const useGetSiteRiskPlotQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['site-risk-plot'],
    queryFn: getSiteRiskPlot,
    select: (res: any) => res?.data,
    ...queryOptions,
  });
};
