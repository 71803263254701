import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { forgotPassword } from '@Services/authentication';
import { toggleModal } from '@Store/actions/common';
import { useTypedDispatch } from '@Store/hooks';
import { FlexRow } from '@Components/common/Layouts';
import Label from '@Components/common/FormUI/Label';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';
import FormControl from '@Components/common/FormUI/FormControl';
import Input from '@Components/common/FormUI/Input';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';

const initialState = {
  email: '',
};
export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { pathname, state: isPublicPage } = useLocation();

  const checkPublicPage = !!isPublicPage;

  const { mutate, isLoading, error } = useMutation<any, any, any, unknown>({
    mutationFn: forgotPassword,
    onSuccess: () => {
      dispatch(toggleModal('change-password'));
    },
    onError: (err: Record<string, any>) => {
      toast.error(err?.response?.data?.error);
    },
  });

  const { register, handleSubmit } = useForm({
    defaultValues: initialState,
  });

  const onSubmit = (data: any) => {
    mutate(data);
  };

  return (
    <>
      <div className="forgot-password">
        <FlexRow className="mx-auto mb-3 h-14 w-14">
          <Icon
            name="lock_reset"
            className="flex h-full w-full items-center justify-center  rounded-[80px] bg-primary-600 px-4 py-[0.9375rem] !text-2xl text-white"
          />
        </FlexRow>
        <h3 className=" my-3 font-semibold text-grey-800">
          Forgot Your Password?
        </h3>
        <p className="w-80 text-center text-body-md text-grey-800">
          {checkPublicPage
            ? 'Select the security questions and answer them to reset your password.'
            : 'Enter the email address and we will send you a link to reset your password.'}
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-[60%] flex-col gap-5 pt-7"
      >
        <FormControl>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            placeholder="Email"
            {...register('email', { required: true })}
          />
          <ErrorMessage message={error?.response?.data?.Message || ''} />
        </FormControl>

        <Button withLoader isLoading={isLoading} type="submit">
          Reset Password
        </Button>
      </form>

      {/* {checkPublicPage && <Button variant="ghost">Try Another Way</Button>} */}
      <Button
        variant="ghost"
        onClick={() => {
          if (checkPublicPage) {
            navigate('/login', {
              state: { isPublicPage: pathname },
            });
          } else {
            navigate('/login');
          }
        }}
        className="mt-2"
      >
        Back to Sign In
      </Button>
    </>
  );
}
