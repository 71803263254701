import { useCallback, useMemo } from 'react';
import { useTypedSelector } from '@Store/hooks';
import { FlexColumn } from '@Components/common/Layouts';
import { MAX_ZOOM, ZOOM_INTERVAL_ON_NEW_ADD } from '@Constants/overlayLayers';
import {
  addNewZoomLevelFuncType,
  handleDeleteZoomLevelFuncType,
  styleChangeHandlerType,
} from '..';
import ZoomBasedStyle from '../common/ZoomBasedStyle';
import LayerStyleController from './LayerStyleController';
import AddZoomLevel from '../common/AddZoomLevel';

interface ILayerStyles {
  styleChangeHandler: styleChangeHandlerType;
  addNewZoomLevel: addNewZoomLevelFuncType;
  handleDeleteZoomLevel: handleDeleteZoomLevelFuncType;
}

function LayerStyles({
  styleChangeHandler,
  addNewZoomLevel,
  handleDeleteZoomLevel,
}: ILayerStyles) {
  const layerZoomLevels = useTypedSelector(
    state => state.overlayLayer.layerZoomLevels.layer,
  );
  const subLayerZoomLevels = useTypedSelector(
    state => state.overlayLayer.layerZoomLevels.subLayer,
  );
  const isActiveSubLayer = useTypedSelector(
    state => state.overlayLayer.isActiveSubLayer,
  );
  const selectedSubLayer = useTypedSelector(
    state => state.overlayLayer.selectedSubLayer,
  );

  const zoomLevels = useMemo(
    () =>
      isActiveSubLayer && selectedSubLayer
        ? subLayerZoomLevels
        : layerZoomLevels,
    [isActiveSubLayer, selectedSubLayer, layerZoomLevels, subLayerZoomLevels],
  );

  const getMaxZoomLevel = useCallback(
    (index: number) => {
      if (index === zoomLevels.length - 1) return MAX_ZOOM;
      return zoomLevels[index + 1].min - 1;
    },
    [zoomLevels],
  );

  return (
    <FlexColumn className="h-full justify-between">
      <FlexColumn className="scrollbar overflow-y-auto px-2">
        {zoomLevels.map((zoom, index) => (
          <ZoomBasedStyle
            key={zoom.id}
            minZoom={zoom.min}
            maxZoom={getMaxZoomLevel(index)}
            index={index}
            zoomLevels={zoomLevels}
            styleType="layer"
            defaultOpen={false}
            handleDeleteZoomLevel={handleDeleteZoomLevel}
          >
            <LayerStyleController
              styleChangeHandler={styleChangeHandler}
              minZoomValue={zoom.min}
            />
          </ZoomBasedStyle>
        ))}
      </FlexColumn>
      <AddZoomLevel
        // disable if the last zoom level exceeds the limit
        disabled={
          zoomLevels[zoomLevels.length - 1]?.min >=
          MAX_ZOOM - ZOOM_INTERVAL_ON_NEW_ADD
        }
        // pass last zoom level value
        handleAddClick={() =>
          addNewZoomLevel(zoomLevels[zoomLevels.length - 1].min, 'layer')
        }
      />
    </FlexColumn>
  );
}

export default LayerStyles;
