import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { UniqueIdentifier } from '@dnd-kit/core';

interface ISortableItemProps {
  id: UniqueIdentifier;
  content: () => JSX.Element;
}

const SortableItem = ({ id, content }: ISortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {/* @ts-ignore */}
      {content({ listeners, attributes })}
    </div>
  );
};

export default SortableItem;
