import { authenticationSlice } from '@Store/slices/authentication';

// eslint-disable-next-line import/prefer-default-export
export const {
  setAuthenticationState,
  setSignUpCountryList,
  setSignUpDistrictList,
  setSignUpMunicipalityList,
  setSignUpSecurityQuestion,
  setSelectedCountryState,
} = authenticationSlice.actions;
