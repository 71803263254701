import './styles.css';

export default function MapLoader() {
  return (
    <div
      className="absolute top-5 z-50 flex w-full 
        items-center justify-center"
    >
      <div
        className="flex items-center justify-center rounded-lg
          bg-white px-9 py-4 shadow-3xl shadow-grey-300"
      >
        <div className="dot-pulse" />
      </div>
    </div>
  );
}
