import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from 'recharts';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import { BarChartFills } from '../constants';
import { IChartProps } from '../types';

function truncateString(str: string, num: number) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`;
}

export const CustomizedLabel = (props: any) => {
  // eslint-disable-next-line react/prop-types
  const { x, y, payload, deviceWidth } = props;
  const isPortrait = deviceWidth > 1080;
  const rotateAngle = isPortrait ? 0 : -25;
  const isHeight = isPortrait ? 0 : 15;

  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        dy={isHeight}
        fontSize={14}
        textAnchor="middle"
        transform={`rotate(${rotateAngle})`}
        width="60"
        verticalAnchor="start"
      >
        {isPortrait
          ? payload?.value
          : truncateString(payload?.value?.toString() || '', 5)}
      </Text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip relative z-20 rounded-xl border-2 bg-white px-3 py-2 text-sm text-primary-600">
        <div className="flex items-center gap-5">
          <span>Name</span>
          <p className="label font-bold">{label}</p>
        </div>
        {payload?.map((item: any) => {
          if (item.dataKey !== 'id')
            return (
              <div
                key={item.dataKey}
                className="flex w-fit items-center justify-between gap-5"
              >
                <div className="flex items-center justify-center gap-1">
                  <div
                    className="h-3 w-3 rounded-sm"
                    style={{ backgroundColor: `${item?.fill}` }}
                  />
                  <span>{capitalizeFirstLetter(item.dataKey)}</span>
                </div>
                <p className="font-semibold">
                  {typeof item.value === 'number'
                    ? item.value
                    : capitalizeFirstLetter(item.value)}
                </p>
              </div>
            );
          return <></>;
        })}
      </div>
    );
  }

  return null;
};

export default function CustomBarChart({
  data,
  fills = BarChartFills,
  barSize = 28,
  scrollable = false,
  Ylabel = {},
  Xlabel = {},
  width = '150%',
}: IChartProps) {
  const { width: deviceWidth } = useWindowDimensions();

  // const keys = Object.keys(data.length > 0 ? data[0] : {});
  const dataObject = data?.length > 0 ? data[0] : {};

  const { name, ...datax } = dataObject;
  const keys = Object.keys(datax);

  return (
    <ResponsiveContainer
      width={scrollable && width ? width : '100%'}
      minHeight={200}
      maxHeight={320}
    >
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 20,
        }}
      >
        <CartesianGrid vertical={false} stroke="#DDD" />
        <XAxis
          dataKey="name"
          style={{
            fontSize: '12px',
            color: '#212121',
          }}
          tickLine={false}
          tick={<CustomizedLabel deviceWidth={deviceWidth} />}
          interval={0}
          label={Xlabel}
        />

        <YAxis
          yAxisId="left"
          style={{
            fontSize: '12px',
            color: '#212121',
          }}
          tickLine={false}
          label={Ylabel}
        />

        <Tooltip content={<CustomTooltip />} />
        {keys.map((key, ind: number) => (
          <Bar
            key={key}
            yAxisId="left"
            dataKey={key}
            fill={fills[ind]}
            barSize={barSize}
            radius={[4, 4, 0, 0]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
