import { api, authenticated, formDataAPI } from '.';

export const getTriggerMessageType = () =>
  authenticated(api).get('/dastaa/trigger-message-type/');

export const getTriggerMessageTemplate = () =>
  authenticated(api).get('/dastaa/trigger-message-template-no-paginate/');

export const postTriggerMessage = (data: any) =>
  authenticated(formDataAPI).post('/dastaa/trigger-message/', data);

export const postTriggerMessageTemplate = (data: any) =>
  authenticated(formDataAPI).post('/dastaa/trigger-message-template/', data);

export const getTriggerMessageTemplatePaginated = (
  params: Record<string, any>,
) => authenticated(api).get('/dastaa/trigger-message-template/', { params });
