/* eslint-disable no-param-reassign */
import axios from 'axios';
import type { AxiosInstance } from 'axios';
import getCookie from '@Utils/cookieUtils';

const {
  USER_API,
  BASE_URL,
  HWCW_URL,
  GEOSERVER_URL,
  GEOSERVER_URL_DASTAA,
  DRONE_ORTHO_URL,
} = process.env;

export const baseUrl = BASE_URL;
export const hwcwUrl = HWCW_URL;
export const geoserverUrl = GEOSERVER_URL;
export const geoserverUrlDastaa = GEOSERVER_URL_DASTAA;
export const droneOrthoUrl = DRONE_ORTHO_URL;

export const api = axios.create({
  baseURL: BASE_URL,
  timeout: 5 * 60 * 1000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

export const formDataAPI = axios.create({
  baseURL: BASE_URL,
  timeout: 5 * 60 * 1000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const userAPI = axios.create({
  baseURL: USER_API,
  timeout: 5 * 60 * 1000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

export const hwcwAPI = axios.create({
  baseURL: HWCW_URL,
  timeout: 5 * 60 * 1000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const authenticated = (apiInstance: AxiosInstance) => {
  const newApiInstance = axios.create({
    ...apiInstance.defaults,
  });

  if (process.env.NODE_ENV === 'development') {
    const token = localStorage.getItem('token');
    if (token) {
      newApiInstance.defaults.headers.common.Authorization = `Token ${token}`;
    }
  } else {
    // Set CSRF token for relevant request methods
    newApiInstance.defaults.headers.post['X-CSRFToken'] =
      getCookie('csrftoken');
    newApiInstance.defaults.headers.patch['X-CSRFToken'] =
      getCookie('csrftoken');
    newApiInstance.defaults.headers.delete['X-CSRFToken'] =
      getCookie('csrftoken');
    // Ensure credentials are included in requests
    newApiInstance.defaults.withCredentials = true;
  }

  return newApiInstance;
};

export const authenticatedUserAPI = (apiInstance: AxiosInstance) => {
  const newApiInstance = axios.create({
    ...apiInstance.defaults,
  });

  if (process.env.NODE_ENV === 'development') {
    const token = localStorage.getItem('token');
    if (token) {
      newApiInstance.defaults.headers.common.Authorization = `Token ${token}`;
    }
  } else {
    // Set CSRF token for relevant request methods
    newApiInstance.defaults.headers.post['X-CSRFToken'] =
      getCookie('csrftoken');
    newApiInstance.defaults.headers.patch['X-CSRFToken'] =
      getCookie('csrftoken');
    newApiInstance.defaults.headers.delete['X-CSRFToken'] =
      getCookie('csrftoken');
    // Ensure credentials are included in requests
    newApiInstance.defaults.withCredentials = true;
  }
  return newApiInstance;
};
