/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { ILayerState, defaultLayersDataPublicPage } from '@Constants/map';
import { FormOptionsRequestDataType } from '@Constants/publicPage';
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import centroid from '@turf/centroid';
import { featureCollection, point } from '@turf/helpers';
import { FeatureCollection } from 'geojson';
import { v4 as uuidv4 } from 'uuid';

export interface PublicPageState {
  activeBaseLayer: string;
  summaryCharts: {
    tabList: Record<string, any>[];
    activeTab: Record<string, any>;
  };
  layers: {
    id: string;
    category: string;
    layers: ILayerState[];
  }[];
  currentLocation: Record<string, any>;
  selectedMetricForChart: Record<string, any> | null;
  siteId: string;
  singleRiskData: Record<string, any>;
  weatherStationsData: Record<string, any>[];
  currentPlaceWeatherData: Record<string, any>;
  currentPlaceWeatherDataWindy: Record<string, any>;
  selectedSource: 'weatherapi' | 'windy';
  selectedOrganizationType: 'GOV' | 'NGO' | string;
  knowMoreInfoActiveTab: 'pdf' | 'audio' | 'video' | string;
  selectedComponentLabel: string;
  selectedParameterLabel: string;
  selectedParameterValue: string | number | null;
  vulnerableStatus: Record<string, any>;
  regionCentroid: {
    lat: number | null;
    lon: number | null;
  };
  isUserHdrmpLoading: boolean;
  isPDFExportEnabled: boolean;
}

const initialState: PublicPageState = {
  activeBaseLayer: 'satellite',
  summaryCharts: { tabList: [], activeTab: {} },
  layers: defaultLayersDataPublicPage,
  currentLocation: {},
  selectedMetricForChart: null,
  siteId: '',
  singleRiskData: {},
  weatherStationsData: [],
  currentPlaceWeatherData: {},
  currentPlaceWeatherDataWindy: {},
  selectedSource: 'weatherapi',
  selectedOrganizationType: 'GOV',
  knowMoreInfoActiveTab: 'pdf',
  selectedComponentLabel: '',
  selectedParameterLabel: '',
  selectedParameterValue: null,
  vulnerableStatus: {},
  regionCentroid: { lat: null, lon: null },
  isUserHdrmpLoading: false,
  isPDFExportEnabled: false,
};

const setPublicPageState: CaseReducer<
  PublicPageState,
  PayloadAction<Partial<PublicPageState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const getSummaryChartsTabSuccess: CaseReducer<
  PublicPageState,
  PayloadAction<any>
> = (state, { payload }) => {
  payload[0].data.forEach((item: Record<string, any>, index: number) => {
    const matchedTab = payload[1].data.find(
      (tab: Record<string, any>) => tab.label === item.label,
    );
    if (matchedTab) {
      state.summaryCharts.tabList[index] = {
        id: matchedTab?.label,
        title: matchedTab?.label,
        risk: item?.value,
        vulnerability: matchedTab?.value,
      };
    }
  });
};

const setSitesRegionBoundary: CaseReducer<PublicPageState> = (
  state,
  action,
) => {
  const areaGeojson = action.payload
    .map((item: any) => item.area_json)
    .filter((item: any) => item !== null);
  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: areaGeojson,
  };

  // @ts-ignore
  const regionCentroid = centroid(geojson)?.geometry?.coordinates;
  const [lon, lat] = regionCentroid;
  return {
    ...state,
    regionCentroid: { lon, lat },
    layers: state.layers.map(layer =>
      layer.category === 'baseline_data'
        ? {
            ...layer,
            layers: layer.layers.map(item =>
              item.id === 'region-boundary'
                ? { ...item, geojson }
                : { ...item },
            ),
          }
        : layer,
    ),
  };
};

const getOverlayLayersSuccess: CaseReducer<
  PublicPageState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { layerData, layerIconData } = action.payload;
  const uniqueCategories = [
    ...new Set(layerData.map((item: any) => item.category)),
  ];
  const modifiedData = uniqueCategories.map(category => ({
    id: uuidv4(),
    category,
    layers: layerData
      .filter((item: any) => item.category === category)
      .map((item: any) => ({
        id: item.id,
        name: item.display_name_en,
        endPoint: 'vector-layer',
        checked: false,
        styles: {
          type:
            // eslint-disable-next-line no-nested-ternary
            item.geometry_type === 'Polygon'
              ? 'fill'
              : item.geometry_type === 'LineString'
                ? 'line'
                : 'circle',
          layout: {},
          paint: item.style || {},
        },
        showLabel: false,
        type: 'overlay-layer',
        dynamicStyle: item.style,
        isSubLayerActive: item.is_sublayer_active,
        dynamicSubLayerStyle: item.sublayer_style,
        icon:
          layerIconData.find((layerIcon: any) => layerIcon.name_en === category)
            ?.image || null,
      })),
  }));
  // @ts-ignore
  state.layers = [...state.layers, ...modifiedData];
};

const setWeatherDataLayers: CaseReducer<
  PublicPageState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { data, id } = action.payload;
  const geojson = featureCollection(
    data.map((item: any) =>
      point([item['location.lon'], item['location.lat']], item),
    ),
  );

  const weatherLayers = state.layers.find(layer => layer.id === 'weather');

  if (!weatherLayers) return;
  const selectedWeatherLayer = weatherLayers.layers.find(
    layer => layer.id === id,
  );
  if (!selectedWeatherLayer) return;
  // @ts-ignore
  selectedWeatherLayer.geojson = geojson;
};

const handleLayerToggle: CaseReducer<
  PublicPageState,
  PayloadAction<{ layerId: string | number; category: string }>
> = (state, action) => {
  const { layerId, category } = action.payload;
  const toggledLayerCategory = state.layers.find(
    layer => layer.category === category,
  );
  const toggledLayer = toggledLayerCategory?.layers?.find(
    item => item.id === layerId,
  );
  if (!toggledLayer) return;
  toggledLayer.checked = !toggledLayer.checked;
};

const publicPageSlice = createSlice({
  name: 'publicPage',
  initialState,
  reducers: {
    setPublicPageState,
    getSummaryChartsTabRequest(
      _,
      payload: PayloadAction<FormOptionsRequestDataType>,
    ) {},
    getSummaryChartsTabSuccess,
    getSummaryChartsTabFailure() {},
    setActiveTab(state, action) {
      state.summaryCharts.activeTab = action.payload;
    },
    setSitesRegionBoundary,
    handleLayerToggle,
    getOverlayLayersRequest() {},
    getOverlayLayersSuccess,
    getOverlayLayersFailure() {},
    setWeatherDataLayers,
  },
});

export { publicPageSlice };

export default publicPageSlice.reducer;
