/* eslint-disable prefer-template */

/**
 *
 * @param {number} n
 * @param {number} decimals
 * @returns {number}
 */
function toFixed(n: number, decimals: number): number {
  const factor = 10 ** decimals;
  return Math.round(n * factor) / factor;
}

/**
 *
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
function modulo(a: number, b: number): number {
  const r = a % b;
  return r * b < 0 ? r + b : r;
}

/**
 *
 * @param {number} number
 * @param {number} width
 * @param {number | undefined} precision
 * @returns {string}
 */
function padNumber(
  number: number,
  width: number,
  precision: number | undefined = undefined,
): string {
  const numberString =
    precision !== undefined ? number.toFixed(precision) : number.toString();
  let decimal = numberString.indexOf('.');
  decimal = decimal === -1 ? numberString.length : decimal;
  return decimal > width
    ? numberString
    : new Array(1 + width - decimal).join('0') + numberString;
}

/**
 *
 * @param {string} hemispheres
 * @param {number} degrees
 * @param {number} fractionDigits
 * @returns {string}
 */
export default function degreesToStringHDMS(
  hemispheres: string,
  degrees: number,
  fractionDigits: number,
): string {
  const normalizedDegrees = modulo(degrees + 180, 360) - 180;
  const x = Math.abs(3600 * normalizedDegrees);
  const decimals = fractionDigits || 0;

  let deg = Math.floor(x / 3600);
  let min = Math.floor((x - deg * 3600) / 60);
  let sec = toFixed(x - deg * 3600 - min * 60, decimals);

  if (sec >= 60) {
    sec = 0;
    min += 1;
  }

  if (min >= 60) {
    min = 0;
    deg += 1;
  }

  let hdms = `${deg}\u00b0`;
  if (min !== 0 || sec !== 0) {
    hdms += ' ' + padNumber(min, 2) + '\u2032';
  }
  if (sec !== 0) {
    hdms += ' ' + padNumber(sec, 2, decimals) + '\u2033';
  }
  if (normalizedDegrees !== 0) {
    hdms += ' ' + hemispheres;
  }

  return hdms;
}
