import { useState } from 'react';
import { useTypedDispatch } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';
import { Button } from '@Components/RadixComponents/Button';
import { FormControl, Input, Label } from '@Components/common/FormUI';
import { verifySignUpUserToken } from '@Services/authentication';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { toggleModal } from '@Store/actions/common';

export default function VerifyUser() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [enteredCode, setEnteredCode] = useState<string | null>(null);

  const { mutate: postUserToken, isLoading } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      verifySignUpUserToken(payloadData),
    onSuccess: () => {
      toast.success('Your account has been activated successfully');
      dispatch(toggleModal());
      navigate('/login');
    },
    onError: (err: Record<string, any>) => {
      toast.error(err?.response.data.message);
    },
  });

  return (
    <div>
      <h6>
        Your account has been created successfully. Please check your email and
        enter your 6 digits code to activate your account
      </h6>
      <FormControl className="mt-4">
        <Label>Enter Code Below</Label>
        <Input
          type="number"
          placeholder="Enter Code"
          value={enteredCode as string}
          onChange={e => {
            setEnteredCode(e.target.value);
          }}
        />
      </FormControl>
      <div className="text-center">
        <Button
          className="mt-4"
          isLoading={isLoading}
          onClick={() => {
            if (enteredCode !== null && enteredCode.length === 6) {
              postUserToken({ token: enteredCode });
            } else {
              toast.error('Code should be of 6 digits');
            }
          }}
        >
          Verify
        </Button>
      </div>
    </div>
  );
}
