import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { toggleModal } from '@Store/actions/common';
import { Button } from '@Components/RadixComponents/Button';
import SearchInput from '@Components/common/FormUI/SearchInput';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { setUserState } from '@Store/actions/users';

export default function FilterSection() {
  const dispatch = useTypedDispatch();

  const searchInput = useTypedSelector(state => state.users.searchInput);

  return (
    <FlexColumn>
      <FlexRow className="flex-col justify-between md:flex-row">
        <SearchInput
          inputValue={searchInput}
          placeholder="Search by Username"
          onChange={e =>
            dispatch(
              setUserState({
                searchInput: e.target.value,
              }),
            )
          }
          onClear={() =>
            dispatch(
              setUserState({
                searchInput: '',
              }),
            )
          }
          className="md:!w-[320px]"
        />

        <FlexRow className="flex items-center justify-end gap-2 pt-3 md:py-0">
          <Button
            leftIcon="add"
            className=" bg-primary-700"
            onClick={() => dispatch(toggleModal('add-user'))}
          >
            Add User
          </Button>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
