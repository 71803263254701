import { Button } from '@Components/RadixComponents/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@Components/RadixComponents/Popover';
import { setNotificationState } from '@Store/actions/notification';
import { useDispatch } from 'react-redux';
import NotificationList from './NotificationList';

export default function NotificationPopup() {
  const dispatch = useDispatch();
  return (
    <div>
      <Popover>
        <PopoverTrigger>
          <Button
            variant="outline"
            type="button"
            onClick={() => {
              dispatch(setNotificationState({ activeNotificationId: null }));
            }}
          >
            <i className="material-icons-outlined">notifications</i>
          </Button>
        </PopoverTrigger>

        <PopoverContent className="mr-3 w-[40vw] bg-[#f2f2f2]">
          <NotificationList />
        </PopoverContent>
      </Popover>
    </div>
  );
}
