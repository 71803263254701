/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { MapMouseEvent } from 'maplibre-gl';
import removeObjectKeys from '@Utils/removeObjectKeys';
import { Popup } from 'maplibre-gl';
import { IVectorTileLayer } from '../types';

const popup = new Popup({
  closeButton: false,
  closeOnClick: false,
});

export default function VectorTileLayer({
  map,
  id,
  url,
  isMapLoaded,
  layerOptions,
  visibleOnMap = true,
  interactions = [],
  onFeatureSelect,
  showLabel,
  showOutline = false,
  refreshLayer = false,
  showRoadPopup = false,
}: IVectorTileLayer) {
  const sourceId = useMemo(() => id.toString(), [id]);
  const hasInteractions = useRef(false);

  useEffect(() => {
    hasInteractions.current = !!interactions.length;
  }, [interactions]);

  const addSourceFn = useCallback(
    (mapx: any) => {
      mapx.addSource(sourceId, {
        type: 'vector',
        tiles: [url],
      });
    },
    [sourceId, url],
  );

  const addLayerFn = useCallback(
    (mapx: any) => {
      mapx.addLayer({
        id: sourceId,
        type: 'fill',
        source: sourceId,
        'source-layer': 'default',
        layout: {},
        ...removeObjectKeys(layerOptions || {}, ['layout']),
      });
    },
    [sourceId, layerOptions],
  );

  // add source and layer to the map
  useEffect(() => {
    if (!map || !isMapLoaded || !visibleOnMap) return () => {};
    addSourceFn(map);
    addLayerFn(map);
    return () => {
      if (map.getLayer(sourceId)) {
        map.removeLayer(sourceId);
        if (map.getLayer(`${sourceId}-label`)) {
          map.removeLayer(`${sourceId}-label`);
        }
        if (map.getLayer(`${sourceId}-outline`)) {
          map.removeLayer(`${sourceId}-outline`);
        }
        map.removeSource(sourceId);
      }
    };
  }, [isMapLoaded, map, url, sourceId, addSourceFn, addLayerFn, visibleOnMap]);

  // change cursor to pointer on feature hover
  useEffect(() => {
    if (!map) return () => {};
    function onMouseOver() {
      if (!map || !hasInteractions.current) return;
      map.getCanvas().style.cursor = 'pointer';
    }
    function onMouseLeave() {
      if (!map || !hasInteractions.current) return;
      map.getCanvas().style.cursor = '';
    }
    map.on('mouseover', sourceId, onMouseOver);
    map.on('mouseleave', sourceId, onMouseLeave);
    // remove event handlers on unmount
    return () => {
      map.off('mouseover', onMouseOver);
      map.off('mouseleave', onMouseLeave);
    };
  }, [map, sourceId]);

  // add select interaction & return properties on feature select
  useEffect(() => {
    if (!map || !interactions.includes('select')) return () => {};
    function handleSelectInteraction(event: MapMouseEvent) {
      if (!map) return;
      map.getCanvas().style.cursor = 'pointer';
      // @ts-ignore
      const { features } = event;
      if (!features?.length) return;
      const { properties, layer } = features[0];
      onFeatureSelect?.({ ...properties, layer: layer?.id });
    }
    map.on('click', sourceId, handleSelectInteraction);
    return () => map.off('click', sourceId, handleSelectInteraction);
  }, [map, interactions, sourceId, onFeatureSelect]);

  // add label to layer
  useEffect(() => {
    if (!map || !isMapLoaded) return () => {};
    const labelSourceId = `${sourceId}-label`;
    map.setGlyphs(
      'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
    );
    if (visibleOnMap && showLabel) {
      map.addLayer({
        id: labelSourceId,
        type: 'symbol',
        source: sourceId,
        'source-layer': 'default',
        layout: {
          // @ts-ignore
          ...(layerOptions?.layout || {}),
        },
        paint: {
          'text-color': '#000000',
          'text-halo-color': '#ffffff',
          'text-halo-width': 1,
        },
      });
    }
    return () => {
      if (map.getLayer(labelSourceId)) {
        map.removeLayer(labelSourceId);
      }
    };
  }, [map, isMapLoaded, visibleOnMap, sourceId, showLabel]); // eslint-disable-line

  // add outline to layer
  useEffect(() => {
    if (!map || !isMapLoaded || !visibleOnMap || !showOutline) return () => {};
    const outlineLayerId = `${sourceId}-outline`;
    if (map.getLayer(outlineLayerId)) return () => {};
    map.addLayer({
      id: outlineLayerId,
      type: 'line',
      source: sourceId,
      'source-layer': 'default',
      layout: {},
      paint: {
        'line-color': '#E27D7D',
        'line-width': 0.6,
        'line-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          11,
          0,
          12,
          0.5,
          13,
          1,
        ],
      },
    });
    return () => {
      if (map.getLayer(outlineLayerId)) {
        map.removeLayer(outlineLayerId);
      }
    };
  }, [
    map,
    isMapLoaded,
    visibleOnMap,
    sourceId,
    showOutline,
    url,
    refreshLayer,
    layerOptions,
  ]);

  useEffect(() => {
    const labelSourceId = `${sourceId}-label`;
    const outlineLayerId = `${sourceId}-outline`;
    if (!refreshLayer || !map || !map.getLayer(sourceId)) return;
    if (map.getLayer(labelSourceId)) {
      map.removeLayer(labelSourceId);
    }
    if (map.getLayer(outlineLayerId)) {
      map.removeLayer(outlineLayerId);
    }
    map.removeLayer(sourceId);
    map.removeSource(sourceId);
    addSourceFn(map);
    addLayerFn(map);
  }, [map, refreshLayer, sourceId, addSourceFn, addLayerFn]);

  // display popup on road hover
  useEffect(() => {
    if (!map || !showRoadPopup) return () => {};
    const handleMouseEnter = (e: any) => {
      map.getCanvas().style.cursor = 'pointer';
      const description = e.features[0].properties.road_name_en;
      popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
    };
    const handleMouseLeave = () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    };
    map.on('mouseenter', 'road', handleMouseEnter);
    map.on('mouseleave', 'road', handleMouseLeave);
    return () => {
      map.off('mouseenter', 'road', handleMouseEnter);
      map.off('mouseleave', 'road', handleMouseLeave);
    };
  }, [map, showRoadPopup]);

  return null;
}
