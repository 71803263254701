import Image from '@Components/RadixComponents/Image';
import ManWithSuitcase from '@Assets/images/public-page/HDPRP/ManWithSuitcase.png';

export default function EmergencySteps() {
  return (
    <section>
      <div className="grid min-h-[30rem] grid-cols-5 rounded-lg bg-[#037342] px-5 py-6 md:col-span-3">
        <div className="col-span-2 flex flex-col justify-center gap-4 p-5 md:items-start md:justify-around">
          <Image src={ManWithSuitcase} alt="Group" />
          <p className="text-[26px] font-extrabold  text-white md:pr-20">
            आकस्मिक अवस्थामा घर छाड्नु भन्दा पहिले अपनाउनु पर्ने सावधानीहरु
          </p>
        </div>
        <div className="col-span-3 flex  flex-col gap-4 rounded-lg bg-white px-5 py-3">
          <div className="flex gap-3">
            <div>
              <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
                १
              </p>
            </div>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              शारीरिक रूपमा अशक्त व्यक्ति, बालबालिका, वृद्ध वृद्धा तथा गर्भवती
              महिला आदिहरुलाई विशेष ख्याल राखौं
            </p>
          </div>
          <div className="flex gap-3">
            <div>
              <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
                २
              </p>
            </div>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              बिजुली एवं आगोको श्रोतहरुलाई पूर्ण रुपमा बन्द गर्नु होस
            </p>
          </div>
          <div className="flex gap-3">
            <div>
              <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
                ३
              </p>
            </div>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              घरको झ्याल तथा ढोकाहरु बन्द गर्नु होस्
            </p>
          </div>
          <div className="flex gap-3">
            <div>
              <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
                ४
              </p>
            </div>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              आफु सँग लिएर जान नसक्ने महत्वपूर्ण सामाग्री भए घर भित्रै अलि उच्च
              स्थानमा राखे
            </p>
          </div>
          <div className="flex gap-3">
            <div>
              <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
                ५
              </p>
            </div>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              सम्भव भए सम्म घर पालुवा जनावरहरु आफु संगै लिएर जाने, नभए
              तिनीहरुलाई खुल्ला छाडीदिने
            </p>
          </div>
          <div className="flex gap-3">
            <div>
              <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
                ६
              </p>
            </div>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              धैर्यता कायम राखे र निरन्तर आपतकालीन अवस्थासँग जुधिरहने र अन्य
              परिवारका सदस्यहरुलाई पनि प्रोत्साहन गर्ने
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
