/* eslint-disable react/no-unstable-nested-components */
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { useTypedSelector } from '@Store/hooks';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import { getSumissionDataList } from '@Services/assessmentData';
import { Button } from '@Components/RadixComponents/Button';

export default function TableSection() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);

  const userColumns = [
    {
      header: 'S .N',
      accessorKey: 'id',
      cell: (info: any) => info.row.index + 1,
      pin: 'left',
    },
    {
      header: 'House Number',
      accessorKey: 'site',
    },
    {
      header: 'Ward',
      accessorKey: 'region_name',
    },
    {
      header: 'Tole Name',
      accessorKey: 'tole_name',
    },
    {
      header: 'House Owner Name',
      accessorKey: 'household_owner',
    },
    {
      header: 'Phone Number',
      accessorKey: 'contact_number',
    },
    {
      header: 'Risk Class',
      accessorKey: 'risk',
    },
    {
      header: 'Vulnerablity Class',
      accessorKey: 'vulnerability',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => (
        <FlexRow className="cursor-pointer justify-around">
          <Button
            variant="ghost"
            onClick={() => {
              navigate(`user-form/${row.original.site_id}`);
            }}
          >
            View Details
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              navigate(`hdprp/${row.original.site_id}`);
            }}
          >
            House Profile
          </Button>
        </FlexRow>
      ),
    },
  ];

  return (
    <DataTable
      columns={userColumns}
      queryKey="assessment-data-list"
      queryFn={getSumissionDataList}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      searchInput=""
      wrapperStyle={{
        height:
          // eslint-disable-next-line no-nested-ternary
          width <= 390
            ? '60vh'
            : showInfoDialog
            ? 'calc(100vh - 15rem)'
            : 'calc(100vh - 11.5rem)',
      }}
    />
  );
}
