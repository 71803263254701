import { Flex } from '@Components/common/Layouts';
import TableSection from './TableSection';

export default function EmergencyDetail() {
  return (
    <Flex gap={5} className="py-5 xl:px-32">
      <h3>Emergency Contacts</h3>
      <TableSection />
    </Flex>
  );
}
