import { useTypedSelector } from '@Store/hooks';
import { FlexRow, FlexColumn } from '@Components/common/Layouts';
import LegendItem from '@Components/common/Charts/LegendItem';
import getLayerStyleColors from '@Components/common/MapLibreComponents/helpers/getLayerStyleColors';

export default function ExportLegend() {
  const orientation = useTypedSelector(state => state.mapExport.orientation);
  // const layers = useTypedSelector(state => state.visualization.layers);
  const layers: any[] = [];
  return (
    <div className="px-4">
      <h5>Legends</h5>
      <div
        className={`flex ${
          orientation === 'landscape' ? 'flex-col gap-3' : 'flex-row gap-8'
        }`}
      >
        {layers
          .filter(layer => layer.checked === true)
          .map(({ id, name, styles }) => {
            const layerColors = getLayerStyleColors(styles);
            return (
              <FlexColumn key={id} className="mt-1.5">
                <span className="font-primary text-body-btn">{name}</span>
                <FlexColumn gap={3} className="mt-1.5 flex flex-col">
                  {/* legend items */}
                  {layerColors?.map(({ color, label }) => (
                    <FlexRow gap={1} className="items-center" key={label}>
                      <LegendItem
                        color={color}
                        // @ts-ignore
                        type={styles?.type || 'fill'}
                      />
                      <p className="text-body-sm text-grey-600">{label}</p>
                    </FlexRow>
                  ))}
                </FlexColumn>
              </FlexColumn>
            );
          })}
      </div>
    </div>
  );
}
