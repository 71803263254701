/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import { DragEndEvent } from '@dnd-kit/core';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { checkedLayersSelector } from '@Store/selector/visualization';
import { handleLayerSort } from '@Store/actions/visualization';
import Icon from '@Components/common/Icon';
import { SortableList, SortableItem } from '@Components/common/DNDKit';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import getLayerStyleColors from '@Components/common/MapLibreComponents/helpers/getLayerStyleColors';
import LegendItem, {
  ColorHexType,
} from '@Components/common/MapLibreComponents/LegendItem';
import Image from '@Components/RadixComponents/Image';

// function getFillOpacity(styles: Record<string, any>) {
//   if (!('paint' in styles)) return 0;
//   const keys = Object.keys(styles.paint);
//   const fillOpacityKey = keys.find(
//     key =>
//       (key.includes('fill') || key.includes('line')) && key.includes('opacity'),
//   );
//   if (!fillOpacityKey) return 0;
//   return styles.paint[fillOpacityKey] * 100;
// }

export default function Legend() {
  const dispatch = useTypedDispatch();

  const checkedLayers = useTypedSelector(checkedLayersSelector);

  return (
    <FlexColumn className="h-full bg-grey-100 px-3 pb-3">
      <FlexRow className="items-center py-2">
        <h5>Legend ( Active Layers )</h5>
      </FlexRow>

      <SortableList
        items={checkedLayers}
        onDragEnd={(event: DragEndEvent) => {
          dispatch(handleLayerSort(event));
        }}
      >
        <FlexColumn gap={2} className="scrollbar overflow-auto ">
          {checkedLayers.map(({ id, name, styles, icon }) => {
            const layerColors = styles ? getLayerStyleColors(styles) : [];
            return (
              <SortableItem
                key={`item-${id}`}
                id={id}
                // @ts-ignore
                content={({ listeners, attributes }) => (
                  <FlexColumn
                    key={id}
                    className="rounded border border-grey-200  bg-white px-1 py-2.5"
                  >
                    <div
                      className={`${
                        icon ? 'justify-between gap-2' : 'flex-col gap-1'
                      } flex px-1`}
                    >
                      <FlexRow className="items-center" gap={1}>
                        {/* drag handle */}
                        <Icon
                          name="drag_indicator"
                          className="!cursor-move text-grey-600"
                          {...listeners}
                          {...attributes}
                        />
                        <p className="btn-text cursor-default capitalize">
                          {name}
                        </p>
                      </FlexRow>
                      {/* checkbox - layer toggle */}
                      {/* <FlexRow gap={1} className="items-center">
                          <IconButton
                            name={
                              checked ? 'check_box' : `check_box_outline_blank`
                            }
                            className="h-8 w-8 text-primary-600"
                            onClick={() => {
                              dispatch(
                                handleLayerToggle({
                                  layerId: id,
                                  category,
                                }),
                              );
                            }}
                          />
                        </FlexRow> */}

                      <FlexColumn>
                        {icon ? (
                          <Image src={icon} width={20} />
                        ) : styles ? (
                          <FlexColumn gap={1}>
                            {/* legend items */}
                            {layerColors?.map(({ color, label }) => (
                              <FlexRow
                                key={label}
                                gap={1}
                                className="h-5 items-center pl-1.5"
                              >
                                <LegendItem
                                  color={color as ColorHexType}
                                  type={styles?.type || 'fill'}
                                />
                                <p className="text-body-sm text-grey-600">
                                  {label}
                                </p>
                              </FlexRow>
                            ))}
                          </FlexColumn>
                        ) : (
                          <></>
                        )}
                      </FlexColumn>
                    </div>
                  </FlexColumn>
                )}
              />
            );
          })}
        </FlexColumn>
      </SortableList>
    </FlexColumn>
  );
}
