import Dashboard from '@Views/Dashboard';
import Visualization from '@Views/Visualization';
import DataPage from '@Views/DataPage';
import Users from '@Views/Users';
import AssessmentData from '@Components/DataPage/AssessmentData';
import OverlayLayers from '@Components/DataPage/OverlayLayers/index';
import TriggerMessage from '@Views/TriggerMessage';
import Notification from '@Views/Notification';
import Rtm from '@Views/Rtm';

export interface ISidebar {
  name: string;
  icon?: string;
  path: string;
  component: () => JSX.Element;
  children?: ISidebar[];
}

const sidebarData: ISidebar[] = [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    path: 'dashboard',
    component: Dashboard,
  },
  {
    name: 'Visualization',
    icon: 'bar_chart',
    path: 'visualization',
    component: Visualization,
  },
  {
    name: 'Data',
    icon: 'timeline',
    path: 'data',
    component: DataPage,
    children: [
      {
        name: 'Assessment Data',
        path: 'assessment-data',
        component: AssessmentData,
      },
      {
        name: 'Overlay Layer',
        path: 'overlay-layer',
        component: OverlayLayers,
      },
    ],
  },
  {
    name: 'Users',
    icon: 'people',
    path: 'users',
    component: Users,
  },
  {
    name: 'RTM',
    icon: 'people',
    path: 'rtm',
    component: Rtm,
  },
  {
    name: 'Trigger Message',
    icon: 'message',
    path: 'trigger-message',
    component: TriggerMessage,
  },
  {
    name: 'Incident Reports',
    icon: 'notifications',
    path: 'incident-reports',
    component: Notification,
  },
];

export default sidebarData;
