import { FlexColumn } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';
import DastaaImage from '@Assets/images/login-banner.svg';

export default function About() {
  return (
    <div className="flex flex-col lg:flex-row">
      <FlexColumn className="w-2/3 px-5 text-center md:text-justify" gap={5}>
        <h2>About DASTAA</h2>
        <p>
          DASTAA: Digital and Spatial Technologies for Anticipatory Action is an
          integrated digital solution that leverages digital and spatial
          technology to enhance disaster preparedness through a comprehensive
          web and mobile application system.
        </p>
        <p>
          It is a one-stop digital platform that facilitates integrated risk
          assessment, risk visualization, risk communication, and forecasting
          for early action support. DASTAA consists of mobile and web
          applications. The mobile app enables agencies to collect data and
          access risk information, while the web application allows users to
          visualize risks, set up early warning messages, and serve as a
          decision support system. By anticipating household risks and sending
          tailored early action messages, DASTAA aims to reduce the impact of
          disasters on vulnerable communities, empowering them to understand,
          communicate, and act on risks while assisting local authorities in
          effective decision-making.
        </p>
        <p>
          The vision of DASTAA is to create a paradigm shift from disaster
          response to disaster preparedness in disaster management practices.
        </p>
      </FlexColumn>
      <Image src={DastaaImage} />
    </div>
  );
}
