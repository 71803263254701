import { useParams } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { Dashboard, Navbar } from '@Components/PublicPage';
import { FlexColumn } from '@Components/common/Layouts';
import About from '@Components/PublicPage/About';
import HDPRP from '@Components/PublicPage/HDPRP';
import Resources from '@Components/PublicPage/Resources';
import Fallback from '@Components/common/Fallback';
import { useGetWeatherDataQuery } from '@Api/publicPage';
import { setWeatherDataLayers } from '@Store/actions/publicPage';

const PublicPage = () => {
  const { tabname } = useParams();
  const dispatch = useTypedDispatch();

  const isLoginChecked = useTypedSelector(
    state => state.authentication.isLoginChecked,
  );

  const getContent = () => {
    switch (tabname) {
      case 'about':
        return <About />;
      case 'hdrmp':
        return <HDPRP />;
      case 'resources':
        return <Resources />;
      default:
        return <Dashboard />;
    }
  };

  // fetch weather data api
  useGetWeatherDataQuery(
    {
      hour: 1,
      source: 'weatherapi_current',
    },
    {
      onSuccess: (data: any) => {
        dispatch(setWeatherDataLayers({ data, id: 'weatherapi_current' }));
      },
    },
  );

  useGetWeatherDataQuery(
    {
      hour: 3,
      source: 'windy',
    },
    {
      onSuccess: (data: any) => {
        dispatch(setWeatherDataLayers({ data, id: 'windy' }));
      },
    },
  );

  if (!isLoginChecked) return <Fallback />;

  return (
    <main className="px-5 md:px-10">
      <Navbar />
      <FlexColumn>{getContent()}</FlexColumn>
    </main>
  );
};

export default PublicPage;
