import getCookie from '@Utils/cookieUtils';

export default function useAuth() {
  const isAuthenticated = () => {
    if (process.env.NODE_ENV === 'development') {
      const token = localStorage.getItem('token');
      return !!token;
    }
    const token = getCookie('csrftoken');
    return !!token;
  };
  return { isAuthenticated };
}
