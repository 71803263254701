import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import { FlexColumn, Flex } from '@Components/common/Layouts';
import { dashboardSummaryCharts } from '@Constants/dashboard';
import HazardRankingStack from './HazardRankingStack';

export default function ChartSection() {
  return (
    <Flex gap={5} className="w-full lg:grid lg:grid-cols-5">
      <FlexColumn gap={5} className="col-span-3 w-full animate-fade">
        {dashboardSummaryCharts.map(data => (
          <ChartRenderer key={data.id} data={data} />
        ))}
      </FlexColumn>
      <Flex className="col-span-2">
        <HazardRankingStack />
      </Flex>
    </Flex>
  );
}
