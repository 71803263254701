import TabBtn from '@Components/common/TabBtn';
import { setRTMState } from '@Store/actions/realTimeMonitering';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';

const rtmTabs = {
  'river-watch': {
    name: 'River Watch',
  },
  'rainfall-watch': {
    name: 'Rainfall Watch',
  },
};

export default function RtmHeader() {
  const activeTab = useTypedSelector(state => state.rtm.activeTab);
  const dispatch = useDispatch();

  return (
    <div className="flex w-full">
      {Object.entries(rtmTabs)?.map(([key, { name }]) => {
        return (
          <TabBtn
            key={key}
            id={key}
            activeTab={activeTab}
            name={name}
            onClick={() => {
              dispatch(setRTMState({ activeTab: key }));
            }}
          />
        );
      })}
    </div>
  );
}
