import { useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useMutation } from '@tanstack/react-query';
import { useGetFloodRiskDataQuery } from '@Api/visualization';
import CustomBarChart from '@Components/common/Charts/BarChart';
import isEmpty from '@Utils/isEmpty';
import { BarChartFills } from '@Components/common/Charts/constants';
import prepareChartData from '@Utils/prepareChartData';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { setVisualizationState } from '@Store/actions/visualization';
import { Button } from '@Components/RadixComponents/Button';
import { postGeoJsonForRiskData } from '@Services/visualization';

export default function ExposureAssessment() {
  const dispatch = useTypedDispatch();

  const [analyzeClick, setAnalyzeClick] = useState(false);
  const [chartFloodData, setChartFloodData] = useState<Record<
    string,
    any
  > | null>(null);

  const measureResult = useTypedSelector(
    state => state.visualization.measureResult,
  );
  const drawnArea = useTypedSelector(state => state.visualization.drawnArea);

  const { data: floodData } = useGetFloodRiskDataQuery();

  const { mutate: postGeoJsonForRisk } = useMutation({
    mutationFn: (payload: Record<string, any>) =>
      postGeoJsonForRiskData(payload),
    onSuccess: (res: any) => {
      setChartFloodData(res.data);
    },
  });

  const isDataEmpty = isEmpty(floodData);

  return (
    <div className="h-[85%] p-2">
      <div>
        <h5>Exposure Assessment</h5>
        <h6 className="mt-2 text-center text-sm">{`${
          analyzeClick
            ? 'Flood Assessment of Selected Region'
            : 'Flood (100 Years return period)'
        }`}</h6>
        <div className="-ml-4">
          {!analyzeClick && !isDataEmpty && (
            <CustomBarChart
              data={prepareChartData(floodData)}
              fills={BarChartFills}
              Xlabel={{
                value: 'Depth in Metres',
                style: { fontSize: '12px' },
                dy: 16,
              }}
              Ylabel={{
                value: 'Number of Houses',
                angle: -90,
                dy: 6,
                dx: -10,
                style: { fontSize: '12px' },
              }}
              barSize={16}
            />
          )}
          {analyzeClick && !isEmpty(chartFloodData as {}) && (
            <CustomBarChart
              data={prepareChartData(!chartFloodData ? {} : chartFloodData)}
              fills={BarChartFills}
              barSize={16}
              Xlabel={{
                value: 'Depth in Metres',
                style: { fontSize: '12px' },
                dy: 16,
              }}
              Ylabel={{
                value: 'Number of Houses',
                angle: -90,
                dy: 6,
                dx: -10,
                style: { fontSize: '12px' },
              }}
            />
          )}
        </div>
        <div>
          <FlexRow className="items-center">
            <p className="text-center text-sm leading-4">
              Households exposed to Flood{' '}
              {`${analyzeClick ? 'in the drawn Area' : '(In 100 years)'}`}
            </p>
          </FlexRow>
          <div className="mt-2">
            {!analyzeClick &&
              !isDataEmpty &&
              Object.keys(floodData).map(item => (
                <FlexColumn key={item} className="pl-6">
                  <FlexRow gap={4} className="mt-1">
                    <span className="font-primary text-[0.75rem] font-semibold">
                      Upto {item} metres :
                    </span>
                    <span className="text-[0.75rem]">{floodData[item]}</span>
                  </FlexRow>
                </FlexColumn>
              ))}
            {analyzeClick &&
              !isEmpty(chartFloodData as {}) &&
              Object.keys(chartFloodData as {}).map(item => (
                <FlexColumn key={item} className="pl-6">
                  <FlexRow gap={4} className="mt-[2px]">
                    <span className="font-primary text-[0.75rem] font-semibold">
                      Upto {item} metres :
                    </span>
                    <span className="text-[0.75rem]">
                      {chartFloodData && chartFloodData[item]}
                    </span>
                  </FlexRow>
                </FlexColumn>
              ))}
          </div>
        </div>
      </div>
      <div>
        <FlexRow className="mt-4 justify-center">
          {!measureResult ? (
            <Button
              onClick={() => {
                dispatch(
                  setVisualizationState({
                    measureType: 'area',
                    measureResult: null,
                    measureFrom: 'exposure_assessment',
                  }),
                );
              }}
            >
              Draw an Area
            </Button>
          ) : (
            <FlexRow>
              <Button
                onClick={() => {
                  if (!drawnArea) return;
                  setChartFloodData(null);
                  postGeoJsonForRisk({ area_json: drawnArea });
                  setAnalyzeClick(true);
                }}
              >
                Analyze
              </Button>
              <Button
                leftIcon="restart_alt"
                variant="ghost"
                onClick={() => {
                  setAnalyzeClick(false);
                  dispatch(
                    setVisualizationState({
                      measureResult: null,
                      measureType: null,
                      measureFrom: null,
                      drawnArea: null,
                    }),
                  );
                }}
              >
                Reset
              </Button>
            </FlexRow>
          )}
        </FlexRow>
      </div>
    </div>
  );
}
