/* eslint-disable no-irregular-whitespace */
/* eslint-disable prettier/prettier */
import { FlexColumn, FlexRow } from '@Components/common/Layouts';

export default function ActionsToAvoidFloodRisk() {
  return (
    <div>
      <FlexRow className="justify-center bg-[#037342] px-[0.73rem] py-[0.49rem]  text-[12px] font-semibold text-white ">
        बाढीको जोखिमबाट बच्न अपनाउनुपर्ने कार्यहरू
      </FlexRow>
      <FlexColumn className="mx-[1.25rem] bg-[#ECF2FF] px-[0.94rem] py-[0.73rem]">
        <ul className="flex list-disc flex-col gap-[2px] pl-[1.88rem] text-[16px] font-normal">
          <li>
            विपद व्यवस्थापन समिति र स्थानीय अधिकारीसँग नियमित सम्पर्कमा रहने।
          </li>
          <li>
            जल तथा मौसम विज्ञान विभागको पूर्वानुमान र अद्यावधिक जानकारीको
            पहुँचमा रहने।
          </li>
          <li>मौसम पूर्वानुमान र बाढी चेतावनीबारे सूचित रहने।</li>
          <li>
            आपतकालीन सामग्री सहित झटपट झोला तयार राख्ने। सुरक्षित स्थान र
            स्थानान्तरण मार्गको पहिचान सहित आपतकालीन योजना विकास गर्ने।
          </li>
          <li>
            विद्युतीय स्विच र उपकरणहरू डुबान नहुने गरि अग्लो स्थानमा जडान 
            गर्ने।
          </li>
          <li>
            आपतकालीन अवस्थामा धैर्य रहनुहोस् र सहयोगको लागि सिट्ठी जस्ता उपकरण
            प्रयोग गर्ने।
          </li>
          <li>
            परिवार, छिमेकी, र साथीहरूसँग सम्पर्कमा रहने र पारस्परिक सुरक्षाको
            सुनिश्चितता गर्ने।
          </li>
          <li>
            शारीरिक रूपमा अशक्त व्यक्ति, बालबालिका, जेष्ठ नागरिक र गर्भवती
            महिलाहरूको विशेष ख्याल राख्ने।
          </li>
          <li>भिरालो जमिन, नदीका किनार नजिक घर वा बस्तीहरू विकास नगर्ने।</li>
          <li>डुबानको क्षतिबाट जोगिन घरहरूको जग अग्लो निर्माण  गर्ने।</li>
          <li>नाला र ढल नियमित रूपमा सफा गर्ने र प्रवाहलाई निर्वाध बनाउने।</li>
          <li>आफु सुरक्षित भए पछि अरुको मद्दत र सहयोग गर्ने।</li>
          <li>
            विस्तृत आपतकालीन योजना विकास गर्ने, सुरक्षित क्षेत्रहरू पहिचान गर्ने
            र बाढी चेतावनीको समयमा निर्देशित दिशानिर्देशहरू पालना गर्ने।
          </li>
        </ul>
      </FlexColumn>
    </div>
  );
}
