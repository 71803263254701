import { Flex } from '@Components/common/Layouts';
import MapSection from './MapSection';
import ChartSection from './ChartSection';

export default function SummarySection() {
  return (
    <Flex gap={5} className="xl:grid xl:grid-cols-9">
      <Flex className="col-span-4 xl:h-[514px]">
        <MapSection />
      </Flex>
      <Flex className="xl:col-span-5">
        <ChartSection />
      </Flex>
    </Flex>
  );
}
