import Image from '@Components/RadixComponents/Image';
import Wave from '@Assets/images/public-page/HDPRP/Wave.png';
import Clipboard from '@Assets/images/public-page/HDPRP/CheckBoard.png';
import GlassofWaterDark from '@Assets/images/public-page/HDPRP/GlassWaterDark.png';
import BagPack from '@Assets/images/public-page/HDPRP/bag.svg';
import DocsDark from '@Assets/images/public-page/HDPRP/DocsDark.png';
import Services from '@Assets/images/public-page/HDPRP/services.svg';
import AirHorn from '@Assets/images/public-page/HDPRP/AirHorn.png';
import Charging from '@Assets/images/public-page/HDPRP/Charging.png';
import WarningRed from '@Assets/images/public-page/HDPRP/WarningRed.png';
import EmergencyExit from '@Assets/images/public-page/HDPRP/EmergemcyExit.png';
import OldMan from '@Assets/images/public-page/HDPRP/OldMan.png';
import PersonRoof from '@Assets/images/public-page/HDPRP/PersonRoof.png';
import RedBag from '@Assets/images/public-page/HDPRP/red-bag.svg';
import WarningGreen from '@Assets/images/public-page/HDPRP/WarningGreen.png';
import HomeSearch from '@Assets/images/public-page/HDPRP/HomeSearch.png';
import ClipboardGreen from '@Assets/images/public-page/HDPRP/CheckBoardGreen.png';
import TwoPeople from '@Assets/images/public-page/HDPRP/TwoPeople.png';
import DropOfWater from '@Assets/images/public-page/HDPRP/DropOdWater.png';
import CallGreen from '@Assets/images/public-page/HDPRP/CallGreen.png';

export const FloodHeaderSection = () => {
  return (
    <section className="relative flex min-h-[3.5rem] w-full items-center justify-center bg-[#05A9E6] pb-5 pt-8">
      <p className="text-center text-[44px] font-semibold leading-[66px] text-white">
        पूर्वतयारी
      </p>
      <Image
        src={Wave}
        alt="wave"
        className="absolute bottom-0 left-0 -mt-5 h-32 w-[110%] translate-y-[60%]"
      />
    </section>
  );
};

export const BeforeFloodPreparationSection = () => {
  return (
    <section className=" px-7 pt-6">
      <div className="full-card mt-10">
        <div className="bg-[#012069] py-4 text-center">
          <p className="leading-36px text-[24px] font-bold text-white">
            बाढी अघि गर्नु पर्ने तयारी
          </p>
        </div>
        <div className="mx-4 grid gap-2 bg-[#ECF2FF]  px-8 py-6 md:grid-cols-2">
          <div className="flex flex-col gap-6 border-[#012069] md:col-span-1 md:border-r md:pr-11">
            <div className="single flex items-start gap-2 ">
              <Image src={Clipboard} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  आपतकालीन योजना बनाउने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  परिवारको आपतकालीन योजना बनाउनुहोस् जसमा निकासी मार्गहरू,
                  निर्धारित भेला हुने स्थानहरू, र सञ्चार विधिहरू समावेश गर्ने ।
                  उच्च उचाईको क्षेत्र, सुरक्षित आश्रय, निकासी केन्द्रहरू आदि
                  पहिचान गर्ने ।
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  आपतकालीन अवस्थाको लागि सुरक्षित निकासी मार्ग (Evacuation
                  route) तथा केन्द्रको (Evacuation center) पहिचान गर्ने राख्ने ।
                </p>
              </div>
            </div>

            <div className="single flex gap-2">
              <Image src={GlassofWaterDark} alt="doc" className="w-8" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  पानी शुद्धिकरण गर्ने औषधी सुरक्षित राख्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  घरमा पानी शुद्धिकरण गर्ने औषधी (चक्की र थोपा ) देखिने ठाउँमा
                  राख्ने ।
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  आपतकालीन समयमा सुरक्षित पिउने पानीको उपलब्धता सुनिश्चितता
                  गर्ने ।
                </p>
              </div>
            </div>

            <div className="single flex gap-2">
              <Image src={BagPack} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  झटपट झोला तयार पार्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  पानी, नकुहिने खाद्य पदार्थ, टर्चलाइट, ब्याट्री, प्राथमिक उपचार
                  किट, औषधि, महत्वपूर्ण कागजातहरू, नगद, र लुगा जस्ता आवश्यक
                  वस्तुहरू सहितको झटपट झोला तयार पार्ने ।
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  सम्भावित प्रभाव तथा डुबानबाट बचाउनको लागि अत्यावश्यक
                  सामग्रीहरु जस्तै: अन्न भण्डारण, पशु आहार आदि सामान्यतया उचाई
                  भन्दा उठाई सुरक्षित बनाउने ।
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 md:col-span-1 md:pl-11">
            <div className="single flex gap-2">
              <Image src={DocsDark} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  महत्वपूर्ण कागजात सुरक्षित राख्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  नागरिकता, लाइसेन्स, जग्गा लालपुर्जा, परिचय खुल्ने पत्र (जस्तै:
                  पेन्सन कागजात, जेष्ठ नागरिक, अपांगता परिचय पत्र) र पासपोर्ट
                  जस्ता अत्यावश्यक कागजातहरूलाई प्लास्टिकले बेरेर नभिज्ने गरि
                  राख्ने ।
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="single flex gap-2">
                <Image
                  src={AirHorn}
                  alt="doc"
                  width={65}
                  className="object-fill"
                />
                <div>
                  <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                    मौसम पूर्वानुमानको बारेमा सूसुचित रहने र पुर्वकार्यलाई तयार
                    रहने
                  </p>
                  <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                    स्थानीय अधिकारीहरूद्वारा जारी भएको मौसम पूर्वानुमान र बाढी
                    चेतावनीहरू बारे जानकारी लिने ।
                  </p>
                  <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                    परिवारमा गर्भवती महिला, अपाङ्गता भएका, जेष्ठ नागरिक, बिरामी
                    र बालबालिका छन् भने उनीहरूलाई विपदको समयमा भोग्नु पर्ने
                    विभिन्न कठिनाइहरूबारे बुझाउने ।
                  </p>
                </div>
              </div>
              <div className="single flex gap-2">
                {/* <Image
                  src={AirHorn}
                  alt="doc"
                  width={35}
                  className="object-fill"
                /> */}
                <p className="mt-3 font-bold text-[#012069]">
                  बाढी तथा मौसम बारे निशुल्क जानकारी पाउनको लागि ११५५ मा सम्पर्क
                  गर्ने
                </p>
              </div>
            </div>
            <div>
              <div className="flex gap-2">
                <Image src={Charging} alt="doc" className="w-12" />
                <div>
                  <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                    फोनमा नियमित चार्ज र पर्याप्त ब्यालेन्स कायम राख्ने
                  </p>
                  <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                    विशेष गरी मनसुनको समयमा मोबाइल फोनमा पर्याप्त ब्यालेन्स तथा
                    ब्याट्री भए नभएको यकिन गर्ने ।
                  </p>
                  <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                    बाढीको घटना पूर्व नै बाढीले परिवार, घर तथा अन्य
                    पूर्वाधारहरुमा हुन सक्ने सम्भावित असर/क्षतिको पहिचान गर्ने ।
                  </p>
                  <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                    फोनमा यदि पर्याप्त ब्यालेन्स नभएको खण्डमा लोन लिने तरिका:
                  </p>
                </div>
              </div>
              <div className="single flex gap-2">
                {/* <Image src={Charging} alt="doc" className="w-8" /> */}
                <div>
                  <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                    <span className="font-bold text-[#012069]">
                      NCELL प्रयोगकर्ताले: *९९८८# मा डायल गर्ने
                      <br />
                      NTC प्रयोगकर्ताले: *१४७७# मा डायल गर्ने
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const DuringFloodPreparationSection = () => {
  return (
    <section className="px-7">
      <div className="full-card mt-10">
        <div className="bg-[#C12D28] py-4 text-center">
          <p className="leading-36px text-[24px] font-bold text-white">
            बाढीको बेला
          </p>
        </div>
        <div className="mx-4 grid gap-2 bg-[#ECF2FF] px-8 py-6 md:grid-cols-2">
          <div className="flex flex-col gap-6 border-[#C12D28] md:col-span-1 md:border-r md:pr-11">
            <div className="single flex gap-2">
              <Image src={WarningRed} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  सूचित र सतर्क रहने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  अद्ध्यावधिक रहन र निर्देशनहरूकालागि स्थानीय समाचार, आपतकालीन
                  चेतावनीहरू र रेडियो समाचारलाई निरन्तर अवलोकन गर्नुहोस् ।
                  आपतकालीन प्रबन्धन अधिकारीहरू वा पहिलो प्रतिक्रिया दिने
                  व्यक्तिहरूबाट आएको औपचारिक निर्देशनहरूलाइ ध्यान दिने ।
                </p>
              </div>
            </div>
            <div className="single flex gap-2">
              <Image src={RedBag} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  आवश्यकता अनुसार तयार पारिएको झटपट झोला लिएर निस्किने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  आवश्तयकता अनुसार तयार पारिएको झटपट झोला (पानी, नकुहिने खाद्य
                  पदार्थ, टर्चलाइट, ब्याट्री, प्राथमिक उपचार किट, औषधि,
                  महत्वपूर्ण कागजातहरू, नगद, र लुगा ) लिएर निस्कने ।
                </p>
              </div>
            </div>
            <div className="single flex  gap-2">
              <Image src={PersonRoof} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  सुरक्षित हुनसम्म कुर्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  औपचारिक रूपमा सुरक्षित भएको घोषणा नभएसम्म बाहिर ननिस्किने वा
                  घर फर्कने कार्य नगर्ने । धैर्यता राख्ने र निर्देशनहरू पालना
                  गर्ने ।
                </p>
                <p className="mt-2 text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  यदि तत्काल सुरक्षित स्थानमा जान सक्ने अवस्था नभएमा, खोज तथा
                  उद्धार कार्यदल एवं सुरक्षा निकायलाई खबर गर्ने र आफु र परिवारका
                  सदस्यहरु विकल्प रहेसम्म उचो स्थानमा बसी उद्धारकर्मीहरुलाई
                  पर्खने ।
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 md:col-span-1 md:pl-11">
            <div className="single flex gap-2">
              <Image src={OldMan} alt="doc" className="max-w-16" />

              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  उद्धारलाई प्राथमिकता दिने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  बालबालिका, गर्भवती महिला, वृद्ध व्यक्तिहरू, अपाङ्गता भएका
                  व्यक्तिहरू, र महिलाहरूको सुरक्षालाई प्राथमिकता दिँदै उनीहरूलाई
                  पहिलो चरणमा सबैभन्दा सुरक्षित स्थानमा लाग्ने
                </p>
              </div>
            </div>
            <div className="single flex gap-2">
              <Image src={EmergencyExit} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  सुरक्षित रूपमा स्थानान्तरण गर्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  पूर्व पहिचान गरिएको निकाशी मार्ग (Evacuation route) मार्फत
                  सम्पूर्ण परिवार सुरक्षित स्थानमा छिटो भन्दा छिटो पुग्ने ।
                </p>
                <p className="mt-2 text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  यदि स्थानान्तरण गर्न भनिएको छ भने तुरून्तै भनिएको व्यवस्था
                  अनुसार स्थानान्तरण गर्ने र सो क्रममा झटपट झोला सहित बाहिर
                  निस्किने ।
                </p>
              </div>
            </div>
            <div className="single flex gap-2">
              <Image src={Services} width={45} alt="doc" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  विध्युत फ्युज तथा ग्याँसको रेगुलेटर जाँच गर्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  विध्युतको फ्युज तथा ग्याँसको रेगुलेटर बन्द गर्ने ।
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const AfterFloodPreparationSection = () => {
  return (
    <section className="bg-[#3397F3] p-5">
      <div className="full-card ">
        <div className="bg-[#037342] py-4 text-center">
          <p className="leading-36px text-[24px] font-bold text-white">
            बाढीपछी
          </p>
        </div>
        <div className="mx-4 grid gap-2 bg-[#ECF2FF] px-8 py-6 md:grid-cols-2">
          <div className="flex flex-col gap-6 border-[#037342] md:col-span-1 md:border-r md:pr-11">
            <div className="single flex gap-2 ">
              <Image src={WarningGreen} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  सूचित रहने, सचेत रहने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  स्थानीय समाचार, आपतकालीन सूचनाहरू, र आधिकारिक सामाजिक सञ्जाल
                  खाताहरूलाई निरन्तर मनिटर गर्ने। अपडेटहरू र निर्देशनहरूको लागि
                  ध्यान दिने। अधिकारीहरू, आपतकालीन व्यवस्थापन वा प्रथम
                  प्रतिक्रिया दिनेहरूबाट आएका आधिकारिक निर्देशनहरूमा ध्यान दिने।
                </p>
              </div>
            </div>
            <div className="single flex gap-2">
              <Image src={HomeSearch} width={45} alt="doc" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  क्षति मूल्यांकन गर्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  आफ्नो सम्पत्तिमा भएको क्षतिको विस्तारलाई मूल्यांकन गर्ने।
                </p>
              </div>
            </div>
            <div className="single flex gap-2">
              <Image src={ClipboardGreen} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  आपतकालीन प्रक्रिया पालन गर्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  स्थानीय अधिकारीहरू वा आपतकालीन व्यवस्थापन एजेन्सीहरूले दिएका
                  आपतकालीन प्रक्रिया र निर्देशनहरू पालन गर्ने।
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 md:col-span-1 md:pl-11">
            <div className="single flex justify-between gap-2">
              <Image src={TwoPeople} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  आफु सुरक्षित भएको खण्डमा अरूलाई मद्दत गर्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  यदि आफु सुरक्षित हुनुहुन्छ भने वृद्ध, अपाङ्ग व्यक्तिहरू वा
                  बालबालिकाहरू जस्ता मद्दत चाहनेहरूलाई सहयोग गर्ने।
                </p>
              </div>
            </div>
            <div className="single flex justify-between gap-2">
              <Image src={DropOfWater} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  स्रोतहरू संरक्षण गर्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  खाना, पानी, र ब्याट्री जस्ता स्रोतहरू संरक्षण गर्ने। सहायता
                  नआउन्जेल र स्थिति स्थिर नभएसम्म तपाईंसँग पर्याप्त रिसोर्सहरू
                  छन् भनी सुनिश्चित गर्न खाना र पानी जस्ता स्रोतहरू थोरै प्रयोग
                  गर्नुहोस्।
                </p>
              </div>
            </div>
            <div className="single flex justify-between gap-2">
              <Image src={CallGreen} alt="doc" className="max-w-16" />
              <div>
                <p className="text-[14px] font-semibold leading-[21px] text-grey-950">
                  आफन्तसँग सम्पर्क गर्ने
                </p>
                <p className="text-[13px] leading-[19.5px] text-[#4c4c4c]">
                  सकेसम्म चाँडो आफ्नो सुरक्षा र ठाँउको बारेमा आफ्नो
                  प्रियजनहरूलाई सूचित गर्नुहोस् फोन लाइनको चाँप भएको खण्डमा
                  टेक्स्ट सन्देश वा सामाजिक सञ्जालको प्रयोग गर्नुहोस्
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default function FloodPreparation() {
  return (
    <section className="rounded-lg  px-4 py-10">
      <FloodHeaderSection />
      <BeforeFloodPreparationSection />
      <DuringFloodPreparationSection />
      <AfterFloodPreparationSection />
    </section>
  );
}
