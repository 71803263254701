import { Controller } from 'react-hook-form';
import { Switch } from '@radix-ui/react-switch';
import { FormFieldType, UseFormPropsType } from './types';
import { Input, MultiSelect, Select } from '../FormUI';
import FileUpload from '../FormUI/FileUpload';
import PasswordInput from './PasswordInput';
import ArrayofInputs from './ArrayofInputs';

export default function getInputElement(
  data: FormFieldType,
  formProps: UseFormPropsType,
  dropdownOptions: Record<string, any>,
  arrayInputFields?: Record<string, any>[],
  _t?: Function,
) {
  const {
    id,
    type,
    placeholder,
    required,
    inputType,
    multiple,
    validationSchema,
    readOnly,
    comparingKey,
    fileAccept,
    selectedOption,
  } = data;

  const { register, control, getValues, errors } = formProps;

  switch (type) {
    case 'input':
      return inputType === 'password' ? (
        <PasswordInput
          id={id}
          readOnly={readOnly}
          validationSchema={validationSchema}
          register={register}
          placeholder={placeholder}
          comparingKey={comparingKey}
          getValues={getValues}
          required={required}
        />
      ) : (
        <Input
          type={inputType || 'text'}
          className="!block"
          placeholder={_t ? _t?.(`${placeholder}`) : placeholder}
          {...register(`${id}`, {
            required: required ? 'Required' : false,
            validate: validationSchema || undefined,
          })}
          readOnly={!!readOnly}
        />
      );

    case 'select':
      return (
        <Controller
          control={control}
          name={id}
          defaultValue=""
          rules={{
            required: required ? 'Required' : false,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder={_t ? _t?.(`${placeholder}`) : placeholder}
              options={dropdownOptions[id] || []}
              labelKey="alias"
              valueKey="name"
              selectedOption={selectedOption || value}
              onChange={onChange}
              className="[&>ul]:z-[1000]"
            />
          )}
        />
      );
    case 'multi-select':
      return (
        <Controller
          control={control}
          name={id}
          defaultValue=""
          rules={{
            required: required ? 'Required' : false,
          }}
          render={({ field: { value, onChange } }) => (
            <MultiSelect
              placeholder={_t ? _t?.(`${placeholder}`) : placeholder}
              options={dropdownOptions[id] || []}
              labelKey="alias"
              valueKey="name"
              selectedOptions={value}
              onChange={onChange}
            />
          )}
        />
      );
    case 'file-upload':
      return (
        <Controller
          control={control}
          name={id}
          rules={{
            required: required ? 'Required' : false,
          }}
          render={({ field: { value, onChange } }) => (
            <FileUpload
              placeholder={_t ? _t?.(`${placeholder}`) : placeholder}
              multiple={!!multiple}
              name={data.id}
              data={value}
              fileAccept={fileAccept}
              onChange={() => onChange(getValues?.()?.[data.id])}
              {...formProps}
            />
          )}
        />
      );
    case 'array-of-inputs':
      return (
        <ArrayofInputs
          id={id}
          register={register}
          required={required}
          arrayData={arrayInputFields}
          errors={errors}
        />
      );

    case 'switch':
      return (
        <Controller
          control={control}
          name={id}
          defaultValue={false}
          rules={{
            required: required ? 'Required' : false,
          }}
          render={({ field: { value, onChange } }) => (
            <Switch checked={value} onClick={() => onChange(!value)} />
          )}
        />
      );

    default:
      return '';
  }
}
