/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AxiosError, AxiosResponse } from 'axios';
import Person from '@Assets/images/person.svg';
import validatePassword from '@Utils/validatePassword';
import getCookie from '@Utils/cookieUtils';
import { useTypedDispatch } from '@Store/hooks';
import { setAuthenticationState } from '@Store/actions/authentication';
import Image from '@Components/RadixComponents/Image';
import Label from '@Components/common/FormUI/Label';
import { Button } from '@Components/RadixComponents/Button';
import InfoDialog from '@Components/common/InfoDialog';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { signInUser } from '@Services/authentication';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import Icon from '@Components/common/Icon';
import FormControl from '@Components/common/FormUI/FormControl';
import Input from '@Components/common/FormUI/Input';
import { ILoginParams, ILoginResponse } from './types';

function validateEmail(email: string) {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const initialState = {
  username: '',
  password: '',
};

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showErrorToggle, setShowErrorToggle] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const validEmail = validateEmail(email);

  const handleShow = () => {
    return setShowPassword(prev => !prev);
  };

  const { mutate, isError, isLoading, error } = useMutation<
    AxiosResponse<any, any>,
    unknown,
    ILoginParams
  >({
    mutationFn: signInUser,
    onSuccess: (res: ILoginResponse) => {
      const { data } = res;
      dispatch(
        setAuthenticationState({
          user: data,
        }),
      );
      if (process.env.NODE_ENV === 'development') {
        localStorage.setItem('token', data.token);
        navigate(`${data.admin ? '/dashboard' : '/'}`);
      }
      if (getCookie('csrftoken')) {
        navigate(`${data.admin ? '/dashboard' : '/'}`);
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: initialState,
  });

  const onSubmit = (data: ILoginParams) => {
    // Prepare the data to be sent in the payload
    const formData = {
      // If the input is not a valid email, send it as 'username'
      ...(validEmail ? { email: data.username } : { username: data.username }),
      password: data.password,
    };
    // Send the mutated data in the payload
    mutate(formData);
  };

  useEffect(() => {
    if (isError) {
      setShowErrorToggle(true);
    }
  }, [isError, error]);

  return (
    <FlexColumn gap={14} className="mx-auto w-4/5 md:w-[360px]">
      <FlexColumn gap={3} className="naxatw grid w-full place-items-center">
        <Image src={Person} />
        <h3 className="font-semibold">Sign In</h3>
      </FlexColumn>
      <FlexColumn>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex translate-x-0 flex-col gap-5 pt-7"
        >
          {showErrorToggle && (
            <InfoDialog
              status="error"
              className="py-2"
              description={
                (error instanceof AxiosError &&
                  error?.response?.data?.message) ||
                'Something is not right.'
              }
              onClose={() => setShowErrorToggle(false)}
            />
          )}
          <FormControl>
            <Label htmlFor="username" required>
              Username or Email
            </Label>
            <Input
              id="username"
              type={validEmail ? 'email' : 'text'}
              placeholder="Username or Email"
              {...register('username', {
                required: 'Required',
                onChange: e => setEmail(e.target.value),
              })}
            />
            <ErrorMessage message={errors?.username?.message || ''} />
          </FormControl>

          <FormControl className="relative">
            <Label htmlFor="password" required>
              Password
            </Label>
            <Input
              id="password"
              placeholder="*******"
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                required: 'Required',
                validate: {
                  matchPattern: (value: any) => validatePassword(value),
                },
                onChange: () => {
                  trigger('password');
                },
              })}
            />
            <Icon
              title={`${showPassword ? 'Hide' : 'Show'} Password`}
              name={showPassword ? 'visibility' : 'visibility_off'}
              className="absolute right-2 top-9 cursor-pointer !text-xl text-grey-800"
              onClick={() => handleShow()}
            />
            <ErrorMessage message={errors?.password?.message || ''} />
          </FormControl>

          <div className="grid place-content-end">
            <Button
              type="button"
              variant="ghost"
              onClick={() => {
                navigate('/forgot-password');
              }}
            >
              Forgot Your Password?
            </Button>
          </div>

          <Button
            type="submit"
            withLoader
            isLoading={isLoading}
            className="w-full !p-5"
          >
            Sign In
          </Button>
        </form>
        <FlexRow gap={4} className="mt-2 items-center justify-center">
          <p className="text-sm">Don’t have a account ?</p>
          <Button
            variant="ghost"
            onClick={() => {
              navigate('/sign-up');
            }}
            className="!p-0 !text-base"
          >
            Sign Up
          </Button>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
}
