import * as React from 'react';

import { cn } from '@Utils/index';

export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  ({ className, placeholder, type, ...rest }, ref) => {
    return (
      <input
        type={type}
        placeholder={placeholder || 'Search'}
        className={cn(
          `flex h-11 border-b border-grey-800 bg-transparent px-3 text-body-md file:font-medium hover:border-primary-600 focus:border-primary-600
            focus:bg-transparent focus:outline-none disabled:cursor-not-allowed `,
          className,
        )}
        ref={ref}
        {...rest}
      />
    );
  },
);
Input.displayName = 'Input';

export default Input;
