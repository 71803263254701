/* eslint-disable no-nested-ternary */
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import PeopleHere from '@Assets/images/public-page/HDPRP/PeopleHere.svg';
import Siren from '@Assets/images/public-page/HDPRP/Siren.svg';
import Image from '@Components/RadixComponents/Image';
import { ReactNode } from 'react';

const { SITE_NAME } = process.env;

const parshuramData = [
  {
    number: '१',
    title: 'महाकाली नदी सतह मापन केन्द्र',
    locations: [
      {
        name: 'परशुराम धाम डडेल्धुरा',
        contact: 'तारा दत्त जोशी - ९८४७६११९७५२',
      },
      { name: 'दत्तु दार्चुला', contact: 'कृष्ण सिंह नागरी - ९७४९५२४८५१' },
    ],
  },
  {
    number: '२',
    title: 'महाकाली नदी सतह मापन केन्द्र',
    locations: [
      { name: 'आलिताल', contact: 'यादव वोगरी: ९८०६४०४५२५' },
      { name: 'कैनपानी', contact: 'पशुपति धामी : ९८४७६१९७५२' },
    ],
  },
];

const dodharaData = [
  {
    number: '१',
    title:
      'साइरन जडान गरिएको स्थान : ओमकार आधारभूत विद्यालय, १० सुर्खेती टोल, दोधारा चाँदनी',
  },
  {
    number: '२',
    title:
      '४ लेन पुलमा नदीको स्तर मापन उपकरण जडान गरिएको स्थान : दोधारा चाँदनी र भिमदत्त जोडिने पुल',
  },
];

const MeasurementCenterWrapper = ({ children }: { children: ReactNode }) => (
  <FlexColumn
    gap={5}
    className="justify-center rounded-lg bg-white px-5 py-5 md:col-span-2 md:px-10"
  >
    {children}
  </FlexColumn>
);

const BheemdattaMessage = () => (
  <h3 className="text-[#037342]">
    हालको लागि भीमदत्त नगरपालिकामा पुर्माणूमानको लागि कुनै पनि भौतिक स्थापना
    भएको छैन ।
  </h3>
);

interface Ilocation {
  name?: string;
  contact?: string;
}

interface IMeasurementCenterProps {
  number: string;
  title: string;
  locations?: Ilocation[];
}

const MeasurementCenter = ({
  number,
  title,
  locations,
}: IMeasurementCenterProps) => (
  <FlexRow gap={5} className="items-center">
    <div>
      <p className="flex h-11 w-11 items-center justify-center rounded-full bg-[#037342] text-3xl font-bold text-white">
        {number}
      </p>
    </div>
    <FlexColumn>
      <p className="text-[1rem] font-bold leading-7 text-[#037342]">{title}</p>
      {locations?.map(location => (
        <p key={location.name} className="text-[1rem] font-bold leading-6">
          {location?.name} : &nbsp;
          <span className="font-bold text-[#037342]">
            ({location?.contact})
          </span>
        </p>
      ))}
    </FlexColumn>
  </FlexRow>
);

const MeasurementCenterList = ({
  data,
}: {
  data: IMeasurementCenterProps[];
}) => (
  <>
    {data.map(center => (
      <MeasurementCenter key={center.number} {...center} />
    ))}
  </>
);

const MeasurementCenters = () => {
  switch (SITE_NAME) {
    case 'Dastaa Bheemdatta':
      return (
        <MeasurementCenterWrapper>
          <BheemdattaMessage />
        </MeasurementCenterWrapper>
      );
    case 'Dastaa Parshuram':
      return (
        <MeasurementCenterWrapper>
          <MeasurementCenterList data={parshuramData} />
        </MeasurementCenterWrapper>
      );
    case 'Dastaa Dodahra':
      return (
        <MeasurementCenterWrapper>
          <MeasurementCenterList data={dodharaData} />
        </MeasurementCenterWrapper>
      );
    default:
      return (
        <MeasurementCenterWrapper>
          <BheemdattaMessage />
        </MeasurementCenterWrapper>
      );
  }
};

export default function ParticipationDecision() {
  return (
    <FlexColumn gap={10}>
      <section>
        <div className="grid rounded-lg bg-[#037342] px-5 py-6 md:grid-cols-3">
          <div className="col-span-1 flex flex-col justify-center gap-5 p-5">
            <Image width={150} src={PeopleHere} />
            <p className="pr-20 text-[32px] font-extrabold leading-[48px] text-white">
              सहभागितामूलक निर्णय
            </p>
          </div>
          <div className="col-span-2 flex flex-col justify-between rounded-lg bg-white px-5 py-3">
            <div className="mt-3 flex gap-4">
              <div>
                <p className="h-[2.5rem] w-[2.5rem] rounded-full bg-[#037342] text-center text-[2rem] font-bold leading-[2.625rem] text-white">
                  १
                </p>
              </div>
              <div>
                <h4 className="text-[#037342]">सचेतना वृद्धि</h4>
                <p className="text-[1rem] leading-[1.688rem] text-[#037342]">
                  घरका सबै सदस्यहरुलाई आफ्नो वरिपरि कस्ता खालका विपद जोखिम छन
                  जानकारी गराउने । साथै घरधुरी स्तरिय विपद जोखिम योजनाको महत्व र
                  कसरी बनाउने वारे व्यापक छलफल गर्ने ।
                </p>
              </div>
            </div>
            <div className="mt-3 flex gap-4">
              <div>
                <p className="h-[2.5rem] w-[2.5rem] rounded-full bg-[#037342] text-center text-[2rem] font-bold leading-[2.625rem] text-white">
                  २
                </p>
              </div>
              <div>
                <h4 className="text-[#037342]">सहभागितामुलक योजना तर्जुमा </h4>
                <p className="text-[1rem] leading-[1.688rem] text-[#037342]">
                  विपदको सामना गर्ने क्षमता घरका सदस्यहरुको विचमा पनि फरक फरक
                  हुन सक्छ । खासगरी जेष्ठ नागरिक, बालबालिका, अपाङ्गता भएका
                  व्याक्ति, गर्भवती तथा सुत्केरी महिलाहरु विपदको वढी जोखिममा
                  हुने भएकाले उनीहरुको जोखिम न्युनिकरणका वारेमा छलफल गर्ने,
                  उनीहरुको विचार र समस्यालाई योजनामा राख्ने ।
                </p>
              </div>
            </div>
            <div className="mt-3 flex gap-4">
              <div>
                <p className="h-[2.5rem] w-[2.5rem] rounded-full bg-[#037342] text-center text-[2rem] font-bold leading-[2.625rem] text-white">
                  ३
                </p>
              </div>
              <div>
                <h4 className="text-[#037342]">प्रकोप र संकटासन्नता पहिचान</h4>
                <p className="text-[1rem] leading-[1.688rem] text-[#037342]">
                  परिवारका सवै सदस्यहरुको सहभागितामा संभावित प्रकोप र जोखिमहरुको
                  पहिचान गर्ने र कुन जोखिमका लागि कस्तो प्रतिकार्य योजना बनाउने
                  छलफल गर्ने ।
                </p>
              </div>
            </div>
            <div className="mt-3 flex gap-4">
              <div>
                <p className="h-[2.5rem] w-[2.5rem] rounded-full bg-[#037342] text-center text-[2rem] font-bold leading-[2.625rem] text-white">
                  ४
                </p>
              </div>
              <div>
                <h4 className="text-[#037342]">प्रकोप र संकटासन्नता पहिचान</h4>
                <p className="text-[1rem] leading-[1.688rem] text-[#037342]">
                  जिम्मेवारी वाडँफाड प्रत्येक परिवारका सदस्यहरुलाई उनीहरुको सिप,
                  क्षमता र रुचिहरुको आधारमा विशेष जिम्मेवारी तोक्ने । यसमा
                  आपतकालिन किटहरु तयार गर्ने, विपद पूर्व सुचनाको जानकारी लिने,
                  प्राथमिक उपचार प्रशिक्षणमा भाग लिने जस्ता कार्यहरु समावेश गर्न
                  सकिन्छ ।
                </p>
              </div>
            </div>
            <div className="mt-3 flex gap-4">
              <div>
                <p className="h-[2.5rem] w-[2.5rem] rounded-full bg-[#037342] text-center text-[2rem] font-bold leading-[2.625rem] text-white">
                  ५
                </p>
              </div>
              <div>
                <h4 className="text-[#037342]">क्षमता विकास</h4>
                <p className="text-[1rem] leading-[1.688rem] text-[#037342]">
                  रकोप पुर्वतयारी र प्रतिकार्यमा घर परिवारका सदस्यहरुको सिप
                  अभिवृद्धि गर्न तालिम र क्षमता निर्माणका अवसरहरु पहिचान गरी
                  उनीहरुको सहभागिताको लागि पहल गर्ने ।
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="grid gap-5 rounded-lg bg-[#037342] p-8 md:grid-cols-3">
        <FlexColumn className="col-span-1 items-center md:items-start">
          <img src={Siren} className="w-28" alt="Siren" />
          <p className="mt-6 text-[2rem] font-extrabold leading-[46px] text-white">
            भौतिक स्थापना
          </p>
          <p className="text-xl font-medium leading-[35px] text-white">
            साइरन जडान गरिएको स्थान
          </p>
        </FlexColumn>
        <MeasurementCenters />
        {/* {SITE_NAME === 'Dastaa Bheemdatta' ? (
          <FlexColumn className="justify-center rounded-lg bg-white px-5 py-5 md:col-span-2 md:px-10">
            <h3 className="text-[#037342]">
              हालको लागि भीमदत्त नगरपालिकामा पुर्माणूमानको लागि कुनै पनि भौतिक
              स्थापना भएको छैन ।
            </h3>
          </FlexColumn>
        ) : SITE_NAME === 'Dastaa Parshuram' ? (
          <FlexColumn
            gap={5}
            className="justify-center rounded-lg bg-white px-5 py-5 md:col-span-2 md:px-10"
          >
            {parshuramData.map(center => (
              <MeasurementCenter key={center.number} {...center} />
            ))}
          </FlexColumn>
        ) : (
          <FlexColumn
            gap={5}
            className="justify-center rounded-lg bg-white px-5 py-5 md:col-span-2 md:px-10"
          >
            {dodharaData.map(center => (
              <MeasurementCenter key={center.number} {...center} />
            ))}
          </FlexColumn>
        )} */}
      </section>
    </FlexColumn>
  );
}
