import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cn } from '@Utils/index';

const RangeSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      `relative flex w-full cursor-pointer touch-none
       select-none items-center`,
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track
      className="relative h-0.5 w-full grow overflow-hidden
        rounded-full bg-grey-200"
    >
      <SliderPrimitive.Range className="absolute h-full bg-grey-600" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb
      className="ring-offset-background focus-visible:ring-ring
        block h-3 w-3 rounded-full bg-grey-600
        transition-colors focus-visible:outline-none 
        focus-visible:ring-2 focus-visible:ring-offset-2 
        disabled:pointer-events-none disabled:opacity-50"
    />
  </SliderPrimitive.Root>
));

RangeSlider.displayName = SliderPrimitive.Root.displayName;

export default RangeSlider;
