import Image from '@Components/RadixComponents/Image';
import CheckListPic from '@Assets/images/public-page/HDPRP/CheckList.png';
import { checkListRowOneData, checkListRowTwoData } from '@Constants/hdprp';

export default function CheckList() {
  return (
    <section>
      <div className="topic text-center">
        <div className="flex items-center gap-5">
          <Image src={CheckListPic} width={25} alt="check" />
          <p className="text-[25px] font-bold leading-[66px] text-grey-950">
            चेकलिस्ट
          </p>
        </div>
      </div>
      <p className="mt-1 text-[20px] leading-[32px] text-[#4c4c4c]">
        घरमा राख्नुपर्ने आवश्यक वस्तुहरू जसलेे मानिसलाई झटपट झोला तयार पार्न
        सहयोग पुर्याउँदछ ।
      </p>
      <div className="mt-4 grid md:grid-cols-2">
        <div className="col-span-1 flex flex-col gap-2">
          {checkListRowOneData.map(data => (
            <div key={data.id} className="single card flex items-center gap-4">
              <div className="h-5 w-5 rounded-[4px] border border-[#08688B]" />
              <Image src={data.icon} alt="checlistimage" width={35} />
              <p className="text-[16px] leading-[32px]">{data.name}</p>
            </div>
          ))}
        </div>
        <div className="col-span-1 flex flex-col gap-2">
          {checkListRowTwoData.map(data => (
            <div key={data.id} className="single card flex items-center gap-4">
              <div className="h-5 w-5 rounded-[4px] border border-[#08688B]" />
              <Image src={data.icon} alt="checlistimage" width={35} />
              <p className="text-[16px] leading-[32px]">{data.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
