/**
 *
 * @returns Range of years as object in a Array.
 */
// eslint-disable-next-line import/prefer-default-export
export const rangeOfYears = Array.from({ length: 20 }, (_, i) => ({
  id: new Date().getFullYear() - i,
  label: String(new Date().getFullYear() - i),
  value: new Date().getFullYear() - i,
}));
