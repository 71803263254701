/* eslint-disable react/no-array-index-key */
import Skeleton from '@Components/RadixComponents/Skeleton';

export default function InfoSectionLoader() {
  return (
    <section className="mt-10 grid gap-10 md:grid-cols-3">
      <div className="heading md:col-span-2">
        <Skeleton className="h-32 rounded-lg" />

        <div className="info mt-10 pl-2">
          <Skeleton className="h-8 w-64 rounded" />

          <div className="mt-5 grid grid-cols-2 gap-5">
            <div className="row-1 flex flex-col gap-2 md:col-span-1">
              {[...Array(3)].map((_, index) => (
                <div key={index} className="flex items-center">
                  <Skeleton className="h-5 w-1/5 rounded" />
                  <Skeleton className="ml-2 h-5 w-2/4 rounded" />
                </div>
              ))}
            </div>
            <div className="row-2 flex flex-col gap-2 md:col-span-1">
              {[...Array(3)].map((_, index) => (
                <div key={index} className="flex items-center">
                  <Skeleton className="h-5 w-1/5 rounded" />
                  <Skeleton className="ml-2 h-5 w-2/4 rounded" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Skeleton className="h-72 w-full rounded-lg md:col-span-1" />
    </section>
  );
}
