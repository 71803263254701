import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Input, Select } from '@Components/common/FormUI';
import Switch from '@Components/RadixComponents/Switch';
import RangeSlider from '@Components/RadixComponents/RangeSlider';
import { Button } from '@Components/RadixComponents/Button';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setMapExportState } from '@Store/actions/mapExport';
import { MapInstanceType } from '@Components/common/MapLibreComponents/types';
import {
  orientationOptions,
  pageSizeOptions,
  outputFormatOptions,
  paperSize,
  marginOptions,
} from '@Constants/visualization';
import { MutableRefObject } from 'react';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { jsPDF as JsPDF } from 'jspdf';

export default function MapExportSidebar({
  exportMap,
  paperRef,
}: {
  exportMap: MapInstanceType | null;
  paperRef: MutableRefObject<HTMLDivElement | null>;
}) {
  const dispatch = useTypedDispatch();

  // all the global states used in map export fetched
  const is3DToggled = useTypedSelector(
    state => state.visualization.is3DToggled,
  );
  const mapTitle = useTypedSelector(state => state.mapExport.mapTitle);
  const orientation = useTypedSelector(state => state.mapExport.orientation);
  const pageSize = useTypedSelector(state => state.mapExport.pageSize);
  const outputFormat = useTypedSelector(state => state.mapExport.outputFormat);
  const isLegendOn = useTypedSelector(state => state.mapExport.isLegendOn);
  const isNorthArrowOn = useTypedSelector(
    state => state.mapExport.isNorthArrowOn,
  );
  const isScaleBarOn = useTypedSelector(state => state.mapExport.isScaleBarOn);
  const isGridOn = useTypedSelector(state => state.mapExport.isGridOn);
  const isOverlayOn = useTypedSelector(state => state.mapExport.isOverlayOn);
  const mapRotation = useTypedSelector(state => state.mapExport.mapRotation);
  const baseLayerOpacity = useTypedSelector(
    state => state.mapExport.baseLayerOpacity,
  );
  const margin = useTypedSelector(state => state.mapExport.margin);
  const currentPaperSize = useTypedSelector(
    state => state.mapExport.currentPaperSize,
  );
  const marginColor = useTypedSelector(state => state.mapExport.marginColor);

  const extraTools: Record<string, any>[] = [
    {
      id: 1,
      name: 'Legend',
      onClick: () => {
        dispatch(setMapExportState({ isLegendOn: !isLegendOn }));
      },
      checked: isLegendOn,
    },
    {
      id: 2,
      name: 'North Arrow',
      onClick: () => {
        dispatch(setMapExportState({ isNorthArrowOn: !isNorthArrowOn }));
      },
      checked: isNorthArrowOn,
    },
    {
      id: 3,
      name: 'Scale Bar',
      onClick: () => {
        dispatch(setMapExportState({ isScaleBarOn: !isScaleBarOn }));
      },
      checked: isScaleBarOn,
    },
    ...(is3DToggled
      ? []
      : [
          {
            id: 4,
            name: 'Grid',
            onClick: () => {
              dispatch(setMapExportState({ isGridOn: !isGridOn }));
            },
            checked: isGridOn,
          },
        ]),
    {
      id: 5,
      name: 'Overlay',
      onClick: () => {
        dispatch(setMapExportState({ isOverlayOn: !isOverlayOn }));
      },
      checked: isOverlayOn,
    },
  ];

  // handles main export of map
  const handleMapExport = () => {
    if (!exportMap || !paperRef) return;
    let size: number[] = [currentPaperSize[0], currentPaperSize[1]];
    if (orientation === 'landscape') {
      size = [currentPaperSize[1], currentPaperSize[0]];
    }
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule(
      'body > div:last-child img { display: inline-block; }',
    );
    html2canvas(paperRef.current as HTMLDivElement, {
      scale: 2,
      allowTaint: true,
      useCORS: true,
    }).then(canvas => {
      if (outputFormat === 'png') {
        canvas.toBlob(blob => {
          if (blob) saveAs(blob, 'map.png');
        });
      } else if (outputFormat === 'jpeg') {
        canvas.toBlob(blob => {
          if (blob) {
            saveAs(blob, 'map.jpg');
          }
        });
      } else if (outputFormat === 'pdf') {
        // @ts-ignore
        const pdf = new JsPDF(orientation, 'mm', pageSize);
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          0,
          0,
          size[0],
          size[1],
        );
        pdf.save('map.pdf');
      }
    });
  };

  return (
    <div className="h-full w-1/4 bg-white">
      <div className="scrollbar relative h-[80%] overflow-y-scroll px-4 pt-3">
        <FlexColumn>
          <span className="text-sm">Map Title</span>
          <Input
            placeholder="Enter Map Title"
            className="w-full"
            value={mapTitle}
            onChange={e =>
              dispatch(setMapExportState({ mapTitle: e.target.value }))
            }
          />
        </FlexColumn>
        <FlexRow className="mt-8 justify-between">
          <FlexColumn className="w-[48%]">
            <span className="text-sm">Orientation</span>
            <Select
              placeholder="Orientation"
              options={orientationOptions}
              selectedOption={orientation}
              onChange={(selectionOption: any) => {
                dispatch(setMapExportState({ orientation: selectionOption }));
              }}
            />
          </FlexColumn>
          <FlexColumn className="w-[48%]">
            <span className="text-sm">Page Size</span>
            <Select
              placeholder="Page Size"
              options={pageSizeOptions}
              selectedOption={pageSize}
              onChange={(selectedOption: any) => {
                dispatch(setMapExportState({ pageSize: selectedOption }));
                dispatch(
                  setMapExportState({
                    currentPaperSize: paperSize[selectedOption],
                  }),
                );
              }}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow className="mt-8 gap-4">
          <FlexColumn className="w-[48%]">
            <span className="text-sm">Output Format</span>
            <Select
              placeholder="Output Format"
              options={outputFormatOptions}
              selectedOption={outputFormat}
              onChange={(selectedOption: any) =>
                dispatch(setMapExportState({ outputFormat: selectedOption }))
              }
            />
          </FlexColumn>
          <FlexColumn className="w-[48%]">
            <span className="text-sm">Map Rotation</span>
            <Input
              type="number"
              placeholder="Rotation"
              value={mapRotation}
              onChange={e => {
                const { value } = e.target;
                if (
                  value === '' ||
                  // checks if the input number is between 0 and 360 degrees
                  (Number(value) >= 0 && Number(value) <= 360)
                ) {
                  dispatch(setMapExportState({ mapRotation: value }));
                }
              }}
              min={0}
              max={360}
            />
          </FlexColumn>
        </FlexRow>
        <FlexColumn className="mt-8 gap-4">
          <span className="text-sm">Opacity</span>
          <RangeSlider
            onValueChange={opacity =>
              dispatch(setMapExportState({ baseLayerOpacity: opacity[0] }))
            }
            defaultValue={[baseLayerOpacity]}
            max={100}
            step={1}
          />
        </FlexColumn>
        <FlexColumn className="mt-8">
          <span className="text-sm">Margin</span>
          <Select
            placeholder="Margin"
            options={marginOptions}
            selectedOption={margin}
            onChange={selectedOption => {
              dispatch(setMapExportState({ margin: selectedOption }));
              // sets margin value and color to the default
              if (selectedOption !== 'custom') {
                dispatch(setMapExportState({ marginValue: +selectedOption }));
                dispatch(setMapExportState({ marginColor: '#FFFFFF' }));
              } else {
                dispatch(setMapExportState({ marginValue: 0 }));
              }
            }}
          />
        </FlexColumn>
        {margin === 'custom' && (
          <>
            <FlexRow className="mt-8 gap-10">
              <FlexColumn className="w-[40%]">
                <span className="text-sm">Margin Thickness (mm)</span>
                <Input
                  type="number"
                  placeholder="Thickness (in mm)"
                  onChange={e => {
                    const { value } = e.target;
                    if (
                      value === '' ||
                      // checks if the input number is between 0 and 4
                      (Number(value) >= 0 && Number(value) <= 4)
                    ) {
                      dispatch(
                        setMapExportState({
                          // converting mm to pixels
                          marginValue: (+value * 96) / 25.4,
                        }),
                      );
                    }
                  }}
                  min={0}
                  max={4}
                />
              </FlexColumn>
              <FlexRow className="w-[48%]">
                <span className="mt-3 text-sm">Margin Color</span>
                <Input
                  type="color"
                  className="!w-16 border-none"
                  onChange={e => {
                    dispatch(
                      setMapExportState({ marginColor: e.target.value }),
                    );
                  }}
                  value={marginColor}
                />
              </FlexRow>
            </FlexRow>
            <FlexRow className="mt-8 gap-4">
              <FlexColumn className="w-[48%]">
                <span className="text-sm">Margin Top</span>
                <Input
                  type="number"
                  placeholder="Margin Top"
                  onChange={e => {
                    const { value } = e.target;
                    if (
                      value === '' ||
                      (Number(value) >= 0 && Number(value) <= 30)
                    ) {
                      dispatch(
                        setMapExportState({ marginTop: (+value * 96) / 25.4 }),
                      );
                    }
                  }}
                  min={0}
                  max={30}
                />
              </FlexColumn>
              <FlexColumn className="w-[48%]">
                <span className="text-sm">Margin Right</span>
                <Input
                  type="number"
                  placeholder="Margin Right"
                  onChange={e => {
                    const { value } = e.target;
                    if (
                      value === '' ||
                      (Number(value) >= 0 && Number(value) <= 30)
                    ) {
                      dispatch(
                        setMapExportState({
                          marginRight: (+value * 96) / 25.4,
                        }),
                      );
                    }
                  }}
                  min={0}
                  max={30}
                />
              </FlexColumn>
            </FlexRow>
            <FlexRow className="mt-8 gap-4">
              <FlexColumn className="w-[48%]">
                <span className="text-sm">Margin Bottom</span>
                <Input
                  type="number"
                  placeholder="Margin Bottom"
                  onChange={e => {
                    const { value } = e.target;
                    if (
                      value === '' ||
                      (Number(value) >= 0 && Number(value) <= 30)
                    ) {
                      dispatch(
                        setMapExportState({
                          marginBottom: (+value * 96) / 25.4,
                        }),
                      );
                    }
                  }}
                  min={0}
                  max={30}
                />
              </FlexColumn>
              <FlexColumn className="w-[48%]">
                <span className="text-sm">Margin Left</span>
                <Input
                  type="number"
                  placeholder="Rotation"
                  onChange={e => {
                    const { value } = e.target;
                    if (
                      value === '' ||
                      (Number(value) >= 0 && Number(value) <= 30)
                    ) {
                      dispatch(
                        setMapExportState({ marginLeft: (+value * 96) / 25.4 }),
                      );
                    }
                  }}
                  min={0}
                  max={30}
                />
              </FlexColumn>
            </FlexRow>
          </>
        )}

        <FlexColumn className="mt-8">
          {/* options while exporting the map */}
          {extraTools.map(tool => (
            <FlexRow key={tool.id} className="mb-5 justify-between">
              <span className="font-primary text-body-lg">{tool.name}</span>
              <Switch checked={tool.checked} onClick={tool.onClick} />
            </FlexRow>
          ))}
        </FlexColumn>
      </div>
      <div className="flex h-[17%] w-full items-center justify-center">
        <Button
          withLoader
          leftIcon="download"
          variant="default"
          onClick={handleMapExport}
          className="mx-auto mt-6"
        >
          Export
        </Button>
      </div>
    </div>
  );
}
