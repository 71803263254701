import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { handleChartAPISuccess } from '@Store/actions/dashboard';
import prepareChartData from '@Utils/prepareChartData';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ChartHeader from '@Components/common/Charts/ChartHeader';
import StackedChart from '@Components/common/Charts/StackedChart';
import YLabelHorizontalBarChart from '@Components/common/Charts/YLabelHorizontalBarchart';
import { summaryChartSortOrder, summaryChartIds } from '@Constants/publicPage';
import { donutChartFills } from '../constants';
import ChartWrapper from '../ChartWrapper';
import HorizontalBarChart from '../HorizontalBarChart';
import CustomBarChart from '../BarChart';
import ScatterChartComponent from '../ScatterChart';
import CustomDonutChart from '../DonutChart';
import NoChartDataComponent from '../NoChartDataComponent';

interface IChartRendererProps {
  data: Record<string, any>;
  chartIndex?: number;
  dynamicRender?: boolean;
}

const numberOfConcurrentAPIRequests = 3;

export default function ChartRenderer({
  data,
  chartIndex,
  dynamicRender = false,
}: IChartRendererProps) {
  const { id, title, chartType, queryFn, wrapperClassName, params } = data;
  const ref = useRef();
  const dispatch = useTypedDispatch();
  const fetchedChartIds = useTypedSelector(
    state => state.dashboard.fetchedChartIds,
  );

  const {
    data: chartData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [id, params],
    queryFn: () => queryFn(params),
    select: res => {
      if (id === 'cooking-sources') {
        return prepareChartData(res.data.cooking_source);
      }
      // sort summary charts
      if (summaryChartIds.includes(id)) {
        const modifiedData = prepareChartData(res.data);
        modifiedData.sort(
          (a, b) =>
            summaryChartSortOrder.indexOf(a.name) -
            summaryChartSortOrder.indexOf(b.name),
        );
        return modifiedData;
      }
      return prepareChartData(res.data);
    },
    onSuccess: () => {
      dispatch(handleChartAPISuccess(id));
    },
    enabled: !dynamicRender,
  });

  // fetch n chart APIs at a time
  // to fix pending api issue
  useEffect(() => {
    if (!dynamicRender || !chartIndex) return;
    if (fetchedChartIds.includes(id)) return;
    // fetch first n chart APIs
    if (chartIndex <= numberOfConcurrentAPIRequests) {
      refetch();
    }
    // fetch n consecutive APIs after completion
    if (chartIndex < fetchedChartIds.length + numberOfConcurrentAPIRequests) {
      refetch();
    }
  }, [dynamicRender, chartIndex, fetchedChartIds, id, refetch]);

  if (isLoading) {
    return (
      <Skeleton
        className={`w-full rounded-lg bg-primary-50
       shadow-sm ${wrapperClassName}`}
      />
    );
  }

  const isDataEmpty = chartData?.length;

  function renderChart() {
    switch (chartType) {
      case 'donut':
        return (
          <ChartWrapper
            componentRef={ref}
            className={`animate-fade-up ${wrapperClassName}`}
          >
            <ChartHeader
              downloadComponentRef={ref}
              chartTitle={title}
              hasDownloadBtn
            />
            {!isDataEmpty ? (
              <NoChartDataComponent />
            ) : (
              <CustomDonutChart data={chartData} fills={donutChartFills} />
            )}
          </ChartWrapper>
        );
      case 'horizontalBar':
        return (
          <ChartWrapper
            componentRef={ref}
            className={`animate-fade-up ${wrapperClassName}`}
          >
            <ChartHeader
              downloadComponentRef={ref}
              chartTitle={title}
              hasDownloadBtn
            />
            {!isDataEmpty ? (
              <NoChartDataComponent />
            ) : (
              <HorizontalBarChart data={chartData} />
            )}
          </ChartWrapper>
        );

      case 'bar':
        return (
          <ChartWrapper
            componentRef={ref}
            className={`animate-fade-up ${wrapperClassName}`}
            yLabel={data?.labelY}
          >
            <ChartHeader
              downloadComponentRef={ref}
              chartTitle={title}
              hasDownloadBtn
            />
            {!isDataEmpty ? (
              <NoChartDataComponent />
            ) : (
              <CustomBarChart data={chartData} />
            )}
          </ChartWrapper>
        );

      case 'stackedChart':
        return (
          <StackedChart
            chartTitle={title}
            header={ChartHeader}
            hasDownloadBtn
            data={chartData || []}
            className={`animate-fade-up ${wrapperClassName}`}
            labelAlignment="horizontal"
          />
        );

      case 'scatterChart':
        return (
          <ChartWrapper
            className={`animate-fade-up ${wrapperClassName}`}
            yLabel={data?.labelY}
          >
            <ChartHeader
              downloadComponentRef={ref}
              chartTitle={title}
              hasDownloadBtn
            />
            {!isDataEmpty ? (
              <NoChartDataComponent />
            ) : (
              <ScatterChartComponent data={chartData} />
            )}
          </ChartWrapper>
        );

      case 'YLabelHorizontalBar':
        return (
          <ChartWrapper className={`animate-fade-up ${wrapperClassName}`}>
            <ChartHeader
              downloadComponentRef={ref}
              chartTitle={title}
              hasDownloadBtn
            />
            {!isDataEmpty ? (
              <NoChartDataComponent />
            ) : (
              <YLabelHorizontalBarChart data={chartData} />
            )}
          </ChartWrapper>
        );
      default:
        return <></>;
    }
  }

  return renderChart();
}
