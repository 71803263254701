import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationState {
  activeTab: string;
  search: string;
  activeNotificationId: null | string;
  incidentPhotos: string[];
  hazard_types: string;
  start_time: string;
  end_time: string;
}

const initialState: NotificationState = {
  activeTab: 'incident-reports',
  search: '',
  activeNotificationId: null,
  incidentPhotos: [],
  hazard_types: '',
  start_time: '',
  end_time: '',
};

const setNotificationState: CaseReducer<
  NotificationState,
  PayloadAction<Partial<NotificationState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationState,
  },
});

export { notificationSlice };

export default notificationSlice.reducer;
