/* eslint-disable import/prefer-default-export */
import prepareFormData from '@Utils/prepareFormData';
import { api, authenticated } from '.';

export const postVectorLayer = (data: any) =>
  authenticated(api).post(
    '/dastaa/layers/vector-layer/',
    prepareFormData(data),
  );

export const updateVectorLayer = (data: any) =>
  authenticated(api).patch(
    `/dastaa/layers/vector-layer/${data.id}/`,
    prepareFormData(data),
  );

export const getVectorLayer = (id: number) =>
  authenticated(api).get(`dastaa/layers/vector-layer/${id}/`);

export const getTaskResponse = (params: Record<string, string>) =>
  authenticated(api).get('dastaa/layers/task-response/', { params });

export const deleteVectorLayer = (id: number) =>
  authenticated(api).delete(`dastaa/layers/vector-layer/${id}/`);

export const uploadBuildingData = (data: any) =>
  authenticated(api).post('/upload-building-data/', prepareFormData(data));

export const uploadBuildingImages = (data: any) =>
  authenticated(api).post('/upload-building-images/', prepareFormData(data));

export const uploadRoadData = (data: any) =>
  authenticated(api).post('/upload-road-data/', prepareFormData(data));

export const getLayerAttributesList = (params: any) =>
  authenticated(api).get('dastaa/layers/attributes-list/', { params });

export const getVectoLayerCategory = (params: any) =>
  authenticated(api).get('dastaa/layers/vector-layer-category/', { params });
