import { FlexRow } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';

interface IHazard {
  data: any;
}

export default function HazardRankingComponent({ data }: IHazard) {
  return (
    <div className="flex w-full items-center justify-between gap-6">
      <FlexRow gap={3} className="items-center">
        <Image src={data?.icon} width={20} />
        <p className="text-[14px] font-semibold text-white">{data?.name}</p>
      </FlexRow>
      <h6 className=" text-xl font-semibold text-white">{data?.ranking}</h6>
    </div>
  );
}
