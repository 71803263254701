/* eslint-disable no-nested-ternary */
// import { useState } from 'react';
import PhoneInput, { Country } from 'react-phone-number-input';
import { Controller } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { useTypedSelector } from '@Store/hooks';
import { FlexColumn } from '@Components/common/Layouts';
import { FormControl, Label } from '@Components/common/FormUI';
import getInputElement from '@Components/common/FormHelpers/getInputElements';
import { UseFormPropsType } from '@Components/common/FormHelpers/types';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import { StepFormField } from '../signUpFormFields';
import './index.css';

interface IFormSectionProps {
  schema: StepFormField;
  formProps: UseFormPropsType;
}

const { SITE_NAME } = process.env;

const getCountries = (): Country[] => {
  switch (SITE_NAME) {
    case 'Dastaa Bangladesh':
      return ['BD'];
    default:
      return ['NP'];
  }
};

const getDefaultCountry = (): Country => {
  switch (SITE_NAME) {
    case 'Dastaa Bangladesh':
      return 'BD';
    default:
      return 'NP';
  }
};

const FormSection = ({ formProps, schema }: IFormSectionProps) => {
  const dropDownOptions = useTypedSelector(
    state => state.authentication.signUpForm.dropDownOptions,
  );

  const { control } = formProps;

  const securityQuestions = useTypedSelector(
    state => state.authentication.signUpForm.securityQuestions,
  );
  const selectedCountry = useTypedSelector(
    state => state.authentication.signUpForm.selectedCountry,
  );

  return (
    <FlexColumn gap={5} className="scrollbar max-h-[390px] overflow-auto">
      <h5>{schema.title}</h5>
      <FlexColumn gap={6}>
        {schema.fields.map(data => {
          const { id, label, type, required } = data;
          const isArrayOfInputs = type?.includes('array-of-inputs');

          const watchCountry = formProps.watch && formProps.watch('country');

          const isZila =
            watchCountry === 2 && id === 'district' && !selectedCountry;
          const isUpazila =
            watchCountry === 2 && id === 'municipality' && !selectedCountry;
          const modifiedLabel = isZila ? 'Zila' : isUpazila ? 'Upazila' : label;

          return (
            <FormControl key={id}>
              <FlexColumn>
                {!isArrayOfInputs && (
                  <Label required={required}>{modifiedLabel}</Label>
                )}

                {id === 'phone_no' && type === 'phone' && (
                  <Controller
                    name="phone_no"
                    control={control}
                    rules={{ required: required ? 'Required' : false }}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        international
                        placeholder="Enter phone number"
                        defaultCountry={getDefaultCountry()}
                        countries={getCountries()}
                      />
                    )}
                  />
                )}

                {getInputElement(
                  {
                    ...data,
                    selectedOption: id === 'country' ? selectedCountry : '',
                  },
                  formProps,
                  dropDownOptions || {},
                  securityQuestions || [],
                )}

                {!isArrayOfInputs && formProps?.errors[id] && (
                  <ErrorMessage
                    message={formProps?.errors[id]?.message as string}
                  />
                )}
              </FlexColumn>
            </FormControl>
          );
        })}
      </FlexColumn>
    </FlexColumn>
  );
};

export default FormSection;
