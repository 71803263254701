import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';
import { useState } from 'react';
import { Input } from '../FormUI';
import Icon from '../Icon';
import { FlexRow } from '../Layouts';

interface IPasswordInputProps {
  placeholder?: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  validationSchema?: Record<string, any>;
  readOnly?: boolean;
  id: string;
  comparingKey?: string;
  getValues: UseFormGetValues<FieldValues> | undefined;
  className?: string;
}

export default function PasswordInput({
  placeholder,
  register,
  required,
  readOnly,
  id,
  getValues,
  className,
  validationSchema,
}: IPasswordInputProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <FlexRow
      className={`group relative w-full items-center border-b border-solid border-grey-800 pr-1 hover:border-primary-600 ${className}`}
    >
      <Input
        className="w-full border-none"
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        {...register(`${id}`, {
          required: required ? 'Required' : false,
          validate: {
            ...validationSchema,
            passwordMatch: value =>
              (getValues && getValues('password') === value) ||
              'Passwords do not match',
          },
        })}
        readOnly={!!readOnly}
      />
      <Icon
        onClick={() => setShowPassword(!showPassword)}
        name={showPassword ? 'visibility' : 'visibility_off'}
        className="!text-xl hover:text-primary-600"
      />
    </FlexRow>
  );
}
