import { useCallback, useEffect, useState } from 'react';

function debounce(func: Function, timeout = 300) {
  let timer: any;
  return (arg: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(arg);
    }, timeout);
  };
}

export default function useDebouncedLoadingState(
  loadingState: boolean,
  delay?: number,
) {
  const [loading, setLoading] = useState(loadingState);

  // eslint-disable-next-line
  const debounceEvent = useCallback(
    debounce(() => setLoading(false), delay || 2200),
    [],
  );

  useEffect(() => {
    if (loadingState) {
      setLoading(true);
    } else {
      // @ts-ignore
      debounceEvent();
    }
  }, [loadingState, debounceEvent]);

  return loading;
}
