import { api, authenticated } from '.';

export const getPalikaProfile = (params: any) =>
  authenticated(api).get('/palika-profile/', {
    params,
  });

export const getSitesBoundaryBox = () =>
  authenticated(api).get('dastaa/sites_tiles/overall_boundary');

export const getSitesRegionBoundary = () =>
  authenticated(api).get('/dastaa/sites/region-boundary/');

export const getOverlayLayers = () =>
  authenticated(api).get(`/dastaa/layers/vector-layer/`);

export const getSubmissionDetail = (params: Record<string, any>) =>
  authenticated(api).post(
    `/dastaa/risk_of_sites/submission-detail/`,
    {
      params,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

export const getTotalUser = () =>
  authenticated(api).get('/dastaa/hazard/total_user/');

export const getIncidentSelectedFields = () =>
  authenticated(api).get('/dastaa/incident/select-fields');
