import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useGetSubmissionDetailQuery } from '@Api/common';
import Icon from '@Components/common/Icon';
import { Flex, FlexColumn, FlexRow } from '@Components/common/Layouts';

export default function UserDetailsForm() {
  const { siteId } = useParams();
  const navigate = useNavigate();

  const { data: userDetails } = useGetSubmissionDetailQuery(siteId);

  return (
    <Flex gap={5} className="w-screen animate-fade px-5 pt-5 md:h-screen">
      <FlexRow className="items-center justify-between">
        <h3>User Form Details</h3>
        <Icon
          name="close"
          className="!text-3xl hover:text-primary-600"
          onClick={() => navigate(-1)}
        />
      </FlexRow>
      {userDetails?.map((userDetail: Record<string, any>) => (
        <FlexColumn key={userDetail.question}>
          <h5 className="capitalize">{userDetail.question}</h5>
          <p>
            {userDetail.question === 'start' || userDetail.question === 'end'
              ? format(new Date(userDetail.answer), 'MMM dd yyyy')
              : userDetail.answer}
          </p>
        </FlexColumn>
      ))}
    </Flex>
  );
}
