import { genderOptions } from '@Constants/users';
import { getUserRoleUniqueFields } from '@Services/users';
import { useQuery } from '@tanstack/react-query';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';

// eslint-disable-next-line import/prefer-default-export
export const useGetUniqueUserRoleQuery = (): Record<string, any> => {
  return useQuery({
    queryKey: ['user-role-unique-fields'],
    queryFn: getUserRoleUniqueFields,
    select: (res: any) => ({
      gender: genderOptions,
      role: prepareDropdownOptions(res.data, {
        labelKey: 'alias',
        valueKey: 'name',
      }),
    }),
  });
};
