import { Select } from '@Components/common/FormUI';
import { patcNotification } from '@Services/notification';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';

export default function StatusCell({ row, options }: any) {
  const { mutate: patchNotification } = useMutation({
    mutationFn: patcNotification,
    onSuccess: () => {
      toast.success('Status changed successfully.');
    },
    onError: (error: any) => {
      toast.error(error.response.data.detail || 'Something is not right.');
    },
  });

  const [value, setValue] = useState(row.original.status as string);
  return (
    <div className="w-[8rem]">
      <Select
        labelKey="value"
        options={options || []}
        selectedOption={value}
        onChange={changedStatus => {
          setValue(changedStatus);
          if (row.original.status !== changedStatus)
            patchNotification({
              id: row.original.id,
              data: { status: changedStatus },
            });
          return {};
        }}
      />
    </div>
  );
}
