import Image from '@Components/RadixComponents/Image';
import {
  checkListPregnantRowOne,
  checkListPregnantRowTow,
} from '@Constants/hdprp';
import CheckListPic from '@Assets/images/public-page/HDPRP/CheckList.png';
// import { useTypedSelector } from '@Store/hooks';

export default function CheckListPregnantWoman() {
  // const vulnerableStatus = useTypedSelector(
  //   state => state.publicPage.vulnerableStatus,
  // );

  return (
    // vulnerableStatus?.pregnent_women && (
    <div>
      <div className="topics">
        <div className="flex items-center gap-5">
          <Image src={CheckListPic} width={25} alt="check" />
          <p className="text-[25px] font-bold leading-[66px] text-grey-950">
            चेकलिस्ट (गर्भवती/ सुत्केरी महिला)
          </p>
        </div>
        <p className="text-[20px] leading-[32px] text-[#4c4c4c]">
          गर्भवती/ सुत्केरी महिला भएका घरमा राख्नुपर्ने आवश्यक वस्तुहरु
        </p>
      </div>
      <div className="mt-4 grid md:grid-cols-2">
        <div className="col-span-1 flex flex-col gap-2">
          {checkListPregnantRowOne.map(data => (
            <div key={data.id} className="single card flex items-center gap-4">
              <div className="h-5 w-5 rounded-[4px] border border-[#08688B]" />
              <Image src={data.icon} alt="checlistimage" width={35} />
              <p className="text-[16px] leading-[32px]">{data.name}</p>
            </div>
          ))}
        </div>
        <div className="col-span-1 flex flex-col gap-2">
          {checkListPregnantRowTow.map(data => (
            <div key={data.id} className="single card flex items-center gap-4">
              <div className="h-5 w-5 rounded-[4px] border border-[#08688B]" />
              <Image src={data.icon} alt="checlistimage" width={35} />
              <p className="text-[16px] leading-[32px]">{data.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    // )
  );
}
