/* eslint-disable no-nested-ternary */
import { useRef, useEffect, useState } from 'react';
import { MapMouseEvent, Popup } from 'maplibre-gl';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import isEmpty from '@Utils/isEmpty';
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { toast } from 'react-toastify';
import { IMapPopupProps } from '../types';

const popup = new Popup({
  closeOnClick: false,
  closeButton: false,
  offset: 25,
});

export default function MapPopup({
  map,
  enable = true,
  onClose,
}: IMapPopupProps) {
  const popupRef = useRef(null);
  const [properties, setProperties] = useState<Record<string, any> | null>(
    null,
  );

  const baseUrl = process.env.BASE_URL;

  const onPopupClose = () => {
    popup.remove();
    onClose?.();
    setProperties(null);
  };

  useEffect(() => {
    if (!map || !enable) return () => {};
    function displayPopup(e: MapMouseEvent): void {
      if (!map) return;
      const features = map.queryRenderedFeatures(e.point);
      if (!features.length) return;
      const clickedFeature = features.find(item =>
        item.source.includes('sites'),
      );
      if (!clickedFeature) return;
      setProperties(clickedFeature.properties);
      popup.setLngLat(e.lngLat);
    }
    map.on('click', displayPopup);
    return () => {
      map.off('click', displayPopup);
      popup.remove();
    };
  }, [map, enable]);

  useEffect(() => {
    if (!map || !properties || !popupRef.current) return;
    popup.setDOMContent(popupRef.current).addTo(map);
  }, [map, properties]);

  if (!properties) return <div />;

  return (
    <div ref={popupRef} className="w-[14rem] pl-2">
      <div className="flex flex-row items-center justify-between">
        <h5 className="py-1.5 text-base">{properties && properties.name}</h5>
        <ToolTip
          name="close"
          message="Close"
          side="top"
          onClick={onPopupClose}
        />
      </div>
      <ul
        className="scrollbar flex h-[12.5rem] flex-col 
        overflow-y-auto border-y-[1px] border-y-grey-500 text-grey-800"
      >
        {properties &&
          Object.keys(properties).map(key => {
            const value = properties[key];
            const valueType =
              typeof value === 'string'
                ? properties[key]?.toString()
                : typeof value === 'number' &&
                  key !== 'floor' &&
                  !Number.isNaN(value) &&
                  !Number.isInteger(value)
                ? value?.toFixed(3)
                : Array.isArray(value)
                ? value.join(', ')
                : typeof value === 'object' && !isEmpty(value)
                ? `[${value.map((val: number) => val.toString()).join(', ')}]`
                : value;
            return (
              <li
                key={key}
                className="flex items-center py-1.5 
                text-body-sm font-bold odd:bg-grey-100"
              >
                <p className="w-1/2">{capitalizeFirstLetter(key)}</p>
                <p className="w-1/2">{valueType || '-'}</p>
              </li>
            );
          })}
      </ul>
      <div className="flex justify-end pt-1.5">
        <Button
          variant="ghost"
          onClick={() => {
            if (!properties?.sites_id_from_assesment) {
              toast.error(
                'Site id not found. Please trying reloading the page.',
              );
              return;
            }
            window.open(
              `${baseUrl}data/assessment-data/hdprp/${properties?.sites_id_from_assesment}`,
              '_blank',
              'noopener, noreferrer',
            );
          }}
        >
          View HDRMP
        </Button>
      </div>
    </div>
  );
}
