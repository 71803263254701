import { Input } from '@Components/common/FormUI';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';

export default function FilterSection() {
  return (
    <FlexColumn>
      <FlexRow className="flex-col justify-between md:flex-row">
        <FlexRow
          className="group relative w-full items-center border-b-2 px-3
         hover:border-b-primary-600 md:w-1/4"
        >
          <Icon
            name="search"
            className="text-grey-500 group-hover:text-primary-600"
          />

          <Input type="text" className="w-full border-none" />
          {/* {!!searchInput.length && (
            <Icon
              name="clear"
              onClick={() =>
                dispatch(
                  setUserManagementState({
                    searchInput: '',
                  }),
                )
              }
            />
          )} */}
        </FlexRow>

        {/* <FlexRow className="flex items-center justify-end gap-2 pt-3 md:py-0">
          <Button leftIcon="add" className=" bg-[#007877]">
            Add Data
          </Button>
        </FlexRow> */}
      </FlexRow>
    </FlexColumn>
  );
}
