import DataTable from '@Components/common/DataTable';
import { useTypedSelector } from '@Store/hooks';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { getEmergencyContactsList } from '@Services/publicPage';

export default function TableSection() {
  const { width } = useWindowDimensions();

  const selectedOrganizationType = useTypedSelector(
    state => state.publicPage.selectedOrganizationType,
  );

  const disasterListColumns = [
    {
      header: 'S.N',
      accessorKey: 'id',
      cell: (info: any) => info.row.index + 1,
      pin: 'left',
    },
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Affiliation',
      accessorKey: 'affiliation',
    },
    {
      header: 'Designation',
      accessorKey: 'designation',
    },
    {
      header: 'Phone Number',
      accessorKey: 'phone_no',
    },
  ];

  return (
    <DataTable
      columns={disasterListColumns}
      queryKey="emergency-contacts"
      queryFn={getEmergencyContactsList}
      queryFnParams={{ org_type: selectedOrganizationType }}
      wrapperStyle={{
        height: width <= 390 ? '60vh' : 'calc(100vh - 11.5rem)',
      }}
    />
  );
}
