/* eslint-disable react/no-array-index-key */

import { PieChartDataItem } from '../types';
import { calculatePercentage, getSumOfKey } from '../utils';

interface ILegendItemProps {
  color: string;
  name: string;
  percentage?: number;
}

const LegendItem = ({ color, name, percentage }: ILegendItemProps) => {
  return (
    <div className="flex w-full items-center justify-between px-1 text-sm">
      <div className="flex items-center justify-center gap-2">
        <span
          className="h-4 w-4 rounded"
          style={{ background: color || '#01A0A2' }}
        />
        <p className="body-md whitespace-nowrap font-normal capitalize text-grey-800">
          {name}
        </p>
      </div>
      <p className="body-md whitespace-nowrap font-bold text-grey-800">
        {percentage}%
      </p>
    </div>
  );
};

export default function RenderLegend(props: Record<string, any>) {
  const { payload, layout = 'horizontal', data = [] } = props;

  if (layout === 'vertical') {
    const maxValue = getSumOfKey(data as PieChartDataItem[], 'value');
    return (
      <div className="flex h-fit w-full  justify-center">
        <div className="cover flex flex-col flex-wrap items-center justify-center gap-1">
          {payload.map((entry: Record<string, any>) => {
            return (
              <div key={entry.value} className="flex min-w-[200px]">
                <LegendItem
                  key={entry.value}
                  color={entry.color || '#01A0A2'}
                  name={entry.value}
                  percentage={Math.round(
                    calculatePercentage(
                      maxValue || 0,
                      entry?.payload?.value ? entry?.payload?.value : 0,
                    ),
                  )}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-fit w-full justify-center">
      <div className="cover flex flex-wrap items-center justify-center gap-4">
        {payload.map((entry: Record<string, any>) => (
          <LegendItem
            key={entry.value}
            color={entry.color || '#01A0A2'}
            name={entry.value}
          />
        ))}
      </div>
    </div>
  );
}
