import { FormFieldType } from '@Components/common/FormHelpers/types';
import validatePassword from '@Utils/validatePassword';

export const signupFormFormIndex: Record<number, string> = {
  0: 'stepOne',
  1: 'stepTwo',
  2: 'stepThree',
  3: 'stepFour',
};

export interface StepFormField {
  title?: string;
  fields: FormFieldType[];
}

type signUpFormFieldsTypes = Record<string, StepFormField>;

export const genderListOptions = [
  { name: 'Male', alias: 'male' },
  { name: 'Female', alias: 'female' },
  { name: 'Others', alias: 'others' },
];

export const signUpFormFields: signUpFormFieldsTypes = {
  stepOne: {
    fields: [
      {
        id: 'email',
        label: 'Email',
        placeholder: 'Email',
        type: 'input',
        validationSchema: {
          matchPattern: (value: any) =>
            !value ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
            'Email address must be a valid address',
        },
      },
      {
        id: 'password',
        label: 'Password',
        placeholder: 'Password',
        type: 'input',
        inputType: 'password',
        required: true,
        validationSchema: {
          matchPattern: (value: any) => validatePassword(value),
        },
      },
      {
        id: 'confirm_password',
        label: 'Confirm Password',
        placeholder: 'Confirm Password',
        type: 'input',
        inputType: 'password',
        required: true,
        comparingKey: 'password',
      },
    ],
  },
  stepTwo: {
    title: 'Personal Details',
    fields: [
      {
        id: 'first_name',
        type: 'input',
        label: 'Name',
        placeholder: 'Name',
        required: true,
      },
      {
        id: 'username',
        type: 'input',
        label: 'Username',
        placeholder: 'Username',
        required: true,
      },
      {
        id: 'gender',
        type: 'select',
        label: 'Gender',
        placeholder: 'Select Gender',
        required: true,
      },
      {
        id: 'phone_no',
        label: 'Phone',
        required: true,
        type: 'phone',
      },
    ],
  },
  stepThree: {
    title: 'Address',
    fields: [
      {
        id: 'country',
        type: 'select',
        label: 'Country',
        placeholder: 'Select Country',
        required: true,
      },
      {
        id: 'district',
        type: 'select',
        label: 'District',
        placeholder: 'Select District',
        required: true,
      },
      {
        id: 'municipality',
        type: 'select',
        label: 'Municipality',
        placeholder: 'Select Municipality',
        required: true,
      },
    ],
  },
  stepFour: {
    title: 'Security Questions',
    fields: [
      {
        id: 'place_of_birth',
        label: 'Place of Birth',
        type: 'input',
        placeholder: 'Place of Birth',
      },
      {
        id: 'date_of_birth',
        inputType: 'date',
        label: 'Date of Birth',
        placeholder: 'Date of Birth',
        type: 'input',
        validationSchema: {
          minimumAge: value => {
            const today = new Date();
            const selectedDate = new Date(value);
            const age = today.getFullYear() - selectedDate.getFullYear();
            const isAtLeast8YearsOld = age >= 8;
            return (
              !value ||
              isAtLeast8YearsOld ||
              'User should be at least 8 years old'
            );
          },
        },
      },
      {
        id: 'security_question',
        label: '',
        type: 'array-of-inputs',
        required: true,
      },
    ],
  },
};
