/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { genderListOptions } from '@Components/Authentication/SignUp/signUpFormFields';
import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// import persist from '@Store/persist';

type DropdownOption = {
  name: string;
  alias: string;
  id?: string | number;
};

interface ISignupForm {
  dropDownOptions: {
    gender: DropdownOption[];
    country: DropdownOption[];
    district: DropdownOption[];
    municipality: DropdownOption[];
  };
  securityQuestions: Record<string, any>[];
  selectedCountry: string | number | undefined;
}

export interface AuthenticationState {
  user: Record<string, any> | null;
  userProfile: Record<string, any> | null;
  permissions: string[];
  signUpForm: ISignupForm;
  isLoginChecked: boolean;
}

const initialState: AuthenticationState = {
  user: null,
  userProfile: null,
  permissions: [],
  signUpForm: {
    dropDownOptions: {
      gender: genderListOptions,
      country: [],
      district: [],
      municipality: [],
    },
    securityQuestions: [],
    selectedCountry: '',
  },
  isLoginChecked: false,
};

const setAuthenticationState: CaseReducer<
  AuthenticationState,
  PayloadAction<Partial<AuthenticationState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setSelectedCountryState = (
  state: AuthenticationState,
  action: PayloadAction<string | number | undefined>,
) => {
  state.signUpForm.selectedCountry = action.payload;
};

const resetAuthenticationState: CaseReducer<AuthenticationState> = () => ({
  ...initialState,
});

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthenticationState,
    resetAuthenticationState,
    setSignUpCountryList(state, action) {
      state.signUpForm.dropDownOptions.country = action.payload;
    },
    setSignUpDistrictList(state, action) {
      state.signUpForm.dropDownOptions.district = action.payload;
    },
    setSignUpMunicipalityList(state, action) {
      state.signUpForm.dropDownOptions.municipality = action.payload;
    },
    setSignUpSecurityQuestion(state, action) {
      state.signUpForm.securityQuestions = action.payload;
    },
    setSelectedCountryState,
  },
});

export { authenticationSlice };

export default authenticationSlice.reducer;
