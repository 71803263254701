import {
  ChartSection,
  DashboardHeader,
  DataCardSection,
  SummarySection,
} from '@Components/Dashboard';
import Fallback from '@Components/common/Fallback';
import { FlexColumn } from '@Components/common/Layouts';
import { useTypedSelector } from '@Store/hooks';

export default function Dashboard() {
  const user = useTypedSelector(state => state.authentication.user);

  if (!user) return <Fallback />;

  return (
    <FlexColumn gap={5} className="dashboard w-full px-5 py-4">
      <DashboardHeader />
      <DataCardSection />
      <SummarySection />
      <ChartSection />
    </FlexColumn>
  );
}
