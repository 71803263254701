import { useParams } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import { useGetUserDetailsQuery } from '@Api/publicPage';
import Image from '@Components/RadixComponents/Image';
import { FlexColumn } from '@Components/common/Layouts';
import { getPalikaName } from '@Constants/hdprp';
import InfoSectionLoader from './InfoSectionLoader';

export default function InfoSection() {
  const { siteId: paramsSiteId } = useParams();

  const publicPageSiteId = useTypedSelector(state => state.publicPage.siteId);

  const siteId = paramsSiteId || publicPageSiteId;

  // const singleRiskData = useTypedSelector(
  //   state => state.publicPage.singleRiskData,
  // );

  const isUserHdrmpLoading = useTypedSelector(
    state => state.publicPage.isUserHdrmpLoading,
  );

  // fetch user details to show in the report
  const { data: userDetails, isLoading: isUserDetailsLoading } =
    useGetUserDetailsQuery(siteId);

  if (isUserHdrmpLoading || isUserDetailsLoading) return <InfoSectionLoader />;

  return (
    <section className="mt-10 grid gap-10 md:grid-cols-3">
      <div className="heading md:col-span-2">
        <div
          className="rounded-lg  py-4"
          // style={{ background: singleRiskData?.color_code }}
        >
          <p className="font-500 text-[1.75rem] leading-[2.625rem] text-[#000000]">
            घरधुरीस्तरीय विपद जोखिम व्यवस्थापन योजना
          </p>
          <div className="mt-1 flex items-center gap-3">
            <span className="text-sm leading-5 text-[#000000]">
              House Code:
            </span>
            <span className="text-sm font-medium leading-5 text-[#000000]">
              {`House ${userDetails?.house_no || '-'}`}
            </span>
            {/* <span className="rounded-full border-2 px-3 py-2 font-semibold text-white">
              {singleRiskData?.remarks}
            </span> */}
          </div>
        </div>
        <div className="info mt-10">
          <p className="text-[20px] font-bold leading-[30px] text-grey-950">
            घरमुलीको जानकारी
          </p>
          <div className="mt-5 grid grid-cols-2">
            <div className="row-1 flex flex-col gap-2 md:col-span-1">
              <div className="flex">
                <p className="w-1/3 text-[0.875rem] leading-[1.313rem] text-[#707070]">
                  घरमुलीको नाम
                </p>
                <p className="text-[0.875rem] capitalize leading-[1.313rem] text-grey-950">
                  {`: ${userDetails?.house_owner_name || '-'}`}
                </p>
              </div>
              <div className="flex">
                <p className="w-1/3 text-[0.875rem] leading-[1.313rem] text-[#707070]">
                  लिङ्ग
                </p>
                <p className="text-[0.875rem] capitalize leading-[1.313rem] text-grey-950">
                  {`: ${userDetails?.gender || '-'}`}
                </p>
              </div>
              <div className="flex">
                <p className="w-1/3 text-[0.875rem] leading-[1.313rem] text-[#707070]">
                  पालिका
                </p>
                <p className="text-[0.875rem] capitalize leading-[1.313rem] text-grey-950 ">
                  {`: ${getPalikaName() || '-'}`}
                </p>
              </div>
            </div>
            <div className="row-2 flex flex-col gap-2 md:col-span-1">
              <div className="flex">
                <p className="w-1/3 text-[0.875rem] leading-[1.313rem] text-[#707070]">
                  मोबाइल नम्बर
                </p>
                <p className="text-[0.875rem] leading-[1.313rem] text-grey-950">
                  {`: ${userDetails?.phone_no || '-'}`}
                </p>
              </div>
              <div className="flex">
                <p className="w-1/3 text-[0.875rem] leading-[1.313rem] text-[#707070]">
                  जात/जाति
                </p>
                <p className="text-[0.875rem] capitalize leading-[1.313rem] text-grey-950 ">
                  {`: ${userDetails?.ethnicity || '-'}`}
                </p>
              </div>
              <div className="flex">
                <p className="w-1/3 text-[0.875rem] leading-[1.313rem] text-[#707070]">
                  वडा
                </p>
                <p className="text-[0.875rem] capitalize leading-[1.313rem] text-grey-950 ">
                  {`: ${userDetails?.ward_no || '-'}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userDetails?.img_url ? (
        <Image
          src={userDetails?.img_url}
          alt="house-img"
          className="h-full max-h-72 w-full overflow-hidden rounded-lg bg-slate-700 object-cover md:col-span-1"
        />
      ) : (
        <FlexColumn
          gap={1}
          className="px4 w-full items-center justify-center rounded-lg bg-slate-700 py-10"
        >
          <h3 className="font-bold text-white">
            {userDetails?.house_no || userDetails?.house_owner_name || '-'}
          </h3>
          <hr className="mx-auto my-1 h-0.5 w-4/5 bg-gray-100 " />
          <h5 className="px-4 text-center capitalize text-white">
            {`${getPalikaName()} - ${userDetails?.name_of_place || '-'}`}
          </h5>
        </FlexColumn>
      )}
    </section>
  );
}
