import Image from '@Components/RadixComponents/Image';
import GroupOfPeople from '@Assets/images/public-page/HDPRP/GroupOfPoeple.png';
// import { useGetVectorLayerCountQuery } from '@Api/dashboard';

export default function TrainingSteps() {
  // const { data: capacityAndResourcesCount } = useGetVectorLayerCountQuery();

  return (
    <section className="grid gap-5 md:grid-cols-4">
      {/* <div className="two-cards grid gap-5">
        <div className="flex flex-col rounded-lg bg-[#08688B] px-3 py-11 text-center">
          <p className="text-[1.25rem] font-bold leading-8 text-white">
            पालिकामा क्षमता र स्रोत संख्या
          </p>
          <p className="text-[3.75em] font-bold leading-[80px] text-white">
            <>{capacityAndResourcesCount}</>
          </p>
        </div>
        <div className="flex flex-col rounded-lg bg-[#08688B] px-3 py-11 text-center">
          <p className="text-[1.25rem] font-bold leading-8 text-white">
            वडामा क्षमता र स्रोत संख्या
          </p>
          <p className="text-[3.75em] font-bold leading-[80px] text-white">
            <>{capacityAndResourcesCount}</>
          </p>
        </div>
      </div> */}
      <div className="grid grid-cols-5 rounded-lg bg-[#037342] px-5 py-6 md:col-span-4">
        <div className="col-span-2 flex flex-col items-start justify-center gap-10 p-5">
          <Image src={GroupOfPeople} alt="Group" />
          <p className="text-[26px] font-extrabold  text-white md:pr-20">
            थाहा पाउनै पर्ने ७ ज्ञान र सिपहरु
          </p>
        </div>
        <div className="col-span-3 flex flex-col justify-between gap-4 rounded-lg bg-white px-5 py-3 ">
          <div className="flex items-center gap-3">
            <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
              १
            </p>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              पूर्वसूचना प्रणाली सम्बन्धि ज्ञान
            </p>
          </div>
          <div className="flex items-center gap-3">
            <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
              २
            </p>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              बाढीको सामना गर्ने रणनीतिको ज्ञान
            </p>
          </div>
          <div className="flex items-center gap-3">
            <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
              ३
            </p>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              प्रकोप प्रतिकार्य सम्बन्धि ज्ञान
            </p>
          </div>
          <div className="flex items-center gap-3">
            <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
              ४
            </p>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              खोज तथा उद्धार सम्बन्धि सिप
            </p>
          </div>
          <div className="flex items-center gap-3">
            <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
              ५
            </p>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              पौडी सम्बन्धि सिप
            </p>
          </div>
          <div className="flex items-center gap-3">
            <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
              ६
            </p>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              घरधुरीको जोखिम, बाढिको जोखिम सम्बन्धि ज्ञान
            </p>
          </div>
          <div className="flex items-center gap-3">
            <p className="flex h-[2rem] w-[2rem] items-center justify-center rounded-[50%] bg-[#037342] text-center text-[1.5rem] font-bold text-white">
              ७
            </p>
            <p className="text-[1.125rem] leading-[1.688rem] text-[#037342]">
              आपतकालीन पूर्वतयारी बारे जनचेतना
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
