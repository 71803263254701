/* eslint-disable react/no-unstable-nested-components */
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { toggleModal } from '@Store/actions/common';
import { getUserList } from '@Services/users';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import { setUserState } from '@Store/actions/users';
import useWindowDimensions from '@Hooks/useWindowDimensions';

export default function TableSection() {
  const dispatch = useTypedDispatch();
  const { width } = useWindowDimensions();

  const searchInput = useTypedSelector(state => state.users.searchInput);
  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);

  const userColumns = [
    {
      header: 'S.N',
      accessorKey: 'id',
      cell: (info: any) => info.row.index + 1,
      pin: 'left',
    },
    {
      header: 'First Name',
      accessorKey: 'first_name',
    },
    {
      header: 'Last Name',
      accessorKey: 'last_name',
    },
    {
      header: 'Username',
      accessorKey: 'username',
    },
    {
      header: 'Role',
      accessorKey: 'groups',
      cell: (info: any) => info.row.original.groups,
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => (
        <FlexRow className="cursor-pointer justify-around">
          <ToolTip
            name="edit"
            message="Edit"
            onClick={() => {
              dispatch(toggleModal('edit-user'));
              dispatch(
                setUserState({
                  selectedUser: row.original,
                }),
              );
            }}
          />
          <ToolTip name="delete" message="Delete" />
        </FlexRow>
      ),
    },
  ];

  return (
    <DataTable
      columns={userColumns}
      queryKey="user-list"
      queryFn={getUserList}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      searchInput={searchInput}
      wrapperStyle={{
        height:
          // eslint-disable-next-line no-nested-ternary
          width <= 390
            ? '60vh'
            : showInfoDialog
            ? 'calc(100vh - 15rem)'
            : 'calc(100vh - 11.5rem)',
      }}
    />
  );
}
