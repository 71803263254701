/* eslint-disable import/prefer-default-export */
import info from '@Assets/images/public-page/info.png';
import call from '@Assets/images/public-page/call.png';
import update from '@Assets/images/public-page/update.png';
import flood from '@Assets/images/public-page/HazardRanking/flood.svg';
import earthquake from '@Assets/images/public-page/HazardRanking/Earthquake.svg';
import fire from '@Assets/images/public-page/HazardRanking/fire.svg';
import drought from '@Assets/images/public-page/HazardRanking/drought.svg';
import landslide from '@Assets/images/public-page/HazardRanking/landslide.svg';
// import heatwave from '@Assets/images/public-page/HeatWave.svg';
import threedot from '@Assets/images/public-page/HazardRanking/threedot.svg';
import epidemic from '@Assets/images/public-page/HazardRanking/Epidemic pandemic.svg';
import coldwave from '@Assets/images/public-page/HazardRanking/COld Wave.svg';
import wildAnimalAttack from '@Assets/images/public-page/HazardRanking/Animal attack.svg';
import pests from '@Assets/images/public-page/HazardRanking/Pests.svg';
import hailstone from '@Assets/images/public-page/HazardRanking/Heavy rainfall.svg';
import windstorm from '@Assets/images/public-page/HazardRanking/Windstorm.svg';
import thunderstorm from '@Assets/images/public-page/HazardRanking/Lightening.svg';
import roadAccident from '@Assets/images/public-page/HazardRanking/Road accident.svg';

import { ChartTypes } from '@Components/common/Charts/types';
import { AxiosResponse } from 'axios';
import {
  getSummaryChartsRiskList,
  getSummaryChartsVulnerabilityList,
} from '@Services/publicPage';

export interface IContentType {
  id: string;
  title: string;
  chartType: ChartTypes;
  queryFn?: any;
  params: any;
  wrapperClassName?: string;
}

export type FormOptionsRequestDataType = {
  queryKey: string[];
  queryFn: () => Promise<AxiosResponse<any, any>>;
}[];

export const parameterVisualizationFilters = [
  {
    id: 'framework',
    title: 'Framework',
    placeholder: 'Select framework',
  },
  {
    id: 'dimension',
    title: 'Dimension',
    placeholder: 'Select Dimension',
  },
  {
    id: 'components',
    title: 'Components',
    placeholder: 'Select Components',
  },
  {
    id: 'parameter',
    title: 'Parameter',
    placeholder: 'Select Parameter',
  },
  { id: 'metric', title: 'Metric', placeholder: 'Select Metric' },
];

export const layersCards = [
  {
    id: 1,
    title: 'School',
    icon: 'school',
  },
  {
    id: 2,
    title: 'Health Services',
    icon: 'local_hospital',
  },

  {
    id: 3,
    title: 'Open Spaces',
    icon: ' crop_portrait',
  },
];

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const featureCountData = [
  {
    id: 'building_count',
    title: 'Buildings',
    count: 0,
    iconName: 'home',
  },
  {
    id: 'open_spaces',
    title: 'Open Space',
    count: 0,
    iconName: 'nature_people',
  },
  {
    id: 'health',
    title: 'Health Services',
    count: 0,
    iconName: 'local_hospital',
  },
  {
    id: 'total_population',
    title: 'Population',
    count: 2,
    iconName: 'groups',
  },
];

export const hazardDataParshuram = [
  {
    id: 'earthquake',
    name: 'Earthquake',
    ranking: '1st',
    iconName: 'earthquake',
    icon: earthquake,
  },
  {
    id: 'landslide',
    name: 'Landslide',
    ranking: '2nd',
    iconName: 'landslide',
    icon: landslide,
  },
  {
    id: 'flood',
    name: 'Flood',
    ranking: '3rd',
    iconName: 'flood',
    icon: flood,
  },
  {
    id: 'fire',
    name: 'Fire',
    ranking: '4th',
    iconName: 'local_fire_department',
    icon: fire,
  },
  {
    id: 'epidemic',
    name: 'Epidemic',
    ranking: '5th',
    iconName: 'medical_services',
    icon: epidemic,
  },
  {
    id: 'coldwave',
    name: 'Cold Wave',
    ranking: '6th',
    iconName: 'ac_unit',
    icon: coldwave,
  },
  {
    id: 'others',
    name: 'Others',
    ranking: '7th',
    iconName: 'more_horiz',
    icon: threedot,
    child: [
      {
        id: 'wild_animals_attack',
        name: 'Wild Animal Attacks',
        ranking: '7th',
        iconName: 'pets',
        icon: wildAnimalAttack,
        // icon: fire,
      },
      {
        id: 'seasonal_drought',
        name: 'Seasonal Drought',
        ranking: '8th',
        iconName: 'light_mode',
        icon: drought,
        // icon: fire,
      },
      {
        id: 'pests',
        name: 'Pests',
        ranking: '9th',
        iconName: 'bug_report',
        icon: pests,
        // icon: fire,
      },
      {
        id: 'lightning_hailstone',
        name: 'Lightning/Hailstone',
        ranking: '10th',
        iconName: 'storm',
        icon: hailstone,
        // icon: fire,
      },
    ],
  },
];

export const hazardDataBheemdutta = [
  {
    id: 'flood',
    name: 'Flood',
    ranking: '1st',
    iconName: 'flood',
    icon: flood,
  },
  {
    id: 'drought',
    name: 'Drought',
    ranking: '2nd',
    iconName: 'light_mode',
    icon: drought,
  },
  {
    id: 'windstorm',
    name: 'Windstorm',
    ranking: '3rd',
    iconName: 'air',
    icon: windstorm,
    // icon: fire,
  },
  {
    id: 'coldwave',
    name: 'Cold Wave',
    ranking: '4th',
    iconName: 'ac_unit',
    icon: coldwave,
    // icon: fire,
  },
  {
    id: 'fire',
    name: 'Fire',
    ranking: '5th',
    iconName: 'local_fire_department',
    icon: fire,
  },
  {
    id: 'earthquake',
    name: 'Earthquake',
    ranking: '6th',
    iconName: 'earthquake',
    icon: earthquake,
  },
  {
    id: 'others',
    name: 'Others',
    ranking: '7th',
    iconName: 'more_horiz',
    icon: threedot,
    child: [
      {
        id: 'animal_attack',
        name: 'Animal Attack',
        ranking: '7th',
        iconName: 'pets',
        icon: wildAnimalAttack,
        // icon: fire,
      },
      {
        id: 'thunderstorm',
        name: 'Thunderstorm',
        ranking: '8th',
        iconName: 'storm',
        icon: thunderstorm,
        // icon: fire,
      },
      {
        id: 'road_accident',
        name: 'Road Accident',
        ranking: '9th',
        iconName: 'directions_car',
        icon: roadAccident,
        // icon: fire,
      },
      {
        id: 'stray_animal',
        name: 'Stray Animal',
        ranking: '10th',
        iconName: 'pets',
        // icon: strayAnimal,
        icon: wildAnimalAttack,
        // icon: fire,
      },
    ],
  },
];

export const hazardDataDodhara = [
  {
    id: 'flood',
    name: 'Flood',
    ranking: '1st',
    iconName: 'flood',
    icon: flood,
  },
  {
    id: 'earthquake',
    name: 'Earthquake',
    ranking: '2nd',
    iconName: 'earthquake',
    icon: earthquake,
  },
  {
    id: 'pandemic',
    name: 'Pandemic',
    ranking: '3rd',
    iconName: 'medical_services',
    icon: epidemic,
    // icon: fire,
  },
  {
    id: 'windstorm',
    name: 'Windstorm',
    ranking: '4th',
    iconName: 'air',
    icon: windstorm,
    // icon: fire,
  },
  {
    id: 'wildlife_conflict',
    name: 'Wildlife Conflict',
    ranking: '5th',
    iconName: 'pets',
    icon: wildAnimalAttack,
    // icon: fire,
  },
  {
    id: 'fire',
    name: 'Fire',
    ranking: '6th',
    iconName: 'local_fire_department',
    icon: fire,
  },
  {
    id: 'others',
    name: 'Others',
    ranking: '7th',
    iconName: 'more_horiz',
    icon: threedot,
    child: [
      {
        id: 'drought',
        name: 'Drought',
        ranking: '7th',
        iconName: 'light_mode',
        icon: drought,
      },
      {
        id: 'coldwave',
        name: 'Cold Wave',
        ranking: '8th',
        iconName: 'ac_unit',
        icon: coldwave,
        // icon: fire,
      },
      {
        id: 'hailstorm',
        name: 'Hailstorm',
        ranking: '9th',
        iconName: 'storm',
        icon: windstorm,
        // icon: fire,
      },
      {
        id: 'road_accident',
        name: 'Road Accident',
        ranking: '10th',
        iconName: 'directions_car',
        icon: roadAccident,
        // icon: fire,
      },
    ],
  },
];

// All Months
export const allMonths = [
  {
    id: 1,
    label: 'January',
    value: 1,
  },
  {
    id: 2,
    label: 'February',
    value: 2,
  },
  {
    id: 3,
    label: 'March',
    value: 3,
  },
  {
    id: 4,
    label: 'April',
    value: 4,
  },
  {
    id: 5,
    label: 'May',
    value: 5,
  },
  {
    id: 6,
    label: 'June',
    value: 6,
  },
  {
    id: 7,
    label: 'July',
    value: 7,
  },
  {
    id: 8,
    label: 'August',
    value: 8,
  },
  {
    id: 9,
    label: 'September',
    value: 9,
  },
  {
    id: 10,
    label: 'October',
    value: 10,
  },
  {
    id: 11,
    label: 'November',
    value: 11,
  },
  {
    id: 12,
    label: 'December',
    value: 12,
  },
];

const { SITE_NAME } = process.env;

export const getHazardData = () => {
  switch (SITE_NAME) {
    case 'Dastaa':
      return hazardDataDodhara;
    case 'Dastaa Bheemdatta':
      return hazardDataBheemdutta;
    case 'Dastaa Parshuram':
      return hazardDataParshuram;
    case 'Dastaa Dodahra':
      return hazardDataDodhara;
    default:
      return hazardDataDodhara;
  }
};

export const foodConsumptionChartData = {
  id: 'food-consumption-chart',
  title: 'Food Consumption Score',
  chartType: 'horizontalBar',
};

export const detailsLinkCard = [
  { id: 1, title: 'Read Detail Imformation', icon: info, pathname: '#' },
  { id: 2, title: 'Emergency Contact', icon: call, pathname: '#' },
  {
    id: 3,
    title: 'Update HDRRP',
    icon: update,
    pathname: '/hdprp',
  },
];

export const summaryChartsRequestTabList: FormOptionsRequestDataType = [
  {
    queryKey: ['summary-charts-risk-tab-list'],
    queryFn: getSummaryChartsRiskList,
  },
  {
    queryKey: ['summary-charts-vulnerability-tab-list'],
    queryFn: getSummaryChartsVulnerabilityList,
  },
];

export const summaryChartSortOrder: string[] = ['High', 'Medium', 'Low'];

export const weatherSourceOptions = [
  {
    id: 1,
    label: 'WeatherAPI',
    value: 'weatherapi',
  },
  {
    id: 2,
    label: 'Windy',
    value: 'windy',
  },
];

export const weatherTabData = [
  { id: 'Today', title: 'Today' },
  { id: '3days', title: '3 days' },
  { id: '7days', title: '7 days' },
];

export const summaryChartIds: string[] = [
  'exposure',
  'vulnerability',
  'risk',
  'hh-level-risk',
  'hh-level-vulnerability',
];

export const organizationListType = [
  { label: 'Governmental Documents', value: 'GOV' },
  { label: 'Non Governmental Documents', value: 'NGO' },
];

export const PAPERSIZE = { a4: [210, 297] };
