/* eslint-disable react/no-array-index-key */

import { useEffect } from 'react';
import Switch from '@Components/RadixComponents/Switch';
import { Label, Select } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { setLayerState } from '@Store/actions/overlayLayer';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  defaultLabelValues,
  defaultLayerStyleValues,
} from '@Constants/overlayLayers';
import { getCaseExpression } from '../../helper';

function SubLayer() {
  const dispatch = useTypedDispatch();
  const isActiveSubLayer = useTypedSelector(
    state => state.overlayLayer.isActiveSubLayer,
  );
  const subLayerList = useTypedSelector(
    state => state.overlayLayer.subLayerList,
  );
  const subLayerStyle = useTypedSelector(
    state => state.overlayLayer.subLayerStyle,
  );
  const subLayerKey = useTypedSelector(state => state.overlayLayer.subLayerKey);
  const layerStyles = useTypedSelector(state => state.overlayLayer.layerStyles);
  // const attributeKeyList = useTypedSelector(
  //   state => state.overlayLayer.attributeKeyList,
  // );

  const handleSubLayerToggle = () => {
    dispatch(setLayerState({ isActiveSubLayer: !isActiveSubLayer }));
  };

  const handleSelectSubLayer = (layer: string) => {
    dispatch(setLayerState({ selectedSubLayer: layer }));
  };

  useEffect(() => {
    if (!isActiveSubLayer || subLayerStyle || !subLayerKey) return;

    const similarValueKeys = ['text-field', 'text-justify', 'symbol-placement'];
    const newSubLayerStyle = layerStyles.map(layer => {
      const { layout, paint } = layer;
      const newLayouts = {};
      const newPaints = {};
      Object.entries(layout).map(([key, value]) => {
        if (similarValueKeys.includes(key))
          return Object.assign(newLayouts, { [key]: value });
        const newValue = [
          'step',
          ['zoom'],
          { ...defaultLabelValues, ...defaultLayerStyleValues }[key],
          0,
          getCaseExpression(
            { ...defaultLabelValues, ...defaultLayerStyleValues }[key],
            subLayerList,
            subLayerKey,
            { ...defaultLabelValues, ...defaultLayerStyleValues }[key],
            key,
          ),
        ];
        return Object.assign(newLayouts, {
          [key]: newValue,
        });
      });
      Object.entries(paint).map(([key, value]) => {
        if (similarValueKeys.includes(key))
          return Object.assign(newPaints, { [key]: value });
        const newValue = [
          'step',
          ['zoom'],
          { ...defaultLabelValues, ...defaultLayerStyleValues }[key],
          0,
          getCaseExpression(
            { ...defaultLabelValues, ...defaultLayerStyleValues }[key],
            subLayerList,
            subLayerKey,
            { ...defaultLabelValues, ...defaultLayerStyleValues }[key],
            key,
          ),
        ];

        return Object.assign(newPaints, {
          [key]: newValue,
        });
      });
      return {
        ...layer,
        paint: newPaints,
        layout: newLayouts,
      };
    });

    dispatch(setLayerState({ subLayerStyle: newSubLayerStyle }));
  }, [isActiveSubLayer, subLayerStyle, subLayerKey]); //eslint-disable-line

  // const getSubLayerKeyOptions = useCallback(() => {
  //   return attributeKeyList.map(key => ({
  //     label: String(key),
  //     value: String(key),
  //   }));
  // }, [attributeKeyList]);

  const handleSubLayerKeyChange = (key: string) => {
    dispatch(setLayerState({ subLayerKey: key }));
  };

  return (
    <FlexColumn className="mb-3 gap-3">
      <FlexRow className="justify-between">
        <p className="text-sm">Sublayer</p>
        <Switch
          checked={isActiveSubLayer}
          onClick={() => handleSubLayerToggle()}
        />
      </FlexRow>

      {isActiveSubLayer && (
        <>
          <FlexRow className="items-center pb-4">
            <div className="w-28">
              <Label>Sub-Layer Key: </Label>
            </div>
            <div className="flex-grow">
              <Select
                className="w-full"
                // options={getSubLayerKeyOptions()}
                options={[]}
                placeholder="Sub-layer Key (id)"
                onChange={(value: string) => handleSubLayerKeyChange(value)}
              />
            </div>
          </FlexRow>
          <FlexRow className="items-center pb-4">
            <div className="w-20">
              <Label>Sub-Layer: </Label>
            </div>
            <div className="flex-grow">
              <Select
                className="w-full"
                options={subLayerList?.map(subLayer => ({
                  label: String(subLayer),
                  value: subLayer,
                }))}
                onChange={(value: string) => handleSelectSubLayer(value)}
              />
            </div>
          </FlexRow>
        </>
      )}
    </FlexColumn>
  );
}

export default SubLayer;
