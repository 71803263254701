import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import Image from '@Components/RadixComponents/Image';
import BheemduttaLogo from '@Assets/images/public-page/HDPRP/bheemdutta-logo.svg';
import ParshuramLogo from '@Assets/images/public-page/HDPRP/parshuramlogo.svg';
import DodharaLogo from '@Assets/images/public-page/HDPRP/dhodhara-logo.svg';
import BangladeshLogo from '@Assets/images/Bangladeshlogo.jpg';
import Icon from '@Components/common/Icon';
import { handlePDFExport } from '@Utils/index';
import {
  useGetHazardSingleRiskQuery,
  useGetMoreHouseInfoQuery,
} from '@Api/publicPage';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setPublicPageState } from '@Store/actions/publicPage';
import ExportHDPRP from './ExportHDPRP';
// import A4Paper from '@Components/common/A4Paper';
// import InfoSection from './InfoSection';
// import RiskAndMap from './RiskAndMap';
// import ImportanLinks from './ImportantLinks';
// import VulnerablePeople from './VulnerablePeople';
// import TrainingSteps from './TrainingSteps';
// import EmergencySteps from './EmergencySteps';
// import ParticipationDecision from './ParticipationDecision';
// import CheckList from './CheckList';
// import CheckListDisabled from './CheckListDisabled';
// import CheckListPregnantWoman from './CheckListPregnantWoman';
// import CheckListOldPeople from './CheckListOldPeople';
// import CheckListDisease from './CheckListDisease';
// import FloodPreparation from './FloodPreparation';
// import RiskSteps from './RiskSteps';
// import ContactSection from './ContactSection';
// import TollFreeContact from './TollFreeContact';
// import DescriptionSection from './DescriptionSection';
// import Footer from './Footer';
// import RiskCommunicationMedium from './RiskCommunicationMedium';
// import ActionsToAvoidFloodRisk from './ActionsToAvoidFloodRisk';
// import ActionsToAvoidFloodRisk from './ActionsToAvoidFloodRisk';

const { SITE_NAME } = process.env;

const getImage = () => {
  switch (SITE_NAME) {
    case 'Dastaa':
      return BheemduttaLogo;
    case 'Dastaa Bheemdatta':
      return BheemduttaLogo;
    case 'Dastaa Parshuram':
      return ParshuramLogo;
    case 'Dastaa Dodahra':
      return DodharaLogo;
    case 'Dastaa Bangladesh':
      return BangladeshLogo;
    default:
      return ParshuramLogo;
  }
};

export default function HDPRP() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { siteId: paramsSiteId } = useParams();

  const publicPageSiteId = useTypedSelector(state => state.publicPage.siteId);

  const siteId = paramsSiteId || publicPageSiteId;

  const pdfExporterDivRef = useRef<HTMLDivElement>(null);

  const [isExportingComponent, setIsExportingComponent] = useState(false);
  const [isExportSuccess, setIsExportSuccess] = useState(false);

  // const vulnerableStatus = useTypedSelector(
  //   state => state.publicPage.vulnerableStatus,
  // );

  async function exportComponent() {
    await handlePDFExport({ exportableComponentID: 'hdrmp-report', siteId });
    setIsExportingComponent(false);
    setIsExportSuccess(true);
  }

  useGetMoreHouseInfoQuery({
    onSuccess: (res: any) => {
      dispatch(setPublicPageState({ vulnerableStatus: res }));
    },
  });

  const { isLoading } = useGetHazardSingleRiskQuery({
    onSuccess: (res: any) => {
      dispatch(
        setPublicPageState({ siteId: res?.site_id, singleRiskData: res }),
      );
    },
  });

  useEffect(() => {
    dispatch(setPublicPageState({ isUserHdrmpLoading: isLoading }));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (!isExportingComponent) return () => {};
    const timer = setTimeout(() => {
      exportComponent();
    }, 500);
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExportingComponent]);

  useEffect(() => {
    if (!isExportSuccess) return;
    dispatch(setPublicPageState({ isPDFExportEnabled: false }));
  }, [dispatch, isExportSuccess]);

  return (
    <main
      ref={pdfExporterDivRef}
      className={` w-full ${
        paramsSiteId ? 'mx-5' : 'md:px-10 xl:px-20'
      } animate-fade  pb-20 pt-5 font-poppins`}
    >
      <header className="flex w-full items-center gap-5 border-b border-b-[#DDDDDD] pb-5">
        {paramsSiteId && (
          <Icon
            name="arrow_back"
            className="hover:text-primary-600"
            onClick={() => navigate(-1)}
          />
        )}
        <FlexRow className="w-full items-center justify-between">
          <FlexRow gap={5} className="justify-center lg:items-center">
            <Image src={getImage()} />
            <h2 className="font-poppins text-2xl font-bold text-grey-950">
              घरधुरीस्तरीय विपद जोखिम व्यवस्थापन योजना
            </h2>
          </FlexRow>
          <Button
            leftIcon="download"
            disabled={isExportingComponent}
            className="!h-fit"
            onClick={() => {
              setIsExportingComponent(true);
              dispatch(setPublicPageState({ isPDFExportEnabled: true }));
            }}
          >
            Export Report
          </Button>
        </FlexRow>
      </header>

      {/* parent section for all the sections to mainatain gap */}
      <section className="flex w-full flex-col items-center gap-16">
        <ExportHDPRP />
      </section>
      {isExportingComponent && React.cloneElement(<ExportHDPRP exportMode />)}
    </main>
  );
}
