import { useCallback, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { MAX_ZOOM, ZOOM_INTERVAL_ON_NEW_ADD } from '@Constants/overlayLayers';
import { Label, Select } from '@Components/common/FormUI';
import { updateLabelKeyOnLayer } from '@Store/actions/overlayLayer';
import {
  addNewZoomLevelFuncType,
  handleDeleteZoomLevelFuncType,
  styleChangeHandlerType,
} from '..';
import ZoomBasedStyle from '../common/ZoomBasedStyle';
import LabelStyleController from './LabelStyleController';
import AddZoomLevel from '../common/AddZoomLevel';

interface ILabelStyles {
  styleChangeHandler: styleChangeHandlerType;
  addNewZoomLevel: addNewZoomLevelFuncType;
  handleDeleteZoomLevel: handleDeleteZoomLevelFuncType;
}
function LabelStyles({
  styleChangeHandler,
  addNewZoomLevel,
  handleDeleteZoomLevel,
}: ILabelStyles) {
  const dispatch = useTypedDispatch();
  const layerZoomLevels = useTypedSelector(
    state => state.overlayLayer.labelZoomLevels.layer,
  );
  const subLayerZoomLevels = useTypedSelector(
    state => state.overlayLayer.labelZoomLevels.subLayer,
  );
  const isActiveSubLayer = useTypedSelector(
    state => state.overlayLayer.isActiveSubLayer,
  );
  const selectedSubLayer = useTypedSelector(
    state => state.overlayLayer.selectedSubLayer,
  );
  // const attributeKeyList = useTypedSelector(
  //   state => state.overlayLayer.attributeKeyList,
  // );

  const zoomLevels = useMemo(
    () =>
      isActiveSubLayer && selectedSubLayer
        ? subLayerZoomLevels
        : layerZoomLevels,
    [isActiveSubLayer, selectedSubLayer, layerZoomLevels, subLayerZoomLevels],
  );

  const getMaxZoomLevel = useCallback(
    (index: number) => {
      if (index === zoomLevels.length - 1) return MAX_ZOOM;
      return zoomLevels[index + 1].min - 1;
    },
    [zoomLevels],
  );

  // const getLabelKeyOptions = useCallback(() => {
  //   return [
  //     { label: 'Hide name', value: '' },
  //     ...attributeKeyList.map(key => ({
  //       label: String(key),
  //       value: String(key),
  //     })),
  //   ];
  // }, [attributeKeyList]);

  return (
    <FlexColumn className="h-full justify-between">
      <FlexColumn className="h-full">
        <FlexRow className="my-2 items-center pb-4">
          <div className="w-16">
            <Label>Label Key: </Label>
          </div>
          <div className="flex-grow">
            <Select
              className="w-full"
              placeholder="Label key"
              // This list is for temporary use cause we are getting ids only from vector tile
              options={[
                { label: 'id', value: 'id' },
                { label: 'Hide Label', value: '' },
              ]}
              // options={getLabelKeyOptions()}
              onChange={(value: string) => {
                dispatch(updateLabelKeyOnLayer(value));
              }}
            />
          </div>
        </FlexRow>

        <FlexColumn className="scrollbar h-full overflow-y-auto px-2">
          {zoomLevels.map((zoom, index) => (
            <ZoomBasedStyle
              key={zoom.id}
              minZoom={zoom.min}
              maxZoom={getMaxZoomLevel(index)}
              index={index}
              zoomLevels={zoomLevels}
              styleType="label"
              defaultOpen={false}
              handleDeleteZoomLevel={handleDeleteZoomLevel}
            >
              <LabelStyleController
                minZoomValue={zoom.min}
                styleChangeHandler={styleChangeHandler}
              />
            </ZoomBasedStyle>
          ))}
        </FlexColumn>
      </FlexColumn>
      <AddZoomLevel
        // disable if the last zoom level exceeds the limit
        disabled={
          zoomLevels[zoomLevels.length - 1]?.min >=
          MAX_ZOOM - ZOOM_INTERVAL_ON_NEW_ADD
        }
        // pass last zoom level value
        handleAddClick={() =>
          addNewZoomLevel(zoomLevels[zoomLevels.length - 1].min, 'label')
        }
      />
    </FlexColumn>
  );
}

export default LabelStyles;
