import { useEffect, useMemo } from 'react';
import { ICircleMarker } from '../types';

const defaultStyle = {
  type: 'circle',
  paint: {
    'circle-radius': 8,
    'circle-color': '#0088F8',
  },
};

export default function CircleMarker({
  map,
  id,
  coordinates,
  isMapLoaded,
  layerOptions = { ...defaultStyle },
  visibleOnMap = true,
  zoomToLayer = false,
}: ICircleMarker): null {
  const sourceId = useMemo(() => id.toString(), [id]);

  useEffect(() => {
    if (!map || !isMapLoaded) return;
    if (map.getSource(sourceId)) {
      if (map.getLayer(sourceId)) {
        map?.removeLayer(sourceId);
      }
      map?.removeSource(sourceId);
    }
    map.addSource(sourceId, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates,
        },
      },
    });
  }, [sourceId, isMapLoaded, map, coordinates]);

  useEffect(() => {
    if (!map || !isMapLoaded) return;
    if (visibleOnMap) {
      map.addLayer({
        id: sourceId,
        type: 'circle',
        source: sourceId,
        layout: {},
        ...layerOptions,
      });
    } else if (map.getLayer(sourceId)) {
      map.removeLayer(sourceId);
    }
  }, [map, isMapLoaded, visibleOnMap, sourceId, coordinates]); // eslint-disable-line

  useEffect(() => {
    if (!map || !zoomToLayer) return;
    map.flyTo({
      center: coordinates,
      zoom: 14,
    });
  }, [map, coordinates, zoomToLayer]);

  useEffect(
    () => () => {
      map?.removeLayer(sourceId);
      map?.removeSource(sourceId);
    },
    [map, sourceId],
  );

  return null;
}
