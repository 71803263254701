/* eslint-disable react/no-unstable-nested-components */
import { format } from 'date-fns';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import { useTypedSelector } from '@Store/hooks';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { getDisasterListData } from '@Services/publicPage';
import FileSaver from 'file-saver';

export default function TableSection() {
  const { width } = useWindowDimensions();

  const knowMoreInfoActiveTab = useTypedSelector(
    state => state.publicPage.knowMoreInfoActiveTab,
  );
  const selectedOrganizationType = useTypedSelector(
    state => state.publicPage.selectedOrganizationType,
  );

  const disasterListColumns = [
    {
      header: 'S.N',
      accessorKey: 'id',
      cell: (info: any) => info.row.index + 1,
      pin: 'left',
    },
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Description',
      accessorKey: 'description',
    },
    {
      header: 'File Type',
      accessorKey: 'pdf_file',
      cell: 'PDF',
    },
    {
      header: 'Updated on',
      accessorKey: 'updated_at',
      cell: (info: any) => {
        const date = info.getValue();
        if (!date) return '';
        return format(new Date(date), 'MMM dd yyyy hh:mm a');
      },
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => (
        <FlexRow className="cursor-pointer justify-around">
          <ToolTip
            name="visibility"
            message="visibility"
            onClick={() => {
              window.open(
                row.original.pdf_file,
                '_blank',
                'noopener, noreferrer',
              );
            }}
          />
          <ToolTip
            name="file_download"
            message="Download"
            onClick={() => {
              FileSaver.saveAs(row.original.pdf_file, `${row.original.name}`);
            }}
          />
        </FlexRow>
      ),
    },
  ];

  return (
    <DataTable
      columns={disasterListColumns}
      queryKey="disaster-list"
      queryFn={getDisasterListData}
      queryFnParams={{
        type: knowMoreInfoActiveTab,
        org_type: selectedOrganizationType,
      }}
      wrapperStyle={{
        height: width <= 390 ? '60vh' : 'calc(100vh - 11.5rem)',
      }}
    />
  );
}
