/* eslint-disable import/prefer-default-export */
import { api, authenticated } from '.';

export const getFloodRiskData = () =>
  authenticated(api).get('dastaa/sites_tiles/flood_risk_data');

export const postGeoJsonForRiskData = (data: Record<string, any>) =>
  authenticated(api).post(`/dastaa/sites_tiles/filter-sites-within/`, data, {
    headers: { 'Content-Type': 'application/json' },
  });

export const getOverlayLayersIcon = () =>
  authenticated(api).get(`/dastaa/layers/vector-layer-category/`);
