import { useParams } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import DisabledMan from '@Assets/images/public-page/HDPRP/disbaled-man-blue.png';
import Image from '@Components/RadixComponents/Image';
import { hdprpRiskCards } from '@Constants/hdprp';
import { useGetRiskValuesQuery } from '@Api/publicPage';
// import { useGetFloodRiskQuery } from '@Api/publicPage';
import { Flex, FlexColumn, FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import PregnantWoman from '@Assets/images/public-page/HDPRP/pregnant-blue.svg';
import OldMan from '@Assets/images/public-page/HDPRP/old-man-blue.svg';
import SickMan from '@Assets/images/public-page/HDPRP/sickness-1.svg';

// function getRiskPercentage(risk: number) {
//   const threshold = 5;
//   const waterLevelPercentage = (risk / threshold) * 100;

//   if (waterLevelPercentage === 0) return '0%';
//   if (waterLevelPercentage < 25) return '25%';
//   if (waterLevelPercentage > 25 && waterLevelPercentage < 50) return '50%';
//   if (waterLevelPercentage > 50 && waterLevelPercentage < 75) return '75%';
//   return '100%';
// }

export default function RiskAndMap() {
  const { siteId: paramsSiteId } = useParams();

  const publicPageSiteId = useTypedSelector(state => state.publicPage.siteId);

  const siteId = paramsSiteId || publicPageSiteId;

  const singleRiskData = useTypedSelector(
    state => state.publicPage.singleRiskData,
  );

  const vulnerableStatus = useTypedSelector(
    state => state.publicPage.vulnerableStatus,
  );

  // fetch for riskValues according to theme
  const { data: riskData, isLoading: isRiskDataLoading } =
    useGetRiskValuesQuery(siteId);

  // fetch for flood risk
  // const { data: riskValue } = useGetFloodRiskQuery(siteId);

  // add risk scores to existing array
  const updatedHdprpRiskCards = hdprpRiskCards.map(card => {
    // @ts-ignore
    const apiItem = riskData?.find((item: any) => item.name === card.key);
    if (apiItem) {
      return {
        ...card,
        color_code: apiItem.color_code,
        risk_status: apiItem.risk_status,
      };
    }
    return card;
  });

  // const riskPercentage = getRiskPercentage(riskValue);

  if (isRiskDataLoading) {
    return (
      <FlexRow gap={5} className="grid lg:grid-cols-3">
        <Flex className="grid gap-5 md:grid-cols-4 lg:col-span-2">
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton
              key={index}
              className="min-h-56
               rounded-lg bg-primary-50 shadow-sm"
            />
          ))}
        </Flex>
        <Skeleton className="min-h-56 h-full w-full rounded-lg" />
      </FlexRow>
    );
  }

  return (
    <section className="gap-10">
      <FlexColumn gap={5} className="w-full">
        <FlexRow gap={6} className="grid w-full grid-cols-3 items-center ">
          <FlexRow gap={20} className="col-span-3 items-center ">
            <FlexRow gap={5} className="items-center">
              <p className="text-[1.25rem] font-bold leading-[1.875rem] text-grey-950">
                संकटासन्नताको अंक :{' '}
              </p>
              <p
                className="text-xl font-bold"
                style={{ color: singleRiskData?.color_code }}
              >
                {singleRiskData?.remarks}
              </p>
            </FlexRow>
            <FlexRow gap={3} className="items-center">
              {vulnerableStatus?.disability && <Image src={DisabledMan} />}
              {vulnerableStatus?.pregnent_women && (
                <Image src={PregnantWoman} />
              )}
              {vulnerableStatus?.old_person && <Image src={OldMan} />}
              {vulnerableStatus?.ill_ness && <Image src={SickMan} />}
            </FlexRow>
          </FlexRow>
          {/* <p className="text-[1.25rem] font-semibold leading-[1.875rem] text-grey-950">
            बाढीको जोखिम
          </p> */}
        </FlexRow>
        <FlexRow gap={5} className="grid lg:grid-cols-3">
          <Flex className="grid gap-5 md:grid-cols-4 lg:col-span-3">
            {updatedHdprpRiskCards?.map(card => (
              <FlexColumn
                key={card.category}
                className="col-span-1 items-center justify-center gap-5 rounded-lg py-5"
                style={{
                  border: `2px solid ${card?.color_code}`,
                }}
              >
                <Image src={card.icon} alt="house" />
                <FlexColumn className="items-center justify-center">
                  <p className="text-lg font-semibold  text-[#4C4C4C]">
                    {card.category}
                  </p>
                  <h4
                    style={{
                      color: card?.color_code,
                    }}
                  >
                    {card.risk_status}
                  </h4>
                </FlexColumn>
              </FlexColumn>
            ))}
          </Flex>
          {/* <Image
            src={riskImages[riskPercentage]}
            className="h-full  w-full overflow-hidden rounded-lg bg-slate-700 object-cover"
          /> */}
        </FlexRow>
      </FlexColumn>
    </section>
  );
}
