import { Button } from '@Components/RadixComponents/Button';
import { FlexColumn } from '@Components/common/Layouts';

interface IAddZoomLevel {
  disabled: boolean;
  handleAddClick: () => void;
}

function AddZoomLevel({ disabled = false, handleAddClick }: IAddZoomLevel) {
  return (
    <FlexColumn className="w-40 px-2 pt-4">
      <Button
        variant="outline"
        leftIcon="add"
        disabled={disabled}
        onClick={handleAddClick}
      >
        Add Zoom Level
      </Button>
    </FlexColumn>
  );
}

export default AddZoomLevel;
