import { useEffect, useMemo } from 'react';
import { Popup } from 'maplibre-gl';
import removeObjectKeys from '@Utils/removeObjectKeys';
import { IVectorLayer } from '../types';

const popup = new Popup({
  closeButton: false,
  closeOnClick: false,
});

export default function VectorLayer({
  map,
  id,
  geojson,
  isMapLoaded,
  layerOptions,
  visibleOnMap = true,
  showOutline = false,
  showLabel,
  showPopupOnHover,
}: IVectorLayer) {
  const sourceId = useMemo(() => id.toString(), [id]);

  useEffect(() => {
    if (!map || !isMapLoaded || !geojson) return;
    if (map.getSource(sourceId)) {
      if (map.getLayer(`${sourceId}-label`)) {
        map.removeLayer(`${sourceId}-label`);
      }
      if (map.getLayer(`${sourceId}-outline`)) {
        map.removeLayer(`${sourceId}-outline`);
      }
      map?.removeLayer(sourceId);
      map?.removeSource(sourceId);
    }
    map.addSource(sourceId, {
      type: 'geojson',
      data: geojson,
    });
  }, [sourceId, isMapLoaded, map, geojson]);

  useEffect(() => {
    if (!map || !isMapLoaded || !geojson) return;
    if (visibleOnMap) {
      map.addLayer({
        id: sourceId,
        type: 'line',
        source: sourceId,
        layout: {},
        ...removeObjectKeys(layerOptions || {}, ['layout']),
      });
    } else if (map.getLayer(sourceId)) {
      map.removeLayer(sourceId);
    }
  }, [map, isMapLoaded, visibleOnMap, sourceId, geojson]); // eslint-disable-line

  // add outline to layer
  useEffect(() => {
    if (!map || !isMapLoaded || !visibleOnMap || !showOutline || !geojson)
      return () => {};
    const outlineLayerId = `${sourceId}-outline`;
    if (map.getLayer(outlineLayerId)) return () => {};
    map.addLayer({
      id: outlineLayerId,
      type: 'line',
      source: sourceId,
      layout: {},
      paint: {
        'line-color': '#006866',
        'line-width': 1,
        'line-dasharray': [1, 0.2],
      },
    });
    return () => {
      if (map.getLayer(outlineLayerId)) {
        map.removeLayer(outlineLayerId);
      }
    };
  }, [map, isMapLoaded, visibleOnMap, sourceId, showOutline, geojson]);

  // add label to layer
  useEffect(() => {
    if (!map || !isMapLoaded) return () => {};
    const labelSourceId = `${sourceId}-label`;
    map.setGlyphs(
      'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
    );
    if (visibleOnMap && showLabel) {
      map.addLayer({
        id: labelSourceId,
        type: 'symbol',
        source: sourceId,
        layout: {
          // @ts-ignore
          ...(layerOptions?.layout || {}),
        },
        paint: {
          'text-color': '#000000',
          'text-halo-color': '#ffffff',
          'text-halo-width': 1,
        },
      });
    }
    return () => {
      if (map.getLayer(labelSourceId)) {
        map.removeLayer(labelSourceId);
      }
    };
  }, [
    map,
    isMapLoaded,
    visibleOnMap,
    layerOptions,
    sourceId,
    showLabel,
    geojson,
  ]);

  // display detail on hover
  useEffect(() => {
    if (!map || !showPopupOnHover) return () => {};

    const generatePopupContent = (properties: Record<string, any>): string => {
      return Object.entries(properties)
        .filter(([key]) => key !== 'geometry')
        .map(
          ([key, value]) =>
            `<strong>${key.replace(/_/g, ' ')}:</strong> ${value}`,
        )
        .join('<br>');
    };

    const handleMouseEnter = (e: any) => {
      if (!e.features?.length) return;

      const canvas = map.getCanvas();
      canvas.style.cursor = 'pointer';

      const { properties } = e.features[0];
      if (!properties) return;

      const popupContent = generatePopupContent(properties);
      popup.setLngLat(e.lngLat).setHTML(popupContent).addTo(map);
    };

    const handleMouseLeave = () => {
      const canvas = map.getCanvas();
      canvas.style.cursor = '';
      popup.remove();
    };

    map.on('mouseenter', sourceId, handleMouseEnter);
    map.on('mouseleave', sourceId, handleMouseLeave);

    return () => {
      map.off('mouseenter', sourceId, handleMouseEnter);
      map.off('mouseleave', sourceId, handleMouseLeave);
    };
  }, [map, sourceId, showPopupOnHover]);

  return null;
}
