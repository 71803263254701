import { useLocation } from 'react-router-dom';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import SignInBanner from './SignInBanner';
import SignUp from './SignUp';

export default function AuthenticationPage() {
  const { pathname } = useLocation();

  const getContent = () => {
    switch (pathname) {
      case '/login':
        return <Login />;
      case '/forgot-password':
        return <ForgotPassword />;
      case '/sign-up':
        return <SignUp />;
      default:
        return <Login />;
    }
  };

  return (
    <FlexRow className="h-screen w-full">
      <div className="hidden h-full overflow-hidden  lg:block lg:w-[54%]">
        <SignInBanner />
      </div>
      <FlexColumn className="h-full flex-1 items-center justify-center py-8 duration-500 ease-in-out">
        {getContent()}
      </FlexColumn>
    </FlexRow>
  );
}
