import Icon from '@Components/common/Icon';
import DastaaQR from '@Assets/images/public-page/HDPRP/qr-code - dastaa.svg';
import BheemduttaQR from '@Assets/images/public-page/HDPRP/qr-code - bheemdatta.svg';
import ParshuramQR from '@Assets/images/public-page/HDPRP/qr-code - parshuram.svg';
import DhodharaQR from '@Assets/images/public-page/HDPRP/qr-code - dodhara.svg';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';
import { importantLinks } from '@Constants/hdprp';
import Siren from '@Assets/images/public-page/HDPRP/Siren.svg';

const { SITE_NAME } = process.env;

const getImage = () => {
  switch (SITE_NAME) {
    case 'Dastaa':
      return DastaaQR;
    case 'Dastaa Bheemdatta':
      return BheemduttaQR;
    case 'Dastaa Parshuram':
      return ParshuramQR;
    case 'Dastaa Dodahra':
      return DhodharaQR;
    case 'Dastaa Bangladesh':
      return DastaaQR;
    default:
      return DastaaQR;
  }
};

const Parshuram = () => {
  return (
    <div className="flex flex-col  rounded-lg bg-white p-2">
      <div className="flex flex-col border-b-2 border-b-[#D3DED9] pb-2">
        <p className="font-poppins font-semibold text-[#037342]">
          माहाकाली नदी सतह मापन केन्द्र
        </p>
        <div className="flex gap-2 font-poppins">
          <p className="font-medium text-black">परशुराम धाम डडेल्धुरा :</p>
          <p className="font-medium text-[#037342]">
            (तारा दत्त जोशी - ९८४७६११९७५२ )
          </p>
        </div>
        <div className="flex gap-2 font-poppins">
          <p className="font-medium text-black">दत्तु दार्चुला :</p>
          <p className="font-medium text-[#037342]">
            (कृष्ण सिंह नागरी - ९७४९५२४८५१ )
          </p>
        </div>
      </div>

      <div className="flex flex-col pt-2 ">
        <p className="font-poppins font-semibold text-[#037342]">
          रगुन खोला सतह मापन केन्द्र
        </p>
        <div className="flex gap-2 font-poppins">
          <p className="font-medium text-black">आलिताल :</p>
          <p className="font-medium text-[#037342]">
            ( यादव वोगरी: ९८०६४०४५२५ )
          </p>
        </div>
        <div className="flex gap-2 font-poppins">
          <p className="font-medium text-black">कैनपानी :</p>
          <p className="font-medium text-[#037342]">
            ( पशुपति धामी : ९८४७६१९७५२ )
          </p>
        </div>
      </div>
    </div>
  );
};

const Bheemdatta = () => {
  return (
    <div className=" my-auto  flex flex-col rounded-lg bg-white p-2">
      <div className="flex flex-col  pb-2">
        <p className="font-poppins font-semibold text-black">
          हालको लागि भीमदत्त नगरपालिकामा पुर्माणूमानको लागि कुनै पनि भौतिक
          स्थापना भएको छैन ।{' '}
        </p>
      </div>
    </div>
  );
};

const Dodahra = () => {
  return (
    <div className=" my-auto  flex flex-col rounded-lg bg-white p-2">
      <div className="flex flex-col  pb-2">
        <ul className="flex list-disc flex-col gap-2 pl-5 font-poppins font-semibold text-black">
          <li>
            साइरन जडान गरिएको स्थान: ओमकार आधारभूत विद्यालय , १० सुर्खेती टोल,
            दोधारा चाँदनी
          </li>
          <li>
            ४ लेन पुलमा नदीको स्तर मापन उपकरण जडान गरिएको स्थान: दोधारा चाँदनी र
            भिमदत्त जोडिने पुल
          </li>
        </ul>
      </div>
    </div>
  );
};

const Dastaa = () => {
  return (
    <div className="flex flex-col  rounded-lg bg-white p-2">
      <div className="flex flex-col border-b-2 border-b-[#D3DED9] pb-2">
        <p className="font-poppins font-semibold text-[#037342]">
          माहाकाली नदी सतह मापन केन्द्र
        </p>
        <div className="flex gap-2 font-poppins">
          <p className="font-medium text-black">परशुराम धाम डडेल्धुरा :</p>
          <p className="font-medium text-[#037342]">
            (तारा दत्त जोशी - ९८४७६११९७५२ )
          </p>
        </div>
        <div className="flex gap-2 font-poppins">
          <p className="font-medium text-black">दत्तु दार्चुला :</p>
          <p className="font-medium text-[#037342]">
            (कृष्ण सिंह नागरी - ९७४९५२४८५१ )
          </p>
        </div>
      </div>

      <div className="flex flex-col pt-2 ">
        <p className="font-poppins font-semibold text-[#037342]">
          रगुन खोला सतह मापन केन्द्र
        </p>
        <div className="flex gap-2 font-poppins">
          <p className="font-medium text-black">आलिताल :</p>
          <p className="font-medium text-[#037342]">
            ( यादव वोगरी: ९८०६४०४५२५ )
          </p>
        </div>
        <div className="flex gap-2 font-poppins">
          <p className="font-medium text-black">कैनपानी :</p>
          <p className="font-medium text-[#037342]">
            ( पशुपति धामी : ९८४७६१९७५२ )
          </p>
        </div>
      </div>
    </div>
  );
};

const getBhautikSthapana = () => {
  switch (SITE_NAME) {
    case 'Dastaa':
      return <Dastaa />;
    case 'Dastaa Bheemdatta':
      return <Bheemdatta />;
    case 'Dastaa Parshuram':
      return <Parshuram />;
    case 'Dastaa Dodahra':
      return <Dodahra />;
    default:
      return <Dastaa />;
  }
};

export default function ImportanLinks() {
  return (
    <div className="mx-[1.25rem] flex justify-between gap-4">
      <section className="w-[18.7rem] flex-1 gap-20 rounded-md bg-[#F8F8F8] pr-4 pt-4 md:w-full md:grid-cols-4 md:gap-4">
        <FlexColumn gap={8} className="md:col-span-3">
          <FlexRow gap={5} className="items-center px-2">
            <Icon
              name="link"
              className="flex h-11 w-11 rotate-45 items-center justify-center rounded-full bg-[#08688B] text-white"
            />
            <div className="!text-[14px] font-semibold !leading-10 text-grey-950">
              महत्वपूर्ण लिंकहरुको लागि QR Scan गर्नुहोला
            </div>
          </FlexRow>

          <FlexRow className="flex items-start justify-between">
            <FlexColumn className="w-fit">
              {importantLinks.map((link, index) => (
                <a
                  key={link.id}
                  href={link.url}
                  className={`border-b-2 border-grey-200 px-6 py-1 text-[14px] font-normal text-[#000000] even:bg-white ${
                    index % 2 === 1 ? '' : ''
                  }`}
                  title={link.url}
                >
                  {link.text}
                </a>
              ))}
            </FlexColumn>
            <FlexRow className="h-[6.938rem] w-[6.938rem] ">
              <Image src={getImage()} className="w-full" />
            </FlexRow>
          </FlexRow>
        </FlexColumn>
      </section>
      <section className="flex  flex-1 flex-col gap-3 rounded-lg bg-[#037342] px-[0.88rem] py-3 md:w-full">
        <div className="head flex items-end gap-2 text-[#FFFEFC]">
          <img src={Siren} className="h-8 w-8" alt="Siren" />
          <p className="font-poppins text-[18px] font-bold">भौतिक स्थापना</p>
        </div>
        {getBhautikSthapana()}
      </section>
    </div>
  );
}
