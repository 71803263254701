import Image from '@Components/RadixComponents/Image';
import CheckListPic from '@Assets/images/public-page/HDPRP/CheckList.png';
import {
  checkListDisabledRowOne,
  checkListDisabledRowTwo,
} from '@Constants/hdprp';
// import { useTypedSelector } from '@Store/hooks';

export default function CheckListDisabled() {
  // const vulnerableStatus = useTypedSelector(
  //   state => state.publicPage.vulnerableStatus,
  // );

  return (
    // vulnerableStatus?.disability && (
    <div>
      <div className="topic">
        <div className="flex items-center gap-5">
          <Image src={CheckListPic} width={25} alt="check" />
          <p className="text-[25px] font-bold leading-[66px] text-grey-950">
            चेकलिस्ट (अपाङ्गता भएका व्यक्ति)
          </p>
        </div>
        <p className=" text-[20px] leading-[32px] text-[#4c4c4c]">
          अपाङ्गता भएका व्यक्ति बस्ने घरमा राख्नुपर्ने आवश्यक वस्तुहरु
        </p>
      </div>
      <div className="mt-4 grid gap-5 md:grid-cols-2">
        <div className="col-span-1 flex flex-col gap-2">
          {checkListDisabledRowOne.map(data => (
            <div key={data.id} className="single card flex gap-4">
              <div className="h-5 w-5 rounded-[4px] border border-[#08688B]" />
              <Image src={data.icon} alt="checlistimage" width={35} />
              <p className="flex-1 text-[16px] leading-[32px]">{data.name}</p>
            </div>
          ))}
        </div>
        <div className="col-span-1 flex flex-col gap-2">
          {checkListDisabledRowTwo.map(data => (
            <div key={data.id} className="single card flex items-center gap-4">
              <div className="h-5 w-5 rounded-[4px] border border-[#08688B]" />
              <Image src={data.icon} alt="checlistimage" width={35} />
              <p className="text-[16px] leading-[32px]">{data.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    // )
  );
}
