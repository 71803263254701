import BheemduttaLogo from '@Assets/images/BheemdattaMuniLogo.svg';
import DodharaLogo from '@Assets/images/dodhara-muni-logo.png';
import BangladeshLogo from '@Assets/images/Bangladeshlogo.jpg';
import ParshuramLogo from '@Assets/images/parshuram-muni-logo.png';
import { FlexRow } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';
import NotificationPopup from './NotificationPopup';

const { SITE_NAME } = process.env;

const getImage = () => {
  switch (SITE_NAME) {
    case 'Dastaa':
      return BheemduttaLogo;
    case 'Dastaa Bheemdatta':
      return BheemduttaLogo;
    case 'Dastaa Parshuram':
      return ParshuramLogo;
    // return BangladeshLogo;
    case 'Dastaa Dodahra':
      return DodharaLogo;
    case 'Dastaa Bangladesh':
      return BangladeshLogo;
    default:
      return BheemduttaLogo;
  }
};

export default function DashboardHeader() {
  // const handleWardFilter = () => {};
  // const handleClearFilter = () => {};

  return (
    <FlexRow className="flex-col items-center justify-between md:flex-row">
      <Image
        src={getImage()}
        className="hidden w-[60%] md:block md:h-16 md:w-full"
      />

      <FlexRow
        gap={4}
        className="w-full items-center justify-end pr-2 md:w-auto"
      >
        {/* 
        <MultiSelect
          options={wardOptions || []}
          selectedOptions={[]}
          onChange={handleWardFilter}
          placeholder="All Ward"
          className="!w-40 justify-center border-b-[1.5px]"
        />
        <ToolTip
          name="restart_alt"
          message="Reset filter"
          iconClassName="!text-primary-600 hover:animate-pulse hover:text-primary-600 hidden md:block"
          onClick={handleClearFilter}
        /> 
        */}

        <NotificationPopup />
      </FlexRow>
    </FlexRow>
  );
}
