import { useState } from 'react';

interface LanguageToggleProps {
  selectedLanguage: string;
  languages: [string, string];
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onLanguageChange: (language: string) => void;
}

export default function LanguageToggle({
  selectedLanguage,
  languages,
  className,
  onLanguageChange,
}: LanguageToggleProps) {
  const [currentLanguage, setCurrentLanguage] = useState(selectedLanguage);

  const handleToggle = () => {
    const nextLanguageIndex =
      (languages.indexOf(currentLanguage) + 1) % languages.length;
    const nextLanguage = languages[nextLanguageIndex];
    setCurrentLanguage(nextLanguage);
    onLanguageChange(nextLanguage);
  };

  return (
    <div
      className={`${className} h-[2rem] w-[3.75rem] rounded-2xl bg-grey-200 p-1 hover:cursor-pointer`}
      onClick={handleToggle}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <div
        className={`h-[1.5rem] w-[1.5rem] rounded-[50%] bg-primary-700 text-center ${
          selectedLanguage === languages[1]
            ? 'translate-x-6 transition-all duration-300 ease-in-out'
            : 'translate-x-0 transition-all duration-300 ease-in-out'
        }`}
      >
        <span className="font-primary text-[0.75rem] font-semibold text-white">
          {currentLanguage === 'Nepali' ? 'ने' : currentLanguage.slice(0, 2)}
        </span>
      </div>
    </div>
  );
}
