/* eslint-disable import/prefer-default-export */
import { api, authenticated } from '.';

export const getNotification = (params: Record<string, any>) =>
  api.get('/dastaa/incident/reports-all/', { params });

export const patcNotification = ({ id, data }: Record<string, any>) => {
  return authenticated(api).patch(`/dastaa/incident/reports-all/${id}/`, data);
};

export const getHazardSelectChoices = () => {
  return api.get('/dastaa/hazards_calender/hazard_select_fields/');
};
