/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import Spinner from '@Components/common/Spinner';
import MapLibreGL from 'maplibre-gl';
import { IMapFooter } from '../types';

export default function MapFooter({ map, isMapLoaded }: IMapFooter) {
  const layerAttributionRef = useRef<HTMLDivElement | null>(null);
  const mapScaleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!map) return;
    const attribution = new MapLibreGL.AttributionControl();
    if (layerAttributionRef.current) {
      layerAttributionRef.current.appendChild(attribution.onAdd(map));
    }

    const scale = new MapLibreGL.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    if (mapScaleRef.current) {
      mapScaleRef.current.appendChild(scale.onAdd(map));
    }
  }, [map]);

  return (
    <div
      className="absolute bottom-0 z-10 flex h-[1.875rem] 
        w-full items-center justify-between bg-white px-5 py-3"
    >
      <div className="map-footer flex items-center gap-4">
        <p className="text-sm font-medium">D-MAPS</p>
        {!isMapLoaded && <Spinner className="!h-4 !w-6 fill-primary-600" />}
        <span ref={layerAttributionRef} className=" text-grey-500" />
      </div>

      <div ref={mapScaleRef} />
    </div>
  );
}
