import { useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { toggleInfoDialog } from '@Store/actions/common';
import { infoDialogTimeoutValue } from '@Constants/index';
import { FlexColumn } from '@Components/common/Layouts';
import InfoDialog from '@Components/common/InfoDialog';
import { getInfoDialogContent } from '@Constants/getInfoDialogContent';
import FilterSection from './FilterSection';
import TableSection from './TableSection';

export default function UserData() {
  const dispatch = useTypedDispatch();

  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);
  const infoDialogContent = useTypedSelector(
    state => state.common.infoDialogContent,
  );

  // reset info dialog content on unmount
  useEffect(() => {
    if (!infoDialogContent) return () => {};
    const timer = setTimeout(
      () => dispatch(toggleInfoDialog()),
      infoDialogTimeoutValue,
    );
    return () => {
      clearTimeout(timer);
    };
  }, [infoDialogContent, dispatch]);

  return (
    <div className="relative w-screen animate-fade px-5 pt-5 md:h-screen">
      <h3 className=" text-grey-800">Users</h3>
      <FlexColumn gap={2.5}>
        <FilterSection />
        {showInfoDialog && !!infoDialogContent && (
          <InfoDialog
            status={getInfoDialogContent(infoDialogContent)?.status}
            title={getInfoDialogContent(infoDialogContent)?.title}
            description={getInfoDialogContent(infoDialogContent)?.description}
            onClose={() => dispatch(toggleInfoDialog())}
          />
        )}
        <TableSection />
      </FlexColumn>
    </div>
  );
}
