import { FlexColumn } from '@Components/common/Layouts';
import FilterSection from './FilterSection';
import TableSection from './TableSection';

export default function AssessmentData() {
  return (
    <div className="relative w-screen animate-fade px-5 pt-5 md:h-screen">
      <h3 className=" text-grey-800">Assessment Data</h3>
      <FlexColumn gap={2.5}>
        <FilterSection />
        <TableSection />
      </FlexColumn>
    </div>
  );
}
