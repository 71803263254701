/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import DastaaFullLogo from '@Assets/images/dastaa-full-logo.svg';
import DastaaLogo from '@Assets/images/dastaa-logo.svg';
import Image from '@Components/RadixComponents/Image';
import Icon from '@Components/common/Icon';
import { setCommonState, togglePromptDialog } from '@Store/actions/common';
import sidebarData, { ISidebar } from '@Constants/sidebarData';
import { FlexColumn, FlexRow } from '../Layouts';
import LanguageToggle from '../LanguageToggle';
import UserAvatar from '../UserAvatar';

export default function Sidebar() {
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [isSidebarHovered, setIsSidebarHovered] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [activeHoverTab, setActiveHoverTab] = useState('');
  const [showMobileNav, setShowMobileNav] = useState(false);

  const language = useTypedSelector(state => state.common.language);

  const handleActiveTab = (tabName: string) => {
    const activeTabData = sidebarData.find(item => {
      if (item.children) {
        return item.children.some(child =>
          tabName.includes(`${item.path}/${child.path}`),
        );
      }
      return tabName.includes(`${item.path}`);
    });

    if (activeTabData) {
      setActiveTab(activeTabData.path);
    }
  };

  useEffect(() => {
    if (!pathname) {
      navigate('/dashboard');
    }
  }, [pathname, navigate]);

  useEffect(() => {
    const activeTabData = sidebarData.find(item => {
      if (item.children) {
        return item.children.some(child =>
          pathname.includes(`${item.path}/${child.path}`),
        );
      }
      return pathname.includes(`${item.path}`);
    });
    if (activeTabData) {
      setActiveTab(activeTabData.path);
    }
    setShowMobileNav(false);
    setIsSidebarHovered(false);
  }, [pathname]);

  const toggleMobileMenu = () => {
    setShowMobileNav(!showMobileNav);
    setIsSidebarHovered(!isSidebarHovered);
  };

  const isLoginChecked = useTypedSelector(
    state => state.authentication.isLoginChecked,
  );
  const user = useTypedSelector(state => state.authentication.user);

  if (!isLoginChecked) return <></>;

  return (
    <>
      <FlexRow
        className="fixed left-0 top-0 z-50 mb-3 w-full items-center
       justify-between border-b border-grey-300 bg-white px-3
        py-3 md:hidden"
      >
        <div
          onClick={() => navigate('/dashboard')}
          className="cursor-pointer hover:animate-pulse"
        >
          <Image className="h-10 w-full" src={DastaaFullLogo} width={200} />
        </div>
        <Icon
          name={`${showMobileNav ? 'close' : 'menu'}`}
          className="rounded px-1 py-2 !text-2xl hover:bg-primary-50 md:hidden"
          onClick={toggleMobileMenu}
        />
      </FlexRow>
      <aside
        className={`${
          isSidebarHovered
            ? 'w-full animate-fade-down  md:w-[14.813rem] md:animate-fade-right '
            : 'hidden md:w-[90px]'
        }  fixed left-0 top-0 z-40 h-screen md:grid`}
        aria-label="Sidebar"
        onMouseEnter={() => {
          if (!showMobileNav) {
            setIsSidebarHovered(true);
          }
        }}
        onMouseLeave={() => {
          if (!showMobileNav) {
            setIsSidebarHovered(false);
            setActiveHoverTab('');
          }
        }}
      >
        <div
          className="h-full overflow-y-auto
          bg-white py-4 shadow-[0_4px_11px_0_#00000040]"
        >
          <FlexRow
            className={
              isSidebarHovered ? 'flex flex-row gap-5 px-3' : 'justify-center'
            }
          >
            <Link
              to="/"
              className={`flex items-center justify-center md:pb-10 `}
            >
              <Image src={isSidebarHovered ? DastaaFullLogo : DastaaLogo} />
            </Link>

            {isSidebarHovered && (
              <LanguageToggle
                selectedLanguage={language}
                languages={['English', 'Nepali']}
                className="mt-1"
                onLanguageChange={changedLanguage => {
                  dispatch(setCommonState({ language: changedLanguage }));
                }}
              />
            )}
          </FlexRow>

          <FlexColumn className=" flex-col-reverse md:flex-col">
            <div className="mt-5 font-medium md:mt-0 ">
              {sidebarData.map(({ name, icon, path, children }: ISidebar) => (
                <div
                  key={name}
                  onMouseEnter={() => {
                    setActiveHoverTab(name);
                  }}
                  className={`${
                    children &&
                    activeHoverTab === name &&
                    pathname.includes(`${path}`)
                      ? 'bg-primary-50'
                      : ''
                  }`}
                >
                  <NavLink
                    to={children?.length ? `${path}/${children[0].path}` : path}
                    onClick={() => {
                      handleActiveTab(
                        children?.length ? `${path}/${children[0].path}` : path,
                      );
                    }}
                    className={`${
                      activeTab === path
                        ? 'border-primary-600  bg-primary-50 text-primary-800'
                        : 'border-white text-grey-600'
                    } ${
                      !isSidebarHovered ? 'flex-col py-2' : 'py-[18px]'
                    } group flex items-center  border-l-[5px] px-2
                 hover:bg-primary-50 hover:text-primary-800 `}
                  >
                    <i className="material-icons-outlined">{icon}</i>
                    <div
                      className={`${
                        isSidebarHovered && 'pl-4'
                      } flex-1 whitespace-nowrap text-sm font-bold`}
                    >
                      {isSidebarHovered ? name : name.split(' ')[0]}
                    </div>
                    {children && isSidebarHovered && (
                      <i className="material-icons-outlined">
                        {activeHoverTab === name
                          ? 'expand_less'
                          : 'expand_more'}
                      </i>
                    )}
                  </NavLink>
                  <div>
                    {children &&
                      (activeHoverTab === name || showMobileNav) &&
                      children.map(child => (
                        <NavLink
                          key={child.name}
                          to={`${path}/${child.path}`}
                          className="group flex items-center p-2 
                        text-grey-700 hover:bg-primary-50 hover:text-primary-600"
                        >
                          <span
                            className={`${
                              isSidebarHovered ? 'ml-11' : 'ml-4'
                            } ${
                              pathname.includes(`${path}/${child.path}`) &&
                              ' text-primary-600 '
                            }  `}
                          >
                            {isSidebarHovered
                              ? child.name
                              : child?.name.split(' ')[0]}
                          </span>
                        </NavLink>
                      ))}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="bottom-20 mb-1 h-[2px]
             w-full bg-grey-100 md:absolute md:flex"
            />
            {/* user profile section */}
            <div
              className={`${
                !isSidebarHovered && 'flex-col items-center'
              }  bottom-5 flex w-full
               items-center justify-between px-3 py-2 hover:bg-primary-50 md:absolute`}
            >
              <div
                className={`${
                  isSidebarHovered ? '' : 'flex-col'
                } flex items-center justify-center`}
              >
                <UserAvatar className="cursor-pointer" name={user?.username} />

                <div className={`${isSidebarHovered && 'pl-3'} flex flex-col `}>
                  <div className="text-sm font-bold capitalize text-grey-700">
                    {isSidebarHovered
                      ? user?.username
                      : user?.username.split(' ')[0]}
                  </div>
                  {isSidebarHovered && (
                    <div className="text-xs capitalize text-grey-700">
                      {user?.isAdmin && 'Admin'}
                    </div>
                  )}
                </div>
              </div>
              {isSidebarHovered && (
                <Icon
                  name="logout"
                  className="cursor-pointer pt-1 text-grey-700 "
                  onClick={() => dispatch(togglePromptDialog('confirm-logout'))}
                />
              )}
            </div>
          </FlexColumn>
        </div>
      </aside>
    </>
  );
}
