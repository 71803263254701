/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { useGetTriggerMessageTypeQuery } from '@Api/triggerMessage';
import {
  FormControl,
  Input,
  Label,
  MultiSelect,
  Select,
} from '@Components/common/FormUI';
import { Flex, FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import TextArea from '@Components/common/FormUI/TextArea';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { postTriggerMessage } from '@Services/triggerMessage';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { togglePromptDialog } from '@Store/actions/common';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import { useEffect } from 'react';

const TargetedMessageSkeleton = () => (
  <Flex gap={2}>
    {Array.from({ length: 5 }, (_, index) => (
      <Skeleton key={index} className="h-5 bg-primary-50" />
    ))}
  </Flex>
);

interface IInitialState {
  message_type: string;
  targeted_group: string[];
  ward: string[];
  description: string;
}

const initialState: IInitialState = {
  message_type: '',
  targeted_group: [],
  ward: [],
  description: '',
};

export default function TemplateSection() {
  const dispatch = useTypedDispatch();

  const activeAlertTab = useTypedSelector(
    state => state.triggerMessage.activeAlertTab,
  );

  const triggerMessageTemplateData = useTypedSelector(
    state => state.triggerMessage.triggerMessageTemplateData,
  );

  const {
    data: triggerMessageOptionsList,
    isLoading: isTrigerrMessageLoading,
  } = useGetTriggerMessageTypeQuery();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: initialState,
  });

  useEffect(() => {
    reset(triggerMessageTemplateData[activeAlertTab as number]);
  }, [triggerMessageTemplateData, activeAlertTab, reset]);

  const { message_type } = watch();

  const { mutate, isLoading } = useMutation<unknown, unknown, void, unknown>({
    mutationFn: postTriggerMessage,
    onSuccess: () => {
      dispatch(togglePromptDialog('send-notification'));
    },
  });

  const onSubmit = (data: any) => {
    mutate(data);
  };

  return (
    <FlexColumn className="w-full lg:w-1/2">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        {/* message type */}
        <FormControl>
          <Label htmlFor="message_type" required>
            Message Type
          </Label>
          <Controller
            control={control}
            name="message_type"
            defaultValue=""
            rules={{
              required: 'Required',
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                options={triggerMessageOptionsList?.message_type || []}
                selectedOption={value}
                onChange={onChange}
              />
            )}
          />
          <ErrorMessage message={errors?.message_type?.message || ''} />
        </FormControl>

        {/* target group */}
        {message_type === 'Targeted Message' && (
          <FlexColumn gap={1}>
            <Label htmlFor="targeted_group" required>
              Targeted Group
            </Label>
            {!isTrigerrMessageLoading ? (
              triggerMessageOptionsList?.targeted_group?.map(
                (targetedGroup: Record<string, any>) => (
                  <FormControl
                    className="group !flex-row items-center gap-3 px-3 py-1 duration-200 hover:bg-primary-50"
                    key={targetedGroup.label}
                  >
                    <Input
                      type="checkbox"
                      {...register('targeted_group', { required: 'Required' })}
                      className="!h-4 !w-4 !cursor-pointer"
                      value={targetedGroup.label}
                    />
                    <div className="select-none text-sm">
                      {targetedGroup.label}
                    </div>
                  </FormControl>
                ),
              )
            ) : (
              <TargetedMessageSkeleton />
            )}
            <ErrorMessage message={errors?.targeted_group?.message || ''} />
          </FlexColumn>
        )}

        {/* ward */}
        <FormControl>
          <Label htmlFor="ward" required>
            Select Ward
          </Label>
          <Controller
            control={control}
            name="ward"
            defaultValue={['']}
            rules={{
              required: 'Required',
            }}
            render={({ field: { value, onChange } }) => (
              <MultiSelect
                placeholder="Choose Ward"
                options={triggerMessageOptionsList?.ward}
                selectedOptions={value || []}
                onChange={onChange}
              />
            )}
          />
          <ErrorMessage message={errors?.ward?.message || ''} />
        </FormControl>

        {/* message */}
        <FormControl className="gap-2">
          <Label htmlFor="description">Message</Label>
          <TextArea
            rows={5}
            className="border border-grey-800"
            {...register('description')}
          />
        </FormControl>
        <FlexRow>
          <Button type="submit" withLoader isLoading={isLoading}>
            Send Notification
          </Button>
        </FlexRow>
      </form>
    </FlexColumn>
  );
}
