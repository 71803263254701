import { useTypedSelector } from '@Store/hooks';
import { useGetSubmissionDetailQuery } from '@Api/common';
import { Flex, FlexColumn } from '@Components/common/Layouts';

export default function UserForm() {
  const siteId = useTypedSelector(state => state.publicPage.siteId);

  const { data: userDetails } = useGetSubmissionDetailQuery(siteId);

  return (
    <Flex gap={5} className="py-5 xl:px-32">
      <h3>User Form Details</h3>
      {userDetails?.map((userDetail: Record<string, any>) => (
        <FlexColumn key={userDetail.question}>
          <h5 className="capitalize">{userDetail.question}</h5>
          <p>{userDetail.answer}</p>
        </FlexColumn>
      ))}
    </Flex>
  );
}
