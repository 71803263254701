/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
// import { useIncidentReportQuery } from '@Api/notification';
// import { useTypedSelector } from '@Store/hooks';
import { useGetIncidentSelectedFields } from '@Api/common';
import DataTable from '@Components/common/DataTable';
import { getNotification } from '@Services/notification';
import { useTypedSelector } from '@Store/hooks';
import { FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { useDispatch } from 'react-redux';
import { toggleModal } from '@Store/actions/common';
import { setNotificationState } from '@Store/actions/notification';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import FilterSection from './FilterSection';
import StatusCell from './lib/StatusCell';

export default function NotificationPlayground() {
  const searchInput = useTypedSelector(state => state.notification.search);
  const hazard_types = useTypedSelector(
    state => state.notification.hazard_types,
  );
  const start_time = useTypedSelector(state => state.notification.start_time);
  const end_time = useTypedSelector(state => state.notification.end_time);
  const { data: incidentSelectedFields } = useGetIncidentSelectedFields();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const userColumns = [
    {
      header: 'S .N',
      accessorKey: 'id',
      cell: (info: any) => info.row.index + 1,
      pin: 'left',
    },
    {
      header: 'Hazard types',
      accessorKey: 'hazard_types',
    },
    {
      header: 'Name of incident',
      accessorKey: 'name_of_incident',
    },
    {
      header: 'Place name',
      accessorKey: 'place_name',
    },
    {
      header: 'Date of occurrence',
      accessorKey: 'date_of_occurrence',
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row }: any) => (
        <StatusCell
          row={row}
          options={incidentSelectedFields?.status_choices || []}
        />
      ),
    },
    {
      header: 'Ward',
      accessorKey: 'ward',
    },
    {
      header: 'Name of tole',
      accessorKey: 'name_of_tole',
    },
    {
      header: 'Household owner name',
      accessorKey: 'household_owner_name',
    },
    {
      header: 'Time',
      accessorKey: 'time',
    },
    {
      header: 'Extent of damage:',
      accessorKey: 'extent_of_damage',
    },
    {
      header: 'Depth(in cm)',
      accessorKey: 'depth',
    },
    {
      header: 'Incident Photos',
      accessorKey: 'photos',
      cell: ({ row }: any) => {
        return (
          <FlexRow className="cursor-pointer justify-around">
            {row?.original?.photos.length ? (
              <Button
                variant="outline"
                onClick={() => {
                  dispatch(
                    setNotificationState({
                      incidentPhotos: row?.original?.photos,
                    }),
                  );
                  dispatch(toggleModal('view-inncident-photos'));
                }}
              >
                <i className="material-icons-outlined">preview</i>
              </Button>
            ) : (
              <p>No images </p>
            )}
          </FlexRow>
        );
      },
    },
  ];

  return (
    <div className="flex w-full flex-col gap-2">
      <FilterSection />
      <DataTable
        columns={userColumns}
        queryKey="assessment-data-list"
        queryFn={getNotification}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        queryFnParams={{
          search: searchInput,
          hazard_types,
          start_time,
          end_time,
        }}
        searchInput={searchInput}
        wrapperStyle={{
          height: width <= 390 ? '60vh' : 'calc(100vh - 11.5rem)',
          position: 'relative',
        }}
      />
    </div>
  );
}
