import Authentication from '@Views/Authentication';
import PublicPage from '@Views/PublicPage';
import PrivacyPolicy from '@Views/PrivacyPolicy';
import sidebarData, { ISidebar } from '@Constants/sidebarData';
import OverlayLayersMultiStepForm from '@Components/DataPage/OverlayLayers/MultiStepForm';
import UserDetailsForm from '@Components/DataPage/AssessmentData/UserDetailsForm';
import HDPRP from '@Components/PublicPage/HDPRP';

import { IRoute } from './types';

function getRoutes(data: ISidebar[]): ISidebar[] {
  return data.map(({ name, path, component, children }) => ({
    path,
    name,
    component,
    authenticated: true,
    children: children ? getRoutes(children) : undefined,
  }));
}

const appRoutes: IRoute[] = [
  {
    path: '/login',
    name: 'Login',
    component: Authentication,
    authenticated: false,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: Authentication,
    authenticated: false,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: Authentication,
    authenticated: false,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    authenticated: false,
  },
  {
    path: '/data/assessment-data/user-form/:siteId',
    name: 'User Details Form',
    component: UserDetailsForm,
    authenticated: true,
  },
  {
    path: '/data/assessment-data/hdprp/:siteId',
    name: 'User Details Form',
    component: HDPRP,
    authenticated: true,
  },
  {
    path: '/data/overlay-layer/form',
    name: 'Overlay Data Form',
    component: OverlayLayersMultiStepForm,
    authenticated: true,
  },
  {
    path: '/data/overlay-layer/:formId',
    name: 'Overlay Data Form',
    component: OverlayLayersMultiStepForm,
    authenticated: true,
  },
  ...getRoutes(sidebarData),
  {
    path: '/:tabname/:id?',
    name: 'Public Page',
    component: PublicPage,
    authenticated: false,
  },
  {
    path: '/',
    name: 'PublicPage',
    component: PublicPage,
    authenticated: false,
  },
];

export default appRoutes;
