export const ChartFills = [
  '#01A0A2',
  '#5BB6FF',
  '#AFDED6',
  '#0077E4',
  '#00947C',
  '#FF9525',
];

// export const hazardChartFills = [
//   '#B62B26',
//   '#C24539',
//   '#CD5B4C',
//   '#D77160',
//   '#E08574',
//   '#E99A8A',
//   '#F1AEA0',
// ];

export const hazardChartFills = [
  '#006866',
  '#007775',
  '#008582',
  '#009693',
  '#00A8A5',
  '#00BEBB',
  '#00CFCB',
];

export const BarChartFills = ['#01A0A2', '#01A0A2', '#01A0A2'];

export const StackedChartFills = ['#01A0A2', '#FF9525', '#BDBDBD'];

export const PieChartFills = ['#42B4A2', '#5BB6FF', '#AFDED6', '#0077E4'];

export const donutChartFills = [
  '#FF9525',
  '#01A0A2',
  '#FFE28A',
  '#5BB6FF',
  '#FFD85C',
  '#FF9525',
  '#AFDED6',
  '#0077E4',
];

export const HorizontalBarChartFills = [
  '#7ED0D2',
  '#7ED0D2',
  '#98DFE0',
  '#B1EDEE',
];

export const scatterChartFills = ['#FF9525', '#418FDE', '#5CB8B2', '#FF8042'];

export const TestData = [
  { name: 'Group A', label: 'Group A', value: 10 },
  { name: 'Group B', label: 'Group B', value: 30 },
  { name: 'Group C', label: 'Group C', value: 20 },
];
export const TestBarChartData = [
  { name: 'Group A', value: 10 },
  { name: 'Group B', value: 30 },
  { name: 'Group C', value: 20 },
];
