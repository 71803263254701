import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import {
  getTriggerMessageTemplate,
  getTriggerMessageTemplatePaginated,
  getTriggerMessageType,
} from '@Services/triggerMessage';

export const useGetTriggerMessageTypeQuery = (): Record<string, any> => {
  return useQuery({
    queryKey: ['trigger-message-type'],
    queryFn: getTriggerMessageType,
    select: (res: any) =>
      Object.entries(res.data)?.reduce(
        (obj, [key, value]) => ({
          ...obj,
          [key]: prepareDropdownOptions(value as Record<string, any>),
        }),
        {},
      ),
  });
};

export const useGetTriggerMessageTemplateQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): Record<string, any> => {
  return useQuery({
    queryKey: ['trigger-message-template'],
    queryFn: getTriggerMessageTemplate,
    select: (res: any) => res?.data,
    ...queryOptions,
  });
};

export const useGetTriggerMessageTemplatePaginatedQuery = (
  queryOptions?: Partial<UseInfiniteQueryOptions>,
): UseInfiniteQueryResult => {
  return useInfiniteQuery({
    queryKey: ['trigger-message-template-paginated'],
    queryFn: ({ pageParam }) => {
      return getTriggerMessageTemplatePaginated({
        ...(pageParam || {}),
      });
    },
    select: (res: any) => res,
    getNextPageParam: (lastPage: any) => {
      if (!lastPage?.data?.next) return false;
      const search = new URL(lastPage?.data?.next || '')?.search;
      const urlSearchParams = new URLSearchParams(search);
      return Object.fromEntries(urlSearchParams.entries());
    },
    ...queryOptions,
  });
};
