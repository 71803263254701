import DataTable from '@Components/common/DataTable';
import { getHydrologyData, getRtmData } from '@Services/realTimeMonitering';
import { useTypedSelector } from '@Store/hooks';

const rtmColumns = [
  {
    header: 'S .N',
    accessorKey: 'id',
    cell: (info: any) => info.row.index + 1,
    pin: 'left',
  },
  {
    header: 'Basin Name',
    accessorKey: 'basin',
  },
  {
    header: 'Station Name',
    accessorKey: 'station_name',
  },
  {
    header: 'District',
    accessorKey: 'district',
  },
  {
    header: 'Water Level (m)',
    accessorKey: 'water_level',
  },
  {
    header: 'Warning Level (m)',
    accessorKey: 'warning_level',
  },
  {
    header: 'Danger Level (m)',
    accessorKey: 'danger_level',
  },
  {
    header: 'Trend',
    accessorKey: 'name_of_tole',
  },
];

export default function RtmTable() {
  const activeTab = useTypedSelector(state => state.rtm.activeTab);

  return (
    <div>
      <DataTable
        columns={rtmColumns}
        queryKey={activeTab}
        queryFn={activeTab === 'river-watch' ? getRtmData : getHydrologyData}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        queryFnParams={{ name_of_stations: 'Mahakali at Darchula (Dattu)' }}
        // searchInput={searchInput}
        wrapperStyle={
          {
            // height:
            //   // eslint-disable-next-line no-nested-ternary
            //   width <= 390
            //     ? '60vh'
            //     : showInfoDialog
            //     ? 'calc(100vh - 15rem)'
            //     : 'calc(100vh - 11.5rem)',
          }
        }
      />
    </div>
  );
}
