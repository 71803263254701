import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { toggleInfoDialog, toggleModal } from '@Store/actions/common';
import { setUserState } from '@Store/actions/users';
import isEmpty from '@Utils/isEmpty';
import prepareFormData from '@Utils/prepareFormData';
import removeObjectKeys from '@Utils/removeObjectKeys';
import { postUser, updateUser } from '@Services/users';
import { useGetUniqueUserRoleQuery } from '@Api/users';
import usersFormSchema from '@Constants/users';
import getInputElement from '@Components/common/FormHelpers/getInputElements';
import { Button } from '@Components/RadixComponents/Button';
import { FormControl, Label } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import {
  handleAddUserTable,
  handleUserTablePatch,
} from '../../../queries/users';

type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

// https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
export const getDirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject | Record<string, any> => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      // @ts-ignore
      getDirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};

export default function UserForm() {
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();

  const modalContent = useTypedSelector(state => state.common.modalContent);
  const formType = modalContent === 'add-user' ? 'add' : 'edit';
  const selectedUser = useTypedSelector(state => state.users.selectedUser);

  const { data: dropdownOptions } = useGetUniqueUserRoleQuery();

  const handleModalClose = () => {
    dispatch(toggleModal());
    dispatch(
      setUserState({
        selectedUser: null,
      }),
    );
    dispatch(
      toggleInfoDialog(
        formType === 'add' ? 'add-user-success' : 'edit-user-success',
      ),
    );
  };

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    register,
    getValues,
    control,
    setValue,
    reset,
  } = useForm({ mode: 'all' });

  const formProps = { register, control, errors, setValue, getValues };

  // for edit user form
  useEffect(() => {
    if (!selectedUser || modalContent !== 'edit-user') return;
    // replace thumbnail to image key
    const data = {
      ...removeObjectKeys(selectedUser, ['image']),
      image: selectedUser.image ? [selectedUser.image] : null,
    };
    // reset form data before setting values
    reset();
    // set form state values
    Object.entries(data).forEach(([name, value]: any) => setValue(name, value));
  }, [selectedUser, modalContent, setValue, reset]);

  // update query cache on add/edit
  const { mutate: updateTableData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      formType === 'add'
        ? handleAddUserTable(queryClient, payloadData)
        : handleUserTablePatch(queryClient, payloadData),
  });

  const { mutate: postUserRequest, isLoading } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      formType === 'add'
        ? postUser(payloadData)
        : updateUser(selectedUser?.id, payloadData),
    onSuccess: res => {
      if (res) {
        updateTableData({ id: selectedUser?.id, ...res.data.details });
        handleModalClose();
      }
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const onSubmit = (values: Record<string, any>) => {
    const payload: Record<string, any> =
      formType === 'add' ? values : getDirtyValues(dirtyFields, values);

    const modifiedPayload: Record<string, any> = {
      ...payload,
      image: payload?.image?.length ? payload?.image : '',
    };
    if (modifiedPayload?.image?.[0]) {
      modifiedPayload.image = values.image[0]?.file || '';
    }
    if (
      'image' in payload &&
      !modifiedPayload?.image &&
      isEmpty(modifiedPayload?.image)
    ) {
      modifiedPayload.delete_image = true;
    }
    if (isEmpty(modifiedPayload)) {
      handleModalClose();
      return;
    }
    postUserRequest(prepareFormData(modifiedPayload));
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <FlexColumn className="flex gap-5 py-5 md:grid md:grid-cols-2">
        {usersFormSchema.map(data => {
          const { id, label, required, layout } = data;
          return (
            <FormControl
              key={id}
              className={`${layout === 'grid-cols-2' ? 'col-span-2' : ''}`}
            >
              <Label required={required}>{label}</Label>
              {getInputElement({ ...data }, formProps, dropdownOptions || {})}
              {formProps.errors[id] && (
                <ErrorMessage
                  className="mt-2"
                  /* @ts-ignore */
                  message={formProps.errors[id]?.message}
                />
              )}
            </FormControl>
          );
        })}
      </FlexColumn>
      <FlexRow className=" w-full justify-center">
        <Button variant="ghost" onClick={() => dispatch(toggleModal())}>
          Cancel
        </Button>
        <Button withLoader isLoading={isLoading} type="submit">
          {formType === 'add' ? 'Add' : 'Save'}
        </Button>
      </FlexRow>
    </form>
  );
}
