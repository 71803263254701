import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow, Grid } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';
import { setMapExportState } from '@Store/actions/mapExport';
import { useTypedDispatch } from '@Store/hooks';

export default function Toolbox() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const data = [
    {
      name: 'Analysis',
      icon: 'query_stats',
      onClick: () => {
        navigate('/#/');
      },
    },
    {
      name: 'Filter',
      icon: 'tune',
      onClick: () => {
        navigate('/#/');
      },
    },
    { name: 'Draw', icon: 'draw' },
    {
      name: 'Export',
      icon: 'download',
      iconClassName: 'material-icons',
      onClick: () => {
        dispatch(setMapExportState({ isExportOn: true }));
      },
    },
    // { name: 'Proximity', icon: 'motion_photos_on' },
  ];

  return (
    <FlexColumn className="px-3">
      <FlexRow className="h-11 items-center">
        <h5>Toolbox</h5>
      </FlexRow>

      <Grid>
        {data.map(
          ({
            name,
            icon,
            onClick,
            iconClassName = 'material-symbols-outlined',
          }) => (
            <FlexColumn
              key={name}
              className="cursor-pointer items-center justify-center p-[1.125rem]
              text-primary-600 hover:bg-primary-50"
              gap={1}
              onClick={onClick}
            >
              <Icon
                className={`icon-md text-primary-600 ${iconClassName}`}
                name={icon}
              />
              <p className="text-sm font-bold text-primary-600">{name}</p>
            </FlexColumn>
          ),
        )}
      </Grid>
    </FlexColumn>
  );
}
