import { Fragment, useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { FormControl, Label } from '@Components/common/FormUI';
import { FlexColumn, Grid } from '@Components/common/Layouts';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import getInputElement from '@Components/common/FormHelpers/getInputElements';
import MapSection from '@Components/DataPage/OverlayLayers/MultiStepForm/MapSection';
import InfoDialog from '@Components/common/InfoDialog';
import { getInfoDialogContent } from '@Constants/getInfoDialogContent';
import { toggleInfoDialog } from '@Store/actions/common';
import { infoDialogTimeoutValue } from '@Constants/index';
import {
  FormFieldType,
  UseFormPropsType,
  formSchemaType,
} from '@Components/common/FormHelpers/types';
import TableFormSection from '../TableFormSection';

interface IFormSectionProps {
  schema: formSchemaType;
  formProps: UseFormPropsType;
  activeStep: string;
}

function renderFormGroups(
  data: FormFieldType[],
  formProps: UseFormPropsType,
  dropdownOptions: Record<string, any>,
) {
  return (
    <Grid className="w-[65%] py-4" gap={7}>
      {data.map(child => (
        <FormControl key={child.id} className="w-10/12 ">
          <Label required={child.required}>{child.label}</Label>
          {getInputElement(child, { ...formProps }, dropdownOptions)}
          {formProps.errors[child.id] && (
            /* @ts-ignore */
            <ErrorMessage message={formProps.errors[child.id].message} />
          )}
        </FormControl>
      ))}
    </Grid>
  );
}

function FormSection({ schema, formProps, activeStep }: IFormSectionProps) {
  const dispatch = useTypedDispatch();
  const dropdownOptions = useTypedSelector(
    state => state.overlayLayer.dropdownOptions,
  );
  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);
  const infoDialogContent = useTypedSelector(
    state => state.common.infoDialogContent,
  );

  useEffect(() => {
    if (!getInfoDialogContent) return () => {};
    const timer = setTimeout(
      () => dispatch(toggleInfoDialog()),
      infoDialogTimeoutValue,
    );
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, infoDialogContent]);

  if (!schema) return <h3>No data</h3>;
  if (schema === 'map') return <MapSection activeStep={activeStep} />;
  if (schema === 'table')
    return (
      <TableFormSection
        inputType={activeStep === 'attributes alias' ? 'input' : 'checkbox'}
        label={
          activeStep === 'attributes alias'
            ? 'Select fields to make public'
            : 'Select public fields'
        }
      />
    );

  return (
    <FlexColumn
      gap={10}
      className="scrollbar h-[100vh-2rem] items-center overflow-auto pt-5"
    >
      {showInfoDialog && (
        <FlexColumn className="w-full md:w-[40%]">
          <InfoDialog
            status={getInfoDialogContent(infoDialogContent)?.status}
            title={getInfoDialogContent(infoDialogContent)?.title}
            description={getInfoDialogContent(infoDialogContent)?.description}
            onClose={() => dispatch(toggleInfoDialog())}
          />
        </FlexColumn>
      )}

      {schema.map(data => {
        const { id, label, required, children, layout, type } = data;

        // show schema in form group for grid layout
        if (children && layout === 'grid') {
          return (
            <Fragment key={id}>
              {renderFormGroups(children, formProps, dropdownOptions)}
            </Fragment>
          );
        }

        return (
          <FormControl
            key={id}
            className={`w-full ${
              type === 'switch' ? '!flex-row !justify-between' : ''
            }`}
          >
            <Label required={required}>{label}</Label>
            {getInputElement(data, formProps, dropdownOptions || {})}
            {formProps.errors[id] && (
              /* @ts-ignore */
              <ErrorMessage message={formProps.errors[id].message} />
            )}
          </FormControl>
        );
      })}
    </FlexColumn>
  );
}

export default FormSection;
