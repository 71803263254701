import type {
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { Input, Label } from '../FormUI';
import { FlexColumn } from '../Layouts';
import ErrorMessage from '../FormUI/ErrorMessage';

interface IArrayOfProps {
  id: string;
  required?: boolean;
  arrayData: Record<string, any>[] | undefined;
  className?: string;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
}

const ArrayofInputs = ({
  arrayData,
  register,
  className,
  id,
  required,
  errors,
}: IArrayOfProps) => {
  return (
    <FlexColumn className={`${className}`} gap={6}>
      {arrayData?.map((item: Record<string, any>) => {
        return (
          <div key={item.id} className="capitalize">
            <Label required={required}>{item.question}</Label>
            <Input
              className="w-full capitalize"
              placeholder={item.question}
              {...register(`${id}.${item.id}`, {
                required: required ? `${item.question} is Required` : false,
              })}
            />
            {/* @ts-ignore */}
            {errors[id]?.[item.id] ? (
              /* @ts-ignore */
              <ErrorMessage message={errors[id]?.[item.id]?.message} />
            ) : null}
          </div>
        );
      })}
    </FlexColumn>
  );
};

export default ArrayofInputs;
