import { all } from 'redux-saga/effects';
import publicPageWatcher from './publicPage';
import overlayLayersWatcher from './overlayLayers';
import visualizationWatcher from './visualization';

export default function* rootSaga() {
  yield all([
    publicPageWatcher(),
    overlayLayersWatcher(),
    visualizationWatcher(),
  ]);
}
