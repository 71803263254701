import { Flex, FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setTriggerMessageState } from '@Store/actions/triggerMessage';
import Skeleton from '@Components/RadixComponents/Skeleton';

export default function SidebarSection() {
  const dispatch = useTypedDispatch();

  const activeAlertTab = useTypedSelector(
    state => state.triggerMessage.activeAlertTab,
  );

  const triggerMessageTemplateData = useTypedSelector(
    state => state.triggerMessage.triggerMessageTemplateData,
  );

  const triggerMessageTemplateDataLoading = useTypedSelector(
    state => state.triggerMessage.triggerMessageTemplateDataLoading,
  );

  if (triggerMessageTemplateDataLoading) {
    return (
      <Flex gap={1} className="w-60">
        {Array.from({ length: 5 }, (_, index) => (
          <Skeleton key={index} className="h-10" />
        ))}
      </Flex>
    );
  }

  return (
    <FlexColumn gap={10} className="h-full w-60 bg-grey-100">
      <FlexColumn className="scollbar max-h-[calc(100vh-10rem)] overflow-y-auto">
        {triggerMessageTemplateData?.length ? (
          triggerMessageTemplateData?.map((template: Record<string, any>) => (
            <>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                className={`${
                  activeAlertTab === template.id
                    ? 'bg-primary-600 text-white hover:bg-primary-600'
                    : ''
                } cursor-pointer px-5 py-3.5 font-semibold capitalize text-grey-600 duration-200 hover:bg-primary-50`}
                key={template.id}
                onClick={() => {
                  dispatch(
                    setTriggerMessageState({
                      activeAlertTab: template.id,
                      templateType: 'send-notifcation',
                    }),
                  );
                }}
              >
                {template.alert_name}
              </div>
            </>
          ))
        ) : (
          <div className="pt-5 text-center text-base font-bold text-primary-800">
            No Alert
          </div>
        )}
      </FlexColumn>
      <FlexRow className="justify-center">
        <Button
          variant="outline"
          leftIcon="add"
          onClick={() => {
            dispatch(
              setTriggerMessageState({ templateType: 'add-alert-template' }),
            );
          }}
        >
          Add Alert Template
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
