import { useEffect, useRef } from 'react';

export default function usePrevious(value: any, defaultValue: any): any {
  const ref = useRef(null);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      ref.current = value;
    }, 300);

    return () => clearTimeout(timeOut);
  }, [value]);
  return ref.current || defaultValue;
}
