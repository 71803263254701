import { useNavigate } from 'react-router-dom';
import Switch from '@Components/RadixComponents/Switch';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { getOverlayLayers } from '@Services/common';
import { togglePromptDialog } from '@Store/actions/common';
import { setLayerState } from '@Store/actions/overlayLayer';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';

export default function TableSection() {
  const { width } = useWindowDimensions();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const searchInput = useTypedSelector(state => state.overlayLayer.searchInput);
  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);

  const overlayLayerColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'Display Name',
      accessorKey: 'display_name_en',
    },
    {
      header: 'Category',
      accessorKey: 'category',
    },
    {
      header: 'Data Group',
      accessorKey: 'data_group',
    },
    {
      header: 'Data Source',
      accessorKey: 'data_source',
    },
    {
      header: 'Count',
      accessorKey: 'count',
    },
    {
      header: 'Default Load',
      accessorKey: 'default_load',
      // eslint-disable-next-line react/no-unstable-nested-components,
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.default_load} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: 'Public View',
      accessorKey: 'is_public',
      // eslint-disable-next-line react/no-unstable-nested-components,
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.is_public} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: 'Download',
      accessorKey: 'is_downloadable',
      // eslint-disable-next-line react/no-unstable-nested-components,
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.is_downloadable} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => (
        <FlexRow className="cursor-pointer justify-around">
          <ToolTip name="info" message="More info" />

          <ToolTip
            name="edit"
            message="Edit"
            onClick={() => {
              navigate(`/data/overlay-layer/${row?.original?.id}/`);
            }}
          />
          <ToolTip
            name="delete"
            message="Delete"
            iconClassName="text-red-400 hover:text-red-500"
            onClick={() => {
              dispatch(togglePromptDialog('delete-layer'));
              dispatch(
                setLayerState({
                  selectedLayer: row.original,
                }),
              );
            }}
          />
        </FlexRow>
      ),
    },
  ];

  return (
    <DataTable
      columns={overlayLayerColumns}
      queryKey="overlay-layer-list"
      queryFn={getOverlayLayers}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      searchInput={searchInput}
      wrapperStyle={{
        height:
          // eslint-disable-next-line no-nested-ternary
          width <= 390
            ? '60vh'
            : showInfoDialog
            ? 'calc(100vh - 15rem)'
            : 'calc(100vh - 11.5rem)',
      }}
    />
  );
}
