import { api, authenticated } from '.';

export const getHouseHoldRegion = (params: any) =>
  authenticated(api).get('/dastaa/hazard/regions_house/', { params });

export const getVectorLayerCount = () =>
  authenticated(api).get('/dastaa/layers/vector-layer-count/');

export const getHHlevelVulnerability = (params: any) =>
  authenticated(api).get('/dastaa/risk_of_sites/metric-value', { params });

export const getHHLevelRisk = (params: any) =>
  authenticated(api).get('/dastaa/risk_of_sites/metric-value', {
    params,
  });

// map section
export const getSiteRiskPlot = () =>
  authenticated(api).get('/dastaa/risk_of_sites/site-risk-plot/');

// general Information
// export const getAgeOfRes = (params: any) =>
//   authenticated(api).get('/dastaa/hazard/age_of_resp/', { params });

export const getHouseOfLeadership = (params: any) =>
  authenticated(api).get('/dastaa/hazard/house_leadership/', { params });

export const getAvailableMobileSet = (params: any) =>
  authenticated(api).get('/dastaa/hazard/available_mobile_set/', { params });

export const getAvailableMobileNetwork = (params: any) =>
  authenticated(api).get('/dastaa/hazard/available_mobile_network/', {
    params,
  });

export const getEthnicityGroup = (params: any) =>
  authenticated(api).get('/dastaa/hazard/ethnicity_group/', {
    params,
  });

export const getSexGender = (params: any) =>
  authenticated(api).get('/dastaa/hazard/sex_gender/', {
    params,
  });

export const getDisabilityData = (params: any) =>
  authenticated(api).get('/dastaa/hazard/disability_data/', {
    params,
  });

// accessibility
export const getEarlyWarningInformation = (params: any) =>
  authenticated(api).get('/dastaa/hazard/bank_account/', {
    params,
  });

export const getLifeInsurance = (params: any) =>
  authenticated(api).get('/dastaa/hazard/life_insurance/', {
    params,
  });

export const getBankAccount = (params: any) =>
  authenticated(api).get('/dastaa/hazard/bank_account/', {
    params,
  });

export const getElectricity = (params: any) =>
  authenticated(api).get('/dastaa/hazard/electricity/', {
    params,
  });

export const getWeatherForecastInformation = (params: any) =>
  authenticated(api).get('/dastaa/hazard/weather_forecast/', {
    params,
  });

// livelihood Information
export const getFoodSufficiency = (params: any) =>
  authenticated(api).get('/dastaa/hazard/food_sufficiency/', {
    params,
  });

export const getNearbyRoadType = (params: any) =>
  authenticated(api).get('/dastaa/hazard/type_of_road/', {
    params,
  });

export const getCookingSources = (params: any) =>
  authenticated(api).get('/dastaa/hazard/cooking_source/', {
    params,
  });

export const getToiletType = (params: any) =>
  authenticated(api).get('/dastaa/hazard/type_of_toilet/', {
    params,
  });

export const getWaterResources = (params: any) =>
  authenticated(api).get('/dastaa/hazard/water_resources/', {
    params,
  });

export const getCheckToilet = (params: any) =>
  authenticated(api).get('/dastaa/hazard/check_toilet/', {
    params,
  });

// building information
export const getBuildingTopology = (params: any) =>
  authenticated(api).get('/dastaa/hazard/building_constructed_via/', {
    params,
  });

export const getNumberOfStoreys = (params: any) =>
  authenticated(api).get('/dastaa/hazard/story_of_building/', {
    params,
  });

export const getBuildingInsurance = (params: any) =>
  authenticated(api).get('/dastaa/hazard/building_insurance/', {
    params,
  });

// income source
export const getIncomeSource = (params: any) =>
  authenticated(api).get('/dastaa/hazard/source_of_income/', {
    params,
  });

// flood occurrence
// export const getFrequencyOfFloodOccurrence = (params: any) =>
//   authenticated(api).get('/dastaa/hazard/frequency_of_flood_occurrence/', {
//     params,
//   });

// proximity information
export const getNearbyRiver = (params: any) =>
  authenticated(api).get('/dastaa/hazard/nearest_school/', {
    params,
  });

export const getNearbySchool = (params: any) =>
  authenticated(api).get('/dastaa/hazard/nearest_school/', {
    params,
  });

export const getNearbyEvacuationCenter = (params: any) =>
  authenticated(api).get('/dastaa/hazard/nearest_school/', {
    params,
  });

export const getNearbyMarket = (params: any) =>
  authenticated(api).get('/dastaa/hazard/nearest_market/', {
    params,
  });

export const getNearbyealthServices = (params: any) =>
  authenticated(api).get('/dastaa/hazard/nearest_health_post/', {
    params,
  });

export const getOpenSpaceDistance = (params: any) =>
  authenticated(api).get('/dastaa/hazard/open_space_distance/', {
    params,
  });
