import { organizationListType } from '@Constants/publicPage';
import { setPublicPageState } from '@Store/actions/publicPage';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { Select } from '@Components/common/FormUI';
import { Flex, FlexRow } from '@Components/common/Layouts';
import TableSection from './TableSection';
import TabSection from './TabSection';

export default function KnowMoreInformation() {
  const dispatch = useTypedDispatch();

  const selectedOrganizationType = useTypedSelector(
    state => state.publicPage.selectedOrganizationType,
  );

  return (
    <Flex gap={5} className="py-5 xl:px-32">
      <FlexRow className="items-center justify-between">
        <h3>Know More Information</h3>
        <Select
          options={organizationListType}
          selectedOption={selectedOrganizationType}
          placeholder="Select"
          className="!w-60 rounded-lg border border-grey-400 bg-white"
          onChange={value => {
            dispatch(setPublicPageState({ selectedOrganizationType: value }));
          }}
        />
      </FlexRow>
      <TabSection />
      <TableSection />
    </Flex>
  );
}
