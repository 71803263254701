import { FlexColumn } from '@Components/common/Layouts';
import NotificationPlayground from './NotificationPlayground';

export default function Notification() {
  return (
    <FlexColumn className=" h-[100svh] w-full bg-[#FAFAFA] p-5">
      <h1 className="text-2xl font-semibold text-[#484848]">
        Incident Reports
      </h1>
      <div className="flex h-full flex-col gap-2">
        {/* <NotificationSidebar /> */}
        <NotificationPlayground />
      </div>
    </FlexColumn>
  );
}
