/* eslint-disable no-unsafe-optional-chaining */
import ChartHeader from '@Components/common/Charts/ChartHeader';
import ChartWrapper from '@Components/common/Charts/ChartWrapper';
import { hazardChartFills } from '@Components/common/Charts/constants';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { getHazardData } from '@Constants/publicPage';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import { useRef } from 'react';
import HazardRankingComponent from './HazardRankingComponent';

export default function HazardRankingStack() {
  const ref = useRef(null);
  const hazardData = getHazardData();
  return (
    <ChartWrapper className="animate-fade !p-3" componentRef={ref}>
      <FlexRow gap={3} className="items-center">
        <ChartHeader chartTitle="Hazard Ranking" downloadComponentRef={ref} />
        <ToolTip
          name="info"
          message="Ranking based on the risk of hazard and it's occurrence"
          side="top"
          iconClassName="mt-2 !text-lg"
        />
      </FlexRow>
      <div className="mt-2">
        {hazardData.map((data: Record<string, any>, index) => {
          if (data.id === 'others')
            return (
              <TooltipProvider key={data.id}>
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <div
                      className={`px-5 py-[19.5px] ${
                        index === 0 && 'rounded-t-2xl'
                      } ${index === hazardData.length - 1 && 'rounded-b-lg'}`}
                      style={{ background: hazardChartFills[index] }}
                    >
                      <HazardRankingComponent data={data} />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent className="!w-full shadow-lg">
                    {(data?.child as Record<string, any>[])?.map(nested => (
                      <div
                        className={`!w-full px-5 py-[19.5px] ${
                          index === 0 && 'rounded-t-2xl'
                        } ${
                          index === data?.child?.length - 1 && 'rounded-b-lg'
                        }`}
                        style={{ background: hazardChartFills[index] }}
                        key={nested.id}
                      >
                        <HazardRankingComponent data={nested} />
                      </div>
                    ))}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            );
          return (
            <div
              className={`px-5 py-[19.5px] ${index === 0 && 'rounded-t-2xl'} ${
                index === hazardData.length - 1 && 'rounded-b-lg'
              }`}
              style={{ background: hazardChartFills[index] }}
              key={data.id}
            >
              <HazardRankingComponent data={data} />
            </div>
          );
        })}
      </div>
    </ChartWrapper>
  );
}
