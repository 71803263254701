/**
 * Validates the password against the following requirements:
 * - Must be at least 8 characters long
 *
 * @param {string} password - The password to validate
 * @returns {boolean} True if the password meets the requirements, false otherwise
 */

export default function validatePassword(password: string) {
  // Check if the password has at least 8 characters
  if (password.length < 8) {
    return 'Password must be 8+ chars';
  }

  // Check if the password is all numbers
  if (/^[0-9]+$/.test(password)) {
    return 'Password must contain at least one letter';
  }

  // Check if the password is all letters
  if (/^[a-zA-Z]+$/.test(password)) {
    return 'Password must contain at least one number';
  }

  // Check if the password is a combination of letters and numbers
  if (/^[a-zA-Z0-9]+$/.test(password)) {
    return true;
  }

  // Check if the password is a combination of letters, numbers, and special characters
  if (/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?]+$/.test(password)) {
    return true;
  }

  // If none of the above conditions are met, return false
  return false;
}
