import { FlexColumn } from '@Components/common/Layouts';
import NoDataImage from '@Assets/images/no-data.png';

interface INoChartDataComponent {
  className?: string;
}

export default function NoChartDataComponent({
  className,
}: INoChartDataComponent) {
  return (
    <div
      className={`${className} flex h-full min-h-[150px] w-full items-center justify-center rounded-lg`}
    >
      <FlexColumn className="gap-3">
        <img src={NoDataImage} alt="No Data" height={100} width={100} />
        <h6>No Data Available</h6>
      </FlexColumn>
    </div>
  );
}
