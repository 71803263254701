import { GeojsonType } from '@Components/common/MapLibreComponents/types';
import OSM from '@Assets/images/layers/osm.png';
import Satellite from '@Assets/images/layers/satellite.png';
import Hybrid from '@Assets/images/layers/hybrid.png';
import Outdoor from '@Assets/images/layers/outdoor.png';
import None from '@Assets/images/layers/none.png';

export const orthoPhotoRasterId = process.env.ORTHOPHOTO_RASTER_ID || 1;

export const heatmapToPointTransitionZoomLevel = 18;

const lineColor = '#EC7FA0';
const measureColor = '#fbb64b';
const buildingColor = '#0077E4';
const roadColor = '#0077E4';
const gateColor = '#FF9525';
const regionBoundaryColor = '#B1EDEE';

export const startPointColor = '#FF9525';
export const endPointColor = '#e55e5e';
export const userLocationColor = '#0088F8';
export const weatherStationsColor = '#3AD7EE';
export const userInputColor = '#FF9525';
export const currentWeatherColor = '#FF9525';
export const currentWindyColor = '#0088F8';

export const highRiskColor = '#dc1010';
export const mediumRiskColor = '#e8b620';
export const lowRiskColor = '#13de13';

export const baseLayersData = [
  { id: 'osm', name: 'OSM', image: OSM },
  { id: 'satellite', name: 'Satellite', image: Satellite },
  { id: 'hybrid', name: 'Hybrid', image: Hybrid },
  { id: 'topo', name: 'Outdoor', image: Outdoor },
  { id: 'none', name: 'None', image: None },
];

export interface ILayerState {
  id: string | number;
  name: string;
  endPoint: string | null;
  checked: boolean;
  styles?: Record<string, any>;
  showLabel?: boolean;
  showOutline?: boolean;
  type?: string;
  dynamicStyle?: Record<string, any>[];
  isSubLayerActive?: boolean;
  dynamicSubLayerStyle?: Record<string, any>[];
  geojson?: GeojsonType;
  queryParam?: string;
  icon?: string | null;
  showPopupOnHover?: boolean;
}

export const weatherStationsStyle = {
  type: 'circle',
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['get', 'station_name'],
    'text-size': 13,
    'text-anchor': 'center',
  },
  paint: {
    'circle-radius': 8,
    'circle-color': weatherStationsColor,
  },
};

export const userLocationStyle = {
  type: 'circle',
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['get', 'station_name'],
    'text-size': 13,
    'text-anchor': 'center',
  },
  paint: {
    'circle-radius': 8,
    'circle-color': userLocationColor,
  },
};

export const currentWeatherDataStyles = {
  type: 'circle',
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['get', 'station_name'],
    'text-size': 13,
    'text-anchor': 'center',
  },
  paint: {
    'circle-radius': 8,
    'circle-color': currentWeatherColor,
  },
};

export const currentWindyStyles = {
  type: 'circle',
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['get', 'station_name'],
    'text-size': 13,
    'text-anchor': 'center',
  },
  paint: {
    'circle-radius': 8,
    'circle-color': currentWindyColor,
  },
};

export const sitesStyles = {
  type: 'fill-extrusion',
  paint: {
    'fill-extrusion-opacity': 1,
    // 'fill-extrusion-color': [
    //   'case',
    //   ['==', ['get', 'risk'], null],
    //   '#BDBDBD', // If risk value is null or not calculated set default sites color to '#BDBDBD'
    //   [
    //     'step',
    //     ['get', 'risk'],
    //     lowRiskColor, // If risk value < 0.24 set sites color to '#01A6A8'
    //     0.24,
    //     mediumRiskColor, // If risk value > 0.24 & <= 0.33  set sites color to '#01A6A8'
    //     0.33,
    //     highRiskColor, // If risk value > 0.33 set sites color to '#01A6A8'
    //   ],
    // ],
    'fill-extrusion-color': [
      'case',
      ['any', ['==', ['get', 'risk'], null], ['==', ['get', 'risk'], 0]],
      '#BDBDBD',
      ['<=', ['get', 'risk'], ['get', 'low_risk_threshold_risk']],
      lowRiskColor,
      ['<', ['get', 'risk'], ['get', 'high_risk_threshold_risk']],
      mediumRiskColor,
      highRiskColor,
    ],
    'fill-extrusion-height': [
      '*',
      ['max', 1, ['coalesce', ['get', 'floor'], 3]], // If 'floor' property is null or less than 1, use 1 as the default value
      3,
    ],
  },
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['step', ['zoom'], '', 17, ['get', 'name']],
    'text-size': 13,
    'text-anchor': 'center',
  },
};

export const riskStyles = {
  type: 'fill-extrusion',
  paint: {
    'fill-extrusion-opacity': 1,
    'fill-extrusion-color': [
      'case',
      ['any', ['==', ['get', 'risk'], null], ['==', ['get', 'risk'], 0]],
      '#BDBDBD',
      ['<=', ['get', 'risk'], ['get', 'low_risk_threshold_risk']],
      lowRiskColor,
      ['<', ['get', 'risk'], ['get', 'high_risk_threshold_risk']],
      mediumRiskColor,
      highRiskColor,
    ],
    'fill-extrusion-height': [
      '*',
      ['max', 1, ['coalesce', ['get', 'floor'], 3]], // If 'floor' property is null or less than 1, use 1 as the default value
      3,
    ],
  },
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['step', ['zoom'], '', 17, ['get', 'name']],
    'text-size': 13,
    'text-anchor': 'center',
  },
};

export const vulnerabilityStyles = {
  type: 'fill-extrusion',
  paint: {
    'fill-extrusion-opacity': 1,
    'fill-extrusion-color': [
      'case',
      [
        'any',
        ['==', ['get', 'vulnerability'], null],
        ['==', ['get', 'vulnerability'], 0],
      ],
      '#BDBDBD',
      [
        '<=',
        ['get', 'vulnerability'],
        ['get', 'low_risk_threshold_vulnerability'],
      ],
      lowRiskColor,
      [
        '<',
        ['get', 'vulnerability'],
        ['get', 'high_risk_threshold_vulnerability'],
      ],
      mediumRiskColor,
      highRiskColor,
    ],
    'fill-extrusion-height': [
      '*',
      ['max', 1, ['coalesce', ['get', 'floor'], 3]], // If 'floor' property is null or less than 1, use 1 as the default value
      3,
    ],
  },
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['step', ['zoom'], '', 17, ['get', 'name']],
    'text-size': 13,
    'text-anchor': 'center',
  },
};

export const currentWeatherStyles = {
  type: 'fill',
  paint: {
    'fill-opacity': ['interpolate', ['linear'], ['zoom'], 4, 0, 5, 0.5, 10, 1],
    'fill-color': [
      'step',
      ['to-number', ['get', 'current_temp_c']],
      '#314755', // If current_temp_c < -5 set color to '#314755'
      -5,
      '#2f5b73', // If risk value > 5 & <= 0 set color to '#2f5b73'
      0,
      '#2d6581',
      5,
      '#2b789f',
      10,
      '#2796cb',
      15,
      '#26a0da',
      20,
      '#f19f13',
      25,
      '#ec840c',
      30,
      '#ea7708',
      35,
      '#e86904',
      40,
      '#940101',
    ],
    'fill-outline-color': '#BDBDBD',
  },
  layout: {},
};

export const currentForecastStyles = {
  type: 'fill',
  paint: {
    'fill-opacity': ['interpolate', ['linear'], ['zoom'], 4, 0, 5, 0.5, 10, 1],
    'fill-color': [
      'step',
      ['to-number', ['get', 'current_temp_c']],
      '#314755', // If current_temp_c < -5 set color to '#314755'
      -5,
      '#2f5b73', // If risk value > 5 & <= 0 set color to '#2f5b73'
      0,
      '#2d6581',
      5,
      '#2b789f',
      10,
      '#2796cb',
      15,
      '#26a0da',
      20,
      '#f19f13',
      25,
      '#ec840c',
      30,
      '#ea7708',
      35,
      '#e86904',
      40,
      '#940101',
    ],
    'fill-outline-color': '#BDBDBD',
  },
  layout: {},
};

export const windyStyles = {
  type: 'fill',
  paint: {
    'fill-opacity': ['interpolate', ['linear'], ['zoom'], 4, 0, 5, 0.5, 10, 1],
    'fill-color': [
      'step',
      ['to-number', ['get', 'wind_u']],
      '#314755', // If current_temp_c < -5 set color to '#314755'
      -5,
      '#2f5b73', // If risk value > 5 & <= 0 set color to '#2f5b73'
      0,
      '#2d6581',
      5,
      '#2b789f',
      10,
      '#2796cb',
      15,
      '#26a0da',
      20,
      '#f19f13',
      25,
      '#ec840c',
      30,
      '#ea7708',
      35,
      '#e86904',
      40,
      '#940101',
    ],
    'fill-outline-color': '#BDBDBD',
  },
  layout: {},
};

export const regionBoundaryStyles = {
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': regionBoundaryColor,
    'fill-opacity': 0.2,
  },
};

const { SITE_NAME } = process.env;

const floodhazardLayers: Record<string, string> = {
  'Dastaa Bheemdatta': 'Dastaa:FD_1_in_200_Bhimdhatta',
  'Dastaa Parshuram': 'Dastaa:Parshuram_Flood_Depth',
  'Dastaa Dodahra': 'Dastaa:FD_1_in_200_Dhodhara',
};

const getFloodhazardLayers = () =>
  SITE_NAME != null
    ? floodhazardLayers[SITE_NAME]
    : 'Dastaa:FD_1_in_200_Bhimdhatta';

export const defaultLayersData = [
  {
    id: 'baseline_data',
    category: 'baseline_data',
    layers: [
      {
        id: 'sites',
        name: 'Survey Completed Sites',
        endPoint: 'dastaa/sites_tiles/site_vectortile',
        checked: true,
        styles: sitesStyles,
        showLabel: true,
        showOutline: true,
        type: 'vectortile',
      },
      // {
      //   id: 'overall-sites',
      //   name: 'Overall Sites',
      //   endPoint: 'dastaa/sites_tiles/site_vectortile',
      //   checked: true,
      //   styles: sitesStyles,
      //   showLabel: true,
      //   showOutline: true,
      //   type: 'vectortile',
      // },
      {
        id: 'region-boundary',
        name: 'Region Boundary',
        endPoint: null,
        checked: true,
        styles: regionBoundaryStyles,
        showLabel: false,
        showOutline: true,
        type: 'geojson',
      },
    ],
  },
  {
    id: 'Flood Hazard Maps',
    category: 'Flood Hazard Maps',
    layers: [
      {
        id: 'flood_hazard',
        name: 'Flood Hazard',
        endPoint: `layers=${getFloodhazardLayers()}`,
        checked: false,
        type: 'raster',
      },
      // {
      //   id: 'flood_hazard_100_years',
      //   name: 'Flood Hazard 100 Years',
      //   endPoint: 'layers=Bheemdatta:flood_model_Bhimdatta_rp',
      //   checked: false,
      //   type: 'raster',
      // },
      // {
      //   id: 'flood_hazard_1000_years',
      //   name: 'Flood Hazard 1000 Years',
      //   endPoint: 'layers=FD_1in1000',
      //   checked: false,
      //   type: 'raster',
      // },
    ],
  },
];

export const defaultLayersDataPublicPage = [
  {
    category: 'baseline_data',
    id: 'baseline_data',
    layers: [
      // {
      //   id: 'overall-sites',
      //   name: 'Overall Sites',
      //   endPoint: 'dastaa/sites_tiles/site_vectortile',
      //   checked: true,
      //   styles: {
      //     ...sitesStyles,
      //     paint: {
      //       ...sitesStyles.paint,
      //       'fill-extrusion-height': 0,
      //     },
      //   },
      //   showLabel: true,
      //   showOutline: true,
      //   type: 'vectortile',
      // },
      {
        id: 'sites',
        name: 'Survey Completed Sites',
        endPoint: 'dastaa/sites_tiles/site_vectortile',
        checked: true,
        styles: {
          ...sitesStyles,
          paint: {
            'fill-extrusion-color': '#0FABDB',
            'fill-extrusion-height': 0,
          },
        },
        showLabel: false,
        showOutline: true,
        type: 'vectortile',
      },
      {
        id: 'region-boundary',
        name: 'Region Boundary',
        endPoint: null,
        checked: true,
        styles: regionBoundaryStyles,
        showLabel: false,
        type: 'geojson',
        showOutline: true,
        showPopupOnHover: false,
      },
    ],
  },
  {
    category: 'weather',
    id: 'weather',
    layers: [
      {
        id: 'weatherapi_current',
        name: 'Weatherapi Current',
        endPoint: null,
        checked: false,
        styles: currentWeatherDataStyles,
        showLabel: false,
        type: 'geojson',
        showOutline: false,
        showPopupOnHover: true,
      },
      {
        id: 'windy',
        name: 'Windy',
        endPoint: null,
        checked: false,
        styles: currentWindyStyles,
        showLabel: false,
        type: 'geojson',
        showOutline: false,
        showPopupOnHover: true,
      },

      // {
      //   id: 'weatherapi_current',
      //   name: 'Weatherapi Current',
      //   endPoint: 'weather/site_vectortiles_weather_api',
      //   checked: false,
      //   styles: currentWeatherStyles,
      //   showLabel: false,
      //   showOutline: false,
      //   type: 'hwcw',
      //   queryParam: 'source=weatherapi_current',
      // },
      // {
      //   id: 'weatherapi_forecast',
      //   name: 'Weatherapi Forecast',
      //   endPoint: 'weather/site_vectortiles_weather_api',
      //   checked: false,
      //   styles: currentForecastStyles,
      //   showLabel: false,
      //   showOutline: false,
      //   type: 'hwcw',
      //   queryParam: 'source=weatherapi_forecast',
      // },
      // {
      //   id: 'windy',
      //   name: 'Windy',
      //   endPoint: 'weather/site_vectortiles_windy_api',
      //   checked: false,
      //   styles: windyStyles,
      //   showLabel: false,
      //   showOutline: false,
      //   type: 'hwcw',
      //   queryParam: 'source=windy',
      // },
    ],
  },
];

export const heatMapRiskStyle = {
  type: 'heatmap',
  paint: {
    // Increase the heatmap weight based on frequency and property risk
    'heatmap-weight': ['interpolate', ['linear'], ['get', 'risk'], 0, 0, 1, 1],
    // Increase the heatmap color weight weight by zoom level
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(33,102,172,0)',
      0.2,
      'rgb(103,169,207)',
      0.4,
      'rgb(209,229,240)',
      0.6,
      'rgb(253,219,199)',
      0.8,
      'rgb(239,138,98)',
      1,
      'rgb(178,24,43)',
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      1,
      heatmapToPointTransitionZoomLevel,
      15,
    ],
    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      4,
      0,
      6,
      0.8,
      12,
      1,
      heatmapToPointTransitionZoomLevel,
      0,
    ],
  },
};

export const heatMapRiskCircleStyle = {
  type: 'circle',
  paint: {
    // Size circle radius by earthquake magnitude and zoom level
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      heatmapToPointTransitionZoomLevel - 3,
      ['interpolate', ['linear'], ['get', 'risk'], 1, 5],
      heatmapToPointTransitionZoomLevel,
      ['interpolate', ['linear'], ['get', 'risk'], 1, 10],
    ],
    // Color circle by earthquake magnitude
    'circle-color': [
      'interpolate',
      ['linear'],
      ['get', 'risk'],
      0,
      lowRiskColor,
      0.24,
      mediumRiskColor,
      0.36,
      highRiskColor,
    ],
    'circle-stroke-color': '#f4f4f4',
    'circle-stroke-width': 1,
    // Transition from heatmap to circle layer by zoom level
    'circle-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      heatmapToPointTransitionZoomLevel - 3,
      0,
      heatmapToPointTransitionZoomLevel - 2,
      1,
    ],
  },
};

export const heatMapVulnerabilityStyle = {
  type: 'heatmap',
  paint: {
    // Increase the heatmap weight based on frequency and property risk
    'heatmap-weight': [
      'interpolate',
      ['linear'],
      ['get', 'vulnerability'],
      0,
      0,
      1,
      1,
    ],
    // Increase the heatmap color weight weight by zoom level
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(33,102,172,0)',
      0.2,
      'rgb(103,169,207)',
      0.4,
      'rgb(209,229,240)',
      0.6,
      'rgb(253,219,199)',
      0.8,
      'rgb(239,138,98)',
      1,
      'rgb(178,24,43)',
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      1,
      heatmapToPointTransitionZoomLevel,
      15,
    ],
    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      8,
      0,
      10,
      0.5,
      12,
      1,
      heatmapToPointTransitionZoomLevel,
      0,
    ],
  },
};

export const heatMapVulnerabilityCircleStyle = {
  type: 'circle',
  paint: {
    // Size circle radius by earthquake magnitude and zoom level
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      heatmapToPointTransitionZoomLevel - 3,
      ['interpolate', ['linear'], ['get', 'vulnerability'], 1, 5],
      heatmapToPointTransitionZoomLevel,
      ['interpolate', ['linear'], ['get', 'vulnerability'], 1, 10],
    ],
    // Color circle by earthquake magnitude
    'circle-color': [
      'interpolate',
      ['linear'],
      ['get', 'vulnerability'],
      0,
      lowRiskColor,
      0.24,
      mediumRiskColor,
      0.36,
      highRiskColor,
    ],
    'circle-stroke-color': '#f4f4f4',
    'circle-stroke-width': 1,
    // Transition from heatmap to circle layer by zoom level
    'circle-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      heatmapToPointTransitionZoomLevel - 3,
      0,
      heatmapToPointTransitionZoomLevel - 2,
      1,
    ],
  },
};

export const drawnBuildingStyle = {
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': buildingColor,
    'fill-opacity': 1,
  },
};

export const drawnRoadStyle = {
  type: 'line',
  layout: {},
  paint: {
    'line-color': roadColor,
    'line-width': 3,
  },
};

export const drawStyles = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': lineColor,
      'fill-outline-color': lineColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': lineColor,
      'fill-outline-color': lineColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': lineColor,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-dasharray': [0.2, 2],
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-dasharray': [0.2, 2],
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': lineColor,
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
];
export const measureStyles = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': measureColor,
      'fill-outline-color': measureColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': measureColor,
      'fill-outline-color': measureColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': measureColor,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-dasharray': [0.2, 2],
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-dasharray': [0.2, 2],
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': measureColor,
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
];
