import {
  IForgotPasswordParams,
  ILoginParams,
} from '@Components/Authentication/Login/types';
import { api, authenticatedUserAPI, userAPI } from '.';

export const setCsrf = () => userAPI.get('/set-csrf/');

export const signInUser = (data: ILoginParams) => userAPI.post('/login/', data);

export const forgotPassword = (data: IForgotPasswordParams) =>
  userAPI.post('forgot-password/', data);

export const logoutUser = () => authenticatedUserAPI(userAPI).post('/logout/');

export const checkLogin = () =>
  authenticatedUserAPI(userAPI).get('/check-login/');

export const getSignUpFormCountryList = () =>
  api.get('user/select-fields/select_fields_country/');

export const getSignUpFormDistrictList = (data: any) =>
  api.post('user/select-fields/select_district_single_country/', data);

export const getSignUpFormMunicipalityList = (data: any) =>
  api.post('user/select-fields/select_municipality_single_district/', data);

export const getSignUpFormSecurityQuestion = () =>
  api.get('user/select-fields/security_question/');

export const signUp = (data: any) =>
  api.post('user/sign-up-v3/', data, {
    headers: { 'Content-Type': 'application/json' },
  });

export const checkUserBeforeRegister = (data: any) =>
  api.post('user/check-user-before-register', data, {
    headers: { 'Content-Type': 'application/json' },
  });

export const verifySignUpUserToken = (data: any) =>
  api.post('user/verify-token/', data, {
    headers: { 'Content-Type': 'application/json' },
  });

export const chnageNewPassword = (params: any) =>
  userAPI.post('new-change-password-v3', params);
