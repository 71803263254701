import { layoutProperties } from '@Constants/overlayLayers';
import { ILayer, IZoomLevel } from '@Store/slices/overlayLayers';
import chroma from 'chroma-js';

export const getAllStyles = (allLayers: any[]): Record<string, any> => {
  let styles: Record<string, any> = {};
  allLayers?.forEach((element: Record<string, any>) => {
    if (element.type === 'symbol') {
      styles = { ...styles, ...element.layout, ...element.paint };
      return;
    }
    styles = { ...styles, ...element.paint };
  });
  return styles;
};

export const getValueByZoomLevel = (expression: any[], zoom: number) => {
  let value;
  for (let i = 3; i <= expression.length; i += 2) {
    if (zoom === expression[i]) {
      value = expression[i + 1];
      break;
    }
  }
  return value;
};

export const replaceValueByZoomLevel = (
  currentExpression: any[],
  zoom: number,
  value: string | number,
) => {
  const newExpression = [...currentExpression];
  for (let i = 3; i <= newExpression.length; i += 2) {
    if (zoom === newExpression[i]) {
      newExpression.splice(i + 1, 1, value);
      break;
    }
  }
  return newExpression;
};

export const replaceZoomLevel = (
  currentExpression: any[],
  currentZoomLevel: number,
  newZoomLevel: number,
) => {
  const newExpression = [...currentExpression];
  for (let i = 3; i <= newExpression.length; i += 2) {
    if (currentZoomLevel === newExpression[i]) {
      newExpression.splice(i, 1, newZoomLevel);
      break;
    }
  }
  return newExpression;
};

export const getZoomBasedValues = (
  zoom: number,
  styleJson: Record<string, any> = {},
): Record<string, any> => {
  if (!styleJson || Object.keys(styleJson).length === 0 || zoom < 0) return {};
  const styles = {};
  Object.entries(styleJson).forEach(([key, value]) => {
    Object.assign(styles, { [key]: getValueByZoomLevel(value, zoom) });
  });
  return styles;
};

const getValueByKeyFromCaseExpression = (expression: any[], key: any) => {
  if (!key || !expression) return expression;
  let value;
  for (let i = 1; i < expression.length; i += 2) {
    if (expression[i].includes(key)) {
      value = expression[i + 1];
      break;
    }
  }
  return value;
};

export const getZoomBasedSubLayerValues = (
  zoom: number,
  subLayerKey: string | number,
  styleJson: Record<string, any> = {},
) => {
  if (
    !styleJson ||
    Object.keys(styleJson).length === 0 ||
    zoom < 0 ||
    !subLayerKey
  )
    return {};
  const styles = {};
  Object.entries(styleJson).forEach(([key, value]) => {
    if (Array.isArray(getValueByZoomLevel(value, zoom))) {
      Object.assign(styles, {
        [key]: getValueByKeyFromCaseExpression(
          getValueByZoomLevel(value, zoom),
          subLayerKey,
        ),
      });
    } else {
      Object.assign(styles, { [key]: getValueByZoomLevel(value, zoom) });
    }
  });
  return styles;
};

const replaceValueByKeyFromCaseExpression = (
  currentExpression: any[],
  key: any,
  newValue: string | number,
) => {
  const newExpression = [...currentExpression];
  for (let i = 1; i < currentExpression.length; i += 2) {
    if (currentExpression[i].includes(key)) {
      newExpression.splice(i + 1, 1, newValue);
      break;
    }
  }
  return newExpression;
};
export const replaceValueByZoomLevelAndSubLayerKey = (
  currentExpression: any[],
  zoom: number,
  value: string | number,
  subLayerKey: string | number,
) => {
  if (!subLayerKey || !value) return currentExpression;
  const newExpression = [...currentExpression];
  for (let i = 3; i <= newExpression.length; i += 2) {
    if (zoom === newExpression[i]) {
      newExpression.splice(
        i + 1,
        1,
        replaceValueByKeyFromCaseExpression(
          currentExpression[i + 1],
          subLayerKey,
          value,
        ),
      );
      break;
    }
  }
  return newExpression;
};

export const getCaseExpression = (
  defaultValue: any,
  ids: any[],
  featureKey: string,
  value: any,
  styleKey?: string,
) => {
  const caseExpression: any[] = ['case'];
  ids.forEach(id => {
    caseExpression.push(['==', ['get', featureKey], id]);
    caseExpression.push(
      styleKey === 'fill-color' ? chroma.random().hex() : value,
    );
  });
  caseExpression.push(defaultValue);

  return caseExpression;
};

// below are new updated function*****************************************
export const getUpdatedZoomLevelZoomFieldUpdate = (
  isSubLayer: boolean,
  zoomList: Record<'layer' | 'subLayer', IZoomLevel[]>,
  currentZoomLevel: number,
  newZoomLevel: number,
): Record<'layer' | 'subLayer', IZoomLevel[]> => {
  const newZooms: IZoomLevel[] = isSubLayer
    ? zoomList.subLayer
    : zoomList.layer;

  const updatedZooms: IZoomLevel[] = newZooms.map((zoomLevel: IZoomLevel) => {
    return zoomLevel.min === currentZoomLevel
      ? { ...zoomLevel, min: newZoomLevel }
      : zoomLevel;
  });
  return isSubLayer
    ? { ...zoomList, subLayer: updatedZooms }
    : { ...zoomList, layer: updatedZooms };
};

export const addZoomLevelOnLayerListWithDefaultStyle = (
  layerList: ILayer[],
  zoomLevel: number,
  layerType: 'label' | 'layer',
) => {
  const newLayerList = layerList.map(layer => {
    const { paint, layout } = layer;
    const newPaintProperty = {};
    const newLayoutProperty = {};
    if (layer.type === 'symbol' && layerType === 'label') {
      Object.entries(layout).map(
        ([key, values]) =>
          layoutProperties.includes(key) &&
          Object.assign(newLayoutProperty, {
            [key]: [...values, zoomLevel, values[values.length - 1]],
          }),
      );
      Object.entries(paint).map(([key, values]) =>
        Object.assign(newPaintProperty, {
          [key]: [...values, zoomLevel, values[values.length - 1]],
        }),
      );
      return {
        ...layer,
        layout: { ...layout, ...newLayoutProperty },
        paint: { ...paint, ...newPaintProperty },
      };
    }
    if (layerType !== 'label' && layer.type !== 'symbol') {
      Object.entries(paint).map(([key, values]) =>
        Object.assign(newPaintProperty, {
          [key]: [...values, zoomLevel, values[values.length - 1]],
        }),
      );
      return {
        ...layer,
        paint: { ...paint, ...newPaintProperty },
      };
    }
    return layer;
  });

  return newLayerList;
};

const removeZoomLevelWithValue = (expression: any[], zoomLevel: number) => {
  const newExpression = [...expression];
  for (let i = 3; i <= newExpression.length; i += 2) {
    if (expression[i] === zoomLevel) {
      newExpression.splice(i, 2);
      break;
    }
  }
  return newExpression;
};

export const removeZoomLevelFromLayerStyle = (
  layerList: ILayer[],
  zoomLevel: number,
  layerType: 'label' | 'layer',
) => {
  const newLayerList = layerList.map(layer => {
    const { paint, layout } = layer;
    const newPaintProperty = {};
    const newLayoutProperty = {};
    if (layer.type === 'symbol' && layerType === 'label') {
      Object.entries(layout).map(
        ([key, values]) =>
          layoutProperties.includes(key) &&
          Object.assign(newLayoutProperty, {
            [key]: removeZoomLevelWithValue(values, zoomLevel),
          }),
      );
      Object.entries(paint).map(([key, values]) =>
        Object.assign(newPaintProperty, {
          [key]: removeZoomLevelWithValue(values, zoomLevel),
        }),
      );
      return {
        ...layer,
        layout: { ...layout, ...newLayoutProperty },
        paint: { ...paint, ...newPaintProperty },
      };
    }
    if (layerType !== 'label' && layer.type !== 'symbol') {
      Object.entries(paint).map(([key, values]) =>
        Object.assign(newPaintProperty, {
          [key]: removeZoomLevelWithValue(values, zoomLevel),
        }),
      );
      return {
        ...layer,
        paint: { ...paint, ...newPaintProperty },
      };
    }
    return layer;
  });

  return newLayerList;
};

export const updateZoomLevelByStyleType = (
  layerList: ILayer[],
  layerType: 'label' | 'layer',
  newZoomLevel: number,
  currentZoomLevel: number,
) => {
  const newStyle = layerList.map((layer: ILayer) => {
    const { paint, layout } = layer;
    const newPaintProperty = {};
    const newLayoutProperty = {};
    if (layer.type === 'symbol' && layerType === 'label') {
      Object.entries(layout).map(
        ([key, values]) =>
          layoutProperties.includes(key) &&
          Object.assign(newLayoutProperty, {
            [key]: replaceZoomLevel(values, currentZoomLevel, newZoomLevel),
          }),
      );
      Object.entries(paint).map(([key, values]) =>
        Object.assign(newPaintProperty, {
          [key]: replaceZoomLevel(values, currentZoomLevel, newZoomLevel),
        }),
      );
      return {
        ...layer,
        layout: { ...layout, ...newLayoutProperty },
        paint: { ...paint, ...newPaintProperty },
      };
    }
    if (layerType !== 'label' && layer.type !== 'symbol') {
      Object.entries(paint).map(([key, values]) =>
        Object.assign(newPaintProperty, {
          [key]: replaceZoomLevel(values, currentZoomLevel, newZoomLevel),
        }),
      );
      return {
        ...layer,
        paint: { ...paint, ...newPaintProperty },
      };
    }
    return layer;
  });
  return newStyle;
};

export const getUpdatedZoomLevelOnAddNewLevel = (
  isSubLayer: boolean,
  zoomList: Record<'layer' | 'subLayer', IZoomLevel[]>,
  newZoomLevel: number,
) => {
  const newZooms: IZoomLevel[] = isSubLayer
    ? zoomList.subLayer
    : zoomList.layer;
  const updatedZooms: IZoomLevel[] = [
    ...newZooms,
    { id: newZooms.length + 1, min: newZoomLevel },
  ];

  return isSubLayer
    ? { ...zoomList, subLayer: updatedZooms }
    : { ...zoomList, layer: updatedZooms };
};

export const getUpdatedZoomLevelOnDeleteRange = (
  isSubLayer: boolean,
  zoomList: Record<'layer' | 'subLayer', IZoomLevel[]>,
  removedZoomLevel: number,
) => {
  const tempZooms: IZoomLevel[] = isSubLayer
    ? zoomList.subLayer
    : zoomList.layer;
  const updatedZooms: IZoomLevel[] = tempZooms.filter(
    zoom => zoom.min !== removedZoomLevel,
  );
  return isSubLayer
    ? { ...zoomList, subLayer: updatedZooms }
    : { ...zoomList, layer: updatedZooms };
};
