/* eslint-disable no-param-reassign */
import type { QueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

export async function handleAddUserTable(
  queryClient: QueryClient,
  payloadData: Record<string, any>,
) {
  const queryResults = queryClient.getQueriesData(['user-list']);
  const [keys, previousData] = queryResults[0];
  if (!previousData) return;
  await queryClient.cancelQueries({ queryKey: keys });
  const updatedData = produce(previousData, (draft: Record<string, any>) => {
    const results = draft?.data?.results;
    results.unshift(payloadData);
  });
  queryClient.setQueryData(keys, updatedData);
}

export async function handleUserTablePatch(
  queryClient: QueryClient,
  payloadData: Record<string, any>,
) {
  const queryResults = queryClient.getQueriesData(['user-list']);
  const [keys, previousData] = queryResults[0];
  if (!previousData) return;
  await queryClient.cancelQueries({ queryKey: keys });
  const updatedData = produce(previousData, (draft: Record<string, any>) => {
    const results = draft?.data?.results;
    const index = results.findIndex((item: any) => item.id === payloadData.id);
    results[index] = {
      ...results[index],
      ...payloadData,
    };
  });
  queryClient.setQueryData(keys, updatedData);
}
