/* eslint-disable no-unused-vars */
import { useLocation } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { handle3DToggle } from '@Store/actions/visualization';
import { FlexColumn, FlexRow, Grid } from '@Components/common/Layouts';
import DroneOrtho from '@Assets/images/layers/drone_ortho.png';
import OSM from '@Assets/images/layers/osm.png';
import Satellite from '@Assets/images/layers/satellite.png';
import Hybrid from '@Assets/images/layers/hybrid.png';
import Outdoor from '@Assets/images/layers/outdoor.png';
import None from '@Assets/images/layers/none.png';
import Switch from '@Components/RadixComponents/Switch';

interface IBaseLayersProps {
  activeBaseLayer: string;
  onChange: (id: string) => void;
}

const baseLayersData = [
  { id: 'droneOrtho', name: 'Drone Ortho', image: DroneOrtho },
  { id: 'osm', name: 'OSM', image: OSM },
  { id: 'satellite', name: 'Satellite', image: Satellite },
  { id: 'hybrid', name: 'Hybrid', image: Hybrid },
  { id: 'topo', name: 'Outdoor', image: Outdoor },
  { id: 'none', name: 'None', image: None },
];

const activeClassName = 'border-2 border-primary-600';

export default function BaseLayers({
  activeBaseLayer,
  onChange,
}: IBaseLayersProps) {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();

  const is3DToggled = useTypedSelector(
    state => state.visualization.is3DToggled,
  );

  return (
    <FlexColumn className="px-3">
      <FlexRow className="h-11 items-center justify-between">
        <h5>Base Layers</h5>
        {pathname.includes('visualization') && (
          <FlexRow gap={2} className="items-center">
            <p className="btn-text">3D</p>
            <Switch
              checked={is3DToggled}
              onClick={() => dispatch(handle3DToggle())}
            />
          </FlexRow>
        )}
      </FlexRow>

      <Grid cols={2} className="w-full">
        {baseLayersData.map(({ id, name, image }) => {
          const isActive = id === activeBaseLayer;
          return (
            <FlexColumn
              key={name}
              className="cursor-pointer items-center justify-center px-3 py-2"
              gap={1}
              onClick={() => onChange(id)}
            >
              <img
                className={`scale-95 object-none transition 
                duration-500 hover:scale-110 hover:animate-pulse ${
                  isActive ? activeClassName : ''
                }`}
                src={image}
                alt={name}
              />
              <p
                className={`text-body-md ${isActive ? 'text-primary-600' : ''}`}
              >
                {name}
              </p>
            </FlexColumn>
          );
        })}
      </Grid>
    </FlexColumn>
  );
}
