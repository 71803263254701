/* eslint-disable camelcase */
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormSection from '@Components/DataPage/OverlayLayers/MultiStepForm/FormSection';
import ProgressStep from '@Components/DataPage/OverlayLayers/ProgressStep';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';
import { Flex, FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  overlayDataFormIndex,
  overlayFormSchema,
  overlayFormModal,
} from '@Constants/overlayLayers';
import {
  setActiveIndex,
  setDataManagementFormState,
  setFormState,
} from '@Store/actions/dataManagementForm';
import { FormStateType, initialState } from '@Store/slices/overlayLayers';
import {
  getVectorLayerRequest,
  postVectorLayerRequest,
  setDropdownOptionsState,
} from '@Store/actions/overlayLayer';
import checkIfLoading from '@Utils/checkIfLoading';
import { useMutation } from '@tanstack/react-query';
import { updateVectorLayer } from '@Services/overlayLayers';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { toggleInfoDialog } from '@Store/actions/common';
import { useGetVectoLayerCategoryQuery } from '@Api/overlayLayers';

function MultiStepForm() {
  const { formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const activeIndex = useTypedSelector(
    state => state.dataManagementForm.activeIndex,
  );
  const stepIndex = useTypedSelector(
    state => state.dataManagementForm.stepIndex,
  );
  const formState = useTypedSelector(
    state => state.dataManagementForm.formState,
  );
  const layerDetails = useTypedSelector(
    state => state.overlayLayer.layerDetails,
  );
  const isLayerPosting = useTypedSelector(state =>
    checkIfLoading(state, [postVectorLayerRequest.type]),
  );
  const isLayerDataFetching = useTypedSelector(state =>
    checkIfLoading(state, [getVectorLayerRequest.type]),
  );

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    reset,
  } = useForm({ mode: 'all' });

  useEffect(() => {
    const isDataFetched = Object.values(formState).some(val => !!val);
    if (!isDataFetched) return;
    reset(formState);
  }, [formState, reset]);

  const formSchema = overlayFormSchema;

  const formMode: 'add' | 'edit' = !formId ? 'add' : 'edit';
  const formType = 'Overlay Layer';

  useGetVectoLayerCategoryQuery({
    onSuccess: (res: any = {}) => {
      dispatch(
        setDropdownOptionsState({
          category: res.data.map((item: Record<string, any>) => ({
            name: item.name_en,
            alias: item.name_en,
          })),
        }),
      );
    },
  });

  const { mutate, isLoading } = useMutation<any, any, any, unknown>({
    mutationFn: updateVectorLayer,
    onSuccess: (res: Record<string, any>) => {
      if (res.data.message === 'success') {
        dispatch(setActiveIndex(activeIndex + 1));
      }
    },
    onError: () => {
      dispatch(toggleInfoDialog('error-on-form-submit'));
    },
  });

  useEffect(() => {
    dispatch(
      setDataManagementFormState({
        formState: overlayFormModal,
      }),
    );
  }, [dispatch, formType]);

  useEffect(() => {
    if (!layerDetails?.id) return;
    // eslint-disable-next-line
    const { category, display_name_en, description, is_public, file_upload } =
      layerDetails;
    setValue('category', category);
    setValue('display_name_en', display_name_en);
    setValue('description', description);
    setValue('is_public', is_public);
    setValue('file_upload', [file_upload]);
  }, [layerDetails, setValue]);

  // reset dataManagementForm state on unmount
  useEffect(() => {
    return () => {
      reset({});
    };
  }, [dispatch, reset, formState]);

  const handlePrevious = () => {
    dispatch(setActiveIndex(activeIndex - 1));
  };

  useEffect(() => {
    if (!formId) return;
    // @ts-ignore
    dispatch(getVectorLayerRequest(formId));
  }, [formId, dispatch]);

  const onSubmit = useCallback(
    (values: FormStateType) => {
      if (formId) {
        const { category, display_name_en, description, is_public } =
          layerDetails;
        return mutate({
          id: formId,
          category,
          display_name_en,
          description,
          is_public,
        });
      }
      return dispatch(
        // @ts-ignore
        postVectorLayerRequest({
          formData: {
            ...values,
            file_upload: values.file_upload[0].file,
          },
          activeIndex,
          navigate,
        }),
      );
    },
    [dispatch, navigate, activeIndex, formId, mutate, layerDetails],
  );

  const formProps = { register, control, errors, setValue, getValues };

  useEffect(() => {
    return () => {
      dispatch(setFormState(initialState));
      dispatch(setActiveIndex(0));
    };
  }, [dispatch]);

  return (
    <FlexColumn
      gap={2}
      className="relative h-screen w-screen animate-fade  px-4 py-2"
    >
      <FlexColumn className="sticky top-0 z-10 w-full">
        <FlexRow className="items-center justify-between">
          <h3 className="capitalize">
            {formMode === 'edit' ? `Edit ${formType}` : `Add ${formType}`}
          </h3>
          <Icon
            name="close"
            className="rounded-lg p-2 text-2xl hover:text-primary-600"
            onClick={() => navigate(-1)}
          />
        </FlexRow>
      </FlexColumn>

      <Flex className="flex items-center justify-center">
        <FlexColumn
          className={`${
            activeIndex === 0 ? 'w-full md:w-1/2 lg:w-2/5' : 'w-full'
          } rounded-lg border-2 bg-grey-50 p-5`}
        >
          {/* Progress Step Section */}
          <ProgressStep
            data={overlayDataFormIndex}
            activeIndex={activeIndex}
            stepIndex={stepIndex}
          />
          {isLayerDataFetching ? (
            <FlexColumn className="flex items-center justify-center gap-6 py-12">
              {Array.from({ length: 5 }).map((__, index) => (
                <Skeleton
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}-skleton`}
                  className="h-12 w-full"
                />
              ))}
              <Skeleton className="h-8 w-20" />
            </FlexColumn>
          ) : (
            <>
              {/* Form Content Section */}
              <FormSection
                schema={formSchema[overlayDataFormIndex[activeIndex]]}
                formProps={formProps}
                activeStep={overlayDataFormIndex[activeIndex]}
              />

              {/* Previous/Next/Apply Button Section */}
              {formSchema[overlayDataFormIndex[activeIndex]] !== 'map' && (
                <FlexRow className="justify-center gap-4 pt-3">
                  {!!activeIndex && (
                    <Button
                      variant="outline"
                      leftIcon="chevron_left"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      handleSubmit(onSubmit)();
                    }}
                    withLoader
                    isLoading={isLayerPosting || isLoading}
                    rightIcon="chevron_right"
                  >
                    Next
                  </Button>
                </FlexRow>
              )}
            </>
          )}
        </FlexColumn>
      </Flex>
    </FlexColumn>
  );
}

export default MultiStepForm;
