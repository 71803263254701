/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import { api } from '.';

export const getRtmData = async (
  params: Record<string, any>,
): Promise<AxiosResponse<any, any>> => {
  const response = await api.get(
    'https://weather.naxa.com.np/weather/river-watch-all',
    {
      params,
    },
  );
  return {
    data: response.data.map((res: Record<string, any>) => {
      const { _source, ...props } = res;
      return { ...props, ..._source };
    }),
  } as AxiosResponse;
};

export const getHydrologyData = async (
  params: Record<string, any>,
): Promise<AxiosResponse<any, any>> => {
  const response = await api.get(
    'https://weather.naxa.com.np/weather/hydrology-all',
    {
      params,
    },
  );
  return {
    data: response.data.map((res: Record<string, any>) => {
      const { _source, ...props } = res;
      return { ...props, ..._source };
    }),
  } as AxiosResponse;
};
