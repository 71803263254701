import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setVisualizationOptions } from '@Store/actions/visualization';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Flex from '@Components/common/Layouts/Flex';

interface dataProps {
  id: string;
  label: string;
  icon: string;
  iconClassName: string;
  onChange?: () => void;
}

function VisualizationOptions() {
  const dispatch = useTypedDispatch();
  const selectedOption = useTypedSelector(
    state => state.visualization.visualizationOptions.selectedOption,
  );
  const data: dataProps[] = [
    {
      id: 'risk',
      label: 'Risk',
      icon: 'warning',
      iconClassName: 'material-icons',
      onChange: () => {
        dispatch(setVisualizationOptions({ selectedOption: 'risk' }));
      },
    },
    {
      id: 'vulnerability',
      label: 'Vulnerability',
      icon: 'gpp_maybe',
      iconClassName: 'material-icons',
      onChange: () => {
        dispatch(setVisualizationOptions({ selectedOption: 'vulnerability' }));
      },
    },
  ];

  return (
    <FlexColumn className="px-3">
      <FlexRow className="h-11 items-center">
        <h5>Select Visualization Options</h5>
      </FlexRow>

      <FlexColumn gap={5}>
        <FlexRow>
          {data.map(({ id, label, icon, iconClassName, onChange }) => (
            <FlexColumn
              className={`w-full cursor-pointer items-center 
              pb-2 pt-4 hover:bg-primary-50 ${
                selectedOption === id ? 'bg-primary-50' : ''
              }`}
              gap={0.5}
              key={id}
              onClick={onChange}
            >
              <Flex className="w-full items-end justify-end px-2">
                <input
                  type="radio"
                  name={label}
                  className=" cursor-pointer accent-primary-600"
                  checked={id === selectedOption}
                  onChange={() => {}}
                />
              </Flex>
              <Icon
                className={` ${iconClassName} h-7 text-[2rem] text-primary-800`}
                name={icon}
              />
              <p className="text-xs font-bold text-primary-800">{label}</p>
            </FlexColumn>
          ))}
        </FlexRow>
      </FlexColumn>
      {selectedOption === 'risk' && (
        <FlexColumn className="gap-1 p-3">
          <FlexRow
            className={`cursor-pointer items-start gap-1.5 rounded-md border px-1 py-2 hover:bg-primary-50 ${
              selectedOption === 'risk' ? 'border-primary-600' : 'border-white'
            }`}
          >
            <input
              type="radio"
              className=" mt-0.5 h-4 w-4 cursor-pointer accent-primary-600"
              checked={selectedOption === 'risk'}
              onChange={() => {}}
            />
            <FlexColumn>
              <FlexRow className="items-center gap-1">
                <p className="text-[14px] font-semibold leading-[18px] text-grey-800">
                  Risk 1
                </p>
                <Icon
                  className="material-icons-outlined !text-[16px] text-grey-400"
                  name="info"
                />
              </FlexRow>
              <p className="text-[13px] font-normal leading-[18px]">
                The first pilot of the project tool was conducted here.
              </p>
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
      )}
    </FlexColumn>
  );
}

export default VisualizationOptions;
