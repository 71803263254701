/* eslint-disable import/prefer-default-export */
import { api, authenticated, hwcwAPI, userAPI } from '.';

export const postHazardCalendar = (data: any) =>
  authenticated(api).post('dastaa/hazards_calender/hazard_data', data);

export const getUserDetails = (siteId: any) =>
  authenticated(api).get(`/dastaa/sites_data/user-details/${siteId}`);

export const getRiskValues = (siteId: any) =>
  authenticated(api).get(`/dastaa/sites_data/theme-value/${siteId}`);

// export const getHazardSingleRisk = () =>
// authenticated(api).get('dastaa/hazard/single-risk/');

export const getHazardSingleRisk = () =>
  authenticated(api).get('dastaa/sites_data/user-hdrmp');

export const getMoreHouseInfo = () =>
  authenticated(api).get('dastaa/sites_data/more-info-of-house');

// apis for summary chart
export const getSummaryChartsRiskList = () =>
  api.get('dastaa/risk_of_sites/select-risk');

export const getSummaryChartsVulnerabilityList = () =>
  api.get('dastaa/risk_of_sites/select-vuln');

export const getPublicSummaryChartData = (params: any) =>
  api.get('dastaa/risk_of_sites/metric-value', { params });

// apis for parameter visualization
export const getFrameworksList = () =>
  authenticated(api).get('dastaa/parameter_visualization/framework/');

export const getDimensionsList = () =>
  authenticated(api).get('dastaa/parameter_visualization/dimensions/');

export const postForComponentsList = (data: Record<string, any>) =>
  authenticated(api).post('dastaa/parameter_visualization/components/', data);

export const postForParametersList = (data: Record<string, any>) =>
  authenticated(api).post('dastaa/parameter_visualization/parameters/', data);

export const postForMetricsList = (data: Record<string, any>) =>
  authenticated(api).post('dastaa/parameter_visualization/metrics/', data);

// apis for weather data (weatherbit and windy)
export const getCurentPlaceWeatherData = (params: any) =>
  hwcwAPI.get(`/weather/current-temp-of-an-area`, { params });

export const getCurrentPlaceWeatherDataHourly = (params: any) =>
  hwcwAPI.get(`/weather/day-temp`, { params });

export const getCurentPlaceWeatherData7Days = (params: any) =>
  hwcwAPI.get(`/weather/seven-days-temp-of-an-area`, { params });

export const getCurrentPlaceWeatherDataWindy = (params: any) =>
  hwcwAPI.get(`/weather/current-temp-of-an-area-windy`, { params });

export const getCurrentPlaceWeatherDataWindyHourly = (params: any) =>
  hwcwAPI.get(`/weather/day-temp-windy`, { params });

export const getCurrentPlaceWeatherDataWindy7Days = (params: any) =>
  hwcwAPI.get(`/weather/seven-days-temp-of-an-area-windy`, { params });

// api for weatherstation data
export const getStationWaterLevelData = (params: any) =>
  hwcwAPI.get(`/weather/river-watch-data`, { params });

export const getAllCount = () =>
  authenticated(api).get('/dastaa/layers/all-count/');

export const getDisasterListData = (params: any) =>
  authenticated(api).get('/dastaa/disaster_data/list', { params });

export const getEmergencyContactsList = () =>
  authenticated(userAPI).get('/emergency-contacts');

export const getAllParameterList = (params: any) =>
  authenticated(api).get('/dastaa/parameter_visualization/all-parameters/', {
    params,
  });

export const getFloodRisk = (siteId: string) =>
  authenticated(api).get(`/dastaa/sites_tiles/single_family/${siteId}`);

export const getOverlayLayersIcon = () =>
  authenticated(api).get(`/dastaa/layers/vector-layer-category/`);

export const getWeatherData = (params: any) =>
  hwcwAPI.get('/weather-data', { params });
