import { useParams } from 'react-router-dom';
import { FlexColumn } from '@Components/common/Layouts';
import KnowMoreInformation from './KnowMoreInformation';
import EmergencyDetail from './EmergencyDetail';
import UserForm from './UserForm';

export default function Resources() {
  const { id } = useParams();

  const getContent = () => {
    switch (id) {
      case 'information':
        return <KnowMoreInformation />;
      case 'emergency-detail':
        return <EmergencyDetail />;
      case 'user-form':
        return <UserForm />;
      default:
        return <></>;
    }
  };

  return <FlexColumn>{getContent()}</FlexColumn>;
}
