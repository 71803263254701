/* eslint-disable camelcase */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  getIncidentSelectedFields,
  getSitesBoundaryBox,
  getSitesRegionBoundary,
  getSubmissionDetail,
  getTotalUser,
} from '@Services/common';

export const useGetOverallSitesBboxQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['sites-overall-bbox'],
    queryFn: getSitesBoundaryBox,
    select: (res: any) => res.data,
    ...queryOptions,
  });
};

export const useGetSitesRegionBoundaryQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['sites-region-boundary'],
    queryFn: getSitesRegionBoundary,
    ...queryOptions,
  });
};

export const useGetSubmissionDetailQuery = (siteId: string | undefined) => {
  return useQuery({
    queryKey: ['get-submission-detail', siteId],
    queryFn: () => getSubmissionDetail({ site_id: siteId }),
    select: res => res?.data,
    enabled: !!siteId,
  });
};

export const useGetTotalUserQuery = () => {
  return useQuery({
    queryKey: ['total-user'],
    queryFn: getTotalUser,
    select: res => res.data,
  });
};

export const useGetIncidentSelectedFields = () => {
  return useQuery({
    queryKey: ['incident-selected-fields'],
    queryFn: getIncidentSelectedFields,
    select: res => {
      const { data } = res;
      // @ts-expect-error -
      const hazard_types = data?.hazard_types?.map(type => ({
        id: type,
        value: type,
      }));
      // @ts-expect-error -
      const status_choices = data?.status_choices?.map(choice => ({
        id: choice,
        value: choice,
      }));

      return { hazard_types, status_choices };
    },
  });
};
