/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Person from '@Assets/images/person.svg';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  setSelectedCountryState,
  setSignUpCountryList,
  setSignUpDistrictList,
  setSignUpMunicipalityList,
} from '@Store/actions/authentication';
import Image from '@Components/RadixComponents/Image';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { UseFormPropsType } from '@Components/common/FormHelpers/types';
import {
  getSignUpFormDistrictList,
  getSignUpFormMunicipalityList,
  signUp,
  checkUserBeforeRegister,
} from '@Services/authentication';
import { useSignUpFormCountryListQuery } from '@Api/authentication';
import InfoDialog from '@Components/common/InfoDialog';
import FormSection from './FormSection';
import { signUpFormFields, signupFormFormIndex } from './signUpFormFields';

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const [activeIndex, setActiveIndex] = useState(0);
  const [errorInfo, setErrorInfo] = useState({ show: false, message: '' });
  const [previousPhoneNumber, setPreviousPhoneNumber] = useState('');
  const lastIndex = Object.keys(signUpFormFields).length - 1;

  const selectedCountry = useTypedSelector(
    state => state.authentication.signUpForm.selectedCountry,
  );

  const dropDownOptions = useTypedSelector(
    state => state.authentication.signUpForm.dropDownOptions,
  );

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setValue,
    getValues,
    watch,
    reset,
    control,
  } = useForm({ mode: 'all' });

  const formProps: UseFormPropsType = {
    register,
    control,
    setValue,
    watch,
    getValues,
    errors,
  };

  const watchPhoneNumber = watch('phone_no');
  const watchCountryField = watch('country');
  const watchDistrictField = watch('district');

  const defaultCountryNepal = watchPhoneNumber?.toString()?.startsWith('+977');

  const defaultCountryBangladesh = watchPhoneNumber
    ?.toString()
    ?.startsWith('+880');

  const { mutate: fetchDistricts } = useMutation(getSignUpFormDistrictList, {
    onSuccess: res => {
      const formattedData = res.data.map((item: Record<string, any>) => ({
        ...item,
        alias: item.name,
        name: item.id,
      }));
      dispatch(setSignUpDistrictList(formattedData));
    },
  });

  const { mutate: fetchMunicipalities } = useMutation(
    getSignUpFormMunicipalityList,
    {
      onSuccess: res => {
        const formattedData = res.data.map((item: Record<string, any>) => ({
          ...item,
          alias: item.name,
          name: item.id,
        }));

        dispatch(setSignUpMunicipalityList(formattedData));
      },
    },
  );

  const { mutate: postSignUpData, isLoading: isSignUpLoading } = useMutation(
    signUp,
    {
      onSuccess: () => {
        reset();
        toast.success('User Registered Successfully');
        navigate('/login');
      },
      onError: (err: any) => {
        setErrorInfo({
          show: true,
          message: err?.response?.data?.error || 'An error occurred',
        });
      },
    },
  );

  const { mutate: checkPhoneNumber } = useMutation(checkUserBeforeRegister, {
    onSuccess: () => {
      setErrorInfo({ show: false, message: '' });
      setActiveIndex(activeIndex + 1);
    },
    onError: (err: any) => {
      setErrorInfo({
        show: true,
        message: err?.response?.data?.error || 'An error occurred',
      });
    },
  });

  useSignUpFormCountryListQuery({
    select: (res: any) =>
      res.data.map((item: Record<string, any>) => ({
        ...item,
        alias: item.name,
        name: item.id,
      })),
    onSuccess: formattedData => {
      dispatch(setSignUpCountryList(formattedData));
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    if (activeIndex === 1) {
      const currentPhoneNumber = formData.phone_no.slice(4).replace(/\s/g, '');
      if (currentPhoneNumber !== previousPhoneNumber) {
        checkPhoneNumber({ phone_no: Number(currentPhoneNumber) });
        setPreviousPhoneNumber(currentPhoneNumber);
      } else {
        setActiveIndex(activeIndex + 1);
      }

      // set the default country to Nepal for nepali number
      if (defaultCountryNepal) {
        const nepalCountry = dropDownOptions?.country?.find(
          (item: Record<string, any>) => item.alias === 'Nepal',
        );
        if (nepalCountry) {
          dispatch(setSelectedCountryState(nepalCountry.id));
          setValue('country', nepalCountry);
        }
        // else reset country and district
      } else if (defaultCountryBangladesh) {
        const bangladeshCountry = dropDownOptions?.country?.find(
          (item: Record<string, any>) => item.alias === 'Bangladesh',
        );
        if (bangladeshCountry) {
          dispatch(setSelectedCountryState(bangladeshCountry.id));
          setValue('country', bangladeshCountry);
        }
      } else {
        dispatch(setSelectedCountryState(''));
        dispatch(setSignUpDistrictList([]));
        setValue('country', '');
      }
    } else if (activeIndex === lastIndex) {
      const finalFormData = {
        ...formData,
        security_question: { ...formData.security_question },
        phone_no: Number(formData.phone_no.slice(4).replace(/\s/g, '')),
      };
      postSignUpData(finalFormData);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const getButtonText = () => {
    if (activeIndex === lastIndex) return 'Sign Up';
    if (activeIndex) return 'Next';
    return 'Create New Account';
  };

  useEffect(() => {
    const countryId =
      (defaultCountryNepal || defaultCountryBangladesh
        ? selectedCountry
        : null) || watchCountryField;
    if (countryId) {
      fetchDistricts({ country_id: countryId });
    }
  }, [watchCountryField, defaultCountryNepal, selectedCountry, fetchDistricts]);

  useEffect(() => {
    if (watchDistrictField) {
      fetchMunicipalities({ district_id: watchDistrictField });
    }
  }, [watchDistrictField, fetchMunicipalities]);

  return (
    <FlexColumn gap={14} className="mx-auto w-4/5 lg:w-[360px]">
      <FlexColumn gap={3} className="naxatw grid w-full place-items-center">
        <Image src={Person} />
        <h3 className="font-semibold">Sign Up</h3>
      </FlexColumn>

      {errorInfo.show && (
        <InfoDialog
          status="error"
          className="mb-5 py-2"
          description={errorInfo.message}
          onClose={() => setErrorInfo({ show: false, message: '' })}
        />
      )}

      <FormSection
        schema={signUpFormFields[signupFormFormIndex[activeIndex]]}
        formProps={formProps}
      />

      <FlexColumn gap={2}>
        <FlexRow gap={4}>
          {activeIndex > 0 && (
            <Button
              className="w-full !p-5"
              onClick={() => setActiveIndex(activeIndex - 1)}
            >
              Previous
            </Button>
          )}
          <Button
            className="w-full !p-5"
            onClick={handleSubmit(onSubmit)}
            withLoader
            isLoading={isSignUpLoading || isSubmitting}
          >
            {getButtonText()}
          </Button>
        </FlexRow>
        <FlexRow gap={4} className="items-center justify-center">
          <p className="text-sm">Already have an account?</p>
          <Button
            variant="ghost"
            onClick={() => navigate('/login')}
            className="!p-0 !text-base"
          >
            Sign In
          </Button>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};

export default SignUp;
