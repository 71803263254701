/* eslint-disable import/prefer-default-export */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getSummaryChartsRiskList,
  getSummaryChartsVulnerabilityList,
  getHazardSingleRisk,
  getFrameworksList,
  getDimensionsList,
  getCurentPlaceWeatherData,
  getCurrentPlaceWeatherDataHourly,
  getCurentPlaceWeatherData7Days,
  getCurrentPlaceWeatherDataWindy,
  getCurrentPlaceWeatherDataWindyHourly,
  getCurrentPlaceWeatherDataWindy7Days,
  getAllCount,
  getStationWaterLevelData,
  getAllParameterList,
  getFloodRisk,
  getRiskValues,
  getMoreHouseInfo,
  getEmergencyContactsList,
  getUserDetails,
  getWeatherData,
} from '@Services/publicPage';

export const useGetSummaryChartsRiskTab = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['summary-charts-risk-tab-list'],
    queryFn: getSummaryChartsRiskList,
    ...queryOptions,
    select: res => res,
  });
};

export const useGetSummaryChartsVulnerabilityTab = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['summary-charts-vulnerability-tab-list'],
    queryFn: getSummaryChartsVulnerabilityList,
    ...queryOptions,
    select: res => res,
  });
};

export const useGetHazardSingleRiskQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['single-hazard-risk'],
    queryFn: getHazardSingleRisk,
    ...queryOptions,
    select: (res: any) => res?.data[0],
  });
};

export const useGetMoreHouseInfoQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['get-house-information'],
    queryFn: getMoreHouseInfo,
    ...queryOptions,
    select: (res: any) => res?.data,
  });
};

export const useGetFrameworksListQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['framework-list'],
    queryFn: getFrameworksList,
    ...queryOptions,
    select: (res: any) => res.data,
  });
};

export const useGetDimensionsListQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['dimensions-list'],
    queryFn: getDimensionsList,
    ...queryOptions,
    select: (res: any) => {
      const modifiedResponse = res.data.map((item: Record<string, any>) => ({
        id: item.id,
        label: item.name,
        value: item.name,
      }));
      return modifiedResponse;
    },
  });
};

export const useGetCurrentPlaceWeatherQuery = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-current-data-weatherapi', params],
    queryFn: () => getCurentPlaceWeatherData(params),
    select: (response: any) => response.data[0],
    ...queryOptions,
  });
};

export const useGetCurrentPlaceWeatherQueryWindy = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-current-data-windy', params],
    queryFn: () => getCurrentPlaceWeatherDataWindy(params),
    select: (response: any) => response.data[0],
    ...queryOptions,
  });
};

export const useGetCurrentPlaceWeatherWindyQuery = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-current-data-windy', params],
    queryFn: () => getCurrentPlaceWeatherDataWindy(params),
    select: (response: any) => response.data[0],
    ...queryOptions,
  });
};

export const useGetCurrentPlaceWeatherHourlyQuery = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-hourly-data-weatherapi', params],
    queryFn: () => getCurrentPlaceWeatherDataHourly(params),
    select: (res: any) => {
      const modifiedResponse: Record<string, any>[] = res.data.map(
        (item: any, index: number) => ({
          id: index + 1,
          date: item.current.time,
          temp_max: item.current.feelslike_c,
          temp_min: item.current.feelslike_c,
          icon: item.current.condition.icon,
          humidity: item.current.humidity,
          rain: item.current.precip_mm,
        }),
      );
      return modifiedResponse;
    },
    ...queryOptions,
  });
};

export const useGetCurrentPlaceWeatherHourlyWindyQuery = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-hourly-data-windy', params],
    queryFn: () => getCurrentPlaceWeatherDataWindyHourly(params),
    select: (res: any) => {
      const modifiedResponse = res?.data?.map((item: any, index: number) => ({
        id: index + 1,
        date: item.local_time,
        temp_max: item.temp,
        temp_min: item.temp,
        icon: item.icon,
        humidity: item.relative_humidity,
        rain: item.precip,
      }));
      return modifiedResponse;
    },
    ...queryOptions,
  });
};

export const useGetCurrentPlaceWeather7DaysQuery = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-7days-data-weatherapi', params],
    queryFn: () => getCurentPlaceWeatherData7Days(params),
    select: (res: any) => {
      const modifiedResponse: Record<string, any>[] = res.data.map(
        (
          item: {
            id: number;
            icon: any;
            key_as_string: string;
            temp_c: Record<string, any>;
            feelslike_c: Record<string, any>;
            precip_mm: Record<string, any>;
          },
          index: number,
        ) => ({
          id: index + 1,
          icon: item.icon,
          temp: item.temp_c.value,
          date: item.key_as_string,
          temp_max: item.feelslike_c.value,
          temp_min: item.feelslike_c.value,
          rain: item.precip_mm.value,
        }),
      );
      return modifiedResponse;
    },
    ...queryOptions,
  });
};

export const useGetCurrentPlaceWeather7DaysWindyQuery = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-7days-data-windy', params],
    queryFn: () => getCurrentPlaceWeatherDataWindy7Days(params),
    select: (res: any) => {
      const modifiedResponse: Record<string, any>[] = res.data.map(
        (
          item: {
            id: number;
            icon: any;
            key_as_string: string;
            temp_c: Record<string, any>;
            feelslike_c: Record<string, any>;
            avg_precip: Record<string, any>;
          },
          index: number,
        ) => ({
          id: index + 1,
          icon: item.icon,
          temp: item.temp_c.value,
          date: item.key_as_string,
          temp_max: item.temp_c.value,
          temp_min: item.temp_c.value,
          rain: item.avg_precip.value,
        }),
      );
      return modifiedResponse;
    },
    ...queryOptions,
  });
};

export const useGetWeatherStationsWaterLevelQuery = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-weather-stations-data', params],
    queryFn: () => getStationWaterLevelData(params),
    select: (res: any) => {
      const modifiedResponse = res.data.map((item: Record<string, any>) => ({
        id: item.id,
        station_name: item.description,
        water_level: item.waterLevel.value,
        warning_level: item.warning_level,
        danger_level: item.danger_level,
        latitude: item.latitude,
        longitude: item.longitude,
        status: item.status,
      }));
      return modifiedResponse;
    },
    ...queryOptions,
  });
};

export const useGetAllCountQuery = () => {
  return useQuery({
    queryKey: ['all-count'],
    queryFn: getAllCount,
    select: res => res.data,
  });
};

export const useGetAllParameterListQuery = (params?: any) => {
  return useQuery({
    queryKey: ['all-parameter-list', params],
    queryFn: () => getAllParameterList({ search: params }),
    select: res => res.data,
  });
};

export const useGetRiskValuesQuery = (
  siteId: string,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['risk-data', siteId],
    queryFn: () => getRiskValues(siteId),
    select: (res: any) => res?.data,
    enabled: !!siteId,
    ...queryOptions,
  });
};

export const useGetFloodRiskQuery = (siteId: string) => {
  return useQuery({
    queryKey: ['get-flood-risk', siteId],
    queryFn: () => getFloodRisk(siteId),
    select: res => res.data.value,
    enabled: !!siteId,
  });
};

export const useGetEmergencyContactsListQuery = () => {
  return useQuery({
    queryKey: ['emergency-contacts'],
    queryFn: getEmergencyContactsList,
    select: res => res.data,
  });
};

export const useGetUserDetailsQuery = (siteId: string) => {
  return useQuery({
    queryKey: ['user-details', siteId],
    queryFn: () => getUserDetails(siteId),
    select: res => res.data,
    enabled: !!siteId,
  });
};

export const useGetWeatherDataQuery = (
  params: Record<string, any>,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['weather-data'],
    queryFn: () => getWeatherData(params),
    select: (res: any) => res.data,
    ...queryOptions,
  });
};
