import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface DashboardState {
  fetchedChartIds: string[];
}

const initialState: DashboardState = {
  fetchedChartIds: [], // for fetching n API at a time
};

const handleChartAPISuccess: CaseReducer<
  DashboardState,
  PayloadAction<string>
> = (state, action) => {
  state.fetchedChartIds.push(action.payload);
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    handleChartAPISuccess,
  },
});

export { dashboardSlice };

export default dashboardSlice.reducer;
