import { cn } from '@Utils/index';

interface IChartWrapperProps {
  className?: string;
  children: any;
  componentRef?: React.RefObject<any>;
  yLabel?: string;
  yLabelRight?: string;
}

export default function ChartWrapper({
  className,
  children,
  componentRef,
  yLabel,
  yLabelRight,
}: IChartWrapperProps) {
  return (
    <div
      ref={componentRef}
      className={cn(
        'relative grid h-full w-full grid-cols-12 grid-rows-[auto] rounded-xl border-[0.5px] bg-grey-50 px-4 py-2',
        className,
      )}
    >
      {yLabel ? (
        <div className="y-label absolute  left-0 top-[50%] -translate-y-2/4">
          <p
            className="-scale-100 whitespace-nowrap px-2 text-xs"
            style={{ writingMode: 'vertical-rl' }}
          >
            {yLabel}
          </p>
        </div>
      ) : null}

      {yLabelRight ? (
        <div className="y-label absolute right-[-15px] top-0 flex h-full w-12 items-center justify-end">
          <p className="origin-center -rotate-[-90deg] whitespace-nowrap text-xs">
            {yLabelRight}
          </p>
        </div>
      ) : null}

      <div className="col-span-12 flex h-full flex-col">{children}</div>
    </div>
  );
}
