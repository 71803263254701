import {
  AnyAction,
  CombinedState,
  combineReducers,
  Reducer,
} from '@reduxjs/toolkit';
import users, { UserState } from '@Store/slices/users';
import publicPage, { PublicPageState } from '@Store/slices/publicPage';
import authentication, {
  AuthenticationState,
} from '@Store/slices/authentication';
import overlayLayer, { OverlayLayersState } from '@Store/slices/overlayLayers';
import triggerMessage, {
  TriggerMessageState,
} from '@Store/slices/triggerMessage';
import notification, { NotificationState } from '@Store/slices/notification';
import rtm, { RTMState } from '@Store/slices/realTimeMonitering';
import common, { CommonState } from '../slices/common';
import loader, { LoaderState } from '../slices/loader';
import visualization, { VisualizationState } from '../slices/visualization';
import mapExport, { MapExportState } from '../slices/mapExport';
import dashboard, { DashboardState } from '../slices/dashboard';
import dataManagementForm, {
  DataManagementFormState,
} from '../slices/dataManagementForm';

export interface IRootReducer {
  common: CommonState;
  loader: LoaderState;
  visualization: VisualizationState;
  mapExport: MapExportState;
  users: UserState;
  publicPage: PublicPageState;
  authentication: AuthenticationState;
  dashboard: DashboardState;
  overlayLayer: OverlayLayersState;
  dataManagementForm: DataManagementFormState;
  triggerMessage: TriggerMessageState;
  notification: NotificationState;
  rtm: RTMState;
}

const rootReducer: Reducer<
  CombinedState<IRootReducer>,
  AnyAction
> = combineReducers({
  common,
  loader,
  visualization,
  mapExport,
  users,
  publicPage,
  authentication,
  dashboard,
  overlayLayer,
  dataManagementForm,
  triggerMessage,
  notification,
  rtm,
});

export default rootReducer;
