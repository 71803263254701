/* eslint-disable import/prefer-default-export */
import { getVectoLayerCategory } from '@Services/overlayLayers';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

export const useGetVectoLayerCategoryQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): any => {
  return useQuery({
    queryKey: ['vector-layer-category'],
    queryFn: getVectoLayerCategory,
    ...queryOptions,
  });
};
