import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { FormControl, Input, Label } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import TextArea from '@Components/common/FormUI/TextArea';
import { postTriggerMessageTemplate } from '@Services/triggerMessage';
import { useTypedDispatch } from '@Store/hooks';
import { AxiosResponse } from 'axios';
import { setTriggerMessageState } from '@Store/actions/triggerMessage';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';

interface IInitialState {
  alert_name: string;
  description: string;
}

const initialState: IInitialState = {
  alert_name: '',
  description: '',
};

export default function AddAlertTemplate() {
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });

  const { mutate, isLoading } = useMutation<
    AxiosResponse<any, any>,
    unknown,
    IInitialState
  >({
    mutationFn: postTriggerMessageTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trigger-message-template'] });
      dispatch(
        setTriggerMessageState({
          templateType: 'send-notifcation',
        }),
      );
    },
  });

  const onSubmit = (data: any) => {
    mutate(data);
  };

  return (
    <FlexColumn className="w-full lg:w-1/2">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        {/* alert name */}
        <FormControl>
          <Label htmlFor="alert_name" required>
            Message Type
          </Label>
          <Input
            type="text"
            placeholder="Alert Name"
            {...register('alert_name', { required: 'Required' })}
          />
          <ErrorMessage message={errors?.alert_name?.message || ''} />
        </FormControl>

        {/* message */}
        <FormControl className="gap-2">
          <Label htmlFor="description">Description</Label>
          <TextArea
            rows={5}
            className="border border-grey-800"
            {...register('description')}
          />
        </FormControl>
        <FlexRow>
          <Button type="submit" withLoader isLoading={isLoading}>
            Save
          </Button>
        </FlexRow>
      </form>
    </FlexColumn>
  );
}
